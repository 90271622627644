import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-popover-warning',
  templateUrl: './popover-warning.component.html',
  styleUrls: ['./popover-warning.component.scss'],
})

export class PopoverWarningComponent implements OnInit {

  @Input() openedWarningMessage: boolean = false;
  @Output() modalClosed1 = new EventEmitter<void>();

  constructor(private closeModal: OpenModalService) { }
  ngOnInit(): void { }

  close() {
    this.modalClosed1.emit();
    this.closeModal.closePopover();
    Swal.fire({
      icon: 'success',
      title: 'Modificato!',
      text: 'Ordine aggiornato con successo!!!',
      timer: 1500,
      timerProgressBar: true,
    });
  }

}

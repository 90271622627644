export namespace Printing {

  export interface IPrinterOption {
    printer_bor: boolean;
    printer_box: boolean;
    printer_car: boolean;
    printer_ddt: boolean;
    printer_desc: string;
    printer_id: number;
  }

  export interface IPrinterOptionSelection {
    getPrintersSelections(printers: PrinterOption[]);
    printer_bor_id: number;
    printer_box_id: number;
    printer_car_id: number;
    printer_ddt_id: number;
  }

  export class PrinterOption implements IPrinterOption {
    printer_bor: boolean;
    printer_box: boolean;
    printer_car: boolean;
    printer_ddt: boolean;
    printer_desc: string;
    printer_id: number;

    constructor(printer:IPrinterOption) {
      if(!printer) return;
      this.printer_bor = printer.printer_bor;
      this.printer_box = printer.printer_box;
      this.printer_car = printer.printer_car;
      this.printer_ddt = printer.printer_ddt;
      this.printer_desc = printer.printer_desc;
      this.printer_id = printer.printer_id;
    }
    
  }

  export class PrinterOptionSelection implements IPrinterOptionSelection {    
    printer_bor_id: number;
    printer_box_id: number;
    printer_car_id: number;
    printer_ddt_id: number;

    constructor(printer:IPrinterOptionSelection) {
      if(!printer) return;
      this.printer_bor_id = printer.printer_bor_id;
      this.printer_box_id = printer.printer_box_id;
      this.printer_car_id = printer.printer_car_id;
      this.printer_ddt_id = printer.printer_ddt_id;
    }

    getPrintersSelections(printers:PrinterOption[]) {
      if(!printers) return;
      printers.map((item)=>{
        if(item.printer_bor === true)
          this.printer_bor_id = item.printer_id;
        if(item.printer_box === true)
          this.printer_box_id = item.printer_id;
        if(item.printer_car === true)
          this.printer_car_id = item.printer_id;
        if(item.printer_ddt === true)
          this.printer_ddt_id = item.printer_id;
        return item;
      })
    }    
  }

}

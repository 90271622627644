import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LabelSettings } from '@progress/kendo-angular-progressbar';
import { Observable, Subscription } from 'rxjs';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Orders } from 'src/app/models/Orders.model';
import { IWsResult } from 'src/app/models/ws_result.model';
import { OrdersService } from 'src/app/services/orders.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import { AuthService } from 'src/app/services/auth.service';
import * as dateFns from 'date-fns';
import { Objectives } from 'src/app/models/Orders.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-warehouse-orders',
  templateUrl: './warehouse-orders.component.html',
  styleUrls: ['./warehouse-orders.component.scss'],
})

export class WarehouseOrdersComponent {

  public gridView: GridDataResult;
  public today = new Date();

  public setPercentLabel: LabelSettings = {
    visible: true,
    format: 'percent',
    position: 'center',
  };

  public label: LabelSettings = {
    visible: true,
    format: 'percent',
    position: 'center',
  };

  public label_open: LabelSettings = {
    visible: true,
    format: 'percent',
    position: 'end',
  };

  linkURL = environment.production
    ? 'http://salvatempo.livith.it:88/warehouseorders'
    : 'http://salvatempo.connecta.ovh/warehouseorders';
  openOrders: Orders.IOrderOpenList[] = [];
  closedOrders: Orders.IOrderClosed[] = [];
  myDate = new Date();
  setDate: string;
  currentGoalNumber: number;
  currentNumber: 20;
  status_perc: number = 100;
  complete_state: string = 'Complete';
  ipAddress = '127.0.0.1';
  screenUser: string;
  screenPass: string;
  clickedId: any;
  timer = null;
  refreshInterval: number = 60000;
  searchText: string = '';
  filterTerm: string | undefined;
  progressValue: number;
  obiettivi: Objectives;
  objectives: Objectives = new Objectives(null);
  timerSubscription: Subscription;

  constructor(
    private openOrdersServ: OrdersService,
    private router: Router,
    public openModal: OpenModalService,
    public authServ: AuthService,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.getOpenOrders();
    this.getCloseOrders();
    this.getObjectives();
    this.getfirstObjectives();
    clearTimeout(this.timer);
    this.timerSubscription = this.getConfig().subscribe((config) => {
      this.refreshInterval = config.warehouseTimer;
      this.timer = setInterval(() => {
        this.getOpenOrders();
        this.getCloseOrders();
        this.getObjectives();
        this.getfirstObjectives();
      }, this.refreshInterval);
    });
    this.openOrdersServ.getObjectives().subscribe((obj: Objectives) => {
      this.obiettivi = obj;
    });
  }

  ngOnDestroy() {
    clearTimeout(this.timer);
  }

  getObjectives() {
    this.openOrdersServ.getObjectiveProgress().subscribe((data: any) => {
      console.log('Data: ', data);
    });
  }

  getfirstObjectives() {
    this.openOrdersServ.getObjectives().subscribe((Data: any) => {
      console.log('Data second: ', Data);
    });
  }

  getConfig(): Observable<any> {
    return this.http.get<any>('/assets/config.json');
  }

  getCloseOrders() {
    this.openOrdersServ.getClosedOrders().subscribe((ordersData) => {
      this.closedOrders = ordersData;
      console.log('ORders data is', ordersData);
      this.closedOrders.map((perc) => {
        perc.status_perc = this.status_perc;
        console.log('Status perc is: ', perc.status_perc);
      });
      this.closedOrders.forEach((status) => {
        status.complete_state = this.complete_state;
        console.log('State is: ', status.complete_state);
      });
      console.log('closed length: ', this.closedOrders);
      this.closedOrders = ordersData.filter((order) => {
        return dateFns.isSameDay(
          dateFns.parseISO(order.or_closed_date),
          this.today
        );
      });
    });
  }

  getOpenOrders() {
    this.openOrdersServ
      .getOpenOrders()
      .subscribe((ordersData: Orders.IOrderOpenList[]) => {
        this.openOrders = ordersData;
        console.log('CurrentNumber is: ', this.openOrders.length);
        this.getGoalNumber();
        console.debug('Data is: ', this.openOrders);
      });
  }

  getGoalNumber() {
    this.currentGoalNumber = this.openOrders.length - 20;
    console.log('CurrentGoal: ', this.currentGoalNumber);
  }

  gotoDetails(order_id: number) {
    this.router.navigate(['/prepare-order', order_id]);
  }

  searchOrders() {
    if (!this.filterTerm || this.filterTerm == '') {
      this.openOrdersServ
        .searchNewOrders(this.filterTerm)
        .subscribe((openOrdersData: IWsResult) => {
          this.gridView = openOrdersData.data;
        });
      setTimeout(() => {}, 1000);
    }
    this.openOrdersServ
      .searchNewOrders(this.filterTerm)
      .subscribe((openOrdersData: IWsResult) => {
        this.gridView = openOrdersData.data;
      });
  }
  
}

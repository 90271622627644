import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class SearchServiceService {

  searchForInfo: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  searchOrdersInfo: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  passInfoValue(search: string) {
    this.searchForInfo.next(search);
    console.log('Passed value is', search, this.searchForInfo.value);
  }

  passOrderInfoValue(search: string) {
    this.searchOrdersInfo.next(search);
    console.log('Passed info orders value', this.searchOrdersInfo.value);
  }

  constructor() { }

}

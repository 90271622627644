import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { NgbdPopoverAutoclose } from './app/components/shared/order-details/add-box/box-details/popover-autoclose';

import { environment } from './environments/environment';
// bootstrapApplication(NgbdPopoverAutoclose)
//   .then((ref) => {
//     // Ensure Angular destroys itself on hot reloads.
//     if (window['ngRef']) {
//       window['ngRef'].destroy();
//     }
//     window['ngRef'] = ref;

//     // Otherwise, log the boot error
//   })
//   .catch((err) => console.error(err));
if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = function () {};
  }

  if (window) {
    window.console.debug = function () {};
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

<div class="mainContainer k-display-flex k-w-full">
  <div
    class="ConversationContainer"
    style="width: 400px; overflow: auto; height: 107vh"
  >
    <mat-accordion class="conversation-container">
      <div
        *ngFor="let message of messages"
        style="
          cursor: pointer;
          text-align: center;
          background-color: rgb(226, 77, 77);
          border-radius: 8px;
          border: 1px solid #fff;
        "
      >
        <mat-expansion-panel-header>
          <h1
            class="header-content"
            style="
              padding: 5px;
              background: #526cfe;
              color: #fff;
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
            "
            (click)="enterConversation(message)"
          >
            {{ message.ms_sender_name }}
            <i class="material-icons">{{
              isExpanded(message) ? "expand_less" : "expand_more"
            }}</i>
          </h1>
        </mat-expansion-panel-header>
        <div class="message-content" *ngIf="selectedMessage === message">
          <p>{{ message.ms_message }}</p>
          <p class="time">{{ message.ms_datetime | date : "short" }}</p>
        </div>
      </div>
    </mat-accordion>

    <mat-accordion class="conversation-container">
      <div
        *ngFor="let message of conversations; let i = index"
        style="
          cursor: pointer;
          text-align: center;
          background-color: rgb(226, 77, 77);
          border-radius: 8px;
          border: 1px solid #fff;
        "
      >
        <mat-expansion-panel-header>
          <h1
            class="header-content"
            style="
              padding: 5px;
              background: #526cfe;
              color: #fff;
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
            "
            (click)="enterGroupChat(message)"
          >
            {{ message.ms_order_code }}
            <i class="material-icons">{{
              isExpanded(message) ? "expand_less" : "expand_more"
            }}</i>
          </h1>
        </mat-expansion-panel-header>
        <div class="message-content" *ngIf="selectedMessage === message">
          <p>{{ message.ms_message }}</p>
          <p class="time">{{ message.ms_datetime | date : "short" }}</p>
        </div>
      </div>
    </mat-accordion>
  </div>

  <div
    class="chat-container"
    style="
      background-color: rgb(81, 81, 193);
      height: 100%;
      color: #fff;
      width: 100%;
      border-radius: 8px;
    "
  >
    <ion-header>
      <ion-toolbar color="light">
        <ion-buttons slot="start" style="padding-left: 5px">
          <ion-fab (click)="subscribeNotifications()">
            <ion-fab-button size="small">
              <i class="fas fa-bell"></i>
            </ion-fab-button>
          </ion-fab>
        </ion-buttons>

        <p
          class="header-info"
          style="
            padding-top: 0;
            padding-bottom: 0;
            margin-bottom: 0;
            margin-left: 10px;
            font-size: 20px;
            text-align: center;
          "
          *ngIf="selectedConversation"
        >
          {{
            selectedConversation
              ? (message.ms_recipient_id === this.account.account_id
                  ? "I tuoi messaggi: "
                  : "Attualmente in chat con ") +
                selectedConversation.ms_sender_name
              : ""
          }}
        </p>
      </ion-toolbar>
    </ion-header>

    <ion-content
      class="ion-padding"
      scrollEvents="true"
      (ionScroll)="contentScrolled($event)"
      style="
        height: 87vh;
        --background: url('assets/img/smol.jpg');
        background-size: cover;
      "
      *ngIf="notification"
    >
      <ion-infinite-scroll
        position="top"
        (ionInfinite)="loadMore($event)"
        *ngIf="notification?.messages"
        [disabled]="this.notificationsLimit >= notification?.messages.length"
      >
        <ion-infinite-scroll-content
          loadingSpinner="bubbles"
          loadingText="Loading more data..."
        >
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
      <ion-row
        *ngFor="let message of limitedNotifications"
        style="margin-left: 2px; margin-right: 7px"
      >
        <ion-col
          size="10"
          *ngIf="message.ms_recipient_id !== this.account.account_id"
          class="message other-message"
          style="
            flex: 0 0 calc(calc(10 / var(--ion-grid-columns, 12)) * 100%);
            width: calc(calc(10 / var(--ion-grid-columns, 12)) * 100%);
            max-width: calc(calc(10 / var(--ion-grid-columns, 12)) * 100%);
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          "
        >
          <span class="msg other-text">{{ message.ms_message }}</span>
          <div class="time" text-right>
            <br />{{ message.ms_datetime | date : "short" }}
          </div>
        </ion-col>

        <ion-col
          offset="2"
          size="10"
          *ngIf="message.ms_recipient_id === this.account.account_id"
          class="message my-message"
          style="
            margin-left: calc(calc(2 / var(--ion-grid-columns, 13)) * 100%);
            flex: 0 0 calc(calc(10 / var(--ion-grid-columns, 12)) * 100%);
            width: calc(calc(10 / var(--ion-grid-columns, 12)) * 100%);
            max-width: calc(calc(10 / var(--ion-grid-columns, 12)) * 100%);
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          "
        >
          <span class="msg my-text">{{ message.ms_message }} </span>
          <div class="time ion-text-right">
            <br />{{ message.ms_datetime | date : "short" }}
          </div>
        </ion-col>
      </ion-row>

      <ion-fab
        vertical="bottom"
        horizontal="end"
        slot="fixed"
        *ngIf="scrollPercentage < 0.7 && scrollPercentage > 0"
      >
        <ion-fab-button (click)="scrollDown()">
          <ion-icon name="chevron-down-circle"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </ion-content>

    <ion-footer>
      <ion-toolbar color="light">
        <ion-row
          class="ion-align-items-center k-display-flex"
          style="flex-direction: row; justify-content: end"
        >
          <ion-col size="11">
            <ion-textarea
              class="message-input"
              #input
              rows="1"
              autoGrow="true"
              maxlength="500"
              [(ngModel)]="message.ms_title"
              (keydown.enter)="sendMessage()"
            ></ion-textarea>
          </ion-col>
          <ion-col size="auto" text-center>
            <ion-button
              expand="block"
              fill="clear"
              color="primary"
              class="msg-btn"
              (click)="sendMessage()"
              style="margin-top: 5px; color: #fff"
            >
              <ion-icon name="send" slot="icon-only"></ion-icon>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-toolbar>
    </ion-footer>
  </div>
</div>

import { formatDate } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ICarrier } from 'src/app/models/ICarrier.model';
import { IWorker } from 'src/app/models/IWorker.model';
import { Orders } from 'src/app/models/Orders.model';
import { Priority } from 'src/app/models/Priority.model';
import { NewProduct, Products } from 'src/app/models/Product.model';
import { IWsResult } from 'src/app/models/ws_result.model';
import { OrdersService } from 'src/app/services/orders.service';
import { ProductsService } from 'src/app/services/products.service';
import { DropdownService } from 'src/app/services/shared/dropdown.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';

@Component({
  selector: 'app-ordini-da-firmare-detaggli',
  templateUrl: './ordini-da-firmare-detaggli.component.html',
  styleUrls: ['./ordini-da-firmare-detaggli.component.scss'],
})

export class OrdiniDaFirmareDetaggliComponent {

  @Input() openedFIRMARE = false;
  @Input() order_id: string = '-1';

  public orders: Orders.OrderList[] = [];
  public order: Orders.IOrder;
  public dataSaved = false;
  public orderNew: Orders.IOrderNew;
  public productsNew: Products.IProductToSend[] = [];
  public products: Products.Product[] = [];
  public orderToSend: Orders.IOrderToSend;
  public formattedDate: string = '-';
  public priorityOptions: Priority.IPriority[] = [];
  public carriers: ICarrier[] = [];
  public workers: IWorker[] = [];
  public product: NewProduct[] = [];
  public maxlength = 250;
  public minWidth = 250;
  public dialogWidth = 1056;
  public dialogHeight = 1017;
  assignOrderForm!: FormGroup;

  constructor(
    private activatedRoute: ActivatedRoute,
    private ordersServ: OrdersService,
    private productServ: ProductsService,
    private route: ActivatedRoute,
    private dropdownServ: DropdownService,
    private fb: FormBuilder,
    private formBuilder: FormBuilder,
    private router: Router,
    public closeServ: OpenModalService 
  ) { }

  ngOnInit(): void {
    this.reloadFunction();
  }

  reloadFunction() {
    this.assignOrderForm = this.formBuilder.group({
      or_order_number: [''],
      or_order_date: [''],
      or_assigned_date: [''],
      or_order_priority_cod: ['', Validators.required],
      carrier_id: ['', Validators.required],
      or_customer: [''],
      carrier_desc: [''],
      or_shipping_address: [''],
      or_shipping_zip: [''],
      or_shipping_city: [''],
      or_shipping_prov: [''],
      or_shipping_country: [''],
      or_assigned_id: ['', Validators.required],
      op_code: [''],
    });
    this.ordersServ
      .getOrder(this.order_id)
      .subscribe((orderData: IWsResult) => {
        this.order = orderData.data;
        this.products = this.order.products;
        console.debug('This order neprocesat: ', this.order);
        this.order = new Orders.Order(orderData.data);
        console.debug('This order procesat: ', this.order);
        this.productsNew = this.order.products.map((item) => {
          let product: Products.IProductToSend = {
            op_note: '',
            op_checked: false,
            op_code: item.op_code,
            op_description: item.op_description,
            op_qta: item.op_qta,
            op_weigth: item.op_weigth,
            op_stock: item.op_stock,
            orQtaNeeded: +item.op_qta,
            op_qta_ordered: '',
            op_positions: [],
            tab_order_status_cod: []
          };
          return product;
        });
        this.dropdownServ
          .getPriorities()
          .subscribe((priorityData: Priority.IPriority[]) => {
            this.priorityOptions = priorityData;
          });
        if (this.order.or_order_date)
          this.formattedDate = formatDate(
            this.order.or_order_date,
            'dd/MM/yyyy',
            'it'
          );
        this.assignOrderForm.patchValue({
          or_order_number: this.order.or_order_number,
          or_order_date: this.formattedDate,
          or_order_priority_cod: 'Select',
          carrier_id: 'random text',
          or_customer: this.order.or_customer,
          carrier_desc: this.order.vs_details.carrier_desc,
          or_shipping_address: this.order.or_shipping_address,
          or_shipping_zip: this.order.or_shipping_zip,
          or_shipping_city: this.order.or_shipping_city,
          or_shipping_prov: this.order.or_shipping_prov,
          or_shipping_country: this.order.or_shipping_country
        });
      });
  }

  close() {
    this.closeServ.daFirmareOff();
  }
  
}

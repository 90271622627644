<div
  class="bigContainer k-display-flex justify-content-center flex-column align-items-center"
>
  <h1 class="k-pt-2">CAMBIA OPERATORE</h1>
  <h2 class="k-px-3">
    Scegli un altro operatore a cui affidare la preparazione dell’ordine
  </h2>
  <div class="component-container k-pb-2" *ngIf="operators">
    <kendo-dropdownlist
      style="color: black; width: 250px"
      [data]="formattedOperators"
      textField="full_name"
      valueField="account_id"
      (valueChange)="selectOperator($event)"
      [defaultItem]="defaultItem"
    ></kendo-dropdownlist>
  </div>
  <div class="k-display-flex justify-content-evenly k-pb-2" style="width: 100%">
    <button
      class="btn btn-primary"
      (click)="changeOperator()"
      style="background-color: #00ad46; border-color: #00ad46"
      [disabled]="!selectedOperator || !selectedOperator.account_id"
    >
      <i class="bx bx-check bx-sm"></i>
      <p>Cambia</p>
    </button>
    <button
      class="btn btn-primary"
      (click)="popoverController.dismiss()"
      style="background-color: #cd002b; border-color: #cd002b"
    >
      <i class="bx bx-x bx-sm"></i>
      <p>ANNULLA</p>
    </button>
  </div>
</div>

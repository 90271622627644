import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IAddress } from 'src/app/models/IAddress';
import { Orders } from 'src/app/models/Orders.model';
import { Shipments } from 'src/app/models/shipment';
import { IWsResult } from 'src/app/models/ws_result.model';
import { OrdersService } from 'src/app/services/orders.service';
import { PagesService } from 'src/app/services/pages.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';

@Component({
  selector: 'app-status-spedizioni-dettaggli',
  templateUrl: './status-spedizioni-dettaggli.component.html',
  styleUrls: ['./status-spedizioni-dettaggli.component.scss'],
})

export class StatusSpedizioniDettaggliComponent implements OnInit {

  @Input() parentId: any;
  @Input() status_ID = false;
  @Input() onStoricoID = false;
  @Input() order_id: any;

  public shipments: Shipments.ShipmentList;
  public order: Orders.IOrder;
  public agent_address: IAddress;
  public filteredShipments: Shipments.ShipmentList[] = null;
  public isSearched: boolean = false;
  public formattedDate: string = '-';
  public formattedClosedDate: string = '-';
  public maxlength = 250;
  public minWidth = 250;
  public dialogWidth = 1356;
  public dialogHeight = 1017;

  spedizioneDetailsForm!: FormGroup;
  filterTerm: string = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private orderService: OrdersService,
    private formBuilder: FormBuilder,
    public closeServ: OpenModalService,
    private pagesService: PagesService
  ) { }

  ngOnInit(): void {
    console.debug('Parent id is', this.parentId);
    this.spedizioneDetailsForm = this.formBuilder.group({
      or_order_number: [''],
      or_order_date: [''],
      or_closing_date: [''],
      tab_order_status_desc: [''],
      carrier_desc: [''],
      or_customer: [''],
      or_shipping_address: [''],
      or_shipping_city: [''],
      or_shipping_country: [''],
      or_shipping_customer: [''],
      or_shipping_prov: [''],
      or_shipping_zip: [''],
      products: [''],
      or_note: [''],
      tab_order_priority_desc: [''],
    });
    this.orderService.getOrder(this.parentId).subscribe((result: IWsResult) => {
      this.order = result.data;
      this.agent_address = result.data.or_shipping_note;
      if (this.order.or_order_date)
        this.formattedDate = formatDate(
          this.order.or_order_date,
          'dd/MM/yyyy',
          'it'
        );
      if (this.order.or_closing_date)
        this.formattedClosedDate = formatDate(
          this.order.or_closing_date,
          'dd/MM/yyyy',
          'it'
        );
      if (this.formattedClosedDate === '01/01/0001') {
        this.formattedClosedDate = '-';
      }
      this.formattedClosedDate = '-';
      this.spedizioneDetailsForm.patchValue({
        or_order_number: this.order.or_order_number,
        or_order_date: this.formattedDate,
        or_closing_date: this.formattedClosedDate,
        tab_order_status_desc: this.order.vs_details.tab_order_status_desc,
        carrier_desc: this.order.vs_details.carrier_desc,
        or_customer: this.order.or_customer,
        or_shipping_address: this.order.or_shipping_address,
        or_shipping_city: this.order.or_shipping_city,
        or_shipping_country: this.order.or_shipping_country,
        or_shipping_customer: this.order.or_shipping_customer,
        or_shipping_prov: this.order.or_shipping_prov,
        or_shipping_zip: this.order.or_shipping_zip,
        products: this.order.products,
        or_note: this.order.or_note,
        tab_order_priority_desc: this.order.vs_details.tab_order_priority_desc,
      });
    });
  }

  clickedId(id: any) {
    console.debug('Parent ID is: ', this.parentId);
  }

  public goBack() {
    this.close();
    this.router.navigate(['/status']);
  }

  close() {
    this.closeServ.offId();
  }
  
}

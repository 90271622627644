import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'byDatePipe',
})

export class ByDatePipePipe implements PipeTransform {

  transform(value: any[], args?: any): any[] {
    const uniqueNames = [];
    console.debug('Value is', value);
    console.debug('Args are', args);
    if (value == undefined || args == null || args.toString() == '')
      return value;
    //if(args.toString() == this.selectedYear )
    //  return value;
    return value.filter((item) => item?.or_order_date.indexOf(args) > -1);
  }
  
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable } from 'rxjs';
import { ICarrier } from 'src/app/models/ICarrier.model';
import { IWorker } from 'src/app/models/IWorker.model';
import { Priority } from 'src/app/models/Priority.model';
import { IWsResult } from 'src/app/models/ws_result.model';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from './error-handler.service';

@Injectable({
  providedIn: 'root',
})

export class DropdownService {
  
  url: string = environment.urlServer;
  prioritiesList: Priority.IPriority[] = [];
  isPropritiesList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(
    private http: HttpClient,
    private errorHandlerServ: ErrorHandlerService
  ) { }

  getPriorities(): Observable<Priority.IPriority[]> {
    return this.http
      .get<IWsResult>(`${this.url}/get_priorities`)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getPriorities')
        )
      )
      .pipe(map((result: IWsResult) => result.data));
  }

  getPrio(): Observable<IWsResult> {
    return this.http
      .get<IWsResult>(`${this.url}/get_priorities`)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getPriorities')
        )
      )
      .pipe(map((result: IWsResult) => result.data));
  }

  getCarriers(): Observable<ICarrier[]> {
    return this.http
      .get<IWsResult>(`${this.url}/get_carriers/`)
      .pipe(
        catchError(this.errorHandlerServ.handleError<IWsResult>('getCarriers'))
      )
      .pipe(map((result: IWsResult) => result.data));
  }

  getWorkers(): Observable<IWorker[]> {
    return this.http
      .get<IWsResult>(`${this.url}/get_workers/`)
      .pipe(
        catchError(this.errorHandlerServ.handleError<IWsResult>('getWorkers'))
      )
      .pipe(map((result: IWsResult) => result.data));
  }

  changeOperator(or_id: number, op_id: number) {
    return this.http
      .post<IWsResult>(`${this.url}/change_operator`, { or_id, op_id })
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('deleteAccount')
        )
      );
  }
  
}

<h1 class="page-title">Statistiche</h1>  

<div
  class="filterContainer k-display-flex"
  style="
    flex-direction: row;
    justify-content: center;
  "
>
  <form
    *ngIf="orders"
    class="new-search"
    role="search"
    style="
      display:flex !important;
      flex-direction: row !important;
      justify-content: center !important;
    "
  >
    <label for="search"></label>
    <input
    [(ngModel)]="filterTerm"
      id="search"
      name="search"
      pattern=".*\S.*"
      type="search"
      placeholder="Cerca ordine..."
      autofocus
      required
    />
    <button
      type="submit"
    >
      <i
        class="bx bx-search bx-md"
        style="
          margin: 0;
          padding-top: 10px;
          padding-left: 5px;
          transform:scale(.8)
        "
      >
      </i>
    </button>
  </form>

  <form
    action=""
    class="search-bar"
    style="
      display:flex !important;
      flex-direction: row !important;
      justify-content: center !important;
      visibility: hidden !important;
    "
  >
    <button
      class="btn btn-primary msg-btn"
      routerLink="/new-msg"
      style="
        display:flex !important;
        flex-direction: row !important;
        justify-content: center !important;
        visibility: hidden !important;
      "
    >
      + Messaggio
    </button>
  </form>

  <div class="dateContainer k-display-flex justify-content-evenly">
    <div class="col mx-2">
      <h5 class="text-center">DA:</h5>
      <kendo-datepicker
        [(ngModel)]="filters.startingDate"
        class="dropdown-field drop-the-border"
        style="
          background: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 45px;
        "
      >{{filters.startingDate | date}}</kendo-datepicker>
    </div>
    <div class="col">
      <h5 class="text-center">A:</h5>
      <kendo-datepicker
        [(ngModel)]="filters.endDate"
        class="dropdown-field drop-the-border"
        style="
          background: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 45px;
        "
      ></kendo-datepicker>
    </div>
  </div>
</div>
<hr class="hr-newBlue" />

<div class="header pb-8 pt-3 pt-lg-3 d-flex align-items-center" style="background-image:url(assets/img/brand/banner.jpg); background-size:cover; background-position:center top;">
  <span class="mask bg-gradient-default opacity-8"></span>
  <div class="container-fluid">
    <div class="header-body">
      <div class="row">
        <div class="col-xl-3 col-lg-6">
          <div class="card card-stats mb-2 mb-xl-0">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">N° Ordini evasi</h5>
                  <span class="h2 font-weight-bold mb-0">{{ totaleOrdiniEvasi }}</span>
                </div>
                <div class="col-auto">
                  <div class="icon icon-shape bg-danger text-white rounded-circle shadow">
                    <i class="fas fa-arrow-up"></i>
                  </div>
                </div>
              </div>
              <p class="mt-3 mb-0 text-muted text-sm">
                <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span>
                <span class="text-nowrap"> dall'ultimo mese</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-6">
          <div class="card card-stats mb-4 mb-xl-0">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">N° errori</h5>
                  <span class="h2 font-weight-bold mb-0">{{ totaleErrori }}</span>
                </div>
                <div class="col-auto">
                  <div class="icon icon-shape bg-warning text-white rounded-circle shadow">
                    <i class="fas fa-chart-pie"></i>
                  </div>
                </div>
              </div>
              <p class="mt-3 mb-0 text-muted text-sm">
                <span class="text-danger mr-2"><i class="fas fa-arrow-down"></i> 1.826%</span>
                <span class="text-nowrap"> quest'anno</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-6">
          <div class="card card-stats mb-4 mb-xl-0">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">N° Colli spediti</h5>
                  <span class="h2 font-weight-bold mb-0">{{ totaleColliSpediti }}</span>
                </div>
                <div class="col-auto">
                  <div class="icon icon-shape bg-yellow text-white rounded-circle shadow">
                    <i class="fas fa-users"></i>
                  </div>
                </div>
              </div>
              <p class="mt-3 mb-0 text-muted text-sm">
                <span class="text-warning mr-2"><i class="fas fa-arrow-down"></i> 1.10%</span>
                <span class="text-nowrap"> nell'ultimo mese</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-6">
          <div class="card card-stats mb-4 mb-xl-0">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Tempo preparazione ordine</h5>
                  <span class="h2 font-weight-bold mb-0">49 minuti</span>
                </div>
                <div class="col-auto">
                  <div class="icon icon-shape bg-info text-white rounded-circle shadow">
                    <i class="fas fa-percent"></i>
                  </div>
                </div>
              </div>
              <p class="mt-3 mb-0 text-muted text-sm">
                <span class="text-success mr-2"><i class="fas fa-arrow-up"></i> 12%</span>
                <span class="text-nowrap"> dall'ultimo mese</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <!-- TOTALE ORDINI OPERATORI-->
  <div class="left">
    <ion-card>
      <ion-card-content>
        <canvas class="polar" #totaleOperatoriCanvas></canvas>
      </ion-card-content>
    </ion-card>
  </div>
  <!-- TOTALE ORDINI CORRIERE -->
  <div class="right">
    <ion-card>
      <ion-card-content>
        <canvas class="radar" #totaleCorrieriCanvas></canvas>
      </ion-card-content>
    </ion-card>    
  </div>
</div>

<!-- ORDINI PER CORRIERE V1 -->
<ion-card>
  <ion-card-content>
    <canvas #corriereCanvas></canvas>
  </ion-card-content>
</ion-card>
<br>
<!-- ORDINI PER CORRIERE V2 -->
<ion-card>
  <ion-card-content>
    <canvas #corriere2Canvas></canvas>
  </ion-card-content>
</ion-card>
<br>
<!-- ORDINI PER OPERATORE -->
<ion-card>
  <ion-card-content>
    <canvas #operatoreCanvas></canvas>
  </ion-card-content>
</ion-card>

<div class="container">
  <div class="left">
    <!-- NUMERO ERRORI PER OPERATORE -->
    <ion-card>
      <ion-card-content>
        <canvas #numeroErroriCanvas></canvas>
      </ion-card-content>
    </ion-card>
  </div>
  <div class="right">
    <!-- PERCENTUALE ERRORI PER OPERATORE -->
    <ion-card>
      <ion-card-content>
        <canvas #percentualeErroriCanvas></canvas>
      </ion-card-content>
    </ion-card>   
  </div>
</div>  

<!-- NUMERO ORDINI EVASI -->
<ion-card>
  <ion-card-content>
    <canvas #ordiniEvasiCanvas></canvas>
  </ion-card-content>
</ion-card>   
<br>
<!-- NUMERO COLLI SPEDITI -->
<ion-card>
  <ion-card-content>
    <canvas #colliSpeditiCanvas></canvas>
  </ion-card-content>
</ion-card>    
<br>
<!-- GIORNI LAVORATIVI -->
<ion-card>
  <ion-card-content>
    <canvas #giorniLavorativiCanvas></canvas>
  </ion-card-content>
</ion-card>    
<br>
<!-- MEDIA GIORNALIERA SPEDIZIONI -->
<ion-card>
  <ion-card-content>
    <canvas #mediaSpedizioniCanvas></canvas>
  </ion-card-content>
</ion-card>    
<br>
<!-- MEDIA GIORNALIERA ORDINI -->
<ion-card>
  <ion-card-content>
    <canvas #mediaOrdiniCanvas></canvas>
  </ion-card-content>
</ion-card>  
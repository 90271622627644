<h1 class="page-title">Lista Account</h1>

<div class="filterContainer k-display-flex">
  <form
    action=""
    class="search-bar"
    style="
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;
    "
  >
    <button
      class="btn btn-primary account-btn"
      routerLink="/new-account"
      style="
        display: flex !important;
        flex-direction: row !important;
        justify-content: center !important;
      "
    >
      + Account
    </button>
  </form>
</div>

<hr class="hr-newBlue" />

<div class="container" *ngIf="existingAccounts" style="padding-left: 20px">
  <div style="display: flex; flex-direction: row; justify-content: center">
    <h2 class="col-header">Totale account: {{ existingAccounts?.length }}</h2>
  </div>

  <div
    *ngIf="loading"
    style="display: flex; flex-direction: column; align-items: center"
  >
    <mat-spinner></mat-spinner>
    <p>Attendi..</p>
  </div>

  <div class="grid-wrapper">
    <kendo-grid
      [kendoGridBinding]="existingAccounts"
      [rowHeight]="36"
      [height]="750"
      [pageSize]="pageSize"
      scrollable="virtual"
      [navigable]="true"
      [sortable]="true"
      [reorderable]="true"
    >
      <kendo-grid-column
        [width]="90"
        field="or_order_number"
        title="Avatar"
        [headerStyle]="{ display: 'flex', 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
        filter="numeric"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <img
            [src]="dataItem.ac_photo_url"
            class="resize-img"
            *ngIf="dataItem.ac_photo_url"
          />
          <div *ngIf="!dataItem.ac_photo_url" class="resize-span">
            <h1 class="resize-text">
              {{ dataItem.ac_first_name }}
              {{ dataItem.ac_last_name.substring(0, 1) }}.
            </h1>
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [width]="200"
        field="ac_last_name"
        title="Nome account"
        [headerStyle]="{ 'justify-content': 'left' }"
        [style]="{
          display: 'grid',
          'justify-content': 'left',
          'tetx-align': 'left'
        }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.ac_first_name }} {{ dataItem.ac_last_name }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [width]="130"
        field="tab_user_type_desc"
        title="Tipologia"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.tab_user_type_desc }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-command-column
        title="Dettagli"
        [width]="100"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <button
            kendoGridEditCommand
            rounded="full"
            class="btn-det"
            style="margin: 0; padding: 0; background-color: #017efa !important"
            (click)="ToAccDetails(dataItem.account_id)"
          >
            <div class="setDisplayToFlexBlue btn-det">
              <i class="bx bx-info-circle bx-md" style="margin: 0; padding: 0">
              </i>
              <p class="parButton2"></p>
            </div>
          </button>
        </ng-template>
      </kendo-grid-command-column>
    </kendo-grid>
  </div>
</div>

<app-gestione-account-dettagli
  class="background-cl"
  *ngIf="closeSrv.isAccPageOpen"
  [accPageOpen]="closeSrv.isAccPageOpen"
  [account_id]="clickedId"
  (modalClosed1)="this.getAccounts()"
></app-gestione-account-dettagli>

<kendo-dialog *ngIf="openBox" (close)="goBack()" class="resize-content">
  <p class="setMarginToRight">Scatola Custom</p>
  <div class="setDisplayRow col-md-2 k-pt-8">    
    <h6 style="text-align:center; margin-top:2px;">Altezza:</h6>
    <input
      type="text"
      [(ngModel)]="newBox.ts_box_height"
      value="{{ newBox.ts_box_height }}"
      placeholder=" {{ box.ts_box_height }} mm"
      class="input-box alignText col mb-2 col-md-2 newBorder pos-text3"
    />
    <h6 style="text-align:center; margin-top:2px;">Lunghezza:</h6>
    <input
      type="text"      
      [(ngModel)]="newBox.ts_box_length"
      value="{{ newBox.ts_box_length }}"
      placeholder=" {{ box.ts_box_length }} mm"
      class="input-box alignText col mb-2 col-md-2 newBorder pos-text3"
    />
    <h6 style="text-align:center; margin-top:2px;">Profondità:</h6>
    <input
      type="text"
      [(ngModel)]="newBox.ts_box_depth"
      value="{{ newBox.ts_box_depth }}"
      placeholder=" {{ box.ts_box_depth }} mm"
      class="input-box alignText col mb-2 col-md-2 newBorder pos-text3"
    />
    <h6 style="text-align:center; margin-top:2px;">Peso:</h6>
    <input
      type="text"      
      [(ngModel)]="newBox.ts_box_weight"
      value="{{ newBox.ts_box_weight }}"
      placeholder=" {{ box.ts_box_weight }} kg"
      class="input-box alignText col mb-2 col-md-2 newBorder pos-text3"
    />    
  </div>

  <button
    class="arrangeButtonToRight btn btn-primary"
    [disabled]="
      this.chosenBox.box_type_id == -2 ||
      (this.chosenBox.box_type_id == -1 && this.newBox.checkForNull())
    "
    (click)="validateBox(dataInfo.value)"
    #dataInfo
  >
    SALVA
  </button>
</kendo-dialog>

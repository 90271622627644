<div class="k-overlay dark-background"></div>

<div class="dark-background k-display-flex justify-content-center">
  <kendo-dialog
    title=""
    (close)="goBackFunction()"
    *ngIf="opened"
    [minWidth]="minWidth"
    [width]="dialogWidth"
    [minHeight]="500"
    [maxWidth]="850"
    [width]="850"
    class="k-form"
    [formGroup]="addAccountForm"
  >
    <div 
      style="
        display: flex;
        flex-direction: row;
        justify-content: end;
        margin-top: -15px;
        z-index: 1000;
        position: fixed;
        margin-left: -35px;
      "
    >
      <button
        (click)="close()"
        class="btn btn3"
        rounded="full"
        style="
          background-color: #e90000;
          color: #ffffff;
          width: 35px !important;
          height: 35px !important;
        "
      >    
        <i
          class="bx bx-x bx-sm posRelBox2"
          style="
            color: #ffffff; 
            bottom: 10px; 
            position: relative !important;
            left: -80% !important;
            top: -1px !important;
          "
        ></i>
      </button> 
    </div>
    <h1 class="page-title">Nuovo Account</h1>
    <hr class="hr-dialog" />
    <form
      class="k-form dialog-form"
      (ngSubmit)="addAgent(addAccountForm)"
      [formGroup]="addAccountForm"
    >
      <div class="row det mb-2">
        <kendo-formfield class="col-md-5 setDisplay-Prep">
          <kendo-label 
            [for]="ac_first_name"
            class="title" 
            text="Nome"
          ></kendo-label>
          <input            
            formControlName="ac_first_name"
            kendoTextBox
            #ac_first_name
            required
            class="dropdown-field setBorder-Preppp"
            placeholder="Nome account"
          />
          <kendo-formerror
            *ngIf="
              (addAccountForm.get('ac_first_name')?.touched ||
                addAccountForm.get('ac_first_name')?.dirty) &&
              addAccountForm.get('ac_first_name')?.errors
            "
          >
            Campo obbligatorio!
          </kendo-formerror>
        </kendo-formfield>

        <kendo-formfield class="col-md-5 setDisplay-Prep">
          <kendo-label 
            [for]="ac_last_name"
            class="title" 
            text="Cognome"
          ></kendo-label>
          <input
            formControlName="ac_last_name"
            kendoTextBox
            #ac_last_name
            required
            class="dropdown-field setBorder-Preppp"
            placeholder="Cognome account"
          />
          <kendo-formerror
            *ngIf="
              (addAccountForm.get('ac_last_name')?.touched ||
                addAccountForm.get('ac_last_name')?.dirty) &&
              addAccountForm.get('ac_last_name')?.errors
            "
          >
            Campo obbligatorio!
          </kendo-formerror>
        </kendo-formfield>
      </div>

      <div class="row det mb-2">
        <kendo-formfield class="col-md-5 setDisplay-Prep">
          <kendo-label
            class="title"
            text="Data scadenza"
          ></kendo-label>
          <kendo-datepicker
            formControlName="ac_expiration_date"
            #ac_expiration_date
            class="dropdown-field setBorder-Preppp"
            [min]="today"
            required
          ></kendo-datepicker>
          <kendo-formerror
            *ngIf="
              (addAccountForm.get('ac_expiration_date')?.touched ||
                addAccountForm.get('ac_expiration_date')?.dirty) &&
              addAccountForm.get('ac_expiration_date')?.errors
            "
          >
            Campo obbligatorio!
          </kendo-formerror>
        </kendo-formfield>

        <kendo-formfield class="col-md-5 setDisplay-Prep">
          <kendo-label 
            [for]="ac_user_type_cod"
            class="title" 
            text="Tipologia"
          ></kendo-label>
          <kendo-combobox
            [data]="accountTypesSelect"
            formControlName="ac_user_type_cod"
            kendoTextBox
            #ac_user_type_cod
            (valueChange)="onAccountTypeSelected($event)"
            required
            textField="ac_typo"
            [valuePrimitive]="true"
            valueField="ac_number"
            class="dropdown-field setBorder-Prepp"
            placeholder="Tipo di account"
          >
          </kendo-combobox>
        </kendo-formfield>
      </div>

      <div class="row det">
        <kendo-formfield class="col-md-5 setDisplay-Prep">
          <kendo-label 
            [for]="ac_phone"
            class="title" 
            text="Telefono"
          ></kendo-label>
          <input
            formControlName="ac_phone"
            kendoTextBox
            #ac_phone
            required
            class="dropdown-field setBorder-Preppp"
            placeholder="Numero di telefono"
          />
          <kendo-formerror
            *ngIf="
              (addAccountForm.get('ac_phone')?.touched ||
                addAccountForm.get('ac_phone')?.dirty) &&
              addAccountForm.get('ac_phone')?.errors
            "
          >
            Campo obbligatorio!
          </kendo-formerror>
        </kendo-formfield>

        <kendo-formfield class="col-md-5 setDisplay-Prep">
          <kendo-label 
            [for]="ac_email"
            class="title" 
            text="Email"
          ></kendo-label>
          <input            
            formControlName="ac_email"
            kendoTextBox
            #ac_email
            [required]="true"
            class="dropdown-field setBorder-Preppp"
            placeholder="Indirizzo Email"
          />
          <kendo-formerror
            *ngIf="
              (addAccountForm.get('ac_email')?.touched ||
                addAccountForm.get('ac_email')?.dirty) &&
              addAccountForm.get('ac_email')?.errors
            "
          >
            Email non valida!
          </kendo-formerror>
        </kendo-formfield>
      </div>

      <div class="row column-secret"></div>

      <div class="text-buttons">
        <div class="button-left">
          <button
            type="submit"
            class="btn btn3"
            rounded="full"
            style="
              background-color: #017efa;
              color: #ffffff;
              justify-content: center !important;
              display: flex;
              width: 192px !important;
              height: 42px !important;
            "
            (click)="addAgent(addAccountForm)"
            [disabled]="addAccountForm.invalid"
          >
            <i
              class="bx bx-plus-circle bx-sm posRelBox2"
              style="
                color: #ffffff;
                right: 6px;
                bottom: 10px;
                position: relative;
              "
            ></i>
            <p class="posRelBox2">AGGIUNGI</p>
          </button>
        </div>
      </div>
    </form>
  </kendo-dialog>
</div>
<!-- <div class="row column-secret">
          <kendo-formfield class="col-md-6">
            <kendo-label [for]="ac_active" text="Attivo"></kendo-label>
            <input
              formControlName="ac_active"
              kendoTextBox
              #ac_active
              required
              class="dropdown-field"
            />
            <kendo-formerror
              *ngIf="
                (addAccountForm.get('ac_active')?.touched ||
                  addAccountForm.get('ac_active')?.dirty) &&
                addAccountForm.get('ac_active')?.errors
              "
            >
              Campo obbligatorio!
            </kendo-formerror>
          </kendo-formfield>
        </div>

        <div class="row column-secret">
          <kendo-formfield class="col">
            <kendo-label [for]="ac_note" text="Descrizione"></kendo-label>
            <input
              formControlName="ac_note"
              kendoTextBox
              #ac_note
              class="dropdown-field"
            />
          </kendo-formfield>
        </div>
        <kendo-formfield class="col-md-6">
            <kendo-label
              [for]="tab_user_type_desc"
              text="Descrizione"
            ></kendo-label>
            <input
              formControlName="tab_user_type_desc"
              kendoTextBox
              #tab_user_type_desc
              class="dropdown-field"
            />
          </kendo-formfield>

          <kendo-formfield class="col-md-6">
            <kendo-label [for]="ac_avatar" text="Descrizione"></kendo-label>
            <input
              formControlName="ac_avatar"
              kendoTextBox
              #ac_avatar
              class="dropdown-field"
            />
          </kendo-formfield>


      </form>
    </div>
  </kendo-dialog>
</div>

 <div class="k-overlay dark-background" *ngIf="opened"></div>

<kendo-dialog
  title="Nuovo Account"
  *ngIf="opened"
  (close)="goBackFunction()"
  [minWidth]="250"
  [width]="450"
  class="k-form"
  [formGroup]="addAccountForm"
>
  <hr class="hr-dialog" />
    <form class="k-form dialog-form" [formGroup]="addAccountForm">
      <fieldset class="k-form-fieldset">
        <kendo-formfield>
          <kendo-label [for]="ac_url_foto" text="URL foto"></kendo-label>
          <input formControlName="ac_url_foto" kendoTextBox #ac_url_foto />
        </kendo-formfield>
      </fieldset>

      <div class="row">
        <kendo-formfield class="col-md-6">
          <kendo-label [for]="ac_nome" text="Nome"></kendo-label>
          <input formControlName="ac_nome" kendoTextBox #ac_nome required />
          <kendo-formerror
            *ngIf="
                (addAccountForm.get('ac_nome')?.touched ||
                  addAccountForm.get('ac_nome')?.dirty) &&
                addAccountForm.get('ac_nome')?.errors
              "
          >
            Campo obbligatorio!
          </kendo-formerror>
        </kendo-formfield>
        <kendo-formfield class="col-md-6">
          <kendo-label [for]="ac_cognome" text="Cognome"></kendo-label>
          <input
            formControlName="ac_cognome"
            kendoTextBox
            #ac_cognome
            required
          />
          <kendo-formerror
            *ngIf="
                (addAccountForm.get('ac_cognome')?.touched ||
                  addAccountForm.get('ac_cognome')?.dirty) &&
                addAccountForm.get('ac_cognome')?.errors
            "
          >
            Campo obbligatorio!
          </kendo-formerror>
        </kendo-formfield>
      </div>

      <div class="row">
        <kendo-formfield class="col-md-6">
          <kendo-label [for]="account_id" text="ID"></kendo-label>
          <input
            formControlName="account_id"
            kendoTextBox
            #account_id
            required
          />
          <kendo-formerror
            *ngIf="
                (addAccountForm.get('account_id')?.touched ||
                  addAccountForm.get('account_id')?.dirty) &&
                addAccountForm.get('account_id')?.errors
            "
          >
            Campo obbligatorio!
          </kendo-formerror>
        </kendo-formfield>
        <kendo-formfield class="col-md-6">
          <kendo-label
            [for]="ac_tipo_utente_cod"
            text="Tipologia"
          ></kendo-label>
          <input
            formControlName="ac_tipo_utente_cod"
            kendoTextBox
            #ac_tipo_utente_cod
            required
          />
          <kendo-formerror
            *ngIf="
                (addAccountForm.get('ac_tipo_utente_cod')?.touched ||
                  addAccountForm.get('ac_tipo_utente_cod')?.dirty) &&
                addAccountForm.get('ac_tipo_utente_cod')?.errors
            "
          >
          Campo obbligatorio!
          </kendo-formerror>
        </kendo-formfield>
      </div>

      <div class="row">
        <kendo-formfield class="col-md-6">
          <kendo-label
            [for]="ac_data_scadenza"
            text="Data scadenza"
          ></kendo-label>
          <input
            formControlName="ac_data_scadenza"
            kendoTextBox
            #ac_data_scadenza
            required
          />
          <kendo-formerror
            *ngIf="
                (addAccountForm.get('ac-data_scadenza')?.touched ||
                  addAccountForm.get('ac_data_scadenza')?.dirty) &&
                addAccountForm.get('ac_data_scadenza')?.errors
            "
          >
            Campo obbligatorio!
          </kendo-formerror>
        </kendo-formfield>
        <kendo-formfield class="col-md-6">
          <kendo-label [for]="ac_email" text="Email"></kendo-label>
          <input formControlName="ac_email" kendoTextBox #ac_email required />
          <kendo-formerror
            *ngIf="
                (addAccountForm.get('ac_email')?.touched ||
                  addAccountForm.get('ac_email')?.dirty) &&
                addAccountForm.get('ac_email')?.errors
            "
          >
            Email non valida!
          </kendo-formerror>
        </kendo-formfield>
      </div>

      <div class="row">
        <kendo-formfield class="col-md-6">
          <kendo-label [for]="ac_telefono" text="Telefono"></kendo-label>
          <input
            formControlName="ac_telefono"
            kendoTextBox
            #ac_telefono
            required
          />
          <kendo-formerror
            *ngIf="
                (addAccountForm.get('ac_telefono')?.touched ||
                  addAccountForm.get('ac_telefono')?.dirty) &&
                addAccountForm.get('ac_telefono')?.errors
            "
          >
            Campo obbligatorio!
          </kendo-formerror>
        </kendo-formfield>
        <kendo-formfield class="col-md-6">
          <kendo-label [for]="ac_cellulare" text="Cellulare"></kendo-label>
          <input
            formControlName="ac_cellulare"
            kendoTextBox
            #ac_cellulare
            required
          />
          <kendo-formerror
            *ngIf="
                (addAccountForm.get('ac_cellulare')?.touched ||
                  addAccountForm.get('ac_cellulare')?.dirty) &&
                addAccountForm.get('ac_cellulare')?.errors
            "
          >
            Campo obbligatorio!
          </kendo-formerror>
        </kendo-formfield>
      </div>

      <div class="row">
        <kendo-formfield class="col-md-6">
          <kendo-label [for]="ac_societa" text="Società"></kendo-label>
          <input
            formControlName="ac_societa"
            kendoTextBox
            #ac_societa
            required
          />
          <kendo-formerror
            *ngIf="
                (addAccountForm.get('ac_societa')?.touched ||
                  addAccountForm.get('ac_societa')?.dirty) &&
                addAccountForm.get('ac_societa')?.errors
            "
          >
            Campo obbligatorio!
          </kendo-formerror>
        </kendo-formfield>
        <kendo-formfield class="col-md-6">
          <kendo-label [for]="ac_attivo" text="Attivo"></kendo-label>
          <input
            formControlName="ac_attivo"
            kendoTextBox
            #ac_attivo
            required
          />
          <kendo-formerror
            *ngIf="
                (addAccountForm.get('ac_attivo')?.touched ||
                  addAccountForm.get('ac_attivo')?.dirty) &&
                addAccountForm.get('ac_attivo')?.errors
            "
          >
            Campo obbligatorio!
          </kendo-formerror>
        </kendo-formfield>
      </div>

      <div class="row">
        <kendo-formfield class="col-md-6">
          <kendo-label [for]="ac_note" text="Note"></kendo-label>
          <input formControlName="ac_note" kendoTextBox #ac_note />
        </kendo-formfield>
        <kendo-formfield class="col-md-6">
          <kendo-label
            [for]="tab_tipo_utente_desc"
            text="Descrizione"
          ></kendo-label>
          <input
            formControlName="tab_tipo_utente_desc"
            kendoTextBox
            #tab_tipo_utente_desc
          />
        </kendo-formfield>
      </div>
      <div class="text-buttons">
        <div class="button-right">
          <button kendoButton (click)="clearForm()" class="button-r">
            ANNULLA
          </button>
        </div>
        <div class="button-left">
          <button kendoButton (click)="goBackFunction()" class="button-l">
            SALVA
          </button>
        </div>
      </div>
    </form>
  </kendo-dialog> -->
<!-- </kendo-dialog>
  </div>
</div> -->

<h1 class="page-title">Lista Agenti</h1>

<div
  class="filterContainer k-display-flex"
  style="flex-direction: row; justify-content: center"
>
  <form
    class="new-search"
    role="search"
    style="
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;
    "
  >
    <label for="search"></label>
    <input
      [(ngModel)]="filterTerm"
      id="search"
      name="search"
      pattern=".*\S.*"
      type="search"
      placeholder="Cerca agente..."
      autofocus
      required
      (input)="this.onInputChanged($event)"
    />
    <button type="submit" (click)="searchAgents()">
      <i
        class="bx bx-search bx-md"
        style="
          margin: 0;
          padding-top: 10px;
          padding-left: 5px;
          transform: scale(0.8);
        "
      >
      </i>
    </button>
  </form>
</div>

<hr class="hr-newBlue" />

<div class="container" style="padding-left: 20px">
  <div style="display: flex; flex-direction: row; justify-content: center">
    <h2 class="col-header">Totale agenti: {{ agentsData.length }}</h2>
  </div>

  <div
    *ngIf="loading"
    style="display: flex; flex-direction: column; align-items: center"
  >
    <mat-spinner></mat-spinner>
    <p>Attendi..</p>
  </div>

  <!--<div class="col text-agent">
    <div>
      <i class="bx bxs-coin-stack bx-md" style="color: var(--text-color-k)"></i>
    </div>
    <span class="span-text2" style="color: var(--text-color-k)">
      Lista Agenti</span
    >
  </div>
  <div class="agentFunz" *ngIf="agentsData">
    <div class="row" *ngFor="let data of agentsData; let i = index">
      <div
        class="col-sm-1 textcol1"
        (click)="goToDetails(data.agent_id, data.ag_url)"
        style="cursor: pointer"
      >
        <i
          class="bx bxs-show bx-md"
          color="blue"
          style="color: var(--text-color-k)"
        ></i>
      </div>
      <div class="col-md textcol2">
        <span class="span-text1" style="color: var(--text-color-k)">
          {{ data.ag_first_name }} {{ data.ag_last_name }}
        </span>
      </div>

      <div class="col-md col1">
        <button
          (click)="copyToClipboard(data.ag_url, data)"
          class="btn btn-primary btn1 mr-100 kDisplayFlex"
          rounded="full"
        >
          <i class="bx bxs-copy posRelBox2 bx-sm"></i>
          <p class="parButton">COPIA</p>
        </button>
      </div>
      <div class="col-md-2 col2">
        <button
          class="btn btn2"
          style="background-color: #d89e00"
          *ngIf="!data.ag_url"
          (click)="generateUrl(data.agent_id)"
        >
          <i class="bx bx-plus-circle bx-sm posRelBox"></i>
          GENERA URL
        </button>
        <button
          class="btn btn2 kDisplayFlex"
          *ngIf="data.ag_url"
          (click)="deleteURL(data.agent_id)"
        >
          <i class="bx bxs-trash-alt bx-sm posRelBox"></i>
          <p class="parButton">ELIMINA URL</p>
        </button>
      </div>
    </div>
  </div>-->

  <div class="container" *ngIf="agentsData">
    <div class="grid-wrapper">
      <kendo-grid
        [kendoGridBinding]="agentsData"
        [rowHeight]="36"
        [height]="750"
        [pageSize]="pageSize"
        scrollable="virtual"
        [navigable]="true"
        [sortable]="true"
        [reorderable]="true"
      >
        <kendo-grid-column
          [width]="180"
          field="ag_last_name"
          title="Nome agente"
          [headerStyle]="{ 'justify-content': 'left' }"
          [style]="{
            display: 'grid',
            'justify-content': 'left',
            'tetx-align': 'left'
          }"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.ag_last_name }} {{ dataItem.ag_first_name }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-command-column
          title="Copia URL"
          [width]="100"
          [headerStyle]="{ 'justify-content': 'center' }"
          [style]="{ 'tetx-align': 'center' }"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <button
              kendoGridEditCommand
              rounded="full"
              class="btn-det"
              [disabled]="!dataItem.ag_url"
              style="margin: 0; padding: 0"
              (click)="copyToClipboard(dataItem.ag_url, dataItem)"
            >
              <div class="setDisplayToFlexBlue btn-copia">
                <i
                  class="bx bx-copy bx-md"
                  style="margin: 0; padding: 0; transform: scale(0.8)"
                >
                </i>
                <p class="parButton2"></p>
              </div>
            </button>
          </ng-template>
        </kendo-grid-command-column>

        <kendo-grid-command-column
          title="Genera URL"
          [width]="100"
          [headerStyle]="{ 'justify-content': 'center' }"
          [style]="{ 'tetx-align': 'center' }"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <button
              kendoGridEditCommand
              rounded="full"
              class="btn-det"
              style="margin: 0; padding: 0"
              *ngIf="!dataItem.ag_url"
              (click)="generateUrl(dataItem.agent_id)"
            >
              <div class="setDisplayToFlexGold btn-genera">
                <i
                  class="bx bx-link bx-md"
                  style="margin: 0; padding: 0; transform: scale(0.8)"
                >
                </i>
                <p class="parButton2"></p>
              </div>
            </button>
            <button
              kendoGridEditCommand
              rounded="full"
              class="btn-det"
              style="margin: 0; padding: 0"
              *ngIf="dataItem.ag_url"
              (click)="deleteURL(dataItem.agent_id)"
            >
              <div class="setDisplayToFlexRed btn-elimina">
                <i
                  class="bx bx-trash-alt bx-md"
                  style="margin: 0; padding: 0; transform: scale(0.8)"
                >
                </i>
                <p class="parButton2"></p>
              </div>
            </button>
          </ng-template>
        </kendo-grid-command-column>

        <kendo-grid-command-column
          title="Dettagli"
          [width]="100"
          [headerStyle]="{ 'justify-content': 'center' }"
          [style]="{ 'tetx-align': 'center' }"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <button
              kendoGridEditCommand
              rounded="full"
              class="btn-det"
              style="
                margin: 0;
                padding: 0;
                background-color: #017efa !important;
              "
              (click)="goToDetails(dataItem.agent_id, dataItem.ag_url)"
            >
              <div class="setDisplayToFlexBlue btn-det">
                <i
                  class="bx bx-info-circle bx-md"
                  style="margin: 0; padding: 0"
                >
                </i>
                <p class="parButton2"></p>
              </div>
            </button>
          </ng-template>
        </kendo-grid-command-column>
      </kendo-grid>
    </div>
  </div>
  <div *ngIf="loading" class="k-i-loading"></div>
</div>

<app-ordini-agente-list
  *ngIf="closeServ.isOrderOpen"
  [openOrders]="closeServ.isOrderOpen"
  [agent_token]="ag_token"
>
</app-ordini-agente-list>

<app-funzionari-dettagli
  *ngIf="closeServ.isDetAgent"
  [opened]="closeServ.isDetAgent"
  [id]="clickedID"
  [ag_token]="ag_token"
  (modalClosed1)="this.getAgents()"
>
</app-funzionari-dettagli>

import { Helpers } from './helpters';

export namespace ProgressBarCustom {
  
  export interface IProgresBar {
    perc: number;
    cssClass: string;
  }

  export class Progress implements IProgresBar {
    perc: number;
    cssClass: string;

    constructor(progressPerc: number) {
      this.perc = +progressPerc;

      switch (this.perc) {
        // case 0:
        //   this.cssClass = Helpers.progresBar0p.cssClass;
        case 20:
          this.cssClass = Helpers.progresBar20p.cssClass;
          break;
        case 40:
          this.cssClass = Helpers.progresBar40p.cssClass;
          break;
        case 60:
          this.cssClass = Helpers.progresBar60p.cssClass;
          break;
        case 80:
          this.cssClass = Helpers.progresBar80p.cssClass;
          break;
        case 100:
          this.cssClass = Helpers.progresBar100p.cssClass;
          break;
      }
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TimeScale } from 'chart.js';
import { IAgent } from 'src/app/models/IAgent.model';

@Component({
  selector: 'app-base-agente',
  templateUrl: './base-agente.component.html',
  styleUrls: ['./base-agente.component.scss'],
})

export class BaseAgenteComponent implements OnInit {

  public opened = false;  
  public dataSaved = false;
  public maxlength = 250;
  public minWidth = 250;
  public dialogWidth = 1056;
  public dialogHeight = 1017;

  addAgentForm!: FormGroup;

  constructor() { }

  ngOnInit(): void {
    this.addAgentDialog();
  }

  addAgentDialog() {
    this.addAgentForm = new FormGroup({
      agent_id: new FormControl(''),
      ag_first_name: new FormControl('', [
        Validators.required,
        Validators.maxLength(35),
        Validators.pattern('[a-zA-Z. ]*'),
      ]),
      ag_last_name: new FormControl('', [
        Validators.required,
        Validators.maxLength(35),
        Validators.pattern('[a-zA-Z. -]*'),
      ]),
      ag_email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-z]{2,4}$'),
      ]),
      ag_mobile: new FormControl('', [
        Validators.pattern('[0-9-+ ]*'),
        Validators.maxLength(20),
      ]),
      ag_phone: new FormControl('', [
        Validators.pattern('[0-9-+ ]*'),
        Validators.maxLength(20),
      ]),
      ag_url: new FormControl(''),
      ag_note: new FormControl(''),
    });
  }

  close(status: any) {
    this.opened = false;
  }

  addAgent(addAgentForm: any): void {
    let agent: IAgent = {
      agent_id: this.addAgentForm.value.agent_id,
      ag_first_name: this.addAgentForm.value.ag_first_name,
      ag_last_name: this.addAgentForm.value.ag_last_name,
      ag_email: this.addAgentForm.value.ag_email,
      ag_mobile: this.addAgentForm.value.ag_mobile,
      ag_phone: this.addAgentForm.value.ag_phone,
      ag_url: this.addAgentForm.value.ag_url,
      ag_note: this.addAgentForm.value.ag_note
    };
    console.log(agent);
    this.addAgentForm.markAllAsTouched();
  }

  clearForm(): void {
    this.addAgentForm.reset();
  }
  
}

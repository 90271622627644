import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  IMessage,
  IMessageList,
  Notifications,
} from '../models/IMessage.model';
import { IWsResult } from '../models/ws_result.model';
import { ErrorHandlerService } from './shared/error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class MessagesService {
  private msg: string[] = [];

  url: string = environment.urlServer;
  hasNotification: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  hasConversation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(
    private http: HttpClient,
    private errorHandlerServ: ErrorHandlerService
  ) {}

  getMessages(): Observable<IWsResult> {
    return this.http
      .get<IWsResult>(`${this.url}/get_messages/`)
      .pipe(
        catchError(this.errorHandlerServ.handleError<IWsResult>('getMessages'))
      );
  }

  searchMessages(searchString: string = ''): Observable<IWsResult> {
    return this.http
      .get<IWsResult>(`${this.url}/get_messages`, {
        headers: { search: `${searchString}` },
      })
      .pipe(
        catchError(this.errorHandlerServ.handleError<IWsResult>('getMessages'))
      );
  }

  getMessage(id: any): Observable<IMessageList> {
    return this.http
      .get<IWsResult>(`${this.url}/get_message/${id}`)
      .pipe(
        catchError(this.errorHandlerServ.handleError<IWsResult>('getMessage'))
      )
      .pipe(map((result: IWsResult) => result.data));
  }

  putMessage(msg: IMessage): Observable<IMessage> {
    return this.http
      .post<IWsResult>(`${this.url}/put_message/`, msg)
      .pipe(
        catchError(this.errorHandlerServ.handleError<IWsResult>('getMessage'))
      )
      .pipe(map((result: IWsResult) => result.data));
  }

  setReadStatus(id: number) {
    return this.http
      .post(`${this.url}/set_read_message/`, { message_id: id })
      .pipe(
        catchError(this.errorHandlerServ.handleError<IWsResult>('sendMessage'))
      );
  }

  getMsg(): string[] {
    return this.msg;
  }

  addMsg(msg: string) {
    this.msg.push(msg);
  }

  getNotifications(): Observable<Notification[]> {
    return this.http
      .get<IWsResult>(`${this.url}/get_messages/`)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getNotifications')
        )
      )
      .pipe(
        map((result: IWsResult) => {
          if (!result.success) return;
          this.hasNotification.next(
            result.data.some(
              (item: Notifications.Notification) =>
                item.ms_status && item.ms_status == 'U'
            )
          );
          console.log('this.has notification', this.hasNotification.value);
          return result.data.map((item) => {
            let order = new Notifications.Notification(item);
            return order;
          });
        })
      );
  }

  getConversations(): Observable<Notifications.Conversations[]> {
    return this.http
      .get<IWsResult>(`${this.url}/get_last_messages_person/`)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getConversations')
        )
      )
      .pipe(
        map((result: IWsResult) => {
          console.log('result on conversations ', result);
          if (!result.success) return;
          this.hasConversation.next(
            result.data.some(
              (item: Notifications.Conversations) =>
                item.ms_status && item.ms_status == 'U'
            )
          );
          console.log('this.has notification', this.hasConversation.value);
          return result.data.map((item) => {
            let order = new Notifications.Conversations(item);
            return order;
          });
        })
      );
  }

  getConversationGroup(id: number): Observable<IWsResult> {
    return this.http
      .get<IWsResult>(`${this.url}/get_conversation_group/${id}`)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getConversation/id')
        )
      );
    //.pipe(map((result: IWsResult) => new Notifications.Conversation(result.data)));
  }

  getConversationsGroup(): Observable<Notifications.Conversations[]> {
    return this.http
      .get<IWsResult>(`${this.url}/get_last_messages_group/`)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getConversations')
        )
      )
      .pipe(
        map((result: IWsResult) => {
          console.log('result on conversations ', result);
          if (!result.success) return;
          this.hasConversation.next(
            result.data.some(
              (item: Notifications.Conversations) =>
                item.ms_status && item.ms_status == 'U'
            )
          );
          console.log('this.has notification', this.hasConversation.value);
          return result.data.map((item) => {
            let order = new Notifications.Conversations(item);
            console.log('ORDER IS', order);
            return order;
          });
        })
      );
  }

  getConversation(id: number) {
    return this.http
      .get<IWsResult>(`${this.url}/get_conversation_person/${id}`)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getConversation/id')
        )
      );
    // .pipe(
    //   map((result: IWsResult) => new Notifications.Conversation(result.data))
    // );
  }

  sendNotification(notification: Notifications.IMessage) {
    return this.http
      .post(`${this.url}/put_message/`, notification)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('sendNotification')
        )
      );
  }
}

<div class="k-overlay dark-background" *ngIf="openedFIRMARE"></div>

<kendo-dialog
  title=""
  *ngIf="openedFIRMARE && order"
  [minWidth]="250"
  [width]="1150"
  [maxWidth]="1150"
  class="k-form"
  (close)="close()"
  [formGroup]="assignOrderForm"
  style="left: 6%;"
>
  <div 
    style="
      display: flex;
      flex-direction: row;
      justify-content: end;
      margin-top: -15px;
      z-index: 1000;
      position: fixed;
      margin-left: -35px;
    "
  >
    <button
      (click)="close()"
      class="btn btn3"
      rounded="full"
      style="
        background-color: #e90000;
        color: #ffffff;
        width: 35px !important;
        height: 35px !important;
      "
    >    
      <i
        class="bx bx-x bx-sm posRelBox2"
        style="
          color: #ffffff; 
          bottom: 10px; 
          position: relative !important;
          left: -80% !important;
          top: -1px !important;
        "
      ></i>
    </button> 
  </div>
  <h1 class="page-title">Ordini da Firmare</h1>
  <hr class="hr-dialog" />
  <div *ngIf="order" class="container container-ordine">
    <form class="k-form dialog-form" [formGroup]="assignOrderForm">
      <div class="row det">
        <div class="col-md setDisplay-Prep" *ngIf="order">
          <kendo-label 
            class="title" 
            text="N° Ordine"
          ></kendo-label>
          <kendo-textbox
            #or_order_number
            rounded="full"
            [placeholder]="order.or_order_number"
            class="order-column setBorder-Prepp"
          >
          </kendo-textbox>
        </div>
        <div class="col-md setDisplay-Prep">
          <kendo-label 
            class="title" 
            text="Data arrivo"
          ></kendo-label>
          <kendo-textbox
            #or_order_date
            rounded="full"
            [placeholder]="order.or_order_date | date : 'dd/MM/yyyy'"
            class="order-column setBorder-Prepp"
          >
          </kendo-textbox>
        </div>
        <div class="col-md setDisplay-Prep">
          <kendo-label 
            class="title" 
            text="Data evasione"
          ></kendo-label>
          <kendo-textbox
            #or_quote_date
            rounded="full"
            [placeholder]="order.or_assignment_date | date : 'dd/MM/yyyy'"
            class="order-column setBorder-Prepp"
          >
          </kendo-textbox>
        </div>        
      </div>

      <div class="row det mb-2">
        <div class="col-md setDisplay-Prep">
          <kendo-label 
            class="title" 
            text="N° articoli"
          ></kendo-label>
          <kendo-textbox
            #boxes
            rounded="full"
            placeholder="{{ order.products.length }}"
            class="order-column setBorder-Prepp"
          >
          </kendo-textbox>
        </div>
        <div class="col-md setDisplay-Prep">
          <kendo-label
            [for]="boxes"
            class="title"
            text="N° colli"
          ></kendo-label>
          <kendo-textbox
            #boxes
            rounded="full"
            placeholder="{{ order.boxes.length }}"
            class="order-column setBorder-Prepp"
          >
          </kendo-textbox>
        </div>
        <div class="col-md setDisplay-Prep">
          <kendo-label 
            class="title" 
            text="Corriere"
          ></kendo-label>
          <kendo-textbox
            #carrier_desc
            rounded="full"
            placeholder="{{ order.vs_details.carrier_desc }}"
            class="order-column setBorder-Prepp"
          >
          </kendo-textbox>
        </div>
      </div>

      <div class="row det mb-2">
        <div class="col-md setDisplay-Prep">
          <kendo-label 
            class="title"
            text="Cliente" 
          ></kendo-label>
          <kendo-textbox
            #or_customer
            rounded="full"
            [placeholder]="order.or_customer"
            class="order-column setBorder-Prepp"
          >
          </kendo-textbox>
        </div>        
      </div>  
      
      <div class="row det">
        <div class="col-md setDisplay-Prep">
          <kendo-label 
            class="title" 
            text="Indirizzo"
          ></kendo-label>
          <kendo-textbox
            #or_shipping_address
            rounded="full"
            [placeholder]="order.or_shipping_address"
            class="order-column setBorder-Prepp"
          >
          </kendo-textbox>
        </div>
      </div>

      <div class="row det">        
        <div class="col-md setDisplay-Prep">
          <kendo-label 
            class="title" 
            text="Provincia"
          ></kendo-label>
          <kendo-textbox
            #or_shipping_prov
            rounded="full"
            [placeholder]="order.or_shipping_prov"
            class="order-column setBorder-Prepp"
          >
          </kendo-textbox>
        </div>
        <div class="col-md setDisplay-Prep">
          <kendo-label 
            class="title" 
            text="CAP"
          ></kendo-label>
          <kendo-textbox
            #or_shipping_zip
            rounded="full"
            [placeholder]="
              order.or_shipping_zip ? order.or_shipping_zip : 'cap'
            "
            class="order-column setBorder-Prepp"
          >
          </kendo-textbox>
        </div>
        <div class="col-md setDisplay-Prep">
          <kendo-label 
            class="title" 
            text="Città"
          ></kendo-label>
          <kendo-textbox
            #or_shipping_city
            rounded="full"
            [placeholder]="order.or_shipping_city"
            class="order-column setBorder-Prepp"
            placeholder="Città"
          >
          </kendo-textbox>
        </div>
        <div class="col-md setDisplay-Prep">
          <kendo-label 
            class="title" 
            text="Nazione"
          ></kendo-label>
          <kendo-textbox
            #or_shipping_country
            rounded="full"
            [placeholder]="order.or_shipping_country"
            class="order-column setBorder-Prepp"
          >
          </kendo-textbox>
        </div>
      </div>
    </form>

    <div class="row det cont-dx">
      <div class="row column-secret panelbar-wrapper">
        <kendo-panelbar
            *ngIf="order && products"
            [animate]="true"
            style="
              text-align: center;
              border: #fff;
            "
          >
            <kendo-panelbar-item 
              [title]="'Prodotti'" 
              style="
                text-align: center;
                border-radius: 45px
              "
            >
              <ng-template kendoPanelBarContent>
                <div style="max-height: 100%; margin-left:12px; width:96.3%">
                  <div
                      class="row"
                      *ngFor="let prod of order.products; let i = index"
                      style="
                        text-align: center;
                        padding: 8px;
                        border: 1px solid #47A1FB;
                        display: flex;
                        justify-content: space-evenly;
                        align-items: center;
                      "
                    >
                    <div 
                      class="col-10"
                      style="text-align: left"
                    >
                      <span
                        style="
                          font-family: 'Lato';
                          font-style: normal;
                          font-weight: 600;
                          font-size: 16px;
                          line-height: 19px;
                          color: #0C65E9;
                        "
                        >{{ prod.op_description }}
                      </span>
                    </div>
                    <div 
                      class="col-2"
                      style="text-align:right"
                    >
                      <span
                        style="
                          font-family: 'Lato';
                          font-style: normal;
                          font-weight: 600;
                          font-size: 16px;
                          line-height: 19px;
                          color: #0C65E9;
                        "
                        >{{ prod.op_qta + " pz" }}
                      </span>
                    </div>
                  </div>
                </div>
              </ng-template>
            </kendo-panelbar-item>
          </kendo-panelbar>
      </div>
    </div>
  </div>
</kendo-dialog>

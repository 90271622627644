import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Boxes } from 'src/app/models/Boxes.models';
import { OrdersService } from 'src/app/services/orders.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-box',
  templateUrl: './add-box.component.html',
  styleUrls: ['./add-box.component.scss'],
})

export class AddBoxComponent implements OnInit {  
  
  @Input() box_type_id: string = '-1';
  @Input() openBox = false;
  @Output() dataInfo = new EventEmitter<Object>();

  public boxes: Boxes.Box[] = [];

  newBox: Boxes.BoxOrder = new Boxes.BoxOrder(null);
  addBoxForm!: FormGroup;
  numbers: number[] = [];
  selectedNumber: number = 0;
  boxNo: number = 10;
  chosenBox: Boxes.Box = {
    box_type_id: -2,
    box_type_desc: '',
  };  

  constructor(
    private orderService: OrdersService,
    private router: Router,
    public openModal: OpenModalService
  ) { }

  ngOnInit() {
    this.orderService.getBox().subscribe((result) => {
      this.boxes = result;
    });
    this.numbers = Array.from(
      { length: this.boxNo },
      (_element, index) => index + 1
    );
  }

  closeBox() {
    this.openModal.closeBox(null);
  }

  changeBox(id, desc = '') {
    this.chosenBox.box_type_desc = desc;
    this.chosenBox.box_type_id = id;
    console.debug('result: ', this.chosenBox);
  }

  validateBox(value: string) {
    this.chosenBox.box_type_id =
      this.chosenBox.box_type_id < 0 ? null : this.chosenBox.box_type_id;
    let dataInfo = {
      isCustom: this.chosenBox.box_type_id == -1 ? true : false,
      normal: this.chosenBox,
      custom: this.newBox,
      boxesNo: this.selectedNumber,
    };
    this.openModal.closeBox(dataInfo);
    this.dataInfo.emit(dataInfo);
    console.debug('Output is: ', this.dataInfo);
    console.debug('DataInfo is: ', dataInfo);
    Swal.fire({
      icon: 'success',
      title: 'Aggiunta!',
      text: 'Scatola aggiunta con successo!!!',
      timer: 1500,
      timerProgressBar: true,
    });
  }
  
}

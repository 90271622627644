import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })

export class AuthGuard  {

  constructor(
    private router: Router,
    private authServ:AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const tokenValue = localStorage.getItem('tokenValue');
    if (tokenValue) {
      this.authServ.isUser.next(true);
      return true;
    }
    else { 
      this.authServ.isUser.next(false);
      this.authServ.stateUrl=state.url;
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false; 
    }
  }
  
}

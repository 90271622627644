import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { ProductImage, Products } from 'src/app/models/Product.model';
import { IWsResult } from 'src/app/models/ws_result.model';
import { ProductsService } from 'src/app/services/products.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import Swal from 'sweetalert2';

export interface ImageInfo {
  src: string;
  width: number;
  height: number;
}

@Component({
  selector: 'app-product-position',
  templateUrl: './product-position.component.html',
  styleUrls: ['./product-position.component.scss'],
})

export class ProductPositionComponent implements OnInit {

  @Output() public valueChange: EventEmitter<ImageInfo> =
    new EventEmitter<ImageInfo>();
  @Output() modalClosed = new EventEmitter<void>();
  @Input() product: Products.IProductDetails;
  @Input() imageStr: string;
  @Input() openPhoto = false;

  public uploadSaveUrl =
    'http://salvatempo.connecta.ovh/api/services/put_product_position';
  public uploadRemoveUrl = '';
  public productPosition: Products.IProductPosition;
  public numeProdus: Products.IProductPosition;
  public myRestrictions: FileRestrictions = {
    allowedExtensions: ['.png', '.jpg', '.jpeg'],
  };

  addProductPositionForm!: FormGroup;
  id: string = '';
  route: string = '';
  shortLink: string = '';
  loading: boolean = false;
  show: boolean = false;
  file: ProductImage;
  pr_position: Products.IProductPosition[] = [];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    public openSrv: OpenModalService,
    public productSrv: ProductsService,
    private activatedRoute: ActivatedRoute
  ) { }

  goBack() {
    this.openSrv.closePhoto();
    console.debug('MERGE?');
  }

  onFileLoad(event: any) {
    console.debug(event);
    {
      this.productPosition = {
        pr_description: '',
        product_position_id: '0',
        pr_code: '',
        product_id: '0',
        pr_image_position: '',
        pr_b64_image_position: '',
        pr_lane: '',
        pr_shelf: '',
        pr_position: '',
        pr_qty: 0,
      };
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e: any) => {
        this.productPosition.product_id = this.product.product_id;
        console.debug(reader);
        this.productPosition.pr_b64_image_position = reader.result?.toString();
        this.productSrv.productAvatar = this.productPosition;
        this.productPosition.pr_b64_image_position =
          this.productPosition.pr_b64_image_position?.substring(23);
        console.debug(this.productPosition);
      };
      reader.onerror = (error) => {
        //console.log(error);
        //this.showError('Error: ' + error.toString());
        //write error in the control something like: File load failed
      };
    }
    this.getAvatarByURL();
  }

  getAvatarByURL() {}

  onChange(event: any) {
    this.file = event.target.files[0];
    this.show = true;
  }

  onUpload() {
    this.productPosition = {
      pr_description: '',
      product_id: '0',
      product_position_id: '0',
      pr_code: '0',
      pr_image_position: '',
      pr_b64_image_position: '',
      pr_lane: '',
      pr_shelf: '',
      pr_position: '',
      pr_qty: 0,
    };
    this.loading = !this.loading;
    this.productPosition.pr_b64_image_position =
      this.file.pr_b64_position_image?.toString();
    console.log(this.file);
    this.productSrv
      .addProductPosition(this.productPosition)
      .subscribe((event: any) => {
        if (typeof event === 'object') {
          this.shortLink = event.link;
          this.loading = false;
        }
      });
  }

  close() {
    this.openSrv.offPos();
  }

  addProductPosDialog() {
    this.addProductPositionForm = new FormGroup({
      pr_description: new FormControl(''),
      pr_b64_image_position: new FormControl('', [Validators.required]),
      pr_image_position: new FormControl(''),
      pr_lane: new FormControl(''),
      pr_shelf: new FormControl(''),
      pr_position: new FormControl(''),
      pr_qty: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.addProductPosDialog();
    console.log('This is the product: ', this.product);
  }

  addProductPos(addProductPositionForm: any): void {
    let product: Products.IProductPosition = {
      product_id: this.product.product_id,
      pr_code: this.product.pr_code,
      pr_description: this.product.pr_description,
      product_position_id:
        this.addProductPositionForm.value.product_position_id,
      pr_b64_image_position: this.productPosition?.pr_b64_image_position,
      pr_image_position: this.addProductPositionForm?.value.pr_image_position,
      pr_lane: this.addProductPositionForm.value.pr_lane,
      pr_shelf: this.addProductPositionForm.value.pr_shelf,
      pr_position: this.addProductPositionForm.value.pr_position,
      pr_qty: this.addProductPositionForm.value.pr_qty,
    };
    this.productSrv
      .addProductPosition(product)
      .subscribe((productData: IWsResult) => {
        this.productPosition = productData.data;
        this.modalClosed.emit();
        this.openSrv.offPos();
        Swal.fire({
          icon: 'success',
          title: 'Aggiunta!',
          text: 'Posizione aggiunta con successo!!!',
          timer: 1500,
          timerProgressBar: true,
        });
      });

    // this.addProductPositionForm.markAllAsTouched();
    // this.openSrv.triggerPosition.next(true);
    // this.onUpload();
  }

  triggerRefresh() {
    // let fromFamily = this.activatedRoute.snapshot.paramMap.get('family');
    // let routeId = fromFamily ? 'id' : 'productId';
    // if (routeId === undefined) return;
    // this.id = this.activatedRoute.snapshot.paramMap.get(routeId);
    // const newId = this.id?.substring(this.id.lastIndexOf('-') + 1);
    // console.log(' Activated route: ', this.activatedRoute.snapshot.paramMap);
    // const route = this.router.url;
    // this.route = route.substring(0, route.lastIndexOf('/'));
    // this.route = this.route.substring(0, this.route.lastIndexOf('/'));
    // this.productSrv
    //   .getProduct(newId)
    //   .subscribe((result: Products.IProductDetails) => {
    //     console.debug('product: ', result);
    //     this.product = result;
    //     this.pr_position = this.product.pr_positions;
    //     console.log('Position is: ', this.pr_position);
    //   });
  }
  
}

<h1 class="page-title">Prepara Ordine</h1>

<div
  class="filterContainer k-display-flex"
  style="flex-direction: row; justify-content: center"
>
  <form
    class="new-search"
    role="search"
    style="
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;
    "
  >
    <label for="search"></label>
    <input
      [(ngModel)]="filterTerm"
      id="search"
      name="search"
      pattern=".*\S.*"
      type="search"
      placeholder="Cerca ordine..."
      autofocus
      required
      (input)="this.onInputChanged($event)"
    />
    <button type="submit" (click)="searchOrders()">
      <i
        class="bx bx-search bx-md"
        style="
          margin: 0;
          padding-top: 10px;
          padding-left: 5px;
          transform: scale(0.8);
        "
      >
      </i>
    </button>
  </form>

  <form
    action=""
    class="search-bar"
    style="
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;
      visibility: hidden !important;
    "
  >
    <button
      class="btn btn-primary msg-btn"
      routerLink="/new-msg"
      style="
        display: flex !important;
        flex-direction: row !important;
        justify-content: center !important;
        visibility: hidden !important;
      "
    >
      + Messaggio
    </button>
  </form>

  <div class="dateContainer k-display-flex justify-content-evenly">
    <div class="col mx-2">
      <h5 class="text-center">DA:</h5>
      <kendo-datepicker
        [(ngModel)]="filters.startingDate"
        class="dropdown-field drop-the-border"
        style="
          background: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 45px;
        "
      ></kendo-datepicker>
    </div>
    <div class="col">
      <h5 class="text-center">A:</h5>
      <kendo-datepicker
        [(ngModel)]="filters.endDate"
        class="dropdown-field drop-the-border"
        style="
          background: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 45px;
        "
      ></kendo-datepicker>
    </div>
  </div>
</div>

<hr class="hr-newBlue" />

<div
  class="row k-display-flex justify-content-end align-items-center"
  style="padding-left: 20px"
>
  <div style="display: flex; flex-direction: row; justify-content: center">
    <h2 class="col-header">
      Totale ordini da preparare: {{ gridData.length }}
    </h2>
  </div>

  <div
    *ngIf="loading"
    style="display: flex; flex-direction: column; align-items: center"
  >
    <mat-spinner></mat-spinner>
    <p>Attendi..</p>
  </div>

  <div class="grid-wrapper">
    <kendo-grid
      [kendoGridBinding]="
        gridData
          | byToDate
            : [
                filters.startingDate,
                filters.endDate,
                filters.dateOrginizedBy.value
              ]
      "
      [skip]="skip"
      [rowHeight]="36"
      [height]="750"
      [width]="1200"
      id="my-grid"
      [pageSize]="pageSize"
      scrollable="virtual"
      (pageChange)="pageChange($event)"
      [navigable]="true"
      [sortable]="true"
      [rowClass]="rowClass"
      [reorderable]="true"
    >
      <kendo-grid-column
        [width]="100"
        field="or_order_number"
        title="N° Ordine"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'text-align': 'center' }"
      >
      </kendo-grid-column>

      <kendo-grid-column
        [width]="420"
        field="or_customer"
        title="Cliente"
        [headerStyle]="{ 'justify-content': 'left' }"
        [style]="{
          display: 'grid',
          'justify-content': 'left',
          'tetx-align': 'left'
        }"
      >
        <ng-template
          kendoGridCellTemplate
          let-dataItem
          style="justify-content: left; text-align: left !important"
        >
          {{ dataItem.or_customer }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [width]="80"
        field="tab_order_priority_desc"
        title="Priorità"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.tab_order_priority_desc }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [width]="150"
        field="ac_last_name"
        title="Operatore"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{
          'tetx-align': 'center',
          display: 'flex',
          'justify-content': 'space-evenly'
        }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div style="display:flex; flex:2; justify-content:start">
            {{ dataItem.ac_first_name }}
            {{ dataItem.ac_last_name?.substring(0, 1) }}.
          </div>  
          <div style="display:flex; flex:1; justify-content:end">
            <button
                class="btn"
                (click)="openChangeOperator(dataItem)"
                [disabled]="dataItem.tab_order_status_cod !== 101"
                style="padding: 0; margin: 0; border: none"
              >
              <i class="bx bx-down-arrow-circle bx-sm"></i>
            </button>
          </div>                
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [width]="220"
        field="tab_order_status_perc"
        title="Avanzamento"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="progress-bar-div">
            <kendo-progressbar
              [value]="dataItem.tab_order_status_perc"
              [label]="setPercentLabel"
            ></kendo-progressbar>
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [width]="120"
        field="or_order_date"
        title="Data ordine"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.or_order_date | date : "dd/MM/yyyy" }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-command-column
        [width]="100"
        title="Controlla"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <button
            kendoGridEditCommand
            rounded="full"
            class="btn-det"
            style="margin: 0; padding: 0; background-color: #017efa !important"
            (click)="gotoDetails(dataItem.order_id)"
            *ngIf="dataItem.tab_order_status_perc < 100"
          >
            <div class="setDisplayToFlexRed btn-controlla">
              <i
                class="bx bx-check-circle bx-md"
                style="margin: 0; padding: 0; transform: scale(0.8)"
              >
              </i>
              <p class="parButton2"></p>
            </div>
          </button>
          <button
            kendoGridEditCommand
            rounded="full"
            class="btn-det"
            style="margin: 0; padding: 0; background-color: #017efa !important"
            (click)="gotoDetails(dataItem.order_id)"
            *ngIf="dataItem.tab_order_status_perc === 100"
          >
            <div class="setDisplayToFlexBlue">
              <i
                class="bx bx-sign bx-md"
                style="margin: 0; padding: 0; transform: scale(0.8)"
              >
              </i>
              <p class="parButton2"></p>
            </div>
          </button>
        </ng-template>
      </kendo-grid-command-column>

      <kendo-grid-command-column
        title="Cancella"
        [width]="100"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <button
            kendoGridEditCommand
            rounded="full"
            class="btn-det"
            style="margin: 0; padding: 0"
            (click)="confirmCancel(dataItem)"
          >
            <div class="setDisplayToFlex btn-cancella">
              <i
                class="bx bx-trash bx-md"
                style="margin: 0; padding: 0; transform: scale(0.8)"
              >
              </i>
              <p class="parButton2"></p>
            </div>
          </button>
        </ng-template>
      </kendo-grid-command-column>

      <kendo-grid-command-column
        title="Blocca/Sblocca"
        [width]="150"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <button
            kendoGridEditCommand
            rounded="full"
            class="btn-det"
            style="margin: 0; padding: 0; background-color: #017efa !important"
            (click)="block(dataItem)"
            *ngIf="dataItem.tab_order_status_desc !== 'Blocca'"
            title="Blocca"
          >
            <div class="setDisplayToFlex btn-blocca">
              <i
                class="bx bx-lock-open bx-md"
                style="margin: 0; padding: 0; transform: scale(0.8)"
              >
              </i>
              <p class="parButton2"></p>
            </div>
          </button>
          <button
            kendoGridEditCommand
            rounded="full"
            class="btn-det"
            style="margin: 0; padding: 0; background-color: #017efa !important"
            (click)="unblock(dataItem)"
            *ngIf="dataItem.tab_order_status_desc === 'Blocca'"
            title="Sblocca"
          >
            <div class="setDisplayToFlex btn-sblocca">
              <i
                class="bx bx-lock bx-md"
                style="margin: 0; padding: 0; transform: scale(0.8)"
              >
              </i>
              <p class="parButton2"></p>
            </div>
          </button>
        </ng-template>
      </kendo-grid-command-column>
    </kendo-grid>
  </div>
</div>

<app-preparazione-ordini-detaggli
  *ngIf="openModal.isOpened"
  [opened]="openModal.isOpened"
  [account_id]="clickedId"
>
</app-preparazione-ordini-detaggli>

<app-confirm-cancel
  *ngIf="openModal.isConfirmCancel"
  [openConfirmCancel]="openModal.isConfirmCancel"
  [orderToGet]="orderToSend"
  (canceledSucces)="this.reloadFunction()"
></app-confirm-cancel>

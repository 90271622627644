<h1 class="page-title">Dashboard</h1>
<hr class="hr-newBlue" />

<div class="searchContainer">
  <div class="containerChecks k-display-flex k-justify-content-center">
    <button
      (click)="checkBoolean('1')"
      (click)="checkedOrder = !checkedOrder"
      [disabled]="checkedCliente || checkedNotes || checkedTracking"
      class="k-display-flex mx-3"
      *ngIf="!checkedOrder"
      style="border: none; background: none; padding: 0; color: #017efa"
    >
      <i class="bx bx-circle bx-md mx-1" style="color: #0658a9"></i>
      <p style="margin: 0; font-size: 28px" class="p-style-title">Ordine</p>
    </button>

    <button
      (click)="checkBoolean('1')"
      (click)="checkedOrder = !checkedOrder"
      class="k-display-flex mx-3"
      *ngIf="checkedOrder"
      style="border: none; background: none; padding: 0; color: #017efa"
    >
      <i class="bx bx-check-circle bx-md mx-1" style="color: #0658a9"></i>
      <p style="margin: 0; font-size: 28px" class="p-style-title">Ordine</p>
    </button>

    <button
      (click)="checkBoolean('2')"
      (click)="checkedCliente = !checkedCliente"
      [disabled]="checkedOrder || checkedNotes || checkedTracking"
      class="k-display-flex mx-3"
      *ngIf="!checkedCliente"
      style="border: none; background: none; padding: 0; color: #017efa"
    >
      <i class="bx bx-circle bx-md mx-1" style="color: #0658a9"></i>
      <p style="margin: 0; font-size: 28px" class="p-style-title">Cliente</p>
    </button>
    <button
      (click)="checkBoolean('2')"
      (click)="checkedCliente = !checkedCliente"
      [disabled]="checkedOrder || checkedNotes || checkedTracking"
      class="k-display-flex mx-3"
      *ngIf="checkedCliente"
      style="border: none; background: none; padding: 0; color: #017efa"
    >
      <i class="bx bx-check-circle bx-md mx-1" style="color: #0658a9"></i>
      <p style="margin: 0; font-size: 28px" class="p-style-title">Cliente</p>
    </button>

    <button
      (click)="checkBoolean('3')"
      (click)="checkedNotes = !checkedNotes"
      [disabled]="checkedOrder || checkedCliente || checkedTracking"
      class="k-display-flex mx-3"
      *ngIf="!checkedNotes"
      style="border: none; background: none; padding: 0; color: #017efa"
    >
      <i class="bx bx-circle bx-md mx-1" style="color: #0658a9"></i>
      <p style="margin: 0; font-size: 28px" class="p-style-title">
        Riferimento
      </p>
    </button>
    <button
      (click)="checkBoolean('3')"
      (click)="checkedNotes = !checkedNotes"
      [disabled]="checkedOrder || checkedCliente || checkedTracking"
      class="k-display-flex mx-3"
      *ngIf="checkedNotes"
      style="border: none; background: none; padding: 0; color: #017efa"
    >
      <i class="bx bx-check-circle bx-md mx-1" style="color: #0658a9"></i>
      <p style="margin: 0; font-size: 28px" class="p-style-title">
        Riferimento
      </p>
    </button>

    <button
      (click)="checkBoolean('4')"
      (click)="checkedTracking = !checkedTracking"
      [disabled]="checkedOrder || checkedCliente || checkedNotes"
      class="k-display-flex mx-3"
      *ngIf="!checkedTracking"
      style="border: none; background: none; padding: 0; color: #017efa"
    >
      <i class="bx bx-circle bx-md mx-1" style="color: #0658a9"></i>
      <p style="margin: 0; font-size: 28px" class="p-style-title">Tracking</p>
    </button>
    <button
      (click)="checkBoolean('4')"
      (click)="checkedTracking = !checkedTracking"
      [disabled]="checkedOrder || checkedCliente || checkedNotes"
      class="k-display-flex mx-3"
      *ngIf="checkedTracking"
      style="border: none; background: none; padding: 0; color: #017efa"
    >
      <i class="bx bx-check-circle bx-md mx-1" style="color: #0658a9"></i>
      <p style="margin: 0; font-size: 28px" class="p-style-title">Tracking</p>
    </button>
  </div>
  <div class="k-display-flex my-2" style="width: 20%; margin: 0 auto">
    <h5 
      class="text-center mx-2"
      style="
        width: 130px;
        margin-top: 3px
      "
    >DAL:</h5>
    <kendo-datepicker
      [(ngModel)]="endDate"
      class="dropdown-field drop-the-border"
      style="
        background: #ffffff;
        border: 1px solid #e0e0e0;
        border-radius: 45px;
      "
      (valueChange)="checkData()"
    ></kendo-datepicker>
  </div>
  <div
      class="col k-display-flex k-align-items-center"
      style="
        justify-content: center;
        border-bottom-left-radius: 10px
      "
    >
      <input
        [(ngModel)]="search"
        type="text"
        placeholder="Cerca.. "
        rounded="full"
        class="input-kendo-lookalike"
        style="text-align: center"
      />
      <button
        class="btn btn-primary searchButton"
        rounded="full"
        [disabled]="this.formattedDate === ''"
        (click)="searchInfoOrders(this.formattedDate, search)"
      >
        <i class="bx bx-sm bx-search"></i>
        CERCA
      </button>
    </div>
</div>

<div class="container text-center mt-4" style="border-radius: 15px">
  <div class="row rowRadius">
    <div class="col colAllign">Ordini da assegnare</div>
    <div
      class="col colAllign"
      style="border-left: 1px solid; border-right: 1px solid"
    >
      Ordini da Preparare:
    </div>
    <div class="col colAllign">Ordini Parziali:</div>
  </div>
  <div class="row">
    <div
      class="col k-display-flex k-align-items-center colInfo"
      style="border-bottom-left-radius: 10px"
    >
      <input
        type="text"
        disabled
        [placeholder]="orderInfo.t_or_new === 0 ? '-' : orderInfo.t_or_new"
        rounded="full"
        class="input-kendo-lookalike"
      />

      <!-- routerLink="/assign-order" -->
      <button
        class="vaiButton"
        rounded="full"
        routerLink="/assign-order"
        [queryParams]="dynamicLink"
        *ngIf="orderInfo.t_or_new"
      >
        <i class="bx bx-sm bxs-right-arrow-circle"></i>
        VAI
      </button>
    </div>

    <div class="col k-display-flex k-align-items-center colInfo">
      <input
        type="text"
        disabled
        [placeholder]="
          orderInfo.t_or_prepare === 0 ? '-' : orderInfo.t_or_prepare
        "
        rounded="full"
        class="input-kendo-lookalike"
      />
      <button
        class="vaiButton"
        rounded="full"
        routerLink="/prepare-order"
        [queryParams]="dynamicLink"
        *ngIf="orderInfo.t_or_prepare"
      >
        <i class="bx bx-sm bxs-right-arrow-circle"></i>
        VAI
      </button>
    </div>
    <div
      class="col k-display-flex k-align-items-center colInfo"
      style="border-bottom-right-radius: 10px"
    >
      <input
        type="text"
        disabled
        [placeholder]="orderInfo.t_or_partial"
        [placeholder]="
          orderInfo.t_or_partial === 0 ? '-' : orderInfo.t_or_partial
        "
        rounded="full"
        class="input-kendo-lookalike"
      />
      <button
        class="vaiButton"
        rounded="full"
        routerLink="/partial-orders"
        [queryParams]="dynamicLink"
        *ngIf="orderInfo.t_or_partial"
      >
        <i class="bx bx-sm bxs-right-arrow-circle"></i>
        VAI
      </button>
    </div>
  </div>
</div>

<div class="container text-center mt-4" style="border-radius: 15px">
  <div class="row rowRadius">
    <div class="col colAllign">Ordini PM:</div>
    <div
      class="col colAllign"
      style="border-left: 1px solid; border-right: 1px solid"
    >
      Ordini da Firmare:
    </div>
    <div class="col colAllign">Ordini Completati:</div>
  </div>
  <div class="row">
    <div
      class="col k-display-flex k-align-items-center colInfo"
      style="border-bottom-left-radius: 10px"
    >
      <input
        type="text"
        disabled
        [placeholder]="orderInfo.t_or_cm === 0 ? '-' : orderInfo.t_or_cm"
        rounded="full"
        class="input-kendo-lookalike"
      />
      <button
        class="vaiButton"
        rounded="full"
        routerLink="/pm-orders"
        [queryParams]="dynamicLink"
        *ngIf="orderInfo.t_or_cm"
      >
        <i class="bx bx-sm bxs-right-arrow-circle"></i>
        VAI
      </button>
    </div>

    <div class="col k-display-flex k-align-items-center colInfo">
      <input
        type="text"
        disabled
        rounded="full"
        [placeholder]="
          orderInfo.t_or_to_sign === 0 ? '-' : orderInfo.t_or_to_sign
        "
        class="input-kendo-lookalike"
      />
      <button
        class="vaiButton"
        rounded="full"
        routerLink="/firmare"
        [queryParams]="dynamicLink"
        *ngIf="orderInfo.t_or_to_sign"
      >
        <i class="bx bx-sm bxs-right-arrow-circle"></i>
        VAI
      </button>
    </div>
    <div
      class="col k-display-flex k-align-items-center colInfo"
      style="border-bottom-right-radius: 10px"
    >
      <input
        type="text"
        disabled
        rounded="full"
        [placeholder]="
          orderInfo.t_or_completed === 0 ? '-' : orderInfo.t_or_completed
        "
        class="input-kendo-lookalike"
      />
      <button
        class="vaiButton"
        rounded="full"
        routerLink="/history"
        [queryParams]="dynamicLink"
        *ngIf="orderInfo.t_or_completed"
      >
        <i class="bx bx-sm bxs-right-arrow-circle"></i>
        VAI
      </button>
    </div>
  </div>
</div>
<div class="container text-center mt-4" style="border-radius: 15px">
  <div class="row rowRadius">
    <div class="col colAllign">Borderò generati:</div>
    <div
      class="col colAllign"
      style="border-left: 1px solid; border-right: 1px solid"
    >
      Spedizioni:
    </div>
    <div class="col colAllign">Prodotti magazzino:</div>
  </div>
  <div class="row">
    <div
      class="col k-display-flex k-align-items-center colInfo"
      style="border-bottom-left-radius: 10px"
    >
      <input
        type="text"
        disabled
        rounded="full"
        [placeholder]="
          otherInfo.t_or_bordero === 0 ? '-' : otherInfo.t_or_bordero
        "
        class="input-kendo-lookalike"
      />
      <button
        class="vaiButton"
        rounded="full"
        routerLink="/borderò"
        [queryParams]="dynamicLink"
        *ngIf="otherInfo.t_or_bordero"
      >
        <i class="bx bx-sm bxs-right-arrow-circle"></i>
        VAI
      </button>
    </div>

    <div class="col k-display-flex k-align-items-center colInfo">
      <input
        type="text"
        disabled
        rounded="full"
        class="input-kendo-lookalike"
        [placeholder]="
          orderInfo.t_or_delivery === 0 ? '-' : orderInfo.t_or_delivery
        "
      />
      <button
        class="vaiButton"
        rounded="full"
        routerLink="/status"
        [queryParams]="dynamicLink"
        *ngIf="orderInfo.t_or_delivery"
      >
        <i class="bx bx-sm bxs-right-arrow-circle"></i>
        VAI
      </button>
    </div>
    <div
      class="col k-display-flex k-align-items-center colInfo"
      style="border-bottom-right-radius: 10px"
    >
      <input
        type="text"
        disabled
        rounded="full"
        [placeholder]="otherInfo.t_products === 0 ? '-' : otherInfo.t_products"
        class="input-kendo-lookalike"
      />
      <button
        class="vaiButton"
        rounded="full"
        routerLink="/products"
        [queryParams]="dynamicLink"
        *ngIf="otherInfo.t_products"
      >
        <i class="bx bx-sm bxs-right-arrow-circle"></i>
        VAI
      </button>
    </div>
  </div>
</div>

<div class="container text-center mt-4" style="border-radius: 15px">
  <div class="row rowRadius">
    <div class="col colAllign">Accounts:</div>
    <div
      class="col colAllign"
      style="border-left: 1px solid; border-right: 1px solid"
    >
      Agenti:
    </div>
    <div class="col colAllign">Messaggi:</div>
  </div>
  <div class="row">
    <div
      class="col k-display-flex k-align-items-center colInfo"
      style="border-bottom-left-radius: 10px"
    >
      <input
        type="text"
        rounded="full"
        class="input-kendo-lookalike"
        [placeholder]="otherInfo.t_account"
        disabled
      />
      <button
        class="vaiButton"
        rounded="full"
        routerLink="/accounts"
        [queryParams]="dynamicLink"
        *ngIf="otherInfo.t_account"
      >
        <i class="bx bx-sm bxs-right-arrow-circle"></i>
        VAI
      </button>
    </div>

    <div class="col k-display-flex k-align-items-center colInfo">
      <input
        type="text"
        rounded="full"
        class="input-kendo-lookalike"
        [placeholder]="otherInfo.t_agents === 0 ? '-' : otherInfo.t_agents"
        disabled
      />
      <button
        class="vaiButton"
        rounded="full"
        routerLink="/agents"
        [queryParams]="dynamicLink"
        *ngIf="otherInfo.t_agents"
      >
        <i class="bx bx-sm bxs-right-arrow-circle"></i>
        VAI
      </button>
    </div>
    <div
      class="col k-display-flex k-align-items-center colInfo"
      style="border-bottom-right-radius: 10px"
    >
      <input
        type="text"
        rounded="full"
        class="input-kendo-lookalike"
        disabled
        [placeholder]="otherInfo.t_message === 0 ? '-' : otherInfo.t_message"
      />

      <button
        class="vaiButton"
        rounded="full"
        routerLink="/messages"
        [queryParams]="dynamicLink"
        *ngIf="otherInfo.t_message"
      >
        <i class="bx bx-sm bxs-right-arrow-circle"></i>
        VAI
      </button>
    </div>
  </div>
</div>

<!-- <div class="containerr">
  <div class="section">
    <div class="nft1" routerLink="/assign-order">
      <div class="main">
        <h2 class="title-card">Ordini da Assegnare:</h2>
        <hr />
        <div class="creator">
          <h2 class="title-card duration">
            {{ orderInfo.t_or_new === 0 ? "-" : orderInfo.t_or_new }}
          </h2>
        </div>
      </div>
    </div>
    <div class="nft1" routerLink="/prepare-order">
      <div class="main">
        <h2 class="title-card">Ordini da Preparare:</h2>
        <hr />
        <div class="creator">
          <h2 class="title-card duration">
            {{ orderInfo.t_or_prepare === 0 ? "-" : orderInfo.t_or_prepare }}
          </h2>
        </div>
      </div>
    </div>
    <div class="nft1" routerLink="/partial-orders">
      <div class="main">
        <h2 class="title-card">Ordini Parziali:</h2>
        <hr />
        <div class="creator">
          <h2 class="title-card duration">{{ orderInfo.t_or_partial }}</h2>
        </div>
      </div>
    </div>
    <div class="nft1" routerLink="/pm-orders">
      <div class="main">
        <h2 class="title-card">Ordini PM:</h2>
        <hr />
        <div class="creator">
          <h2 class="title-card duration">
            {{ orderInfo.t_or_cm === 0 ? "-" : orderInfo.t_or_cm }}
          </h2>
        </div>
      </div>
    </div>
  </div>
  <div class="section">
    <div class="nft1" routerLink="/firmare">
      <div class="main">
        <h2 class="title-card">Ordini da Firmare:</h2>
        <hr />
        <div class="creator">
          <h2 class="title-card duration">{{ orderInfo.t_or_to_sign }}</h2>
        </div>
      </div>
    </div>
    <div class="nft2" routerLink="/history">
      <div class="main">
        <h2 class="title-card">Ordini Completati:</h2>
        <hr />
        <div class="creator">
          <h2 class="title-card duration">{{ orderInfo.t_or_completed }}</h2>
        </div>
      </div>
    </div>
    <div class="nft2" routerLink="/borderò">
      <div class="main">
        <h2 class="title-card">Borderò generati:</h2>
        <hr />
        <div class="creator">
          <h2 class="title-card duration">{{ otherInfo.t_or_bordero }}</h2>
        </div>
      </div>
    </div>
    <div class="nft2" routerLink="/status">
      <div class="main">
        <h2 class="title-card">Spedizioni:</h2>
        <hr />
        <div class="creator">
          <h2 class="title-card duration">{{ orderInfo.t_or_delivery }}</h2>
        </div>
      </div>
    </div>
  </div>
  <div class="section">
    <div class="nft1" routerLink="/partial-orders">
      <div class="main">
        <h2 class="title-card">Ordini Parziali:</h2>
        <hr />
        <div class="creator">
          <h2 class="title-card duration">{{ otherInfo.t_products }}</h2>
        </div>
      </div>
    </div>

    <div class="nft3" routerLink="/accounts">
      <div class="main">
        <h2 class="title-card">Account:</h2>
        <hr />
        <div class="creator">
          <h2 class="title-card duration">{{ otherInfo.t_account }}</h2>
        </div>
      </div>
    </div>
    <div class="nft2" routerLink="/agents">
      <div class="main">
        <h2 class="title-card">Agenti:</h2>
        <hr />
        <div class="creator">
          <h2 class="title-card duration">{{ otherInfo.t_agents }}</h2>
        </div>
      </div>
    </div>
  </div>
  <div class="section">
     <div class="nft4" routerLink="/quality">
      <div class="main">
        <h2 class="title-card">Ordini da Controllare:</h2>
        <hr />
        <div class="creator">
          <h2 class="title-card duration">-</h2>
        </div>
      </div>
    </div>
    <div class="nft4" routerLink="/quality">
      <div class="main">
        <h2 class="title-card">Ordini Conformi:</h2>
        <hr />
        <div class="creator">
          <h2 class="title-card duration">-</h2>
        </div>
      </div>
    </div>
    <div class="nft4" routerLink="/quality">
      <div class="main">
        <h2 class="title-card">Ordini Non Conformi:</h2>
        <hr />
        <div class="creator">
          <h2 class="title-card duration">-</h2>
        </div>
      </div>
    </div> -->
<!-- <div class="nft3" routerLink="/messages">
      <div class="main">
        <h2 class="title-card">Messaggi:</h2>
        <hr />
        <div class="creator">
          <h2 class="title-card duration">{{ otherInfo.t_message }}</h2>
        </div>
      </div>
    </div>
  </div>
</div>  -->

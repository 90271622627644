import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { IWorker } from 'src/app/models/IWorker.model';
import { DropdownService } from 'src/app/services/shared/dropdown.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-change-operator',

  templateUrl: './change-operator.component.html',
  styleUrl: './change-operator.component.scss',
})

export class ChangeOperatorComponent implements OnInit {

  @Input() itemSelected: any;
  operators: IWorker[] = [];
  selectedOperator;
  formattedOperators: any;
  public defaultItem: { full_name: string; account_id: number } = {
    full_name: 'Seleziona un operatore..',
    account_id: null,
  };

  constructor(
    private workerService: DropdownService,
    public popoverController: PopoverController
  ) { }

  ngOnInit(): void {
    console.log('Item selected is', this.itemSelected);
    this.getOperators();
  }

  getOperators() {
    this.workerService.getWorkers().subscribe((data) => {
      if (!data) 
        return;
      this.operators = data;
      console.log('Operators are', this.operators);
      this.formattedOperators = this.operators.map((operator) => {
        return {
          full_name: operator.ac_first_name + ' ' + operator.ac_last_name,
          account_id: operator.account_id,
        };
      });
    });
  }
  
  selectOperator(event) {
    console.log('Selected operator is', event);
    this.selectedOperator = event;
  }

  changeOperator() {
    console.log('Selected on change is', this.selectedOperator);
    this.workerService
      .changeOperator(
        this.itemSelected.order_id,
        this.selectedOperator.account_id
      )
      .subscribe((data) => {
        if (!data) 
          return;
        if (data.success) {
          Swal.fire({
            icon: 'success',
            title: 'Modifiche!',
            text: "Modifiche dell'operatore effettuate con successo!",
            timer: 1500,
            timerProgressBar: true,
          });
          this.popoverController.dismiss();
        }
        if (!data.success) {
          Swal.fire({
            icon: 'error',
            title: 'Errore!',
            text: "Impossibile cambiare l'operatore! Riprova!",
            timer: 1500,
            timerProgressBar: true,
          });
          this.popoverController.dismiss();
        }
        console.log('Data is', data);
      });
  }

}

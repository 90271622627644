export namespace Error {

  export interface IError {
    err_code: number;
    err_type: string;
    err_descr: string;
    err_value: number;
    err_color: string;
    class: string;
  }

  export class Error implements IError {
    err_code: number;
    err_type: string;
    err_descr: string;
    err_value: number;
    err_color: string;
    class: string = '';

    constructor(
      code,
      type,
      descr,
      value,
      color,
      setColor = false
    ) {
      this.err_code = code;
      this.err_type = type;
      this.err_descr = descr;
      this.err_value = value;
      this.err_color = color;

      switch (code) {
        case 1:
          this.class = 'high-priority';
          if (setColor)
            document.body.style.setProperty(
              '--app-color-high',
              this.err_color
            );
          break;
        case 2:
          this.class = 'medium-priority';
          break;
        case 3:
          this.class = 'low-priority';
          break;
      }
    }
  }
  
}



export interface IErrors {
  err_id: number;
  err_type: string;
  err_description: string;
  err_value: number;
}

export class Errors implements IErrors {
  err_id: number;
  err_type: string;
  err_description: string;
  err_value: number;


  constructor(item: IErrors | null) {
    if (!item) return;
    this.err_id = item.err_id;
    this.err_type = item.err_type;
    this.err_description = item.err_description;
    this.err_value = item.err_value;
  }
}

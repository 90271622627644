<nav [class.close]="isClosedR" class="right-sidebar dark-background">
  <header>
    <i class="bx bx-chevron-right toggle" (click)="toggleRightSidenav()"></i>
  </header>

  <div class="wrapper">
    <div class="right-text1">     
      <h1 class="right-text">Priorità lista ordini</h1>
    </div>

    <div class="title-priority">
      <div class="buttons-class">
        <button class="button1" (click)="selectOrders(1)">
          <h1
            style="
              font-family: 'Lato';
              font-style: normal;
              font-weight: 700;
              font-size: 20px;
              line-height: 24px;
              color: #5ec186;
            "
          >
            Normale
          </h1>
        </button>
        <button class="button2" (click)="selectOrders(2)">
          <h1
            class="h1PrioritaTextMedia"
            style="
              font-family: 'Lato';
              font-style: normal;
              font-weight: 700;
              font-size: 20px;
              line-height: 24px;
              position: relative;
              right: 10px;
            "
          >
            Media
          </h1>
        </button>
        <button class="button3" (click)="selectOrders(3)">
          <h1
            class="h1PrioritaTextAlta"
            style="
              font-family: 'Lato';
              font-style: normal;
              font-weight: 700;
              font-size: 20px;
              line-height: 24px;
              position: relative;
              right: 18px !important;
              color: #e00000;
            "
          >
            Alta
          </h1>
        </button>
      </div>
    </div>

    <div class="row hr-col">
      <hr class="col-md-4" [class.hr-newGreen]="isChanged == 1" />
      <hr class="col-md-4" [class.hr-newYellow]="isChanged == 2" />
      <hr class="col-md-4" [class.hr-newRed]="isChanged == 3" />
    </div>
    <div class="ngfor">
      <div
        class="high-priority"
        *ngFor="let order of openOrders | byPriorityCode : isChanged"
      >
        <span
          ><strong>{{ order.or_order_number }}</strong></span
        >
        <div *ngIf="order.tab_order_priority_desc == 'Alta'">
          <button
            class="setDisplayToFlex setWidthForButtonPriorita"
            (click)="goToOrder(order.order_id)"
            style="border: 1px solid #08da5c"
          >
            <i 
              class="bx bx-edit bx-sm posRelBox2 k-pr-1" 
              style="
                margin-right: -20px; 
                margin-left: -50px;
              "
            ></i>
            <p 
              class="parButton3" 
              style="
                margin-left: -40px; 
                font-size: 15px
              "
            >PREPARA</p>
          </button>
        </div>

        <div *ngIf="order.tab_order_priority_desc == 'Media'">
          <button
            class="setDisplayToFlex2 setWidthForButtonPriorita"
            (click)="goToOrder(order.order_id)"
          >
            <i 
              class="bx bx-edit bx-sm posRelBox2 k-pr-1" 
              style="
                margin-right: -20px; 
                margin-left: -50px;
              "
            ></i>
            <p 
              class="parButton3" 
              style="
                margin-left: -40px; 
                font-size: 15px
              "
            >PREPARA</p>
          </button>
        </div>
        <div *ngIf="order.tab_order_priority_desc == 'Bassa'">
          <button
            class="setDisplayToFlex3 setWidthForButtonPriorita"
            (click)="goToOrder(order.order_id)"
          >
            <i 
              class="bx bx-edit bx-sm posRelBox2 k-pr-1" 
              style="
                margin-right: -20px; 
                margin-left: -50px;
              "
            ></i>
            <p 
              class="parButton3" 
              style="
                margin-left: -40px; 
                font-size: 15px
              "
            >PREPARA</p>
          </button>
        </div>
        <span style="font-size:15px;">{{ order.or_order_date | date : "dd/MM/yyyy" }}</span>
      </div>
    </div>
  </div>
  <br /><br><br><br>

  <div class="msg-header">
    <h1 class="msg-text">Messaggi</h1>
  </div>
  <div class="row hr-col resizeColHr">
    <hr class="col-md-10 blue-hr" />
  </div>
  <div class="container ngfor">
    <div class="high-priority2" *ngFor="let msg of messsages">
      <div class="row new-col">
        <div class="col-2">
          <span class="col-md-10 new-column" *ngIf="msg.ms_status == 'U'">
            <i
              class="bx bxs-bell bx-md"
              style="
                cursor: pointer; 
                color:red
              "
              (click)="goToMessage(msg.message_id)"
            ></i>
          </span>
          <span
            [ngClass]=""
            class="col-md-2 new-column"
            *ngIf="msg.ms_status == 'R'"
          >
            <i
              class="bx bx-bell bx-md"
              style="cursor:pointer"
              (click)="goToMessage(msg.message_id)"
            ></i>
          </span>
        </div>        
        <div 
          class="col-10" 
          style="
            text-align: left; 
            cursor: pointer; 
            margin-bottom: 10px;
          "
          (click)="goToMessage(msg.message_id)">{{ msg.ms_title }}
        </div>
      </div>
      <div class="row" style="text-align: center">
        <div>
          <span class="col text-col2">{{ msg.ms_recipient_name }}</span>
        </div>
      </div>
    </div>
  </div>
</nav>

<app-messaggi-dettagli
  (modalClosed)="onModalClosed()"
  *ngIf="closeSrv.isPhotoOn"
  [opened]="closeSrv.isPhotoOn"
  [message_id]="clickedId"
>
</app-messaggi-dettagli>

<app-preparazione-ordini-detaggli
  *ngIf="closeSrv.isOpened"
  [opened]="closeSrv.isOpened"
  [account_id]="clickedId"
>
</app-preparazione-ordini-detaggli>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Orders } from 'src/app/models/Orders.model';
import { OrdersService } from 'src/app/services/orders.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';

@Component({
  selector: 'app-bordero-page',
  templateUrl: './bordero-page.component.html',
  styleUrls: ['./bordero-page.component.scss'],
})

export class BorderoPageComponent {

  @Input() openedBorder: boolean = false;
  @Output() modalClosed = new EventEmitter<void>();
  @Output() answerSubmitted = new EventEmitter<string>();
  answer: string;

  public borderForm: FormGroup;
  public orders: Orders.OrderSignature[] = [];

  submitForm() {
    this.answerSubmitted.emit(this.answer);
    this.openModal.offBordero();
  }  

  constructor(
    private openOrdersServ: OrdersService,
    private router: Router,
    public openModal: OpenModalService
  ) { }

  ngOnInit() {
    //
  }

  close() {
    this.openModal.offBordero();
  }

  onYesClick(): void {
    this.modalClosed.emit();
    this.openModal.offConfirmComplete();
  }
  
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Orders } from 'src/app/models/Orders.model';
import { Products } from 'src/app/models/Product.model';
import { OrdersService } from 'src/app/services/orders.service';

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss'],
})

export class CollectionComponent implements OnInit {  
  
  public products: Products.Product[] = [];
  public order: Orders.Order;
  public areas: string[];
  firstEnteredOnPage: boolean = true;
  isViewDetails: boolean = false;
  numbers: number[] = [];
  id: string;

  constructor(
    private router: Router,
    private orderService: OrdersService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.firstEnteredOnPage = true;
    this.orderService.getOrder(this.id).subscribe(async (result) => {
      this.order = result.data;
      await this.orderService.getOrderSaved(this.id).then((result) => {
        if (result) this.order.products = result.products;
      });
      console.debug('orders: ', this.order);
      this.checkSelectedAll();
    });
  }

  async checkProduct(id: number) {
    this.order.products[id].op_checked = !this.order.products[id].op_checked;
    this.order.orPartial =
      this.order.products.every((value) => value.op_checked == true) &&
      this.order.products.every((value) => value.op_qta == value.orQtaNeeded)
        ? 'N'
        : 'S';
    this.order.products.sort(
      (a, b) =>
        +(a.op_checked ? a.op_checked : false) -
        +(b.op_checked ? b.op_checked : false)
    );
    this.checkSelectedAll();
  }

  async goToDetails(id: number) {
    this.order.products[id].op_checked = !this.order.products[id].op_checked;
    this.checkSelectedAll();
  }

  showDetails(productId: string) {
    console.debug('productId');
    productId = productId.trim();
    this.router.navigate([`product/${productId.trim()}`], {
      relativeTo: this.activatedRoute,
    });
  }

  async checkSelectedAll(stepBack = false) {
    const stepFrw = 1;
    const stepBck = 0;
    this.orderService.setProductsChecked(this.order);
    if (
      this.order.status[0].os_status_order_cod > 1 &&
      this.order.status[0].os_status_order_cod < 7 &&
      this.firstEnteredOnPage
    ) {
      this.order.products.forEach((element) => {
        element.op_checked = true;
        element.orQtaNeeded = element.op_qta;
      });
      this.firstEnteredOnPage = false;
    }
    console.log('Status is', this.order.status[0].os_status_order_cod);
    if (this.order.products.every((value) => value.op_checked == true)) {
      this.order.stepCount = stepFrw;
      if (this.orderService.pages.value <= stepFrw)
        this.orderService.pages.next(stepFrw);
    } 
    else {
      this.order.stepCount = stepBck;
      this.orderService.pages.next(stepBck);
      this.order.buttons[stepBck].completed = false;
    }
  }
  
}

import {
  faBoxOpen,
  faTruck,
  faTruckFast,
  faTruckRampBox,
} from '@fortawesome/free-solid-svg-icons';

export namespace Buttons {

  export interface IButton {
    title: string;
    completed: boolean;
    titleCompleted: string;
    option?: any;
    link: string;
  }

  export interface IButtonAnswer {
    text: string;
    value: boolean;
    class: string;
  }

  export class AnswerButtons {
    static buttonYes: IButtonAnswer = {
      text: 'Si',
      value: true,
      class: 'button-yes',
    };
    static buttonSave: IButtonAnswer = {
      text: 'Salva',
      value: true,
      class: 'button-yes',
    };
    static buttonNo: IButtonAnswer = {
      text: 'Annulla',
      value: false,
      class: 'button-no',
    };
    static buttonChange: IButtonAnswer = {
      text: 'Cambia',
      value: false,
      class: 'button-no',
    };
    static buttonGenerateLabel: IButtonAnswer = {
      text: 'GENERA ETICHETTA',
      value: true,
      class: 'button-yes',
    };
  }

  export class Button implements IButton {
    title: string;
    completed: boolean;
    titleCompleted: string;
    option?: any;
    link: string;

    constructor(
      title: string,
      completed: boolean,
      titleCompleted: string,
      link: string,
      option = null
    ) {
      this.title = title;
      this.completed = completed;
      this.titleCompleted = titleCompleted;
      this.link = link;
      this.option = option;
    }
  }

  export class Buttons extends Button {

    public buttons: IButton[] = [];

    public getMobileUserPrepareOrder() {
      let button = new Button(
        'INIZIA RACCOLTA',
        false,
        'PRODOTTI',
        'collection'
      );
      this.buttons.push(button);
      button = new Button(
        'RACCOLTA DOCUMENTI',
        false,
        'DOCUMENTI',
        'documents'
      );
      this.buttons.push(button);
      button = new Button(
        'IMBALLAGGIO', 
        false, 
        'IMBALLAGGIO', 
        'packaging'
      );
      this.buttons.push(button);
      button = new Button(
        'PREPARA SPEDIZIONE',
        false,
        'SPEDIZIONE',
        'shipment'
      );
      this.buttons.push(button);
      return this.buttons;
    }

    public getMobileAdminPrepareOrder() {
      let button = new Button(
        'VERIFICA PRODOTTI',
        false,
        'PRODOTTI VERIFICATI',
        'check-products'
      );
      this.buttons.push(button);
      button = new Button(
        'SCEGLI PRIORITA’', 
        false, 
        'PRIORITA’', 
        'priority'
      );
      this.buttons.push(button);
      button = new Button(
        'SCEGLI CORRIERE', 
        false, 
        'CORRIERE', 
        'carrier'
      );
      this.buttons.push(button);
      button = new Button(
        'VERIFICA INDIRIZZO',
        false,
        'INDIRIZZI VERIFICATI',
        'check-address'
      );
      this.buttons.push(button);
      button = new Button(
        'SCEGLI OPERATORE', 
        false, 
        'OPERATORE', 
        'worker'
      );
      this.buttons.push(button);
      return this.buttons;
    }

    public getShipmentDetails() {
      let button = new Button(
        'Pronto per spedizione',
        false,
        '',
        '',
        faTruckRampBox
      );
      this.buttons.push(button);
      button = new Button(
        'In transito', 
        false, 
        '', 
        '', 
        faTruck
      );
      this.buttons.push(button);
      button = new Button(
        'In Consegna', 
        false, 
        '', 
        '', 
        faTruckFast
      );
      this.buttons.push(button);
      button = new Button(
        'Consegnato',
        false,
        'Mancata consegna',
        '',
        faBoxOpen
      );
      this.buttons.push(button);

      return this.buttons;
    }

    constructor() {
      super(null, null, null, null);
    }
    
  }

}

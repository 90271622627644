import {
  Component,
  OnInit,
  Output,
  Renderer2,
  RendererFactory2,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { IMessageList } from 'src/app/models/IMessage.model';
import { Orders } from 'src/app/models/Orders.model';
import { IWsResult } from 'src/app/models/ws_result.model';
import { AuthService } from 'src/app/services/auth.service';
import { MessagesService } from 'src/app/services/messages.service';
import { OrdersService } from 'src/app/services/orders.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import { SidebarService } from 'src/app/services/shared/sidebar.service';

@Component({
  selector: 'app-right-sidebar',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.scss'],
})

export class RightSidebarComponent implements OnInit {  

  @Output() modalClosed1 = new EventEmitter<void>();

  public openOrders: Orders.IOrderOpenList[] = [];
  public subsetOrders: Orders.IOrderOpenList[] = [];
  public messsages: IMessageList[] = [];
  public loading: boolean = true;
  private renderer!: Renderer2;
  public scroll = true;

  isStatus: string = 'R';
  clickedId: any;
  timer = null;
  refreshInterval: number = 60000;
  isChanged: number = 1;
  isClosed: boolean = false;
  isClosedR: boolean = false;
  isDark: boolean = false;
  theme: string = 'Dark mode';

  constructor(
    rendererFactory: RendererFactory2,
    public sidebarService: SidebarService,
    public authServ: AuthService,
    public ordersServ: OrdersService,
    public router: Router,
    private cdr: ChangeDetectorRef,
    private msgSrv: MessagesService,
    public closeSrv: OpenModalService
  ) {
      this.renderer = rendererFactory!.createRenderer(null, null);
  }  

  goToOrder(order_id: any) {
    this.router.navigate(['/prepare-order', order_id]);
    this.modalClosed1.emit();
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.getMessages();
    this.getOpenOrders(1);
  }

  toggleRightSidenav() {
    this.isClosedR = !this.isClosedR;
    this.sidebarService.toggleRightSidebar();
  }

  selectOrders(code: any) {
    this.isChanged = code;
  }

  getOpenOrders(priorityCode: number) {
    this.ordersServ
      .getOpenOrders()
      .subscribe((openOrdersData: Orders.OrderOpenList[]) => {
        this.openOrders = openOrdersData;
      });
  }

  getMessages() {
    return this.msgSrv.getMessages().subscribe((messageData: IWsResult) => {
      if (
        messageData.error_message &&
        messageData?.error_message?.msg_code === 8
      )
        this.messsages = [];
      else 
        this.messsages = messageData.data;
    });
  }

  goToMessage(message_id: any) {
    this.clickedId = message_id;
    console.debug(this.clickedId);
    this.closeSrv.openMsg();
  }

  onModalClosed() {
    this.getMessages();
    console.log('Modal Closed successfully!');
  }
  
}

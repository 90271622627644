import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})

export class AutoLoginGuard  {

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.isUser.pipe(
      filter((val) => val !== null), 
      take(1), 
      map((isUser) => {
        if (isUser) {
          this.router.navigateByUrl('/prepare-order', { replaceUrl: true });
        } 
        else {
          return true;
        }
        return false;
      })
    );
  }

}

<div class="k-overlay dark-background"></div>

<div class="dark-background">
  <kendo-dialog
    title=""
    (close)="goBackFunction()"
    *ngIf="opened"
    [minWidth]="250"
    [width]="550"
    [maxWidth]="1150"
    class="k-form"
    [formGroup]="addObtForm"
    style="left: 6%;"
  >
    <div 
      style="
        display: flex;
        flex-direction: row;
        justify-content: end;
        margin-top: -15px;
        z-index: 1000;
        position: fixed;
        margin-left: -35px;
      "
    >
      <button
        (click)="close()"
        class="btn btn3"
        rounded="full"
        style="
          background-color: #e90000;
          color: #ffffff;
          width: 35px !important;
          height: 35px !important;
        "
      >    
        <i
          class="bx bx-x bx-sm posRelBox2"
          style="
            color: #ffffff; 
            bottom: 10px; 
            position: relative !important;
            left: -80% !important;
            top: -1px !important;
          "
        ></i>
      </button> 
    </div>
    <h1 class="page-title">Obiettivo</h1>
    <hr class="hr-dialog" style="top: 10px !important" />
    <form 
      class="k-form" 
      [formGroup]="addObtForm"
      (ngSubmit)="addValue(addObtForm)">
      <fieldset class="k-form-fieldset det mb-2">
        <kendo-formfield class="setDisplay-Prep">
          <kendo-label 
            [for]="value"
            class="title" 
            text="Valore"
          ></kendo-label>
          <input
            formControlName="value"
            kendoTextBox
            #value
            [required]="true"
            rounded="full"
            class="setBorder-Prepp"
            type="number"
          />
          <kendo-formerror
            *ngIf="
              (addObtForm.get('value')?.touched ||
                addObtForm.get('value')?.dirty) &&
              addObtForm.get('value')?.errors
            "
          >
            Campo obbligatorio!
          </kendo-formerror>
        </kendo-formfield>
      </fieldset>   

      <div
        class="col"
        style="
          display: flex; 
          justify-content: center; 
          margin-top: 10px
        "
      >
        <button
          class="btn btn-primary btn1 mr-100 kDisplayFlex"
          rounded="full"
          type="submit"
          [disabled]="!addObtForm.valid"
          (click)="addValue(addObtForm)"
          style="
            position: relative; 
            left: 30px;
            top: 20px;
          "
        >
          <i 
            class="bx bx-check bx-sm posRelBox2"
            style="
              color: #ffffff; 
              right: 3px; 
              position: relative; 
              bottom: 10px
            "
          ></i>
          <p class="posRelBox2">IMPOSTA</p>
        </button>
      </div>
    </form>
  </kendo-dialog>
</div>

<kendo-dialog
  *ngIf="isProdCheck"
  (close)="close()"
  [minWidth]="250"
  [width]="1350"
  [maxWidth]="1350"
  center
  style="left: 6%"
>
  <div
    style="
      display: flex;
      flex-direction: row;
      justify-content: end;
      margin-top: -15px;
      z-index: 1000;
      position: fixed;
      margin-left: -35px;
    "
  >
    <button
      (click)="close()"
      class="btn btn3"
      rounded="full"
      style="
        background-color: #e90000;
        color: #ffffff;
        width: 35px !important;
        height: 35px !important;
      "
    >
      <i
        class="bx bx-x bx-sm posRelBox2"
        style="
          color: #ffffff;
          bottom: 10px;
          position: relative !important;
          left: -80% !important;
          top: -1px !important;
        "
      ></i>
      <p class="posRelBox2" style="margin-top: -3px">AGGIUNGI</p>
    </button>
  </div>
  <h1 class="page-title">Seleziona i prodotti</h1>
  <div class="row" style="display: flex; flex-direction: row">
    <div class="text-buttons">
      <div class="button-left" style="justify-content: start !important">
        <button
          class="k-display-flex align-items: flex-end;"
          (click)="selectAll()"
          class="btn btn3 btn-select"
          rounded="full"
        >
          <i
            class="bx bx-check-circle bx-sm posRelBox2"
            style="
              color: #ffffff;
              right: 6px;
              bottom: 10px;
              position: relative;
              margin-top: 2px;
            "
          ></i>
          <span style="padding-top: 5px"> SELEZIONA TUTTI </span>
        </button>
      </div>
      <div
        class="button-left"
        style="justify-content: end !important"
        *ngIf="!isDDT"
      >
        <button
          class="btn btn3"
          rounded="full"
          style="
            background-color: #017efa;
            color: #ffffff;
            justify-content: center !important;
            display: flex;
            width: 192px !important;
            height: 45px !important;
          "
          (click)="addNewProduct()"
        >
          <i
            class="bx bx-plus-circle bx-sm posRelBox2"
            style="color: #ffffff; right: 6px; bottom: 10px; position: relative"
          ></i>
          <p class="posRelBox2" style="margin-top: -3px">AGGIUNGI</p>
        </button>
      </div>
    </div>
  </div>
  <div class="bigusContainer" style="display: flex">
    <div class="max-height-scroll-250 mt-3" style="width: 100%">
      <div
        class="new-text-2 arrange-filter mt-2"
        *ngFor="let prod of order?.products; let i = index"
        [class.hr-newGreen]="prod.op_checked"
      >
        <div
          class="setDescr"
          style="display: flex; justify-content: flex-end; align-items: center"
        >
          <kendo-label (click)="opChecked(prod, i)" class="changeSize">
            {{ prod.op_description }}
          </kendo-label>
        </div>

        <div class="example" class="col-sm-3 setDisplayToProducts">
          <kendo-dropdownlist
            *ngIf="!isDDT"
            [data]="numbers"
            (click)="changeProductNumber(prod.op_qta)"
            placeholder="{{ prod.orQtaNeeded }} / {{ prod.op_qta }}"
            (ngModelChange)="updateProduct(prod.orQtaNeeded, i)"
            [(ngModel)]="prod.orQtaNeeded"
            class="drop-the-border w-50"
          >
            <ng-template></ng-template>
          </kendo-dropdownlist>
          <div class="container" *ngIf="isDDT">{{ prod.op_qta }}</div>
          <div class="deleteButton" *ngIf="!isDDT">
            <i
              class="bx bx-plus-circle bx-md"
              style="cursor: pointer; color: rgb(0, 52, 97)"
              (click)="selectProduct(prod, i)"
              title="Sostituisci prodotto"
            ></i>
          </div>
          <div class="deleteButton" *ngIf="!isDDT">
            <i
              class="bx bxs-trash-alt bx-md"
              style="cursor: pointer; color: rgb(255, 41, 41)"
              (click)="deleteProduct(prod, i)"
              title="Elimina prodotto"
            ></i>
          </div>
          <div class="buttones">
            <i
              class="bx bx-info-circle bx-md"
              style="cursor: pointer"
              (click)="goToProductDetails(prod.op_code)"
              title="Info"
            ></i>
          </div>
        </div>
      </div>
      <div class="text-buttons my-5">
        <div class="button-left">
          <button
            class="btn btn3"
            rounded="full"
            style="
              background: rgb(0, 146, 73);
              color: #ffffff;
              justify-content: center !important;
              display: flex;
              width: 192px !important;
              height: 50px !important;
            "
            (click)="saveStep('products')"
          >
            <i
              class="bx bx-check-circle bx-sm posRelBox2"
              style="
                color: #ffffff;
                right: 6px;
                bottom: 10px;
                position: relative;
                margin-top: 4px;
              "
            ></i>
            <p class="posRelBox2">SALVA</p>
          </button>
        </div>
      </div>
    </div>
    <div
      class="secondContainer"
      style="width: 65%"
      *ngIf="openedSearch === true"
    >
      <div
        class="filterContainer k-display-flex"
        style="flex-direction: row; justify-content: center"
      >
        <form
          class="new-search"
          role="search"
          style="
            display: flex !important;
            flex-direction: row !important;
            justify-content: center !important;
            width: 550px !important;
          "
        >
          <label for="search"></label>
          <input
            [(ngModel)]="filterTerm"
            id="search"
            name="search"
            pattern=".*\S.*"
            type="search"
            placeholder="Cerca prodotto..."
            autofocus
            required
            style="font-size: 15px !important"
          />
          <button type="submit" (click)="getSimilarProducts()">
            <i
              class="bx bx-search bx-md"
              style="
                margin: 0;
                padding-top: 10px;
                padding-left: 5px;
                transform: scale(0.8);
              "
            >
            </i>
          </button>
        </form>
      </div>
      <div
        class="row"
        style="
          margin-top: 17px;
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          max-height: 535px;
          overflow-y: auto;
          flex-wrap: nowrap;
        "
        *ngIf="similarProducts"
      >
        <div
          class="col"
          *ngFor="let product of similarProducts; let i = index"
          style="padding-top: 5px; padding-bottom: 5px"
          (click)="selectProductToAdd(i)"
        >
          <div class="containerProducts">
            <i
              class="bx bx-check-circle bx-md"
              *ngIf="product.pr_checked !== true"
              style="cursor: pointer; margin-right: 20px"
            ></i>
            <i
              class="bx bxs-check-circle bx-md"
              *ngIf="product.pr_checked === true"
              style="cursor: pointer; color: green; margin-right: 20px"
            ></i>

            <span
              class="product-description"
              title="{{ product.pr_description }}"
            >
              {{ product.pr_description | slice : 0 : 35
              }}{{ product.pr_description.length > 35 ? "..." : "" }}
            </span>
          </div>
        </div>
      </div>
      <div *ngIf="!isSimilar" class="row">
        <div class="col-md-6">
          <kendo-label [for]="qty" text="Quantità"></kendo-label>
          <kendo-textbox
            [(ngModel)]="quantity"
            type="number"
            kendoTextBox
            #qty
            rounded="full"
          >
          </kendo-textbox>
        </div>
        <div class="col-md-6">
          <kendo-label [for]="note" text="Nota"></kendo-label>
          <kendo-textbox
            [(ngModel)]="pr_note"
            type="text"
            kendoTextBox
            #note
            rounded="full"
          >
          </kendo-textbox>
        </div>
      </div>
      <button
        class="btn btn-primary"
        kendoButton
        themeColor="primary"
        rounded="full"
        style="margin-left: 20px"
        (click)="addProduct()"
        *ngIf="isAnyChecked"
      >
        {{ isSimilar ? "Cambia prodotto" : "Aggiungi prodotto" }}
      </button>
    </div>
  </div>
</kendo-dialog>

<app-product-detail-modal
  *ngIf="closeServ.isProdDetOn"
  [opened]="closeServ.isProdDetOn"
  [pr_code]="clickedProduct"
>
</app-product-detail-modal>

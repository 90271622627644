import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TextBoxComponent } from "@progress/kendo-angular-inputs";
import { AuthService } from 'src/app/services/auth.service';
import { OrdersService } from 'src/app/services/orders.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-confirm-cancel',
  templateUrl: './confirm-cancel.component.html',
  styleUrls: ['./confirm-cancel.component.scss'],
})

export class ConfirmCancelComponent {

  @Input() openConfirmCancel: boolean = false;
  @Input() orderToGet: any;
  @Output() canceledSucces = new EventEmitter<void>();
  ipAddress = '';

  constructor(
    private loginServ: AuthService,
    private http: HttpClient,
    public openModal: OpenModalService,
    private orderServ: OrdersService
  ) { }

  @ViewChild("password") public textbox: TextBoxComponent;

  public ngAfterViewInit(): void {
    this.textbox.input.nativeElement.type = "password";
  }

  public toggleVisibility(): void {
    const inputEl = this.textbox.input.nativeElement;
    if (inputEl.type === "password") {
      inputEl.type = "text";
    } 
    else {
      inputEl.type = "password";
    }
  }

  ngOnInit(): void {
    this.getIPAddress();
    console.log('OrderToGet is: ', this.orderToGet);
  }

  public loginForm: FormGroup = new FormGroup({
    order_id: new FormControl(''),
    email: new FormControl('', [
      Validators.required,
      Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-z]{2,4}$'),
    ]),
    password: new FormControl('', Validators.required),
    keepLogged: new FormControl(),
  });

  getIPAddress() {
    this.http.get('http://api.ipify.org/?format=json').subscribe((res: any) => {
      this.ipAddress = res.ip;
      console.log('DADADADA');
    });
  }

  checkPass(formValues: any): void {
    this.loginForm.markAllAsTouched();
    let currentScreen = JSON.parse(localStorage.getItem('currentUser') || '{}');
    this.loginServ
      .checkPass(currentScreen.ac_email, formValues.password, this.ipAddress)
      .subscribe((res: any) => {
        if (res.success) 
          console.log('bravo boss!');
      });
  }

  checkPassAndDelete(formValues: any): void {
    this.loginForm.markAllAsTouched();
    let currentScreen = JSON.parse(localStorage.getItem('currentUser') || '{}');
    this.orderServ
      .cancelOrder(
        this.orderToGet.order_id,
        currentScreen.ac_email,
        formValues.password
      )
      .subscribe((res: any) => {
        if (res.success) {
          console.log('bravo boss!');
          Swal.fire({
            icon: 'success',
            title: 'Cancellato!',
            text: 'Ordine annullato con successo!!!',
            timer: 1500,
            timerProgressBar: true,
          });
          this.canceledSucces.emit();
          this.openModal.offConfirmCancel();
        } 
        else
          Swal.fire({
            icon: 'warning',
            title: 'Attenzione!',
            text: 'Inserisci la password prima!!!',
            timer: 1500,
            timerProgressBar: true,
          });
      });
  }

  offModal() {
    this.openModal.offConfirmCancel();
  }
  
}
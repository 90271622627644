import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Orders } from 'src/app/models/Orders.model';
import { Products } from 'src/app/models/Product.model';
import { Documents } from 'src/app/models/documents.models';
import { IWsResult } from 'src/app/models/ws_result.model';
import { OrdersService } from 'src/app/services/orders.service';
import { ProductsService } from 'src/app/services/products.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';

@Component({
  selector: 'app-borderò-dettagli',
  templateUrl: './borderò-dettagli.component.html',
  styleUrls: ['./borderò-dettagli.component.scss'],
})

export class BorderòDettagliComponent implements OnInit {

  @Input() opened = false;
  @Input() order_id: string = '-1';
  @Input() code: any;
  @Input() isProdOpen = false;
  @Input() product_id: string = '-1';

  public order: Orders.IOrder;
  public product: Products.Product[] = [];
  public goBack = false;
  public dataSaved = false;
  public isEditable = false;
  public maxlength = 250;
  public minWidth = 250;
  public dialogWidth = 1356;
  public dialogHeight = 1017;
  public documents: Documents.Document[] = [];

  ordersDetailsForm!: FormGroup;
  formattedDate: string = '-';
  linkToDDT: string = null;
  isLinkToDDT: boolean = null;
  clickedProduct: any;
  or_url_box_label: string;  
  pr_code: string;
  clickLabel: boolean = false;
  ettArray: {
    scatola: string;
    property2: string;
    property3: string;
    onClick: (index: number) => void;
  }[] = [
    {
      scatola: 'Bordero',
      property2: 'value5',
      property3: 'value6',
      onClick: (index) => this.triggerFunction(index),
    }
  ];
  linkAllLabels: any;
  linkCarrier: any;
  linkBordero: any;

  constructor(
    private ordersServ: OrdersService,
    private productServ: ProductsService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    public closeServ: OpenModalService
  ) { }

  ngOnInit(): void {
    this.ordersDetailsForm = this.formBuilder.group({
      or_assignment_date: [''],
      or_order_number: [''],
      or_customer: [''],
      or_agent_id: [''],
      or_shipping_address: [''],
      or_closing_date: [''],
      vs_details: [''],
      carrier_desc: [''],
      ac_first_name: [''],
      ac_last_name: [''],
      boxes: [''],
      op_articles_number: [''],
      op_address: [''],
      op_city: [''],
      or_shipping_zip: [''],
      op_prov: [''],
      or_shipping_country: [''],
    });
    this.ordersServ.getOrder1(+this.order_id).subscribe((orderData) => {
      this.order = orderData;
      this.product = orderData.products;
      this.or_url_box_label = orderData.or_url_box_label;
      this.linkCarrier = orderData?.shipment?.os_tracking_external_link;
      this.linkBordero = orderData.or_url_box_label;
      this.isLinkToDDT = this.order.or_url_ddt?.indexOf('.pdf') > 0;
      this.linkToDDT = this.isLinkToDDT ? this.order.or_url_ddt : null;
      this.ordersServ.getDocuments(+this.order_id).subscribe(async (result) => {
        this.documents = result;
        await this.ordersServ
          .getOrderDocuments(this.order_id)
          .then((result) => {
            if (result) 
              this.documents = result;
            this.printAllLabels();
          });
      });
      if (this.order.or_assignment_date)
        this.formattedDate = formatDate(
          this.order.or_assignment_date,
          'dd/MM/yyyy',
          'it'
        );
      this.ordersDetailsForm.setValue({
        or_assignment_date: this.formattedDate,
        or_order_number: this.order.or_order_number,
        or_closing_date: this.order.or_closing_date,
        or_agent_id: this.order.or_agent_id,
        or_customer: this.order.or_customer,
        or_shipping_address: this.order.or_shipping_address,
        vs_details: this.order.vs_details.tab_order_priority_desc,
        carrier_desc: this.order.vs_details.carrier_desc,
        ac_first_name: this.order.vs_details.ac_first_name,
        ac_last_name: this.order.vs_details.ac_last_name,
        boxes: this.order.boxes,
        op_articles_number: this.order.vs_details.op_articles_number,
        op_address: this.order.or_shipping_address,
        op_city: this.order.or_shipping_city,
        or_shipping_zip: this.order.or_shipping_zip,
        op_prov: this.order.or_shipping_prov,
        or_shipping_country: this.order.or_shipping_country,
      });
    });
  }

  goEditFunction() {
    this.isEditable = !this.isEditable;
  }

  productDetails(productId: any) {
    console.debug('Merge?', productId);
    this.productServ
      .getProduct(this.code)
      .subscribe((productData: IWsResult) => {
        this.product = productData.data;
      });
    this.clickedProduct = productId;
    this.closeServ.openProd(); 
  }

  close() {
    this.closeServ.offStorico();
  }  

  goToProductDetails(pr_code) {
    this.pr_code = pr_code;
    console.log('Product ID is: ', pr_code);
    this.closeServ.onStoricoProd();
  }

  triggerFunction(index: number): void {
    const clickedScatola = this.ettArray[index].scatola;
    switch (clickedScatola) {
      case 'Bordero':
        this.printBordero();
        break;
      default:
        break;
    }
  }  

  printAllLabels() {
    this.ordersServ
      .getAllBoxLabels(
        this.order.order_id,
        this.order.boxes.map((box, index) => index + 1)
      )
      .subscribe((result) => {
        console.debug('result', result);
        if (!result) 
          return;
        this.linkAllLabels = result.box_label_link_pdf;
        console.log('This LinkAllLabels: ', this.linkAllLabels);
      });
  }

  printBordero() {
    const link = this.or_url_box_label;
    const a = document.createElement('a');
    a.href = link;
    a.target = '_blank';
    a.click();
    console.log('Borderò is: ', link);
  }

}

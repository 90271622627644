<div
  class="topnav"
  *ngIf="this.authServ.isUser.value && !this.authServ.isScreen.value"
>
</div>

<div class="topnav-ruler"></div> 
<app-gestione-account-dettagli
  *ngIf="closeSrv.isAccOpn"
  [accOpn]="closeSrv.isAccOpn"
  [account_id]="clickedId"
>
</app-gestione-account-dettagli>

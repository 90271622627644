<div class="grid-wrapper" *ngIf="agent_token">
  <h1 class="page-title">
    Lista Ordini di
    {{
      this.agent_order.length === 0
        ? " Agente"
        : " " + agent_order[0].ag_first_name + " " + agent_order[0].ag_last_name
    }}
  </h1>

  <br />

  <div
    class="filterContainer k-display-flex"
    style="flex-direction: row; justify-content: center"
  >
    <form
      class="new-search"
      role="search"
      style="
        display: flex !important;
        flex-direction: row !important;
        justify-content: center !important;
      "
    >
      <label for="search"
        ><i
          class="bx bx-search bx-md"
          style="
            margin: 0;
            padding-top: 10px;
            padding-left: 5px;
            transform: scale(0.8);
          "
        >
        </i
      ></label>
      <input
        [(ngModel)]="filterTerm"
        id="search"
        name="search"
        pattern=".*\S.*"
        type="search"
        placeholder="Cerca..."
        autofocus
        required
        (input)="this.onInputChanged($event)"
      />
      <button type="submit" (click)="searchOrders()">
        <i
          class="bx bx-search bx-md"
          style="
            margin: 0;
            padding-top: 10px;
            padding-left: 5px;
            transform: scale(0.8);
            color: red;
            background: #0c65e9;
          "
        >
        </i>
      </button>
    </form>

    <form
      action=""
      class="search-bar"
      style="
        display: flex !important;
        flex-direction: row !important;
        justify-content: center !important;
        text-align: center !important;
      "
    >
      <h1 class="page-title" style="color: rgb(13, 11, 11)">
        Ordini totali: {{ this.agent_order.length }}
      </h1>
    </form>

    <div class="dateContainer k-display-flex justify-content-evenly">
      <div class="col mx-2">
        <h5 class="text-center">DA:</h5>
        <kendo-datepicker
          [(ngModel)]="filters.startingDate"
          class="dropdown-field drop-the-border"
          style="
            background: #ffffff;
            border: 1px solid #e0e0e0;
            border-radius: 45px;
          "
        ></kendo-datepicker>
      </div>
      <div class="col mx-2">
        <h5 class="text-center">A:</h5>
        <kendo-datepicker
          [(ngModel)]="filters.endDate"
          class="dropdown-field drop-the-border"
          style="
            background: #ffffff;
            border: 1px solid #e0e0e0;
            border-radius: 45px;
          "
        ></kendo-datepicker>
      </div>
    </div>
  </div>
  <hr class="hr-newBlue" />

  <kendo-grid
    [data]="agent_order"
    class="mobilez responsive-table"
    [height]="650"
    [rowHeight]="36"
    [sortable]="true"
    scrollable="virtual"
    [navigable]="true"
    [style]="{ 'margin-left': '7px !important' }"
    [reorderable]="true"
  >
    <kendo-grid-column
      field="or_order_number"
      title="Ordini Agente"
      class="col-name"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'text-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem style="font-size: 11px">
        <kendo-panelbar
          class="agente007"
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            border: #fff;
          "
        >
          <kendo-panelbar-item
            [title]="
              dataItem.or_order_number +
              ' | ' +
              dataItem.tab_order_status_desc +
              ' | ' +
              dataItem.or_customer
            "
            style="text-align: center; border-radius: 45px"
          >
            <ng-template kendoPanelBarContent>
              <div style="max-height: 500px; overflow-y: auto">
                <div
                  class="row"
                  style="
                    text-align: center;
                    padding: 8px;
                    border: 1px solid #47a1fb;
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                  "
                >
                  <div class="spanus">
                    <span
                      style="
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 19px;
                        color: #0c65e9;
                      "
                    >
                      <div
                        style="
                          display: flex;
                          flex-direction: row;
                          justify-content: space-between;
                        "
                      >
                        <div style="text-align: left">
                          <h4>Preventivo:</h4>
                        </div>
                        <div style="text-align: right; margin-left: 10px">
                          <h4>{{ dataItem.or_quote_number }}</h4>
                        </div>
                      </div>
                      <div
                        style="
                          display: flex;
                          flex-direction: row;
                          justify-content: space-between;
                        "
                      >
                        <div style="text-align: left">
                          <h4>Riferimento:</h4>
                        </div>
                        <div style="text-align: right; margin-left: 10px">
                          <h4>{{ dataItem.or_note }}</h4>
                          <h4>{{ dataItem.or_shipping_note }}</h4>
                        </div>
                      </div>
                      <div
                        style="
                          display: flex;
                          flex-direction: row;
                          justify-content: space-between;
                        "
                      >
                        <div style="text-align: left">
                          <h4>Ragione Sociale:</h4>
                        </div>
                        <div style="text-align: right; margin-left: 10px">
                          <h4>{{ dataItem.or_customer }}</h4>
                        </div>
                      </div>
                      <div
                        style="
                          display: flex;
                          flex-direction: row;
                          justify-content: space-between;
                        "
                      >
                        <div style="text-align: left">
                          <h4>Indirizzo:</h4>
                        </div>
                        <div style="text-align: right; margin-left: 10px">
                          <h4>{{ dataItem.or_address }}</h4>
                        </div>
                      </div>
                      <div
                        style="
                          display: flex;
                          flex-direction: row;
                          justify-content: space-between;
                        "
                      >
                        <div style="text-align: left">
                          <h4>Data Ordine:</h4>
                        </div>
                        <div style="text-align: right; margin-left: 10px">
                          <h4 *ngIf="dataItem.or_order_date">
                            {{ dataItem.or_order_date }}
                          </h4>
                        </div>
                      </div>
                      <div
                        style="
                          display: flex;
                          flex-direction: row;
                          justify-content: space-between;
                        "
                      >
                        <div style="text-align: left">
                          <h4>Data Evasione:</h4>
                        </div>
                        <div style="text-align: right; margin-left: 10px">
                          <h4 *ngIf="dataItem.or_closing_date">
                            {{
                              dataItem.or_closing_date === "01/01/0001"
                                ? ""
                                : ""
                            }}
                          </h4>
                        </div>
                      </div>
                      <div
                        style="
                          display: flex;
                          flex-direction: row;
                          justify-content: space-between;
                        "
                      >
                        <div style="text-align: left">
                          <h4>Stato:</h4>
                        </div>
                        <div style="text-align: right; margin-left: 10px">
                          <h4>{{ dataItem.tab_order_status_desc }}</h4>
                        </div>
                      </div>
                      <div
                        style="
                          display: flex;
                          flex-direction: row;
                          justify-content: space-between;
                        "
                      >
                        <div style="text-align: left">
                          <h4>DDT:</h4>
                        </div>
                        <div style="text-align: right; margin-left: 10px">
                          <h4>{{ dataItem.or_url_ddt }}</h4>
                          <i class="bx bxs-file-pdf bx-md"></i>
                        </div>
                      </div>
                      <div
                        style="
                          display: flex;
                          flex-direction: row;
                          justify-content: space-between;
                        "
                      >
                        <div style="text-align: left">
                          <h4>Dettagli:</h4>
                        </div>
                        <div style="text-align: right; margin-left: 10px">
                          <button
                            kendoGridEditCommand
                            rounded="full"
                            class="btn-det"
                            style="
                              margin: 0;
                              padding: 0;
                              background-color: #017efa !important;
                            "
                            (click)="
                              goToOrderDetails(
                                dataItem.or_agent_id,
                                dataItem.order_id,
                                dataItem.or_gamma_id,
                                dataItem.or_assigned_date,
                                agent_token,
                                riferimento
                              )
                            "
                          >
                            <div class="setDisplayToFlexBlue btn-det">
                              <i
                                class="bx bx-info-circle bx-md"
                                style="margin: 0; padding: 0"
                              >
                              </i>
                              <p class="parButton2"></p>
                            </div>
                          </button>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </ng-template>
          </kendo-panelbar-item>
        </kendo-panelbar>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>

  <kendo-grid
    [data]="
      agent_order
        | byToDate
          : [
              filters.startingDate,
              filters.endDate,
              filters.dateOrginizedBy.value
            ]
    "
    [rowHeight]="36"
    [height]="650"
    [kendoGridBinding]="agent_order"
    [sortable]="true"
    scrollable="virtual"
    [navigable]="true"
    class="tab table-responsive tablez responsive-table"
  >
    <div>
      <div *ngFor="let order of agent_order; let i = index">
        <p>{{ order.ag_first_name }}</p>
        <p>{{ order.ag_last_name }}</p>
      </div>
    </div>

    <kendo-grid-column
      [width]="150"
      field="ag_last_name"
      title="Agente"
      class="col-name"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem style="font-size: 11px">
        {{ dataItem.ag_last_name }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="150"
      field="or_order_number"
      title="N° Ordine"
      class="col-name"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem style="font-size: 11px">
        {{ dataItem.or_order_number }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="150"
      field="or_quote_number"
      title="Preventivo"
      class="col-name"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem style="font-size: 11px">
        {{ dataItem.or_quote_number }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="150"
      field="or_note"
      title="Riferimento"
      class="col-name"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem style="font-size: 11px">
        {{ dataItem.or_note }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="500"
      field="or_customer"
      title="Ragione sociale"
      class="col-name"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template
        kendoGridCellTemplate
        let-dataItem
        style="font-size: 11px; display: flex !important"
      >
        <div class="flex" style="display: flex !important">
          {{ dataItem.or_customer }}
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="150"
      field="or_order_date"
      title="Data ordine"
      class="col-name"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem style="font-size: 11px">
        {{ dataItem.or_order_date | date : "dd/MM/yyyy" }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="150"
      field="or_closing_date"
      title="Data evasione"
      class="col-name"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem style="font-size: 11px">
        {{
          dataItem.or_closing_date === "0001-01-01T00:00:00"
            ? " "
            : (dataItem.or_closing_date | date : "dd/MM/yyyy")
        }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="200"
      field="tab_order_status_desc"
      title="Stato"
      class="col-name"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem style="font-size: 11px">
        {{ dataItem.tab_order_status_desc }}
        <button
          *ngIf="
            dataItem.os_tracking_number !== null &&
            dataItem.os_tracking_number !== ''
          "
          kendoGridEditCommand
          rounded="full"
          class="btn-track"
          style="margin: 0; padding: 4px; background-color: #be7e00 !important"
          (click)="
            goToTracking(agent_token, dataItem.order_id, dataItem.or_agent_id)
          "
        >
          <div class="setDisplayToFlexBlue btn-track">
            <i class="bx bx-radar bx-md"> </i>
            <p class="parButton2"></p>
          </div>
        </button>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-command-column
      title="DDT"
      [width]="80"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <a href="{{ dataItem?.or_url_ddt }}" target="_blank">
          <i class="bx bxs-file-pdf bx-md"></i>
        </a>
      </ng-template>
    </kendo-grid-command-column>

    <kendo-grid-command-column
      title="Dettagli"
      [width]="100"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'text-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <button
          kendoGridEditCommand
          rounded="full"
          class="btn-det"
          style="margin: 0; padding: 0; background-color: #017efa !important"
          (click)="
            goToOrderDetails(
              dataItem.or_agent_id,
              dataItem.order_id,
              dataItem.or_gamma_id,
              dataItem.or_assigned_date,
              agent_token,
              riferimento
            )
          "
        >
          <div class="setDisplayToFlexBlue btn-det">
            <i class="bx bx-info-circle bx-md" style="margin: 0; padding: 0">
            </i>
            <p class="parButton2"></p>
          </div>
        </button>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
</div>

<!--<div class="mycontainer">
  <img src="http://le.mesys.it/images/esvaweb.png" alt="" /><br>
  <table class="responsive-table">
          <thead><tr>
              <th>Received</th>
              <th>From</th>
              <th>To</th>
              <th>Subject</th>
              <th>Score</th>
              <th>Reason</th>
              <th>Actions</th>
          </tr></thead>
          <tbody>
          <tr style="background-color: #FFFFFFFF" data-msg-id="334B6402AB.AE296">
              <td data-title="Received:" style="white-space: nowrap;"><font color="#000000">19/06/23 14:11</font></td>
              <td data-title="From:"><span title="Hollister@e.hollisterco.com"><font color="#000000">Hollistere.hollisterco.com</font></span></td>
              <td data-title="To:"><span title="m.sani@mesys.it"><font color="#000000">m.sanimesys.it</font></span></td>
              <td data-title="Subject:"><span title="Last day =E2=9D=97 Extra 20% off!"><font color="#000000">Last day =E2=9D=97 Extra 20% off!</font></span></td>
              <td data-title="Score:"><font color="#000000">4.17</font></td>
              <td data-title="Reason:"><span class="spam esvabadge"><font color="#000000">Spam</font></span></td>
              <td data-title="Action:"><font color="#000000"><a target="_blank" href="http://le.mesys.it/viewmail.php?id=3D334B6402AB.AE296" title="Message Preview">[View]</a></font></td>
          </tr>
          <tr style="background-color: #FFFFFFFF" data-msg-id="8B440402AB.A4F20">
              <td data-title="Received:" style="white-space: nowrap;"><font color="#000000">19/06/23 16:54</font></td>
              <td data-title="From:"><span title="ihpetkp@tower=2Egeorgia.su"><font color="#000000">ihpetkptower.georgia.su</font></span></td>
              <td data-title="To:"><span title="m.sani@mesys.it"><font color="#000000">m.sanimesys.it</font></span></td>
              <td data-title="Subject:"><span title="Cardione - Sconfigge l'ipertensione e tutti i suoi sintomi"><font color="#000000">Cardione - Sconfigge l'ipertensione e...</font></span></td>
              <td data-title="Score:"><font color="#000000">24.04</font></td>
              <td data-title="Reason:"><span class="hispam esvabadge"><font color="#000000">High Spam</font></span></td>
              <td data-title="Action:"><font color="#000000"><a target="_blank" href="http://le.mesys.it/viewmail.php?id=3D8B440402AB.A4F20" title="Message Preview">[View]</a></font></td>
          </tr>
          </tbody>
      </table>
</div>-->

<app-accesso-funzionari-tracking
  [track_ID]="shipment_id"
  [token]="agent_token"
  [agentID]="agentID"
  *ngIf="closeServ.isAgentTrack"
  [newTrack]="closeServ.isAgentTrack"
>
</app-accesso-funzionari-tracking>

<app-ordini-agente-dettaggli
  *ngIf="closeServ.isAgentOrderB"
  [agent_id]="clickedId"
  [token_agent]="agent_token"
  [order_id]="orderId"
  [or_gamma_id]="or_gamma_id"
  [or_assigned_date]="or_assigned_date"
  [openOrderDettagli]="closeServ.isAgentOrderB"
>
</app-ordini-agente-dettaggli>

<!--<app-info-agente
  *ngIf="closeServ.isAgentOrderB"
  [agent_id]="clickedId"
  [token_agent]="agent_token"
  [order_id]="orderId"
  [or_gamma_id]="or_gamma_id"
  [or_assigned_date]="or_assigned_date"
  [openOrderDettagli]="closeServ.isAgentOrderB"
>
</app-info-agente>-->

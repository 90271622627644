import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IErrors, OrderErrors } from 'src/app/models/Orders.model';
import { OrdersService } from 'src/app/services/orders.service';
import { ProductsService } from 'src/app/services/products.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modify-error',
  templateUrl: './modify-error.component.html',
  styleUrls: ['./modify-error.component.scss'],
})

export class ModifyErrorComponent {

  @Input() itemForSettings!: any;
  @Input() order_id: number;
  @Input() error: any;
  @Output() modalClosed1 = new EventEmitter<void>();
  public opened = true;
  errorForm!: FormGroup;
  errorTypes: any;
  error_code: any;
  error_gravity: any;
  numbersArray = Array.from({ length: 10 }, (_, index) => index + 1);
  modify: boolean = false;
  oe_description_model: string;
  oe_description: string;
  oe_error_value: number;
  oe_error_gravity: number;
  er_error_type_id: string;
  order_error_id: number;
  order_error_type_description: string;
  values: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  constructor(
    private ordersServ: OrdersService,
    private productServ: ProductsService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    public closeServ: OpenModalService
  ) { }

  ngOnInit(): void {
    console.log('cacat');
    console.log('Error for change', this.error);
    console.log('Order id is', this.order_id);
    console.log('Numbers array is', this.numbersArray);
    this.getErrorTypes();
    this.errorForm = this.formBuilder.group({
      order_id: [''],
      // oe_error_type_id: [''],
      oe_error_gravity: [''],
      oe_description: [''],
      oe_error_type_id: [''],
      // order_error_type_description: [''],
      order_error_id: [''],
    });
    this.errorForm.setValue({
      order_id: this.order_id,
      // oe_error_type_id:
      oe_error_gravity: this.error.oe_error_gravity,
      oe_description: this.error.oe_description,
      oe_error_type_id: this.error.oe_error_type_id,
      // order_error_type_description:
      order_error_id: this.error.order_error_id,
    });
  }

  getErrorTypes() {
    this.ordersServ.getTypesOfOrderErrors().subscribe((data: any) => {
      this.errorTypes = data;
    });
  }

  handleChange(e) {
    console.log('ionChange fired with value: ' + e.detail.value);
    this.error_code = e.detail.value;
    console.log('error code is', this.error_code);
  }

  setError(form) {
    console.log('modify error', form);
    let modifiedError: OrderErrors = {
      oe_description: this.errorForm.value.oe_description,
      oe_error_gravity: this.errorForm.value.oe_error_gravity,
      oe_error_type_id: this.errorForm.value.oe_error_type_id,
      order_error_id: this.error.order_error_id,
      order_id: this.order_id,
    };
    console.log('Mofied error is', modifiedError);
    this.ordersServ.updateError(modifiedError).subscribe((data: any) => {
      if (data.success) {
        console.log('Success!', data);
        this.modalClosed1.emit();
        this.closeServ.closeModifyError();
        Swal.fire({
          title: 'Modificato!',
          text: 'Errore modificato con successo!!!',
          icon: 'success',
          customClass: {
            popup: 'pop-your-mom',
          },
          timer: 1500,
          timerProgressBar: true,
        });
        window.location.reload();  
      }      
      else {
        Swal.fire({
          title: 'Non modificato!',
          text: 'Errore non modificato, riprova!',
          icon: 'error',
          customClass: {
            popup: 'pop-your-mom',
          },
          timer: 1500,
          timerProgressBar: true,
        });
        window.location.reload();  
      }
    });
  }

  public close() {
    this.closeServ.closeModifyError();
  }

  public goBackFunction() {
    this.closeServ.closeModifyError();
    //this.router.navigate(['/history']);
  }

  letModify() {
    this.modify = !this.modify;
  }
  
}

<!-- <p *ngIf="parentId">
    status-spedizioni-dettaggli works! {{ this.parentId }}
</p> -->

<div class="k-overlay dark-background" *ngIf="status_ID"></div>

<div>
  <kendo-dialog
    title=""
    *ngIf="status_ID && order"
    [minWidth]="minWidth"
    [width]="dialogWidth"
    [minHeight]="500"
    [maxWidth]="1350"
    class="k-form container-dialog-assegna"
    (close)="close()"
    [formGroup]="spedizioneDetailsForm"
    style="left: 6%"
  >
    <div
      style="
        display: flex;
        flex-direction: row;
        justify-content: end;
        margin-top: -15px;
        z-index: 1000;
        position: fixed;
        margin-left: -35px;
      "
    >
      <button
        (click)="goBack()"
        class="btn btn3"
        rounded="full"
        style="
          background-color: #e90000;
          color: #ffffff;
          width: 35px !important;
          height: 35px !important;
        "
      >
        <i
          class="bx bx-x bx-sm posRelBox2"
          style="
            color: #ffffff;
            bottom: 10px;
            position: relative !important;
            left: -80% !important;
            top: -1px !important;
          "
        ></i>
      </button>
    </div>
    <h1 class="page-title">Dettagli Spedizione</h1>
    <hr class="hr-newBlue mt-4" />
    <div class="container container-ordine">
      <form
        class="k-form dialog-form cont-sx"
        [formGroup]="spedizioneDetailsForm"
      >
        <div class="row det mb-2">
          <kendo-formfield class="col-md setDisplay-Prep">
            <kendo-label
              [for]="or_order_number"
              class="title"
              text="N° Ordine"
            ></kendo-label>
            <input
              formControlName="or_order_number"
              kendoTextBox
              #or_order_number
              readonly
              class="setBorder-Prepp"
            />
          </kendo-formfield>

          <kendo-formfield class="col-md-3 setDisplay-Prep">
            <kendo-label
              [for]="or_order_date"
              class="title"
              text="Data ordine"
            ></kendo-label>
            <input
              formControlName="or_order_date"
              kendoTextBox
              #or_order_date
              readonly
              class="setBorder-Prepp"
            />
          </kendo-formfield>

          <kendo-formfield class="col-md-3 setDisplay-Prep">
            <kendo-label
              [for]="or_closing_date"
              class="title"
              text="Data evasione"
            ></kendo-label>
            <input
              formControlName="or_closing_date"
              kendoTextBox
              #or_closing_date
              readonly
              class="setBorder-Prepp"
            />
          </kendo-formfield>
        </div>

        <div class="row">
          <div class="row det mb-2">
            <kendo-formfield class="col-md setDisplay-Prep">
              <kendo-label
                [for]="or_customer"
                class="title"
                text="Cliente"
              ></kendo-label>
              <input
                formControlName="or_customer"
                kendoTextBox
                #or_customer
                readonly
                class="setBorder-Prepp"
              />
            </kendo-formfield>
          </div>

          <div class="row det mb-2">
            <kendo-formfield class="col-md setDisplay-Prep">
              <kendo-label
                [for]="carrier_desc"
                class="title"
                text="Corriere"
              ></kendo-label>
              <input
                formControlName="carrier_desc"
                kendoTextBox
                #carrier_desc
                readonly
                class="setBorder-Prepp"
              />
            </kendo-formfield>

            <kendo-formfield class="col-md setDisplay-Prep">
              <kendo-label
                [for]="tab_order_priority_desc"
                class="title"
                text="Priorità"
              ></kendo-label>
              <input
                formControlName="tab_order_priority_desc"
                kendoTextBox
                #tab_order_priority_desc
                readonly
                class="setBorder-Prepp"
              />
            </kendo-formfield>
          </div>

          <div class="row det mb-2">
            <kendo-formfield class="col-md setDisplay-Prep">
              <kendo-label
                [for]="or_shipping_address"
                class="title"
                text="Indirizzo Spedizione"
              ></kendo-label>
              <input
                formControlName="or_shipping_address"
                kendoTextBox
                #or_shipping_address
                readonly
                class="setBorder-Prepp"
              />
            </kendo-formfield>
          </div>

          <div class="row mb-2">
            <kendo-formfield class="col-md-4 setDisplay-Prep">
              <kendo-label
                [for]="or_shipping_city"
                class="title"
                text="Città"
              ></kendo-label>
              <input
                formControlName="or_shipping_city"
                kendoTextBox
                #or_shipping_city
                readonly
                class="setBorder-Prepp"
              />
            </kendo-formfield>

            <kendo-formfield class="col-md-3 setDisplay-Prep">
              <kendo-label
                [for]="or_shipping_zip"
                class="title"
                text="CAP"
              ></kendo-label>
              <input
                formControlName="or_shipping_zip"
                kendoTextBox
                #or_shipping_zip
                readonly
                class="setBorder-Prepp"
              />
            </kendo-formfield>

            <kendo-formfield class="col-md-2 setDisplay-Prep">
              <kendo-label
                [for]="or_shipping_prov"
                class="title"
                text="Prov"
              ></kendo-label>
              <input
                formControlName="or_shipping_prov"
                kendoTextBox
                #or_shipping_prov
                readonly
                class="setBorder-Prepp"
              />
            </kendo-formfield>

            <kendo-formfield class="col-md-2 setDisplay-Prep">
              <kendo-label
                [for]="or_shipping_country"
                class="title"
                text="Nazione"
              ></kendo-label>
              <input
                formControlName="or_shipping_country"
                kendoTextBox
                #or_shipping_country
                readonly
                class="setBorder-Prepp"
              />
            </kendo-formfield>
          </div>

          <div class="row det mb-2">
            <kendo-formfield class="col-md setDisplay-Prep">
              <kendo-label
                [for]="or_note"
                class="title"
                text="Riferimento/note"
              ></kendo-label>
              <input
                formControlName="or_note"
                kendoTextBox
                #or_note
                readonly
                class="setBorder-Prepp"
              />
            </kendo-formfield>
          </div>
        </div>
      </form>

      <div class="row det cont-dx">
        <kendo-panelbar
          *ngIf="order.products"
          [animate]="true"
          style="text-align: center; border: #fff"
        >
          <kendo-panelbar-item
            [title]="'Prodotti'"
            style="text-align: center; border-radius: 45px"
          >
            <ng-template kendoPanelBarContent>
              <div style="max-height: 100%; margin-left:12px; width:96.3%">
                <div
                  class="row"
                  *ngFor="let prod of order.products; let i = index"
                  style="
                    text-align: center;
                    padding: 8px;
                    border: 1px solid #47a1fb;
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                  "
                >
                  <div class="col-10" style="text-align: left">
                    <span
                      style="
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 19px;
                        color: #0c65e9;
                      "
                      >{{ prod.op_description }}
                    </span>
                  </div>
                  <div class="col-2" style="text-align: right">
                    <span
                      style="
                        font-family: 'Lato';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 19px;
                        color: #0c65e9;
                      "
                      >{{ prod.op_qta + " pz" }}
                    </span>
                  </div>
                </div>
              </div>
            </ng-template>
          </kendo-panelbar-item>
        </kendo-panelbar>
      </div>
    </div>
  </kendo-dialog>
</div>

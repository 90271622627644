import { Component, OnInit, Input } from '@angular/core';
import { Objectives } from 'src/app/models/Orders.model';
import { Printing } from 'src/app/models/printer';
import { OrdersService } from 'src/app/services/orders.service';
import { PrinterService } from 'src/app/services/printer/printer.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})

export class SettingsComponent implements OnInit {

  @Input() value: string = '-1';

  printers: Printing.PrinterOption[] = [];
  printerSelection: Printing.PrinterOptionSelection =
    new Printing.PrinterOptionSelection(null);
  dataItem: any;
  objectivesArray: any[] = [];
  objectives: Objectives = new Objectives(null);
  daily: any;
  monthly: any;
  yearly: any;
  data = [{ value1: 0, value2: 0, value3: 0 }]; 

  constructor(
    private printerService: PrinterService,
    public orderService: OrdersService
  ) {
    const savedData = localStorage.getItem('obj');
    if (savedData) {
      this.data = JSON.parse(savedData);
    } 
    else {
      this.data = [
        {
          value1: 10,
          value2: 20,
          value3: 30,
        },
      ];
    }
  }

  async ngOnInit() {
    this.reloadFunction();
  }

  reloadFunction() {
    this.printerService.printers.subscribe((result) => {
      if (result) 
        this.printers = result;
    });
    this.printerService.printerSelection.subscribe((result) => {
      if (result) 
        this.printerSelection = result;
    });
    this.getObjectives();
  }

  async ngOnDestroy() {
    this.printerService.printerSelection.next(this.printerSelection);
    await this.printerService.setPrintersSettings(this.printerSelection);
  }

  getObjectives() {
    this.orderService.getObjectives().subscribe((data) => {
      this.objectives = data;
      this.objectivesArray = Object.keys(this.objectives).map((key) => ({
        name: key,
        value: this.objectives[key],
      }));

      this.daily = this.objectivesArray[0].value;
      this.monthly = this.objectivesArray[1].value;
      this.yearly = this.objectivesArray[2].value;
      console.log('Array: ', this.objectivesArray);
      console.log('Data: ', this.objectivesArray);
      console.log('Obiettivi: ', data);
    });
  }

  changeprinterCustomBorID(printer_id, checkbox_no) {
    this.printers = this.printers.map((item) => {
      switch (checkbox_no) {
        case 0:
          this.printerSelection.printer_ddt_id = printer_id;
          if (item.printer_id === printer_id) 
            item.printer_ddt = true;
          else 
            item.printer_ddt = false;
          break;
        case 1:
          this.printerSelection.printer_box_id = printer_id;
          if (item.printer_id === printer_id) 
            item.printer_box = true;
          else 
            item.printer_box = false;
          break;
        case 2:
          this.printerSelection.printer_car_id = printer_id;
          if (item.printer_id === printer_id) 
            item.printer_car = true;
          else 
            item.printer_car = false;
          break;
        case 3:
          this.printerSelection.printer_bor_id = printer_id;
          if (item.printer_id === printer_id) 
            item.printer_bor = true;
          else 
            item.printer_bor = false;
          break;
      }
      return item;
    });
  }

  setGoal() {    
    console.log('Obiettivo giornaliero: ', this.daily);
    this.objectives.tp_daily_objective = this.daily;    
    this.objectives.tp_monthly_objective = this.monthly;
    this.objectives.tp_yearly_objective = this.yearly;
    console.log('Obiettivo giornaliero: ', this.objectives.tp_daily_objective);
    console.log('Obiettivo mensile: ', this.objectives.tp_monthly_objective);
    console.log('Obiettivo annuale: ', this.objectives.tp_yearly_objective);
    const isDailyValid = this.validateDay(this.daily, this.monthly);
    const isMonthlyValid = this.validateMonth(this.monthly, this.yearly);
    if (isDailyValid && isMonthlyValid) {
      this.orderService.setObjectives(this.objectives).subscribe((data: any) => {
        console.log('SUCCESS!');
        Swal.fire({
          icon: 'success',
          title: 'Aggiornato!',
          text: 'Obiettivo impostato con successo!!!',
          timer: 1500,
          timerProgressBar: true,
        });
        this.reloadFunction();
      });
    }
    else {
        Swal.fire({
          icon: 'error',
          title: 'Errore assurdo!',
          text: this.errorMsg(isDailyValid, isMonthlyValid),
          timer: 3000,
          timerProgressBar: true,
        });
        this.reloadFunction();
    }
  }  

  validateDay(day: number, month: number): boolean {
    return day < month;
  }

  validateMonth(month: number, year: number) : boolean {
    return month < year;
  }  

  errorMsg(isDailyValid: boolean, isMonthlyValid: boolean): string {
    if (!isDailyValid && !isMonthlyValid) {
      Swal.fire({
        icon: 'error',
        title: 'Errore!',
        text: 'Obiettivo giornaliero non può essere superiore a quello mensile e obiettivo mensile non può essere superiore a quello annuale!!! Le basi della matematica su! Non sei stato a scuola?!',
        timer: 1500,
        timerProgressBar: true,
      });
    }
    else if (!isDailyValid) {
      Swal.fire({
        icon: 'error',
        title: 'Errore!',
        text: 'Obiettivo giornaliero non può essere superiore a quello mensile!! Le basi della matematica su! Non sei stato a scuola?!',
        timer: 1500,
        timerProgressBar: true,
      });
    }
    else if (!isMonthlyValid) {
      Swal.fire({
        icon: 'error',
        title: 'Errore!',
        text: 'Obiettivo mensile non può essere superiore a quello annuale!! Le basi della matematica su! Non sei stato a scuola?!',
        timer: 1500,
        timerProgressBar: true,
      });
    }
    return 'Errore sconosciuto, ma è sempre un errore..';
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { IMessage } from 'src/app/models/IMessage.model';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import { MessagesService } from 'src/app/services/messages.service';
import { DropdownService } from 'src/app/services/shared/dropdown.service';
import { IWorker } from 'src/app/models/IWorker.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-messaggi-new',
  templateUrl: './messaggi-new.component.html',
  styleUrls: ['./messaggi-new.component.scss'],
})

export class MessaggiNewComponent implements OnInit {

  public msg: IMessage;  
  public disabledButton: boolean = true;
  public dataSaved = false;
  public maxlength = 250;
  public minWidth = 250;
  public dialogWidth = 1056;
  public dialogHeight = 1017;
  public workers: IWorker[] = [];
  addMsgForm!: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    public closeServ: OpenModalService,
    private msgSrv: MessagesService,
    private dropdownServ: DropdownService
  ) { }

  ngOnInit(): void {
    this.addMsgDialog();
    this.addMsgForm = this.fb.group({
      ms_title: ['', Validators.required],
      account_id: ['', Validators.required],
      ms_message: ['', Validators.required]
    });
  }

  public opened = true;

  public close() {
    this.opened = false;
    this.router.navigate(['/messages'])
  }

  public goBackFunction() {
    this.router.navigate(['/messages']);
  }

  open() {
    this.opened = true;
  }

  addMsgDialog() {    
    this.dropdownServ.getWorkers().subscribe((workersData: IWorker[]) => {
      workersData.forEach(
        (element) =>
          (element.full_name =
            element.ac_first_name + ' ' + element.ac_last_name)
      );
      this.workers = workersData;
    });
    this.addMsgForm = new FormGroup({
      message_id: new FormControl(''),
      account_id: new FormControl(''),
      ms_datetime: new FormControl('', [
      ]),
      ms_title: new FormControl('', [
        Validators.required,
      ]),
      ms_object: new FormControl('', [
        Validators.required,
      ]),
      ms_sender_name: new FormControl('', [
        Validators.pattern('[0-9-+ ]*'),
      ]),
      ms_recipient_name: new FormControl('', [
      ]),
      ms_status: new FormControl(''),
      ms_message: new FormControl(''),
      ms_recipient_id: new FormControl(''),
    });
  }

  addMsg(addMsgForm: any): void {
    let msg: IMessage = {
      ms_title: this.addMsgForm.value.ms_title,
      ms_message: this.addMsgForm.value.ms_message,
      ms_recipient_id: this.addMsgForm.value.account_id,
    };
    this.disabledButton = false;
    this.msgSrv.putMessage(msg).subscribe((messageData: IMessage) => {
      this.msg = messageData;
    });    
    console.log(msg);
    this.addMsgForm.markAllAsTouched();
    this.router.navigate(['/messages']);
    Swal.fire({
      icon: 'success',
      title: 'Inviato!',
      text: 'Messaggio inviato con successo!!!',
      timer: 1500,
      timerProgressBar: true,
    });
  }

  clearForm(): void {
    this.addMsgForm.reset();
  }
  
}

import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Documents } from 'src/app/models/documents.models';
import { Orders } from 'src/app/models/Orders.model';
import { Status } from 'src/app/models/status';
import { IWsResult } from 'src/app/models/ws_result.model';
import { IconsService } from 'src/app/services/icons.service';
import { OrdersService } from 'src/app/services/orders.service';
import { PagesService } from 'src/app/services/pages.service';
import { DropdownService } from 'src/app/services/shared/dropdown.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import { NotificationService } from '@progress/kendo-angular-notification';
import { PrinterService } from 'src/app/services/printer/printer.service';
import Swal from 'sweetalert2';
import { AlertsService } from 'src/app/services/shared/alert-service.service';
import { AlertController } from '@ionic/angular';
import { map } from 'rxjs';

@Component({
  selector: 'app-controllo-quantità',
  templateUrl: './controllo-quantità.component.html',
  styleUrls: ['./controllo-quantità.component.scss'],
})

export class ControlloQuantitàComponent implements OnInit {

  @Input() openedDoc = true;

  public orderToUpdate: Orders.OrderUpdate;
  public order: Orders.Order;
  public documents: Documents.Document[] = [];
  private counter: number = 0;
  public maxlength = 250;
  public minWidth = 250;
  public dialogWidth = 100;
  public dialogHeight = 1017;

  firstEnteredOnPage: boolean = true;
  back: string = '';
  id: string;
  allClicked: boolean;
  isClickedOnIt: boolean = false;
  linkToDDT: string = null;
  isLinkToDDT: boolean = null;
  clickedOnce: boolean = false;
  popoverController: any;

  constructor(
    private router: Router,
    public orderService: OrdersService,
    private pagesService: PagesService,
    private activatedRoute: ActivatedRoute,
    private notifServ: NotificationService,
    private iconService: IconsService,
    public closeServ: OpenModalService,
    private dropdownServ: DropdownService,
    private printerService: PrinterService,
    private alertService: AlertsService,
    private alertController: AlertController
  ) { }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.firstEnteredOnPage = true;
    this.orderService.getOrder1(+this.id).subscribe(async (result) => {
      this.order = result;
      this.isLinkToDDT = this.order.or_url_ddt?.indexOf('.pdf') > 0;
      this.linkToDDT = this.isLinkToDDT ? this.order.or_url_ddt : null;
      this.orderService.getDocuments(+this.id).subscribe(async (result) => {
        this.documents = result;
        await this.orderService.getOrderDocuments(this.id).then((result) => {
          if (result) this.documents = result;
        });
        this.checkSelectedAll();
      });
    });
  }

  reloadFunction() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.firstEnteredOnPage = true;
    this.orderService.getOrder1(+this.id).subscribe(async (result) => {
      this.order = result;
      this.isLinkToDDT = this.order.or_url_ddt?.indexOf('.pdf') > 0;
      this.linkToDDT = this.isLinkToDDT ? this.order.or_url_ddt : null;
      this.orderService.getDocuments(+this.id).subscribe(async (result) => {
        this.documents = result;
        await this.orderService.getOrderDocuments(this.id).then((result) => {
          if (result) this.documents = result;
        });
        this.checkSelectedAll();
      });
    });
  }

  saveStep(pageName) {
    let statusNo = 0;
    switch (pageName) {
      case 'documents':
        console.log('CheckedProduct');
        statusNo = Status.StatusesUser.step2;
    }
    this.orderService
      .setStatus(this.order.order_id, statusNo)
      .subscribe((result: IWsResult) => {
        this.orderService.updateOrder(this.orderToUpdate).subscribe();
        this.close();
        Swal.fire({
          icon: 'success',
          title: 'Aggiunti!',
          text: 'Documenti selezionati con successo!!!',
          timer: 1500,
          timerProgressBar: true,
        });
      });
  }
  checkSelectedAll(stepBack = false) {
    const stepFrw = 2;
    const stepBck = 1;
    if (this.documents)
      this.orderService.setDocumentsChecked(
        this.order.order_id,
        this.documents
      );
    if (
      this.order.status[0].os_status_order_cod > 2 &&
      this.order.status[0].os_status_order_cod < 7 &&
      this.firstEnteredOnPage
    ) {
      this.documents.forEach((element) => {
        element.checked = true;
      });
      this.firstEnteredOnPage = false;
    }
  }

  checkIfDocsClicked() {
    this.allClicked = this.documents.every((doc) => {
      return doc.checked;
    });
  }

  async checkDocument(id: number) {
    this.documents[id].checked = !this.documents[id].checked;
    this.documents.sort(
      (a, b) =>
        +(a.checked ? a.checked : false) - +(b.checked ? b.checked : false)
    );
    this.checkSelectedAll();
    this.checkIfDocsClicked();
    this.showNotification('Documento selezionato con successo!', 'success');
  }

  printAllDocuments() {
    this.orderService
      .getAllDocuments(this.order.order_id)
      .subscribe((result) => {
        console.debug('result', result);
        if (!result) return;
        window.open(result.all_document_link_pdf);
      });
  }

  close() {
    this.closeServ.closeDoc();
  }

  printDDTStamp() {
    this.orderService
      .getGenerateDDT(
        this.order.order_id,
        this.printerService.printerSelection.value.printer_ddt_id
      )
      .subscribe(async (result) => {
        console.log('result', result);
        if (!result) return;

        if (result.error_message.msg_code === 101) {
          console.log('No ENTER');
          const alert = await this.alertController.create({
            header: 'ERROR!!',
            message:
              "Un'altra persona potrebbe averlo generato nello stesso tempo o il server è occupato",
            buttons: [
              {
                text: 'OK',
                handler: () => {
                  // Check if popoverController is defined before calling dismiss
                  if (this.popoverController) {
                    this.popoverController.dismiss();
                  }
                },
              },
            ],
          });

          await alert.present();
        } else if (result.success) {
          this.isLinkToDDT = result.data.or_url_ddt?.indexOf('.pdf') > 0;
          this.linkToDDT = this.isLinkToDDT ? result.data.or_url_ddt : null;
          this.isClickedOnIt = true;
          this.clickedOnce = true;
          window.open(result.data.or_url_ddt);
        }
      });
  }

  allDocumentsChecked(): boolean {
    return this.documents ? this.documents?.every((doc) => doc.checked) : true;
  }

  public showNotification(
    messageText: string,
    styleType: 'none' | 'success' | 'warning' | 'error' | 'info'
  ): void {
    this.notifServ.show({
      content: messageText,
      hideAfter: 600,
      position: { horizontal: 'center', vertical: 'top' },
      animation: { type: 'fade', duration: 2000 },
      type: { style: styleType, icon: true },
    });
  }
}

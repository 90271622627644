import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LabelSettings } from '@progress/kendo-angular-progressbar';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Orders } from 'src/app/models/Orders.model';
import { IWsResult } from 'src/app/models/ws_result.model';
import { Shipments } from 'src/app/models/shipment';
import { Products } from 'src/app/models/Product.model';
import { Documents } from 'src/app/models/documents.models';
import { ProductsService } from 'src/app/services/products.service';
import { OrdersService } from 'src/app/services/orders.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import { PickerService } from 'src/app/services/picker.service';

@Component({
  selector: 'app-borderò',
  templateUrl: './borderò.component.html',
  styleUrls: ['./borderò.component.scss'],
})

export class BorderòComponent {  

  @Input() opened = false;
  @Input() order_id: string = '-1';
  @Input() code: any;
  @Input() isProdOpen = false;
  @Input() product_id: string = '-1';

  public order: Orders.IOrder;
  public ordersStock: Orders.IOrderClosed[] = [];
  public product: Products.Product[] = [];
  public goBack = false;
  public dataSaved = false;
  public isEditable = false;
  public maxlength = 250;
  public minWidth = 250;
  public dialogWidth = 1356;
  public dialogHeight = 1017;
  public documents: Documents.Document[] = [];
  public gridView: GridDataResult;
  public value = 0;
  public indeterminate = false;
  public min = -10;
  public max = 10;
  public pageSize = 20;
  public skip = 0;
  public data: unknown[];
  public orders: Orders.OrderSignature[] = [];
  public shipments: Shipments.ShipmentList[] = [];
  public filteredShipments: Shipments.ShipmentList[] = null;
  public gridData: Orders.IOrderOpenList[] = [];
  public loading: boolean = false;
  public searchString = 'Cliente';
  public customers: string[];
  public setPercentLabel: LabelSettings = {
    visible: true,
    format: 'percent',
    position: 'center',
  };

  linkBordero: any;
  or_url_box_label: string;
  bordero = 'http://salvatempo.connecta.ovh/repository/docs/bordero/';
  openOrders: Orders.IOrderOpenList[] = [];
  ordersDetailsForm!: FormGroup;
  formattedDate: string = '-';
  pr_code: string;
  date: string = '';
  clickLabel: boolean = false;
  linkAllLabels: any;
  clickedProduct: any;
  canSelectAll: boolean = false;
  hiddenStyle: boolean = false;
  clickedId: any;
  clickedOrder: string;
  startDate: Date = new Date(2021, 0, 1);
  endDate: Date = new Date();
  searchText: string = '';
  filterTerm: string | undefined;
  timer = null;
  refreshInterval: number = 60000;
  chosenCarrier: string = null;
  carriers: string[] = [];
  isOneSelected: boolean = false;
  selectedOrders: Orders.OrderSelectedToSign[] = [];
  orderBordero: Orders.OrderBordero = new Orders.OrderBordero();
  checked: boolean = false;
  selectedRowIndex: number;
  customer: string = '';
  showAll: string = 'Mostra tutti';
  customerIndex: number;
  clickedIdComplete: any;
  courier_name: string;
  order_carrier: string;
  carrier_id: string;
  totale: any = []

  ettArray: {
    scatola: string;
    property2: string;
    property3: string;
    onClick: (index: number) => void;
  }[] = [
    {
      scatola: 'Bordero',
      property2: 'value5',
      property3: 'value6',
      onClick: (index) => this.triggerFunction(index),
    },
  ];

  dateBy: any[] = [
    {
      text: 'Data ordine',
      value: 'or_order_number',
    },
  ];

  filters = {
    dateOrginizedBy: {
      text: 'Data ordine',
      value: 'or_order_number',
    },
    startingDate: this.startDate,
    endDate: this.endDate,
    minDate: new Date(),
    maxDate: new Date(),
  };

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
  });

  constructor(
    private openOrdersServ: OrdersService,
    private router: Router,
    public openModal: OpenModalService,
    public pageService: PickerService,
    private ordersServ: OrdersService,
    private productServ: ProductsService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public closeServ: OpenModalService
  ) { }

  printBordero() {
    const link = this.or_url_box_label;
    const a = document.createElement('a');
    a.href = link;
    a.target = '_blank';
    a.click();
    console.log('Borderò is: ', link);
  }

  checkSelectedAll(stepBack = false) {
    this.isOneSelected = this.selectedOrders.some(
      (item) => item.op_checked == true
    );
  }

  deepCopy(obj) {
    let copy;
    if (null == obj || 'object' != typeof obj) return obj;
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }
    if (obj instanceof Array) {
      copy = [];
      for (let i = 0, len = obj.length; i < len; i++) {
        copy[i] = this.deepCopy(obj[i]);
      }
      return copy;
    }
    if (obj instanceof Object) {
      copy = {};
      for (let attr in obj) {
        if (obj.hasOwnProperty(attr)) {
          copy[attr] = this.deepCopy(obj[attr]);
        }
      }
      return copy;
    }
    throw new Error('Impossibile copiare obj! Il suo tipo non è supportato.');
  }

  getOpenOrders() {
    this.openOrdersServ.getOrdersToSign().subscribe((ordersData) => {
      this.orders = ordersData;
      if (!ordersData) 
        return;
      this.loading = false;
    });
  }

  searchOrders() {
    if (!this.filterTerm || this.filterTerm == '') {
      this.openOrdersServ
        .searchOrdersToSign()
        .subscribe((openOrdersData: IWsResult) => {
          this.orders = openOrdersData.data;
          console.log('Searching...');
        });
    }
    this.openOrdersServ
      .searchOrdersToSign(this.filterTerm)
      .subscribe((openOrdersData: IWsResult) => {
        this.orders = openOrdersData.data;
        console.log('Searching...');
      });
  }

  onInputChanged(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.validity && inputElement.validity.valueMissing) {
      this.getOpenOrders();
    }
  }

  async getOrderDate(order_carrier, carrier_id) {
    this.orders.filter((item) => {
      if (item.or_carrier_desc !== null) {
        order_carrier = item.or_carrier_desc;
        carrier_id = item.or_carrier_id;
        this.order_carrier = order_carrier;
        this.carrier_id = carrier_id;
      } 
      else if (item.or_carrier_desc === null) {
        order_carrier = item.or_carrier_other;
        carrier_id = null;
        this.carrier_id = carrier_id;
        this.order_carrier = order_carrier;
      }
    });
    console.log('Order carrier is: ', this.order_carrier);
    console.log('Carrier ID is: ', this.carrier_id);
    this.openModalDamnIt();
  }

  chooseItem(id, event) {
    console.log('This selected orders: ', this.selectedOrders);
    console.log('ID is: ', id);
    event.stopPropagation();
    const index = id - 1;
    this.selectedOrders[index].op_checked =
      !this.selectedOrders[index].op_checked;
    this.checkSelectedAll();
  }

  openModalDamnIt() {
    this.openModal.onBordero();
  }

  gotoDetails(order_id: string) {
    this.clickedId = order_id;
    this.openModal.onBordero();
    console.log('opened!');
  }

  goEditFunction() {
    this.isEditable = !this.isEditable;
  }

  productDetails(productId: any) {
    console.debug('Merge?', productId);
    this.productServ
      .getProduct(this.code)
      .subscribe((productData: IWsResult) => {
        this.product = productData.data;
      });
    this.clickedProduct = productId;
    this.closeServ.openProd();
  }

  ngOnInit(): void {
    // this.loading = true;
    this.getBorderos();
    this.calcolaTotaleImponibile();
    this.timer = setInterval(() => {
      this.getBorderos();
      console.log('Interval set: ');
    }, this.refreshInterval);
    // this.ordersDetailsForm = this.formBuilder.group({
    //   or_assignment_date: [''],
    //   or_order_number: [''],
    //   or_customer: [''],
    //   or_agent_id: [''],
    //   or_shipping_address: [''],
    //   or_closing_date: [''],
    //   vs_details: [''],
    //   carrier_desc: [''],
    //   ac_first_name: [''],
    //   ac_last_name: [''],
    //   boxes: [''],
    //   op_articles_number: [''],
    //   op_address: [''],
    //   op_city: [''],
    //   or_shipping_zip: [''],
    //   op_prov: [''],
    //   or_shipping_country: [''],
    // });
    // this.ordersServ.getOrder1(+this.order_id).subscribe((orderData) => {
    //   this.order = orderData;
    //   this.product = orderData.products;
    //   this.or_url_box_label = orderData.or_url_box_label;
    //   this.linkBordero = orderData.or_url_box_label;
    //   this.ordersServ.getDocuments(+this.order_id).subscribe(async (result) => {
    //     this.documents = result;
    //     await this.ordersServ
    //       .getOrderDocuments(this.order_id)
    //       .then((result) => {
    //         if (result) this.documents = result;
    //         this.printAllLabels();
    //       });
    //   });
    //   this.ordersDetailsForm.setValue({
    //     or_assignment_date: this.formattedDate,
    //     or_order_number: this.order.or_order_number,
    //     or_closing_date: this.order.or_closing_date,
    //     or_agent_id: this.order.or_agent_id,
    //     or_customer: this.order.or_customer,
    //     or_shipping_address: this.order.or_shipping_address,
    //     vs_details: this.order.vs_details.tab_order_priority_desc,
    //     carrier_desc: this.order.vs_details.carrier_desc,
    //     ac_first_name: this.order.vs_details.ac_first_name,
    //     ac_last_name: this.order.vs_details.ac_last_name,
    //     boxes: this.order.boxes,
    //     op_articles_number: this.order.vs_details.op_articles_number,
    //     op_address: this.order.or_shipping_address,
    //     op_city: this.order.or_shipping_city,
    //     or_shipping_zip: this.order.or_shipping_zip,
    //     op_prov: this.order.or_shipping_prov,
    //     or_shipping_country: this.order.or_shipping_country,
    //   });
    // });
    // this.getBorderos();
  }

  close() {
    this.closeServ.offBordero();
  }

  goToProductDetails(pr_code) {
    this.pr_code = pr_code;
    console.log('Product ID is: ', pr_code);
    this.closeServ.onStoricoProd();
  }

  triggerFunction(index: number): void {
    const clickedScatola = this.ettArray[index].scatola;
    switch (clickedScatola) {
      case 'Scatola':
        this.printAllLabels();
        break;
      case 'Bordero':
        this.printBordero();
        break;
      default:
        break;
    }
  }

  getBorderos() {
    this.ordersServ.getNewBorderos().subscribe((data: any) => {
      if (!data) 
        return;
      this.orders = data.data;
      console.log('Borderos are: ', data);
    });
  }

  printAllLabels() {
    this.ordersServ
      .getAllBoxLabels(
        this.order.order_id,
        this.order.boxes.map((box, index) => index + 1)
      )
      .subscribe((result) => {
        console.debug('result', result);
        if (!result) 
          return;
        this.linkAllLabels = result.box_label_link_pdf;
        console.log('This LinkAllLabels: ', this.linkAllLabels);
      });
  }

  calcolaTotaleImponibile() {
    this.totale = 0;
    this.orders.forEach(item => {
      this.totale += item.or_order_number;
    });
    return this.totale
  }

}

<kendo-dialog
  *ngIf="openedBox"
  (close)="close()"
  class="resize-content2 center"
  style="left: 6%"
>
  <div
    style="
      display: flex;
      flex-direction: row;
      justify-content: end;
      margin-top: -40px;
      z-index: 1000;
      position: fixed;
      margin-left: -55px;
    "
  >
    <button
      (click)="close()"
      class="btn btn3"
      rounded="full"
      style="
        background-color: #e90000;
        color: #ffffff;
        width: 35px !important;
        height: 35px !important;
      "
    >
      <i
        class="bx bx-x bx-sm posRelBox2"
        style="
          color: #ffffff;
          bottom: 10px;
          position: relative !important;
          left: -80% !important;
          top: -1px !important;
        "
      ></i>
    </button>
  </div>
  <div class="container text-center my-3">
    <div class="row pt-2">
      <div class="col" style="font-size: 20px; position: relative;">
        COLLI
      </div>
      <div class="col" style="font-size: 20px; position: relative;">
        Viteria
      </div>
      <div class="col" style="font-size: 20px; position: relative;">
        Documenti
      </div>
    </div>
    <hr class="hr-newBlue" />
    <div class="row" *ngFor="let box of orderToUpdate?.boxes; let i = index">
      <div class="col displayPadd">
        <kendo-label
          *ngIf="box.ts_box_type_desc"
          class="pos-text1 newBorder mb-2"
          role="button"
        >
          {{ box.ts_box_type_desc }}
          <strong
            *ngIf="box.ts_box_type_desc"
            (click)="removeBox(i)"
            class="setHover"
          >
            <i class='bx bx-trash icon'></i>
          </strong>
        </kendo-label>
        <kendo-label
          *ngIf="!box.ts_box_type_desc"
          class="pos-text1 newBorder mb-2"
          role="button"
        >
          <span (click)="showBoxDetails(box, i)"> Custom </span>
          <strong
            *ngIf="box.ts_box_height"
            (click)="removeBox(i)"
            role="button"
            class="setHover"
            ><i class='bx bx-trash icon'></i>
          </strong>
        </kendo-label>
      </div>
      <div class="col">
        <input
          class="form-check-input"
          type="checkbox"
          [checked]="box.ts_screws==='S'"
          id="flexCheckDefault"
          (click)="addScrewsToBox(i)"
        />
      </div>
      <div class="col">
        <input
          class="form-check-input"
          type="checkbox"
          [checked]="box.ts_documents==='S'"
          id="flexCheckDefault"
          (click)="addDocumentsToBox(i)"
        />
      </div>
    </div>
  </div>
  <div class="text-buttons">
    <!--<div class="button-right">
      <button
        class="btn btn3"
        rounded="full"
        style="
          background-color: #8d8d8d;
          color: #ffffff;
          justify-content: center !important;
          display: flex;
          width: 192px !important;
          height: 50px !important;
        "
        (click)="close()"
      >
        <i
          class="bx bx-left-arrow-circle bx-sm posRelBox2"
          style="
            color: #ffffff;
            right: 6px;
            bottom: 10px;
            position: relative;
            margin-top: 4px;
          "
        ></i>
        <p class="posRelBox2">INDIETRO</p>
      </button>
    </div>-->
    <div class="button-left">
      <button
        (click)="goToBox(clickedBox)"
        class="btn btn3"
        rounded="full"
        style="
          background: #0D6EFD;
          color: #ffffff;
          justify-content: center !important;
          display: flex;
          width: 192px !important;
          height: 50px !important;
        "
      >
        <i
          class="bx bx-plus-circle bx-sm posRelBox2"
          style="
            color: #ffffff;
            right: 6px;
            bottom: 10px;
            position: relative;
            margin-top: 4px;
          "
        ></i>
        <p class="posRelBox2">AGGIUNGI</p>
      </button>
    </div>
    <div class="button-left">
      <button
        (click)="saveStep('boxes')"
        class="btn btn3"
        rounded="full"
        style="
          background: rgb(0, 146, 73);
          color: #ffffff;
          justify-content: center !important;
          display: flex;
          width: 192px !important;
          height: 50px !important;
        "
      >
        <i
          class="bx bx-check-circle bx-sm posRelBox2"
          style="
            color: #ffffff;
            right: 6px;
            bottom: 10px;
            position: relative;
            margin-top: 4px;
          "
        ></i>
        <p class="posRelBox2">SALVA</p>
      </button>
    </div>
    <!-- <div class="button-left">
      <button kendoButton (click)="goToBox(clickedBox)" class="button-l">
        Add Box
      </button>
    </div> -->
    <div class="button-right">
      <button
        (click)="printAllLabels()"
        class="btn btn3"
        rounded="full"
        style="
          background-color: #f38200f3;
          color: #ffffff;
          justify-content: center !important;
          display: flex;
          width: 192px !important;
          height: 50px !important;
        "
      >
        <i
          class="bx bx-printer bx-sm posRelBox2"
          style="
            color: #ffffff;
            right: 6px;
            bottom: 10px;
            position: relative;
            margin-top: 4px
          "
        ></i>
        <p class="posRelBox2">ETICHETTE</p>
      </button>
    </div>
  </div>
</kendo-dialog>

<app-add-box
  (dataInfo)="addBox($event)"
  *ngIf="closeServ.isBoxOpn"
  [openBox]="closeServ.isBoxOpn"
  [box_type_id]="clickedBox"
>
</app-add-box>

<app-box-details
  (dataInfo)="updateCustomBox($event)"
  *ngIf="closeServ.isBoxDetOn"
  [openBox]="closeServ.isBoxDetOn"
  [box]="clickedBox"
  [boxNo]="boxNu"
></app-box-details>

<h1 class="page-title">Impostazioni</h1>
<br />
<hr class="hr-newBlue" />

<div class="padded rounded-corners print-box">
  <h2 class="col-header">Stampante scelta:</h2>
  <div class="grid-wrapper">
    <kendo-grid
      [kendoGridBinding]="printers"
      [data]="printers"
      [rowHeight]="36"
      [height]="210"
      scrollable="virtual"
      [navigable]="true"
      [sortable]="true"
    >
      <kendo-grid-column
        [width]="200"
        field="ms_title"
        title="Nome stampante"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'text-align': 'center' }"
        [sortable]="true"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.printer_desc }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        [width]="100"
        field="ms_title"
        title="DDT"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'text-align': 'center' }"
        [sortable]="true"
        (click)="changeprinterCustomBorID(dataItem.printer_id, 0)"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <ion-toggle
            [enableOnOffLabels]="true"
            [checked]="dataItem.printer_ddt"
            disabled="false"
            class="--background-white opaicty-full"
            mode="ios"
          ></ion-toggle>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        [width]="100"
        field="ms_title"
        title="Scatola"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'text-align': 'center' }"
        [sortable]="true"
        (click)="changeprinterCustomBorID(dataItem.printer_id, 1)"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <ion-toggle
            [enableOnOffLabels]="true"
            [checked]="dataItem.printer_box"
            disabled="false"
            class="--background-white opaicty-full"
            mode="ios"
          ></ion-toggle>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        [width]="100"
        field="ms_title"
        title="Corriere"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'text-align': 'center' }"
        [sortable]="true"
        (click)="changeprinterCustomBorID(dataItem.printer_id, 2)"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <ion-toggle
            [enableOnOffLabels]="true"
            [checked]="dataItem.printer_car"
            disabled="false"
            class="--background-white opaicty-full"
            mode="ios"
          ></ion-toggle>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        [width]="100"
        field="ms_title"
        title="Borderò"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'text-align': 'center' }"
        [sortable]="true"
        (click)="changeprinterCustomBorID(dataItem.printer_id, 3)"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <ion-toggle
            [enableOnOffLabels]="true"
            [checked]="dataItem.printer_bor"
            disabled="false"
            class="--background-white opaicty-full"
            mode="ios"
          ></ion-toggle>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>
<br /><br />

<div class="padded rounded-corners print-box">
  <h2 class="col-header text-center">Obiettivo:</h2>
  <div class="grid-wrapper" *ngIf="objectivesArray">
    <kendo-grid
      [data]="objectivesArray"
      [rowHeight]="36"
      [height]="100"
      scrollable="virtual"
      [navigable]="true"
      [sortable]="true"
      [style]="{ 'text-align': 'center' }"
    >
      <kendo-grid-column
        [width]="100"
        field="tp_daily_objective"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'text-align': 'center' }"
        [sortable]="true"
        title="Obiettivo giornaliero"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <input
            [(ngModel)]="daily"
            class="editable-input val"
            type="number"
            placeholder="Default Value"
          />
          <button class="btn btn-primary obj" (click)="setGoal()">SALVA</button>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        [width]="100"
        field="tp_monthly_objective"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'text-align': 'center' }"
        [sortable]="true"
        title="Obiettivo mensile"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <input
            [(ngModel)]="monthly"
            class="editable-input val"
            type="number"
          />
          <button class="btn btn-primary obj" (click)="setGoal()">SALVA</button>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        [width]="100"
        field="ms_title"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'text-align': 'center' }"
        [sortable]="true"
        title="Obiettivo annuale"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <input
            [(ngModel)]="yearly"
            class="editable-input val"
            type="number"
          />
          <button class="btn btn-primary obj" (click)="setGoal()">SALVA</button>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>

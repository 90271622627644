import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { Orders } from 'src/app/models/Orders.model';
import { Status } from 'src/app/models/status';
import { IWsResult } from 'src/app/models/ws_result.model';
import { OrdersService } from 'src/app/services/orders.service';
import { IconsService } from '../../../services/icons.service';
import Swal from 'sweetalert2';
import { Subscriber, Subscription } from 'rxjs';
import { parseISO } from 'date-fns';

@Component({
  selector: 'app-ordini-parziali-list',
  templateUrl: './ordini-parziali-list.component.html',
  styleUrls: ['./ordini-parziali-list.component.scss'],
})

export class OrdiniParzialiListComponent implements OnInit {
  
  public gridData: Orders.IOrderNewList[] = [];
  public order: Orders.IOrder = null;
  public loading: boolean = false;
  public isSearched: boolean = false;
  public value = 0;
  public indeterminate = false;
  public min = -10;
  public max = 10;
  public gridView: GridDataResult;
  public pageSize = 20;
  public skip = 0;
  public data: unknown[];

  gridDataInifinity: Orders.IOrderNewList[] = [];
  ordiniParziali: any[] = [];
  filteredOrders: Orders.IOrderNewList[] = [];
  searchText: string = '';
  filterTerm: string | undefined;
  sameIndex: boolean = true;
  id: number;
  date: string = '';
  showAll: string = 'Mostra tutti';
  timer = null;
  refreshInterval: number = 60000;
  startDate: Date = new Date(2021, 0, 1);
  endDate: Date = new Date();

  dateBy: any[] = [
    {
      text: 'Data ordine',
      value: 'or_order_number',
    },
  ];

  filters = {
    dateOrginizedBy: {
      text: 'Data ordine',
      value: 'or_order_number',
    },
    startingDate: this.startDate,
    endDate: this.endDate,
    minDate: new Date(),
    maxDate: new Date(),
  };
  subscriber: Subscription[] = [];
  searchParam: string = '';

  constructor(
    private ordersServ: OrdersService,
    private router: Router,
    private iconService: IconsService,
    private route: ActivatedRoute
  ) { }

  // loadMore() {
  //   this.gridDataInifinity = [
  //     ...this.gridDataInifinity,
  //     ...this.gridData.slice(this.skip, this.skip + this.pageSize),
  //   ];
  //   this.skip += this.pageSize;
  // }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadProducts();
  }

  private loadProducts(): void {
    this.gridView = {
      data: this.gridData.slice(this.skip, this.skip + this.pageSize),
      total: this.gridData.length,
    };
  }

  searchOrders(sarchString?: string) {
    if (sarchString) 
      this.filterTerm = sarchString;
    if (!this.filterTerm || this.filterTerm == '') {
      this.getOrdineParziali();
      return;
    }
    this.ordersServ
      .searchPartialOrders(this.filterTerm)
      .subscribe((newOrdersData: IWsResult) => {
        if (newOrdersData.error_message.msg_code === 8) 
          this.gridData = [];
        else {
          /*if (this.order.or_partial === 'S') 
            this.gridData = newOrdersData.data;*/
          this.gridData = newOrdersData.data;
          console.log('GridData', this.gridData);
        }
        //this.gridDataInifinity = newOrdersData.data;
        this.skip = 0;
        this.gridDataInifinity = [];
        // this.loadMore();
      });
  }

  onInputChanged(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.validity && inputElement.validity.valueMissing) {
      this.filters.startingDate = this.startDate;
      this.getOrdineParziali();
    }
  }

  onDateSelect(name: string, i: number) {
    this.date = name;
    console.log('I is: ', i);
    if (name === this.showAll) 
      this.date = null;
  }

  ngOnInit(): void {
    this.loading = true;
    // this.getNewOrders();

    this.subscriber.push(
      this.route.queryParams.subscribe((params) => {
        console.log('Params is', params);

        // Check if params is an empty object
        if (Object.keys(params).length === 0) {
          this.getOrdineParziali();
          this.loading = false;
          console.log('Params is an empty object. Reloaded.');
        } 
        else {
          this.searchParam = params['search'];
          const dateParam = params['data'];
          this.filters.startingDate = dateParam ? parseISO(dateParam) : null;
          console.log('Date param is', dateParam);
          console.log('Param is', this.searchParam);
          this.searchOrders(this.searchParam);
          this.loading = false;
        }
      })
    );
  }

  getOrdineParziali() {
    this.loading = true;
    this.ordersServ.getNewOrdersPartial().subscribe((openOrdersData) => {
      this.gridData = openOrdersData.data;
      console.log('Ordini parziali: ', this.gridData);
      this.skip = 0;
      this.gridDataInifinity = this.gridData;
      // this.loadMore();
      this.loading = false;
    });
  }

  gotoDetails(order_id: number) {
    this.router.navigate(['/partial-orders', order_id]);
  }

  block(order) {
    this.iconService.changeIconToBlocked(order);
    console.log('BLOCKED!', order);
    this.ordersServ
      .lockNewOrder(order.or_gamma_id)
      .subscribe((result: IWsResult) => {
        if (!result.success) 
          return;
        this.reloadFunction();
        Swal.fire({
          icon: 'success',
          title: 'Bloccato!',
          text: 'Ordine bloccato con successo!!!',
          timer: 1500,
          timerProgressBar: true,
        });
        console.log('BLOCKED!');
      });
  }

  async unblock(order) {
    console.log('BLOCKED!', order);
    this.iconService.changeIconToWorking(order);
    this.ordersServ
      .unlockNewOrder(order.or_gamma_id)
      .subscribe((result: IWsResult) => {
        if (!result.success) 
          return;
        this.reloadFunction();
        Swal.fire({
          icon: 'success',
          title: 'Sbloccato!',
          text: 'Ordine parziale sbloccato con successo!!!',
          timer: 1500,
          timerProgressBar: true,
        });
        console.debug('This order: ', order);
        console.log('UNBLOCKED!');
      });
  }

  async isInWork(): Promise<boolean> {
    if (!this.ordersServ.isUser.getValue()) {
      this.sameIndex = false;
      return true;
    }
    let indexToSuspend = this.gridData.findIndex(
      (order) =>
        (order.tab_order_status_cod >= Status.StatusesUser.step1 &&
          order.tab_order_status_cod <= Status.StatusesUser.step4) ||
        order.tab_order_status_cod == Status.StatusesUser.intiated
    );
    console.debug('indexToSuspend', indexToSuspend);
    this.sameIndex =
      indexToSuspend != -1
        ? this.gridData[indexToSuspend].order_id == this.id
        : false;
    console.debug('this.sameIndex', this.sameIndex);
    if (indexToSuspend != -1 && !this.sameIndex) {
      this.ordersServ
        .setStatus(
          this.gridData[indexToSuspend].order_id,
          Status.StatusesUser.blocked
        )
        .subscribe((result: IWsResult) => {
          if (!result.success) {
            Swal.fire({
              icon: 'error',
              title: 'Attenzione!',
              text: 'L`Ordine non può essere sospeso!!!',
              timer: 1500,
              timerProgressBar: true,
            });
            return;
          }
        });
    }
    return true;
  }

  reloadFunction() {
    // this.getNewOrders();
    this.getOrdineParziali();
  }

}

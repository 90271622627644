import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Orders } from 'src/app/models/Orders.model';
import { OrdersService } from 'src/app/services/orders.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';

@Component({
  selector: 'app-storico-ordini-new',
  templateUrl: './storico-ordini-new.component.html',
  styleUrls: ['./storico-ordini-new.component.scss'],
})

export class StoricoOrdiniNewComponent implements OnInit {

  @Input() opened = false;
  @Input() order_id: string = '-1';
  
  public order: Orders.IAddOrder;

  isEditable: boolean;
  orderDetailsForm!: FormGroup;
  formattedDate: string = '-';

  constructor(
    private ordersSrv: OrdersService,
    private formBuilder: FormBuilder,
    public closeSrv: OpenModalService
  ) { }

  ngOnInit(): void { }  

  goBackFunction() {
    this.closeSrv.close();
  }  

  addOrderDialog() {
    this.orderDetailsForm = new FormGroup({
      or_assignment_date: new FormControl('', [Validators.required]),
      or_customer_phone: new FormControl('', [
        Validators.required,
        Validators.pattern('[0-9-+ ]*'),
        Validators.maxLength(20),
      ]),
      or_order_number: new FormControl('', [Validators.required]),
      or_order_date: new FormControl('', [
        Validators.pattern(
          /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/
        ),
      ]),
      or_agent_id: new FormControl('', []),
      or_shipping_address: new FormControl('', [Validators.required]),
      or_customer: new FormControl('', [Validators.required]),
    });
  }  

}

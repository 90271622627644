import { Component, OnInit, OnChanges } from '@angular/core';
import { AuthService } from './services/auth.service';
import { SidebarService } from './services/shared/sidebar.service';
import { filter } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { PrinterService } from './services/printer/printer.service';
import { OpenModalService } from './services/shared/open-modal.service';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { PushNotificationDataService } from './services/shared/push-notification-data.service';
registerLocaleData(localeIt);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit {

  title = 'Diakonix';
  hideSideBars: boolean = false;
  account: any;
  shouldDisplayContent: boolean = true;
  clickedId: any;
  notificationData: any;

  constructor(
    public sidebarService: SidebarService,
    public authServ: AuthService,
    private router: Router,
    private printersService: PrinterService,
    public closeSrv: OpenModalService,
    private pushNotificationService: PushNotificationDataService
  ) {
    registerLocaleData(localeIt);
  }

  ngOnInit() {
    // this.initWonderPush();

    registerLocaleData(localeIt);
    this.account = JSON.parse(localStorage.getItem('currentUser') || '{}');
    console.log('Account is', this.account);
    // if (this.account) {
    //
    // }

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const currentRoute = this.getCurrentRoute(event.url);
        console.log('Current Route:', currentRoute);
        if (this.isLoginOrdersRoute()) {
          this.shouldDisplay();
        }
        if (this.isWarehouseOrdersRoute()) {
          this.shouldDisplay();
        }
        if (this.isAgentsOrdersRoute()) {
          this.hideSidebars();
        } 
        else {
          this.printersService.getPrinterSettings();
        }
      });
  }

  getCurrentRoute(url: string): string {
    const routeParts = url.split('?');
    const baseRoute = routeParts[0];
    return baseRoute.replace(/^\/|\/$/g, '');
  }

  isAgentsOrdersRoute(): boolean {
    return this.router.url.includes('agentsorders');
  }

  isLoginOrdersRoute(): boolean {
    return this.router.url.includes('login');
  }

  isWarehouseOrdersRoute(): boolean {
    return this.router.url.includes('warehouseorders');
  }

  isMessageRoute(): boolean {
    return this.router.url.includes('messages');
  }

  shouldDisplay() {
    this.shouldDisplayContent = false;
    console.log('Should display false');
  }

  hideSidebars() {
    this.sidebarService.isOpen = false;
    this.sidebarService.isOpenR = false;
    this.sidebarService.isBody = false;
  }

  showSidebars() {
    this.sidebarService.isOpen = true;
    this.sidebarService.isOpenR = true;
    this.sidebarService.isBody = true;
  }

  goToAccDetails() {
    this.clickedId = this.account.account_id;
    this.closeSrv.onPhoto();
  }

  initWonderPush(): void {
    window.WonderPush = window.WonderPush || [];
    window.WonderPush.push(['setUserConsent', true]);
    window.WonderPush.push([
      'init',
      {
        webKey:
          '2ea0e1cebe48d7889573ee679e87c32ea7b222e24615772a3c22f2ad10b87e97',
        serviceWorkerUrl: '/sw.js',
        requiresUserConsent: true,
        onSuccess: function () {
          // WonderPush SDK is fully initialized
          console.log('WonderPush SDK initialized successfully');
          // You can now safely access WonderPush features
          // For example, you can subscribe to notifications here
          // subscribeToNotifications();
        },
        onError: function (error: any) {
          // Error occurred during initialization
          console.error('WonderPush SDK initialization failed:', error);
        },
      },
    ]);
  }
  
}

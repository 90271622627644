import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, catchError, map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IWsResult } from '../models/ws_result.model';
import { ErrorHandlerService } from './shared/error-handler.service';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})

export class AuthService {

  public isUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public currentUserSubject: BehaviorSubject<IWsResult>;
  public currentUser: Observable<IWsResult> | undefined;
  public isScreen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null
  );
  public screenUserSubject: BehaviorSubject<IWsResult>;
  public screen: Observable<IWsResult> | undefined;

  stateUrl: string = 'dashboard';
  url: string = environment.urlServer;

  public notificationPushData: BehaviorSubject<string> =
    new BehaviorSubject<string>(null);

  constructor(
    private http: HttpClient,
    private router: Router,
    private errorHandlerServ: ErrorHandlerService
  ) {
    this.currentUserSubject = new BehaviorSubject<IWsResult>(
      JSON.parse(localStorage.getItem('currentUser') || '{}')
    );
    if (localStorage.getItem('tokenValue')) 
      this.isUser.next(true);
    else 
      this.isUser.next(false);
    this.getScreenEmail();
    this.currentUser = this.currentUserSubject.asObservable();
  }

  getScreenEmail() {
    let currentScreen = JSON.parse(localStorage.getItem('currentUser') || '{}');
    if (currentScreen !== '{}' && currentScreen.ac_user_type_cod === 3) {
      this.isScreen.next(true);
    } 
    else 
      this.isScreen.next(false);
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string, ip: string) {
    let loginInfo = { email: email, password: password, ip_address: ip };
    return this.http.post<any>(`${this.url}/login/`, loginInfo).pipe(
      map((result: IWsResult) => {
        if (result.success && result.data && result.data.user_ID != 0) {
          console.log('ac_user_type_cod', result.data.ac_user_type_cod);
          if (
            result?.data?.ac_user_type_cod > 1 &&
            result?.data?.ac_user_type_cod !== 3
          ) {
            Swal.fire({
              icon: 'error',
              title: 'Accesso negato!',
              text: 'L`utente non ha i permessi necessari!',
              timer: 1500,
              timerProgressBar: true,
            });
            return;
          }
          this.isUser.next(true);
          localStorage.setItem('currentUser', JSON.stringify(result.data));
          localStorage.setItem('tokenValue', result.data.ac_token);
          this.getScreenEmail();
        }
        this.currentUserSubject.next(result.data);
        return result;
      }),
      catchError(this.errorHandlerServ.handleError('login'))
    );
  }

  checkPass(email: string, password: string, ip: string) {
    let loginInfo = {
      email: email,
      password: password,
      ip_address: ip,
    };
    return this.http.post<any>(`${this.url}/login/`, loginInfo).pipe(
      map((result: IWsResult) => {
        if (result.success && result.data && result.data.user_ID != 0) {
          this.isUser.next(true);
        }
        this.currentUserSubject.next(result.data);
        return result;
      }),
      catchError(this.errorHandlerServ.handleError('login'))
    );
  }

  clearUser() {
    this.currentUser = undefined;
    this.isUser.next(false);
    localStorage.removeItem('currentUser');
    localStorage.removeItem('tokenValue');
    let TEMP: IWsResult = {};
    this.currentUserSubject.next(TEMP);
    this.currentUserValue;
  }

  logout() {
    this.clearUser();
    this.router.navigateByUrl('/login', { replaceUrl: true });
  }

  resetPassword(username: string): Observable<IWsResult> {
    return this.http
      .post<IWsResult>(`${this.url}/reset_password/`, username)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('resetPassword')
        )
      );
  }

  changePassword(
    old_password: string,
    new_password: string,
    rep_password: string
  ): Observable<IWsResult> {
    let accountInfo = {
      old_password: old_password,
      new_password: new_password,
      rep_password: rep_password,
    };
    return this.http
      .post<IWsResult>(`${this.url}/put_password/`, accountInfo)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('changePassword')
        )
      );
  }
  
}

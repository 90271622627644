import { Pipe, PipeTransform } from '@angular/core';
import { IMessageList } from 'src/app/models/IMessage.model';

@Pipe({
  name: 'byStatusCode',
})

export class ByStatusCodePipe implements PipeTransform {

  transform(value: IMessageList[], args?: string): IMessageList[] {
    if (value == undefined && args == null && args.toString() == '')
      return value;
    //if(args.toString() == this.selectedYear )
    //  return value;
    return value.filter((item: IMessageList) => item?.ms_status == args);
  }
  
}

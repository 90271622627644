import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'byMsCarrier',
})

export class ByMsCarrierPipe implements PipeTransform {

  transform(value: any[], args?: any): any[] {
    if (!value || !args || !Array.isArray(value)) {
      return value;
    }

    const searchValue = args[0].toLowerCase();
    return value.filter((item) =>
      item?.sm_carrier_desc?.toLowerCase().includes(searchValue)
    );
  }
  
}

export namespace Boxes {

  export interface IBox {
    box_type_id: number;
    box_type_desc: string;
  }

  export interface IBoxOrder {
    ts_box_type_id: number;
    ts_box_type_desc: string;
    ts_box_height: number;
    ts_box_length: number;
    ts_box_depth: number;
    ts_box_weight: number;
    ts_documents: string;
    ts_screws: string;
  }

  export interface ISavedBoxByOrder
    extends IBox,
      Omit<IBoxOrder, 'ts_box_type_id' | 'ts_box_type_desc'> {
    order_id: number;
    custom: boolean;
    // documents: boolean;
    // screws: boolean;
  }

  export interface IBoxNoByOrder {
    boxesNumber: number;
    orderId: number;
    index?: number;
  }

  export class Box implements IBox {
    box_type_id: number;
    box_type_desc: string;

    constructor(box: IBox) {
      this.box_type_id = box.box_type_id;
      this.box_type_desc = box.box_type_desc;
    }
  }

  export class BoxOrder implements IBoxOrder {

    ts_box_type_id: number;
    ts_box_type_desc: string;
    ts_box_height: number;
    ts_box_length: number;
    ts_box_depth: number;
    ts_box_weight: number;
    ts_documents: string;
    ts_screws: string;
    checkForNull(): boolean {
      if (!this.ts_box_height) return true;
      if (!this.ts_box_length) return true;
      if (!this.ts_box_depth) return true;
      if (!this.ts_box_weight) return true;
      return false;
    }

    constructor(box: IBoxOrder) {
      if (!box) return;
      this.ts_box_type_id = box.ts_box_type_id;
      this.ts_box_type_desc = box.ts_box_type_desc;
      this.ts_box_height = box.ts_box_height;
      this.ts_box_length = box.ts_box_length;
      this.ts_box_depth = box.ts_box_depth;
      this.ts_box_weight = box.ts_box_weight;
    }
  }
  
}

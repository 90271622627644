import { formatDate } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Helpers } from 'src/app/models/helpters';
import { Orders, OrderSignature } from 'src/app/models/Orders.model';
import { Priority } from 'src/app/models/Priority.model';
import { NewProduct, Products } from 'src/app/models/Product.model';
import { Status } from 'src/app/models/status';
import { IWsResult } from 'src/app/models/ws_result.model';
import { IconsService } from 'src/app/services/icons.service';
import { OrdersService } from 'src/app/services/orders.service';
import { ProductsService } from 'src/app/services/products.service';
import { DropdownService } from 'src/app/services/shared/dropdown.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import { PopoverController } from '@ionic/angular';
import Swal from 'sweetalert2';

export interface ImageInfo {
  src: string;
  width: number;
  height: number;
}

@Component({
  selector: 'app-controllo-dettagli',
  templateUrl: './controllo-dettagli.component.html',
  styleUrls: ['./controllo-dettagli.component.scss'],
})

export class ControlloDettagliComponent implements OnInit {

  @Input() confirmed: boolean;
  @Output() public valueChange: EventEmitter<ImageInfo> = new EventEmitter<ImageInfo>();

  private orderId: number = -1;  
  public uploadSaveUrl = 'http://salvatempo.connecta.ovh/api/services/put_signature';
  public isSignOn = false;
  public alertButtons = ['OK'];
  public dataSaved = false;
  public assignOrderForm: FormGroup;
  public orderNew: Orders.IOrderNew;
  public productsNew: Products.IProductToSend[] = [];
  public products: Products.Product[];
  public orderToSend: Orders.IOrderToSend;
  public formattedDate: string = '-';
  public priorityOptions: Priority.IPriority[] = [];
  public product: NewProduct[] = [];
  public addOrder: Orders.IOrder;
  public assignOrder: Orders.IAddOrder[] = [];
  public order: Orders.Order;
  public orders: Orders.OrderList[] = [];
  public dropProducts: Products.Product[];
  public value = '';
  public showUpload = false;
  public color = '';
  public backgroundColor = '';
  public strokeWidth = 3;
  public imageURL?: SafeUrl;
  private rawImageURL?: string;  
  public uploadRemoveUrl = '';
  public orderToUpdate: Orders.OrderUpdate;

  public sizes = [
    {
      text: 'Normal',
      click: () => (this.strokeWidth = 1),
    },
    {
      text: 'Wide',
      click: () => (this.strokeWidth = 3),
    },
  ];

  public signatureData: OrderSignature = {
    id: 0,
    signature_b64: '',
  };

  staticOrder = Helpers;
  id: number;
  pageName: string;
  pageChild: number = 0;
  maxChild: number = 4;
  sameIndex: boolean = true;
  clickedProduct: any;
  clickedBox: any;
  isCheckBox: any;
  stepCount: number = 0;
  isChecked = false;
  prodNo: any;
  firstEnteredOnPage: boolean = true;
  checkedProd: boolean = false;
  checkedBox: boolean = false;
  checkedDocs: boolean = false;
  status_desc: string;
  selectProduct = 'Select a product';
  step = 0;
  shortLink: string = '';
  loading: boolean = false;
  show: boolean = false;
  file: OrderSignature;  
  selected = '';
  checkProduct: any;
  checkBolla: any;
  checkDDT: any;
  checkQty: any;
  checkQly: any;
  productChecker: boolean = false;
  defaultItem: any;
  isDDT: any;
  messageFromSync: string;
  openMessageWarning: boolean = false;

  steps = [
    {
      index: 1,
      title: 'Controllo Bolla',
      buttonText: 'VERIFICA CORRISPONDENZA BOLLA',
      function: () => this.seeCheckProduct(this.checkProduct),
    },
    {
      index: 2,
      title: 'Controllo DDT',
      buttonText: 'VERIFICA PRESENZA DDT',
      function: () => this.seeCheckDoc(this.checkProduct),
    },
    {
      index: 3,
      title: 'Controllo Quantità',
      buttonText: 'VERIFICA CORRISPONDENZA QUANTITA',
      function: () => this.seeCheckBox(this.checkProduct),
    },
    {
      index: 4,
      title: 'Controllo Prodotti',
      buttonText: 'VERIFICA QUALITA PRODOTTI',
      function: () => this.seeCheckShipment(this.checkProduct),
    },
  ];

  constructor(
    private activatedRoute: ActivatedRoute,
    private ordersServ: OrdersService,
    private productServ: ProductsService,
    private route: ActivatedRoute,
    private dropdownServ: DropdownService,
    private fb: FormBuilder,
    private formBuilder: FormBuilder,
    private router: Router,
    private iconService: IconsService,
    public closeServ: OpenModalService,
    private sanitizer: DomSanitizer,
    public popoverCtrl: PopoverController,
    public openModal: OpenModalService
  ) { }  

  back() {
    this.router.navigate([`/quality`]);
  }

  onFileLoad(event: any) {
    console.debug('asta e eventul', event);
    {
      this.signatureData = {
        id: 0,
        signature_b64: '',
      };
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e: any) => {
        this.signatureData.id = this.order.order_id;
        console.debug(reader);
        this.signatureData.signature_b64 = reader.result?.toString();
        this.ordersServ.signature = this.signatureData;
        this.signatureData.signature_b64 =
          this.signatureData.signature_b64?.substring(
            Helpers.base64Length + Helpers.base64PNGLength
          );
        return;
        this.ordersServ.saveSignature(this.signatureData).subscribe();
      };
      reader.onerror = (error) => {
      };
    }
  }

  onChange(event: any) {
    this.file = event.target.files[0];
    this.show = true;
    this.onUpload();
  }

  onUpload() {
    this.loading = !this.loading;
    console.log('This signature is: ', this.file.signature_b64);
    this.signatureData.signature_b64 = this.file.signature_b64?.toString();
    console.log(this.file);
    this.ordersServ
      .saveSignature(this.signatureData)
      .subscribe((event: any) => {
        if (typeof event === 'object') {
          this.shortLink = event.link;
          this.loading = false;
        }
      });
  }

  public ngOnDestroy() {
    this.cleanupImage();
  }

  public onSave() {
    console.debug(this.value);
    console.debug('Value: ', this.file);
    this.loading = !this.loading;
    this.signatureData.signature_b64 = this.value?.substring(22);
    console.log('This new sign is: ', this.signatureData.signature_b64);
    this.signatureData.id = this.order.order_id;
    this.ordersServ
      .saveSignature(this.signatureData)
      .subscribe((event: any) => {
        if (typeof event === 'object') {
          this.shortLink = event.link;
          this.loading = false;
        }
      });
  }

  public onClear() {
    this.value = '';
    this.cleanupImage();
  }

  private cleanupImage() {
    if (this.uploadSaveUrl) {
      URL.revokeObjectURL(this.uploadSaveUrl);
      this.imageURL = this.uploadRemoveUrl;
      this.rawImageURL = '';
    }
  }

  public readImage(file: File) {
    const reader = new FileReader();
    const onLoad = () => {
      this.value = reader.result as string;
      reader.removeEventListener('load', onLoad);
    };
    reader.addEventListener('load', onLoad);
    reader.readAsDataURL(file);
  }  

  update(e) {
    this.selected = e.target.value;
  }

  async continue() {
    const check = await this.isInWork();
    this.iconService.changeIconToWorking(this.order);
    console.debug(
      'status from continued',
      this.order.status[1].os_status_order_cod
    );
    if (!this.sameIndex)
      this.ordersServ
        .setStatus(
          this.order.order_id,
          this.order.status[1].os_status_order_cod
        )
        .subscribe((result: IWsResult) => {  });
  }

  async continue2() {
    try {
      const check = await this.isInWork();
      this.iconService.changeIconToWorking(this.order);
      console.debug('status from continued', this.order.status[1].os_status_order_cod);
  
      if (!this.sameIndex) {
        const result: IWsResult = await this.ordersServ.setStatus(
          this.order.order_id,
          this.order.status[1].os_status_order_cod
        ).toPromise();  
      }  
    } 
    catch (error) {
      console.error('Errore durante la chiamata API:', error);
    }
  }
  

  suspend() {
    this.iconService.changeIconToSuspended(this.order);
    this.ordersServ
      .setStatus(this.order.order_id, Status.StatusesUser.suspended)
      .subscribe((result: IWsResult) => {
        this.reloadFunction();
      });
  }

  block() {
    this.ordersServ
      .setStatus(this.order.order_id, Status.StatusesUser.blocked)
      .subscribe((result: IWsResult) => {
        this.reloadFunction();
        Swal.fire({
          icon: 'success',
          title: 'Bloccato!',
          text: 'Ordine bloccato con successo!!!',
          timer: 1500,
          timerProgressBar: true,
        });
        if (result.success) 
          console.log('BLOCKED!');
      });
  }

  async unblock() {
    this.ordersServ
      .setStatus(this.order.order_id, this.order.status[1].os_status_order_cod)
      .subscribe((result: IWsResult) => {
        this.reloadFunction();
      });
      Swal.fire({
        icon: 'success',
        title: 'Sbloccato!',
        text: 'Ordine sbloccato con successo!!!',
        timer: 1500,
        timerProgressBar: true,
      });
  }

  async unblock2() {
    try {
      const result: IWsResult = await this.ordersServ.setStatus(
        this.order.order_id,
        this.order.status[1].os_status_order_cod
      ).toPromise();  
      this.reloadFunction();  
      Swal.fire({
        icon: 'success',
        title: 'Sbloccato!',
        text: 'Ordine sbloccato con successo!!!',
        timer: 1500,
        timerProgressBar: true,
      });
    } 
    catch (error) {
      console.error('Errore durante lo sblocco dell\'ordine:', error);
    }
  }
  

  seeCheckProduct(product_id) {
    this.checkProduct = product_id;
    this.closeServ.openCheck();
  }

  seeCheckBox(product_id) {
    this.isCheckBox = product_id;
    this.closeServ.openCheckBox();
  }

  seeCheckShipment(product_id) {
    this.checkQly = product_id;
    this.closeServ.shipOn();
  }

  checkProddi() {
    this.closeServ.proddi.subscribe((result) => {
      console.log('Products are: ', result);
      if (!result) {
        this.ordersServ
          .getOrder(`${this.id}`)
          .subscribe((orderData: IWsResult) => {
            this.order = new Orders.Order(orderData.data);
            this.dropProducts = this.order.products;
            console.log('The products are: ', this.dropProducts);
            if (this.firstEnteredOnPage) {
              this.order.products.forEach((element) => {
                element.op_checked = true;
                element.orQtaNeeded = element.op_qta;
              });
              this.firstEnteredOnPage = false;
            }
            this.products = orderData.data;
            console.debug('This order neprocesat: ', this.order);
            this.order = new Orders.Order(orderData.data);
            console.debug('This order procesat: ', this.order);
            this.productsNew = this.order.products.map((item) => {
              let product: Products.IProductToSend = {
                op_note: '',
                op_checked: false,
                op_code: item.op_code,
                op_description: item.op_description,
                op_qta: item.op_qta,
                op_weigth: item.op_weigth,
                op_stock: item.op_stock,
                orQtaNeeded: +item.op_qta,
                op_qta_ordered: '',
                op_positions: [],
                tab_order_status_cod: []
              };
              return product;
            });
            if (this.order.or_order_date)
              this.formattedDate = formatDate(
                this.order.or_order_date,
                'dd/MM/yyyy',
                'it'
              );
            this.assignOrderForm.patchValue({
              or_order_number: this.order.or_order_number,
              or_order_date: this.formattedDate,
              or_order_priority_cod: 'Select',
              carrier_id: 'random text',
              or_customer: this.order.or_customer,
              or_shipping_address: this.order.or_shipping_address,
              or_shipping_zip: this.order.or_shipping_zip,
              or_shipping_city: this.order.or_shipping_city,
              or_shipping_prov: this.order.or_shipping_prov,
              or_shipping_country: this.order.or_shipping_country,
              tab_order_status_cod: this.order.tab_order_status_cod
            });
          });
        if (this.confirmed === true) {
          this.closeServ.confirmed;
        }
      }
    });
  }

  checkBoxy() {
    this.closeServ.boxy.subscribe((result) => {
      console.log('Boxy is: ', result);
      if (!result) {
        this.ordersServ
          .getOrder(`${this.id}`)
          .subscribe((orderData: IWsResult) => {
            console.log('Boxy result: ', result);
            this.order = new Orders.Order(orderData.data);
            if (this.firstEnteredOnPage) {
              this.order.products.forEach((element) => {
                element.op_checked = true;
                element.orQtaNeeded = element.op_qta;
              });
              this.firstEnteredOnPage = false;
            }
            this.products = orderData.data;
            console.debug('This order neprocesat: ', this.order);
            console.debug('This order procesat: ', this.order);
            this.assignOrderForm.patchValue({
              or_order_number: this.order.or_order_number,
              or_order_date: this.formattedDate,
              or_order_priority_cod: 'Select',
              carrier_id: 'random text',
              or_customer: this.order.or_customer,
              or_shipping_address: this.order.or_shipping_address,
              or_shipping_zip: this.order.or_shipping_zip,
              or_shipping_city: this.order.or_shipping_city,
              or_shipping_prov: this.order.or_shipping_prov,
              or_shipping_country: this.order.or_shipping_country,
              tab_order_status_cod: this.order.tab_order_status_cod
            });
          });
        if (this.confirmed === true) {
          this.closeServ.confirmed;
        }
      }
    });
  }

  checkShippy() {
    this.closeServ.shippy.subscribe((result) => {
      console.log('Shippy is: ', result);
      if (!result) {
        this.ordersServ
          .getOrder(`${this.id}`)
          .subscribe((orderData: IWsResult) => {
            this.order = new Orders.Order(orderData.data);
            if (this.firstEnteredOnPage) {
              this.order.products.forEach((element) => {
                element.op_checked = true;
                element.orQtaNeeded = element.op_qta;
              });
              this.firstEnteredOnPage = false;
            }
            this.products = orderData.data;
            console.debug('This order neprocesat: ', this.order);
            this.order = new Orders.Order(orderData.data);
            console.debug('This order procesat: ', this.order);
            this.productsNew = this.order.products.map((item) => {
              let product: Products.IProductToSend = {
                op_note: '',
                op_checked: false,
                op_code: item.op_code,
                op_description: item.op_description,
                op_qta: item.op_qta,
                op_weigth: item.op_weigth,
                op_stock: item.op_stock,
                orQtaNeeded: +item.op_qta,
                op_qta_ordered: '',
                op_positions: [],
                tab_order_status_cod: []
              };
              return product;
            });
            if (this.order.or_order_date)
              this.formattedDate = formatDate(
                this.order.or_order_date,
                'dd/MM/yyyy',
                'it'
              );
            this.assignOrderForm.patchValue({
              or_order_number: this.order.or_order_number,
              or_order_date: this.formattedDate,
              or_order_priority_cod: 'Select',
              carrier_id: 'random text',
              or_customer: this.order.or_customer,
              or_shipping_address: this.order.or_shipping_address,
              or_shipping_zip: this.order.or_shipping_zip,
              or_shipping_city: this.order.or_shipping_city,
              or_shipping_prov: this.order.or_shipping_prov,
              or_shipping_country: this.order.or_shipping_country,
              tab_order_status_cod: this.order.tab_order_status_cod
            });
          });
        if (this.confirmed === true) {
          this.closeServ.confirmed;
        }
      }
    });
  }

  checkDoc() {
    this.closeServ.dochy.subscribe((result) => {
      console.log('DDT is: ', result);
      if (!result) {
        this.ordersServ
          .getOrder(`${this.id}`)
          .subscribe((orderData: IWsResult) => {
            this.order = new Orders.Order(orderData.data);
            if (this.firstEnteredOnPage) {
              this.order.products.forEach((element) => {
                element.op_checked = true;
                element.orQtaNeeded = element.op_qta;
              });
              this.firstEnteredOnPage = false;
            }
            this.products = orderData.data;
            console.debug('This order neprocesat: ', this.order);
            this.order = new Orders.Order(orderData.data);
            console.debug('This order procesat: ', this.order);
            this.productsNew = this.order.products.map((item) => {
              let product: Products.IProductToSend = {
                op_note: '',
                op_checked: false,
                op_code: item.op_code,
                op_description: item.op_description,
                op_qta: item.op_qta,
                op_weigth: item.op_weigth,
                op_stock: item.op_stock,
                orQtaNeeded: +item.op_qta,
                op_qta_ordered: '',
                op_positions: [],
                tab_order_status_cod: []
              };
              return product;
            });
            if (this.order.or_order_date)
              this.formattedDate = formatDate(
                this.order.or_order_date,
                'dd/MM/yyyy',
                'it'
              );
            this.assignOrderForm.patchValue({
              or_order_number: this.order.or_order_number,
              or_order_date: this.formattedDate,
              or_order_priority_cod: 'Select',
              carrier_id: 'random text',
              or_customer: this.order.or_customer,
              or_shipping_address: this.order.or_shipping_address,
              or_shipping_zip: this.order.or_shipping_zip,
              or_shipping_city: this.order.or_shipping_city,
              or_shipping_prov: this.order.or_shipping_prov,
              or_shipping_country: this.order.or_shipping_country,
              tab_order_status_cod: this.order.tab_order_status_cod
            });
          });
        if (this.confirmed === true) {
          this.closeServ.confirmed;
        }
      }
    });
  }

  checkDone() {
    this.closeServ.compli.subscribe((result) => {
      console.log('Control is: ', result);
    });
  }

  goToProductDetails(product) {
    for (let prod of this.productsNew) {
      prod.op_code = this.clickedProduct;
      this.clickedProduct = product;
      this.closeServ.openProd();
      console.debug('Merge?');
    }
  }

  seeCheckDoc(product_id) {
    this.checkDDT = product_id;
    this.closeServ.openDoc();
  }

  goToAddBox(box_id) {
    this.clickedBox = box_id;
  }

  async isInWork(): Promise<boolean> {
    if (!this.ordersServ.isUser.getValue()) {
      this.sameIndex = false;
      return true;
    }
    /*let indexToSuspend = this.orders.findIndex(
      (order) =>
        (order.tab_order_status_cod >= Status.StatusesUser.step1 &&
          order.tab_order_status_cod <= Status.StatusesUser.step4) ||
        order.tab_order_status_cod == Status.StatusesUser.intiated
    );
    console.debug('indexToSuspend: ', indexToSuspend);
    this.sameIndex =
      indexToSuspend != -1
        ? this.orders[indexToSuspend].order_id == this.id
        : false;
    console.debug('this.sameIndex', this.sameIndex);
    if (indexToSuspend != -1 && !this.sameIndex) {
      this.ordersServ
        .setStatus(
          this.orders[indexToSuspend].order_id,
          Status.StatusesUser.suspended
        )
        .subscribe((result: IWsResult) => {
          if (!result.success) {
            Swal.fire({
              icon: 'error',
              title: 'Errore!',
              text: 'L`Ordine non può essere sospeso!!!',
              timer: 1500,
              timerProgressBar: true,
            });
            return;
          }
        });
    }*/
    return true;
  }

  async start() {
    const check = await this.isInWork();
    if (!check) return;
    this.iconService.changeIconToWorking(this.order);
    console.debug('status from initiated');
    if (!this.sameIndex)
      this.ordersServ
        .setStatus(this.order.order_id, Status.StatusesUser.intiated)
        .subscribe((result: IWsResult) => {
          this.reloadFunction();
          this.continue();
          console.debug('This order: ', this.order);
        });
  }

  async done() {
    const step = 5;
    let text = `Confermi che l’${this.order.or_order_number}${
      this.order.or_partial === 'S' ? ' parziale' : ''
    } è stato controllato?`;
    this.ordersServ.getDocuments(+this.id).subscribe(async (result) => {
      const orderPrepared = new Orders.OrderPrepared(this.order, result || []);
      this.ordersServ
        .prepareOrder(orderPrepared)
        .subscribe((result: IWsResult) => {
          if (!result.success) return;
          this.order.stepCount = this.stepCount;
          console.log('This stepcount is: ', this.stepCount);
          if (this.stepCount <= step)
            this.ordersServ.pages.next(this.stepCount);
          this.reloadFunction();
        });
      Swal.fire({
        icon: 'success',
        title: 'Completato!',
        text: 'Controllo completato con successo!!!',
        timer: 1500,
        timerProgressBar: true,
      });
    });
  }  

  refreshUponCheck() {
    this.productChecker = !this.productChecker;
    this.reloadFunction();
  }  

  reloadFunction() {
    this.assignOrderForm = this.formBuilder.group({
      or_order_number: [''],
      or_order_date: [''],
      or_order_priority_cod: ['', Validators.required],
      carrier_id: ['', Validators.required],
      or_customer: [''],
      or_shipping_address: [''],
      or_shipping_zip: [''],
      or_shipping_city: [''],
      or_shipping_prov: [''],
      or_shipping_country: [''],
      or_assigned_id: ['', Validators.required],
      op_code: [''],
      tab_order_status_cod: ['']
    });
    this.ordersServ.getOrder1(this.id!).subscribe((orderData) => {
      this.order = orderData;
      if (this.firstEnteredOnPage) {
        this.order.products.forEach((element) => {
          element.op_checked = true;
          element.orQtaNeeded = element.op_qta;
        });
        this.firstEnteredOnPage = false;
      }
      this.products = orderData.products;
      console.debug('This order procesat: ', this.order);
      this.isDDT = orderData.or_url_ddt?.indexOf('.pdf') > 0;
      this.productsNew = this.order.products.map((item) => {
        let product: Products.IProductToSend = {
          op_note: '',
          op_checked: false,
          op_code: item.op_code,
          op_description: item.op_description,
          op_qta: item.op_qta,
          op_weigth: item.op_weigth,
          op_stock: item.op_stock,
          orQtaNeeded: +item.op_qta,
          op_qta_ordered: '',
          op_positions: [],
          tab_order_status_cod: []
        };
        return product;
      });
      if (this.order.or_order_date)
        this.formattedDate = formatDate(
          this.order.or_order_date,
          'dd/MM/yyyy',
          'it'
        );
      this.assignOrderForm.patchValue({
        or_order_number: this.order.or_order_number,
        or_order_date: this.formattedDate,
        or_order_priority_cod: this.order.or_order_priority_cod,
        carrier_id: this.order.or_carrier_id,
        or_customer: this.order.or_customer,
        or_shipping_address: this.order.or_shipping_address,
        or_shipping_zip: this.order.or_shipping_zip,
        or_shipping_city: this.order.or_shipping_city,
        or_shipping_prov: this.order.or_shipping_prov,
        or_shipping_country: this.order.or_shipping_country,
        tab_order_status_cod: this.order.tab_order_status_cod
      });
    });

    if (this.confirmed === true) {
      this.closeServ.confirmed;
    }
  }

  ngOnInit(): void {
    this.id = +this.activatedRoute.snapshot.paramMap.get('id');
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.reloadFunction();
      }
    });
    this.reloadFunction();
    this.checkShippy();
    this.checkBoxy();
    this.checkProddi();
    this.checkDoc();
    this.checkDone();
  }  

  synchronizeOrder(orderId: number, gammaId: number) {
    this.openModal.openPopover();
  }

  synchroneOrder(orderId: number, gammaId: number) {
    this.ordersServ
      .synchronizeOrders(gammaId, orderId)
      .subscribe((data: any) => {
        console.log('Orders ids are: ', orderId, gammaId);
        if (!data) 
          return;
        console.log('Data is: ', data.data);
        let originalMessage = data.data;
        console.log('Original message');
        this.messageFromSync = originalMessage?.replace(/\\r\\n/g, '<br>');
        console.log('This message sync: ', this.messageFromSync);
        console.log('Bravo ba');
        Swal.fire({
          icon: 'success',
          title: 'Aggiornato!',
          text: 'Ordine sincronizzato con successo!!!',
          timer: 1500,
          timerProgressBar: true,
        });
        this.reloadFunction();
      });
  }

}

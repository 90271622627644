<kendo-dialog 
  *ngIf="openConfirmCancel" 
  (close)="offModal()"
  style="left: 6%"
>
  <form class="k-form" [formGroup]="loginForm">
    <div class="row d-flex justify-content-center">
      <h1
        style="
          font-family: 'Lato';
          font-style: normal;
          font-weight: 700;
          font-size: 28px;
          line-height: normal;
          margin: 20px;
          text-align: center;
        "
      >
      Inserisci la tua password per procedere con la cancellazione dell'ordine
    </h1>
      <kendo-formfield class="col-md-6">
        <kendo-label [for]="password" text="Password"></kendo-label>
        <kendo-textbox
          formControlName="password"
          placeholder="Inserisci la tua password"
          #password
          required
          rounded="full"
          [clearButton]="true"
          type="password"
          style="text-align: center;"
        >
          <ng-template kendoTextBoxSuffixTemplate>
            <button
              kendoButton
              look="clear"
              [icon]="'eye'"
              (click)="toggleVisibility()"
            ></button>
          </ng-template>
        </kendo-textbox>
        <kendo-formerror>Password obbligatoria!</kendo-formerror>
      </kendo-formfield>
    </div>

    <div class="row">
      <div
        class="col-12"
        style="
          display:flex; 
          flex-direction:row; 
          justify-content:center;
        "
      >
        <div class="col-6">
          <div 
            class="button-right"
            style="float:right;"
          >
            <button
              class="btn btn3"
              rounded="full"
              style="
                margin-top: 20px;
                background-color: #017efa;
                color: #ffffff;
                justify-content: center !important;
                display: flex;
                width: 192px !important;
                height: 45px !important;
              "
              (click)="checkPassAndDelete(loginForm.value)"
            >
              <i
                class="bx bx-check-circle bx-sm posRelBox2"
                style="
                  margin-right: 8px; 
                  margin-left: 5px
                "
              >
              </i>
              <p class="posRelBox2">CONTINUA</p>
            </button>
          </div>
        </div>  
        <div class="col-6">
          <div class="button-right">
            <button
              class="btn btn3"
              rounded="full"
              style="
                margin-top: 20px;
                background-color: #e20000;
                color: #ffffff;
                justify-content: center !important;
                display: flex;
                width: 192px !important;
                height: 45px !important;
              "
              (click)="offModal()"
            >
              <i
                class="bx bx-block bx-sm posRelBox2"
                style="
                  margin-right: 8px; 
                  margin-left: 5px
                "
              >
              </i>
              <p class="posRelBox2">ANNULLA</p>
            </button>
          </div>
        </div>            
      </div>
    </div>    
  </form>
</kendo-dialog>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Pages } from 'src/app/models/pages';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from './error-handler.service';
import { Priority } from '../models/Priority.model';
import { IWsResult } from '../models/ws_result.model';

@Injectable({
  providedIn: 'root',
})

export class PagesService {

  static isDesktoPlatform: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private mockPages = new Pages.Pages();
  public appPages = this.mockPages.pages;

  url: string = environment.urlServer;
  isDesktop: boolean;
  isUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  prioritiesList: Priority.IPriority[] = null;
  isPropritiesList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(
    private router: Router,
    private http: HttpClient,
    private errorHandlerServ: ErrorHandlerService
  ) { }

  replaceAll(input: string, toBe: string, withWhat: string) {
    if (!input) return;
    input = input.replace(toBe, withWhat);
    if (input.indexOf(toBe) >= 0) {
      return this.replaceAll(input, toBe, withWhat);
    }
    return input;
  }

  processPriorities() {
    if (this.isPropritiesList.value) 
      return;
    console.debug('enters here', 'priorities');
    this.prioritiesList = [];
    this.getPriorities().subscribe((result) => {
      console.debug('priorities list: ', result);
      result.forEach((element) => {
        this.prioritiesList.push(
          new Priority.Priority(
            element.tab_priority_order_cod,
            element.tab_priority_order_desc,
            element.tab_priority_order_color,
            true
          )
        );
      });
      console.debug('this.prioritiesList list: ', this.prioritiesList);
      this.isPropritiesList.next(true);
    });
  }

  deepCopy(obj) {
    let copy;
    if (null == obj || 'object' != typeof obj) 
      return obj;
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }
    if (obj instanceof Array) {
      copy = [];
      for (let i = 0, len = obj.length; i < len; i++) {
        copy[i] = this.deepCopy(obj[i]);
      }
      return copy;
    }
    if (obj instanceof Object) {
      copy = {};
      for (let attr in obj) {
        if (obj.hasOwnProperty(attr)) {
          copy[attr] = this.deepCopy(obj[attr]);
        }
      }
      return copy;
    }
    throw new Error('Impossibile copiare obj! Il suo tipo non è supportato.');
  }

  getPages(isAdmin) {
    this.appPages = isAdmin
      ? this.mockPages.getMobileAdminPages()
      : this.mockPages.getMobileUserPages();
  }

  getParentUrl(level: number = 1): string {
    let route = this.router.url || '';
    let index = 0;
    route = this.getLastIndex(route, level, index);
    return route;
  }

  private getLastIndex(word, count, index): string {
    if (index == count) 
      return word;
    index++;
    return this.getLastIndex(
      word.substring(0, word.lastIndexOf('/')),
      count,
      index
    );
  }

  getPriorities(): Observable<Priority.IPriority[]> {
    return this.http
      .get<IWsResult>(`${this.url}/get_priorities/`)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('get_priorities')
        )
      )
      .pipe(
        map((result: IWsResult) => result.data)
      );
  }
  
}

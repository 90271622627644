export namespace ProductPosition {

  export interface IPosition {
    lane: string;
    shelf: string;
    position: string;
  }

  export interface IPositionInOrder {
    op_full_position: string;
  }

  export interface IPositionFull {
    product_position_id: number;
    pr_b64_position_image: string;
    pr_lane: string;
    pr_shelf: string;
    pr_position: string;
    pr_qty: number
  }

  export class Position implements IPosition {
    lane: string = null;
    shelf: string = null;
    position: string = null;

    constructor(positionFull: IPositionInOrder) {
      if (!positionFull || !positionFull.op_full_position) return;
      this.lane = positionFull.op_full_position.substring(
        0,
        positionFull.op_full_position.indexOf('.')
      );
      positionFull.op_full_position = positionFull.op_full_position.substring(
        positionFull.op_full_position.indexOf('.')
      );
      this.shelf = positionFull.op_full_position.substring(
        0,
        positionFull.op_full_position.indexOf('.')
      );
      positionFull.op_full_position = positionFull.op_full_position.substring(
        positionFull.op_full_position.indexOf('.')
      );
      this.position = positionFull.op_full_position;
    }    
  }

}

import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IMessageList } from 'src/app/models/IMessage.model';
import { MessagesService } from 'src/app/services/messages.service';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import { IWsResult } from 'src/app/models/ws_result.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-messaggi-dettagli',
  templateUrl: './messaggi-dettagli.component.html',
  styleUrls: ['./messaggi-dettagli.component.scss'],
})

export class MessaggiDettagliComponent implements OnInit {  

  @Input() opened = false;
  @Input() message_id: string = '-1';
  @Input() isPhotoOn: string = '-1';
  @Output() modalClosed = new EventEmitter<void>();

  message: IMessageList = null;
  id: number = 0;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private messagesServ: MessagesService,
    public closeSrv: OpenModalService,
    private activatedRoute: ActivatedRoute
  ) { }

  close() {
    this.closeSrv.offMessID();
    this.closeSrv.closeMsg();
    this.modalClosed.emit();
  }

  ngOnInit(): void {
    this.messageDetailsForm = this.formBuilder.group({
      ms_datetime: [''],
      ms_title: [''],
      ms_message: [''],
      ms_sender_name: [''],
      ms_status: [''],
    });
    this.messagesServ
      .getMessage(this.message_id)
      .subscribe((messageData: IMessageList) => {
        this.message = messageData;
        this.messageDetailsForm.setValue({
          ms_datetime: this.message.ms_datetime,
          ms_title: this.message.ms_title,
          ms_message: this.message.ms_message,
          ms_sender_name: this.message.ms_sender_name,
          ms_status: this.message.ms_status,
        });
        if (this.message && this.message.ms_status != 'R') {
          this.messagesServ
            .setReadStatus(+this.message_id)
            .subscribe((result: IWsResult) => {
              if (result.error_message.msg_code != 0)
                Swal.fire({
                  icon: 'error',
                  title: 'None!',
                  text: 'Errore nella lettura del messaggio!!',
                  timer: 1500,
                  timerProgressBar: true,
                });
            });
        }
      });
  }

  public messageDetailsForm: FormGroup = new FormGroup({
    ms_datetime: new FormControl(),
    ms_title: new FormControl(),
    ms_message: new FormControl(),
    ms_sender_name: new FormControl(),
    ms_status: new FormControl(),
  });
  
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ByDatePipePipe } from './by-date-pipe.pipe';

@NgModule({
  exports: [ByDatePipePipe],
  declarations: [ByDatePipePipe],
  imports: [CommonModule],
})

export class byDatePipeModule {

  static forRoot() {
    return {
      ngModule: byDatePipeModule,
      providers: [],
    };
  }
  
}

<div class="k-form" *ngIf="opened">
    <kendo-dialog
      (close)="close()"
      *ngIf="opened"
      class="k-form"
      [minWidth]="250"
      [width]="550"
      [maxWidth]="750"
      style="left: 6%;"
    >
      <div 
        style="
          display: flex;
          flex-direction: row;
          justify-content: end;
          margin-top: -15px;
          z-index: 1000;
          position: fixed;
          margin-left: -35px;
        "
      >
        <button
          (click)="close()"
          class="btn btn3"
          rounded="full"
          style="
            background-color: #e90000;
            color: #ffffff;
            width: 35px !important;
            height: 35px !important;
          "
        >    
          <i
            class="bx bx-x bx-sm posRelBox2"
            style="
              color: #ffffff; 
              bottom: 10px; 
              position: relative !important;
              left: -80% !important;
              top: -1px !important;
            "
          ></i>
        </button> 
      </div>
      <h1 class="page-title">Informazioni Stato</h1>
      <hr class="hr-dialog position-relative" style="margin-bottom: 40px !important" />
      <div
        class="arrange-container"
        style="
            height: auto;
            overflow-y: auto;
            position: relative;
        "
      >
        <div
          class="new-text-2 text-left"
          style="
              display: flex; 
              flex-direction: row; 
              justify-content: space-between
          "
        >
          <kendo-label class="changeSize" style="text-align: left">
              DA ASSEGNARE  
          </kendo-label>
          <div class="example" style="text-align: right">
            <h5>
              L'operatore deve prendere in carico l'ordine
            </h5>
          </div>
        </div>
      </div>
      <div
        class="arrange-container"
        style="
          height: auto;
          overflow-y: auto;
          position: relative;
        "
      >
        <div
          class="new-text-2 text-left"
          style="
              display: flex; 
              flex-direction: row; 
              justify-content: space-between
          "
        >
          <kendo-label class="changeSize" style="text-align: left">
              IN LAVORAZIONE  
          </kendo-label>
          <div class="example" style="text-align: right">
            <h5>
              L'operatore in magazzino ha in carico l'ordine
            </h5>
          </div>
        </div>
      </div>
      <div
        class="arrange-container"
        style="
          height: auto;
          overflow-y: auto;
          position: relative;
        "
      >
        <div
          class="new-text-2 text-left"
          style="
              display: flex; 
              flex-direction: row; 
              justify-content: space-between
          "
        >
          <kendo-label class="changeSize" style="text-align: left">
              PRONTO  
          </kendo-label>
          <div class="example" style="text-align: right">
            <h5>
              L'ordine è pronto per essere ritirato dal corriere o dal cliente
            </h5>
          </div>
        </div>
      </div>
      <div
        class="arrange-container"
        style="
          height: auto;
          overflow-y: auto;
          position: relative;
        "
      >
        <div
          class="new-text-2 text-left"
          style="
              display: flex; 
              flex-direction: row; 
              justify-content: space-between
          "
        >
          <kendo-label class="changeSize" style="text-align: left">
              IN TRANSITO  
          </kendo-label>
          <div class="example" style="text-align: right">
            <h5>
              L'ordine è in transito col corriere
            </h5>
          </div>
        </div>
      </div>
      <div
        class="arrange-container"
        style="
          height: auto;
          overflow-y: auto;
          position: relative;
        "
      >
        <div
          class="new-text-2 text-left"
          style="
              display: flex; 
              flex-direction: row; 
              justify-content: space-between
          "
        >
          <kendo-label class="changeSize" style="text-align: left">
              CONSEGNATO  
          </kendo-label>
          <div class="example" style="text-align: right">
            <h5>
              L'ordine è stato consegnato
            </h5>
          </div>
        </div>
      </div>  
    </kendo-dialog>
  </div>
  
<h1 class="page-title">Status Spedizioni</h1>

<div
  class="filterContainer k-display-flex"
  style="
    flex-direction: row;
    justify-content: center;
  "
>
  <form
    class="new-search"
    role="search"
    style="
      display:flex !important;
      flex-direction: row !important;
      justify-content: center !important;
    "
  >
    <label for="search"></label>
    <input
      [(ngModel)]="filterTerm"
      id="search"
      name="search"
      pattern=".*\S.*"
      type="search"
      placeholder="Cerca ordine..."
      autofocus
      required
      (input)="this.onInputChanged($event)"
    />
    <button
      type="submit"
      (click)="searchOrders()"
    >
      <i
        class="bx bx-search bx-md"
        style="
          margin: 0;
          padding-top: 10px;
          padding-left: 5px;
          transform:scale(.8)
        "
      >
      </i>
    </button>
  </form>

  <form
    action=""
    class="search-bar"
    style="
      display:flex !important;
      flex-direction: row !important;
      justify-content: center !important;
      visibility: hidden !important;
    "
  >
    <button
      class="btn btn-primary msg-btn"
      routerLink="/new-msg"
      style="
        display:flex !important;
        flex-direction: row !important;
        justify-content: center !important;
        visibility: hidden !important;
      "
    >
      + Messaggio
    </button>
  </form>

  <!--<div class="dateContainer k-display-flex justify-content-evenly">
    <div class="col mx-2">
      <h5 class="text-center">FILTRA PER MESE</h5>
      <kendo-datepicker
        [(ngModel)]="filters.startingDate"
        class="dropdown-field drop-the-border"
        style="
          background: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 45px;
        "
        [format]="'MMMM yyyy'"
        [min]="min"
        [max]="max"
        [monthNavigator]="true"
        [yearNavigator]="true"
        [yearRange]="yearRange"
        [placeholder]="getFormattedMonth(filters.startingDate)"
      ></kendo-datepicker>
    </div>
  </div>-->

  <!--<div class="col mx-2" style="padding-top: 25px">
    <kendo-dropdownlist
      [data]="monthNames"
      class="drop-the-border"
      style="
        text-align: center;
        background: #ffffff;
        border: 1px solid #e0e0e0;
        border-radius: 45px;
      "
      (valueChange)="filterByMonth($event)"
      [(ngModel)]="selectedMonth"
    >
    </kendo-dropdownlist>
  </div>-->

  <div class="dateContainer k-display-flex justify-content-evenly">
    <div class="col mx-2">
      <h5 class="text-center">DA:</h5>
      <kendo-datepicker
        [(ngModel)]="filters.startingDate"
        class="dropdown-field drop-the-border"
        style="
          background: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 45px;
        "
      ></kendo-datepicker>
    </div>
    <div class="col">
      <h5 class="text-center">A:</h5>
      <kendo-datepicker
        [(ngModel)]="filters.endDate"
        class="dropdown-field drop-the-border"
        style="
          background: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 45px;
        "
      ></kendo-datepicker>
    </div>

    <div class="col mx-2" style="padding-top: 25px">
      <kendo-dropdownlist
        [data]="customers"
        class="drop-the-border"
        #dropdownList
        [selectedIndex]="carrierIndex"
        style="
          text-align: center;
          background: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 45px;
        "
        (valueChange)="onMessengerNameSelect($event, carrierIndex)"
        [(ngModel)]="searchString"
      >
        <ng-template let-dataItem> </ng-template>
      </kendo-dropdownlist>
    </div>
  </div>
</div>

<hr class="hr-newBlue" />

<div
  class="row k-display-flex justify-content-end align-items-center"
  style="padding-left: 20px;"
>

  <div style="display:flex; flex-direction:row; justify-content:center;">
    <h2 class="col-header">Totale spedizioni: {{ shipments.length }}</h2>
  </div>

  <div
    *ngIf="loading"
    style="
      display: flex;
      flex-direction: column;
      align-items: center
    "
  >
    <mat-spinner></mat-spinner>
    <p>Attendi..</p>
  </div>

  <div class="grid-wrapper">
    <kendo-grid
      [kendoGridBinding]="
        shipments
          | byCustomerName : customer
          | byDatePipe : date
          | byMsCarrier : carrier
          | byOrderNumber : order_number
          | byToDate
            : [
                filters.startingDate,
                filters.endDate,
                filters.dateOrginizedBy.value
              ]
      "
      [rowHeight]="36"
      [skip]="skip"
      [rowHeight]="36"
      [height]="750"
      [pageSize]="pageSize"
      scrollable="virtual"
      (pageChange)="pageChange($event)"
      [sortable]="true"
      [navigable]="true"
      [reorderable]="true"
    >
      <kendo-grid-column
        [width]="100"
        field="or_order_number"
        title="N° Ordine"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
      </kendo-grid-column>

      <kendo-grid-column
        [width]="400"
        field="or_customer"
        title="Cliente"
        [headerStyle]="{ 'justify-content': 'left' }"
        [style]="{
          display: 'grid',
          'justify-content': 'left',
          'tetx-align': 'left'
        }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.or_customer }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [width]="130"
        field="sm_carrier_desc"
        title="Corriere"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.sm_carrier_desc }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [width]="180"
        field="icon.label"
        title="Stato spedizione"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.icon.label }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [width]="120"
        field="shipment_date"
        title="Data ritiro"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.shipment_date | date : "dd/MM/yyyy" }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        [width]="140"
        field="or_closing_date"
        title="Data consegna"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.or_closing_date !== "0001-01-01T00:00:00"
              ? (dataItem.or_closing_date | date : "dd/MM/yyyy")
              : "- WIP" }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-command-column
        title="Tracking"
        [width]="100"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem style="font-size: 11px">
          {{ dataItem.tab_order_status_desc }}
          <button
            *ngIf="
              dataItem.os_tracking_number !== null &&
              dataItem.os_tracking_number !== ''
            "
            kendoGridEditCommand
            rounded="full"
            class="btn-track"
            style="margin: 0; padding: 4px; background-color: #be7e00 !important"
            (click)="goToTracking(dataItem.order_id)"
          >
            <div class="setDisplayToFlexBlue btn-track">
              <i class="bx bx-radar bx-md"> </i>
              <p class="parButton2"></p>
            </div>
          </button>
        </ng-template>
      </kendo-grid-command-column>

      <kendo-grid-command-column
        title="Dettagli"
        [width]="100"
        [headerStyle]="{ 'justify-content': 'center' }"
        [style]="{ 'tetx-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <button
            kendoGridEditCommand
            rounded="full"
            class="btn-det"
            style="margin: 0; padding: 0; background-color: #017efa !important"
            (click)="goToDetails(dataItem.order_id)"
          >
            <div class="setDisplayToFlexBlue btn-det">
              <i class="bx bx-info-circle bx-md" style="margin: 0; padding: 0">
              </i>
              <p class="parButton2"></p>
            </div>
          </button>
        </ng-template>
      </kendo-grid-command-column>
    </kendo-grid>
  </div>
</div>

<app-accesso-funzionari-tracking
  [shipment_id]="shipment_id"
  *ngIf="closeServ.isAgentOrderTrack"
  [openTrack]="closeServ.isAgentOrderTrack"
>
</app-accesso-funzionari-tracking>

<app-status-spedizioni-dettaggli
  [parentId]="clickedId"
  *ngIf="closeServ.isID"
  [status_ID]="closeServ.isID"
>
</app-status-spedizioni-dettaggli>

<div 
  class="text-center d-flex justify-content-center"
  style="left: 6%"
>
  <form class="k-form" [formGroup]="resetForm" autocomplete="on">
    <fieldset class="k-form-fieldset">
      <h1 class="page-title">Recupera password</h1>
      <h2>Inserisci il tuo indirizzo email per ricevere la tua password</h2>
      <div class="row  d-flex justify-content-center">
        <kendo-formfield class="col-md-6">
          <kendo-label [for]="username" text="Email"></kendo-label>
          <kendo-textbox formControlName="username" [clearButton]="true" rounded="full" type="email" #username required min-length="6"></kendo-textbox>
          <kendo-formerror *ngIf="(resetForm.get('username')?.touched || resetForm.get('username')?.dirty) && resetForm.get('username')?.errors">
            Email mancante/invalida
          </kendo-formerror>
        </kendo-formfield>
        <a routerLink="/login">Ritorna al login</a>
      </div>
      <div class="k-form-buttons d-flex justify-content-center">
        <button
          [disabled]="!resetForm.valid || disabledButton"
          class="btn btn3"
          rounded="full"
          style="
            background-color: #017efa;
            color: #ffffff;
            justify-content: center !important;
            display: flex;
            width: 192px !important;
            height: 45px !important;
          "
          (click)="resetPassword(resetForm.value)"
        >    
          <p class="posRelBox2">INVIA</p>
        </button>
      </div>
    </fieldset>
  </form>
</div>
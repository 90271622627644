import { Pipe, PipeTransform } from '@angular/core';
import { Orders } from '../models/Orders.model';

@Pipe({
  name: 'byPriorityCode',
})

export class ByPriorityCodePipe implements PipeTransform {

  transform(
    value: Orders.IOrderOpenList[],
    args?: number
  ): Orders.IOrderOpenList[] {
    if (value == undefined && args == null && args.toString() == '')
      return value;
    //if(args.toString() == this.selectedYear )
    //  return value;
    return value.filter(
      (item: Orders.IOrderOpenList) => item?.tab_order_priority_cod == args
    );
  }
  
}

import { ProductPosition } from './productPosition';

export namespace Products {
  export interface IProductNew {
    op_code: string;
    op_description: string;
    op_qta_ordered: string;
    op_qta: any;
    op_weigth: number;
    op_stock: number;
    op_positions: ProductPosition.IPositionInOrder[];
    //not ok in interface must be moved in implementation class
    op_stockQTA?: string;
    tab_order_status_cod: any;
  }

  export interface IProductToSend extends IProductNew {
    op_note: string;
    op_checked: boolean;
    //From APP
    orQtaNeeded: number;
  }

  export interface IProduct extends Omit<IProductToSend, 'op_checked'> {
    products_order_id: number;
    op_checked: boolean;
    op_qta: number;
  }

  // export interface IProductToSend extends IProductNew {
  //   op_note: string;
  //   op_checked: boolean;
  //   orQtaNeeded: number; //StrutoCamila
  // }

  export interface IPositionInOrder {
    op_full_position: string;
  }

  export interface IProductUpdate {
    product_id: number;
    op_qta: number;
    op_checked: boolean;
  }

  export interface IProductSimilar {
    pr_code: string;
    pr_description: string;
  }

  export class ProductSimilar implements IProductSimilar {
    pr_code: string;
    pr_description: string;
    //from app
    pr_checked: boolean = false;
  }

  export class Product implements IProduct {
    orQtaNeeded: number;
    op_positions: ProductPosition.IPositionInOrder[];
    op_checked: boolean = false;
    op_code: string;
    op_description: string;
    op_qta: number;
    op_qta_ordered: string;
    op_weigth: number;
    products_order_id: number;
    op_note: string;
    op_stock: any;
    op_qta_stock?: string;
    indexNo?: number;
    pr_code?: string;
    full_position?: ProductPosition.Position;
    // not from server
    op_stockQTA?: string;
    tab_order_status_cod: any;
  }

  export class ProductToAdd {
    order_id!: number;
    op_code!: string;
    op_description!: string;
    op_remaining_qty!: string;
    op_qty!: number;
    op_weight!: number;
    op_position!: any;
    op_note!: string;
    op_checked?: boolean;

    constructor(product: ProductSimilar) {
      this.op_description = product.pr_description;
      this.op_code = product.pr_code;
    }

    existingProduct?(item: Product) {
      this.op_remaining_qty =
        item.op_qta_ordered?.length > 0
          ? item.op_qta_ordered
          : `${item.op_qta}`;
      this.op_qty = item.op_qta;
      this.op_note = item.op_note;
    }

    addedProduct?(item: any) {
      this.op_weight = item.op_weigth;
      this.op_position = null;
    }
  }

  export interface StockProducts {
    category_id: number;
    type: string;
    head_family: string;
    sub_family: string;
    head_group: string;
    sub_group: string;
    description: string;
    pr_stock: number;
  }

  export interface IProductDetail {
    family_desc: string;
    sub_family_desc: string;
    group_desc: string;
    sub_group_desc: string;
    pr_code: string;
    pr_description: string;
    pr_stock: number;
  }

  export interface IProductDetails {
    products_order_id?: string;
    product_id: string;
    product_position_id: string;
    family_desc: string;
    sub_family_desc: string;
    group_desc: string;
    sub_group_desc: string;
    pr_code: string;
    pr_b64_image: string;
    pr_b64_position_image: string;
    pr_description: string;
    pr_stock: number;
    pr_weight: number;
    pr_volume: string;
    pr_price: string;
    pr_positions: IProductPosition[];
    pr_qty: number;
  }

  export interface IProductPosition {
    product_position_id: string;
    product_id: string;
    pr_description: string;
    pr_code: string;
    // pr_b64_image?: string;
    pr_b64_image_position?: string;
    pr_b64_position_image?: string;
    pr_image_position?: string;
    pr_lane: string;
    pr_shelf: string;
    pr_position: string;
    pr_qty: number;
  }
}

export interface NewProduct {
  op_code: string;
  op_description: '0';
  op_qta: '0';
  op_weight: '0';
  op_stock: '0';
  op_positions: {
    op_full_positon: string;
  };
}

export interface SaveProducts {
  product_id: number;
  pr_code: string;
  pr_description: string;
  pr_b64_image: string;
  pr_b64_position_image: string;
}

export interface ProductImage {
  product_id: string;
  pr_b64_image?: string;
  pr_b64_position_image?: string;
}

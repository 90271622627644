import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { IMessage } from 'src/app/models/IMessage.model';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import { MessagesService } from 'src/app/services/messages.service';
import { DropdownService } from 'src/app/services/shared/dropdown.service';
import { IWorker } from 'src/app/models/IWorker.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-assegna-valore',
  templateUrl: './assegna-valore.component.html',
  styleUrls: ['./assegna-valore.component.scss'],
})

export class AssegnaValoreComponent implements OnInit {

  public msg: IMessage;  
  public disabledButton: boolean = true;
  public dataSaved = false;
  public maxlength = 250;
  public minWidth = 250;
  public dialogWidth = 1056;
  public dialogHeight = 1017;
  public workers: IWorker[] = [];
  public opened = true;
  addObtForm!: FormGroup;

  public close() {
    this.opened = false;
    this.router.navigate(['/settings'])
  }

  public goBackFunction() {
    this.router.navigate(['/settings']);
  }  

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    public closeServ: OpenModalService,
    private msgSrv: MessagesService,
    private dropdownServ: DropdownService
  ) { }

  ngOnInit(): void {
    this.addObtDialog();
    this.addObtForm = this.fb.group({
      value: ['', Validators.required]
    });
  }  

  open() {
    this.opened = true;
  }

  addObtDialog() {    
    this.dropdownServ.getWorkers().subscribe((workersData: IWorker[]) => {
      workersData.forEach(
        (element) =>
          (element.full_name =
            element.ac_first_name + ' ' + element.ac_last_name)
      );
      this.workers = workersData;
    });
    this.addObtForm = new FormGroup({
      value: new FormControl(''),
    });
  }

  addValue(addObtForm: any): void {
    let msg: IMessage = {
      ms_title: this.addObtForm.value.ms_title,
      ms_message: this.addObtForm.value.ms_message,
      ms_recipient_id: this.addObtForm.value.account_id,
    };
    this.disabledButton = false;
    this.msgSrv.putMessage(msg).subscribe((messageData: IMessage) => {
      this.msg = messageData;
    });    
    console.log(msg);
    this.addObtForm.markAllAsTouched(); 
    this.router.navigate(['/settings']);
    Swal.fire({
      icon: 'success',
      title: 'Obiettivo aggiornato!',
      text: 'Valore impostato con successo!!!',
      timer: 1500,
      timerProgressBar: true,
    });
  }

  clearForm(): void {
    this.addObtForm.reset();
  }
  
}

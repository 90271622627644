import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IAccount } from 'src/app/models/IAccount.model';
import { IWsResult } from 'src/app/models/ws_result.model';
import { AccountsService } from 'src/app/services/accounts.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import { addYears } from 'date-fns';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-account-new',
  templateUrl: './gestione-account-new.component.html',
  styleUrls: ['./gestione-account-new.component.scss'],
})

export class GestioneAccountNewComponent implements OnInit {
  
  @Input() opened = true;
  @Input() agent_id: string = '-1';

  public dataSaved = false;
  public maxlength = 250;
  public minWidth = 250;
  public dialogWidth = 250;
  public dialogHeight = 1017;
  public account: IAccount;
  public accountType: number;
  public accountTypesSelect: {
    ac_typo: string;
    ac_number: number;
  }[] = [
    { ac_typo: 'Admin', ac_number: 1 },
    {
      ac_typo: 'User',
      ac_number: 2,
    },
  ];

  addAccountForm!: FormGroup;
  today: Date = new Date();

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    public closeServ: OpenModalService,
    public accServ: AccountsService
  ) { }

  public close() {
    this.opened = false;
    this.router.navigate(['/accounts']);
  }

  open() {
    this.opened = true;
  }

  ngOnInit(): void {
    this.addAccountDialog();
    this.account;
  }

  goBackFunction() {
    this.router.navigate(['/accounts']);
  }

  onAccountTypeSelected(value: any) {
    console.log('Selected account type: ', value);
    this.account.ac_user_type_cod = value.toString();
    console.log('Account type is: ', this.account.ac_user_type_cod);
  }

  addAccountDialog() {
    this.addAccountForm = new FormGroup({
      account_id: new FormControl(''),
      ac_first_name: new FormControl('', [
        Validators.required,
        Validators.maxLength(35),
        Validators.pattern('[a-zA-Z. ]*'),
      ]),
      ac_last_name: new FormControl('', [
        Validators.required,
        Validators.maxLength(35),
        Validators.pattern('[a-zA-Z. ]*'),
      ]),
      ac_email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-z]{2,4}$'),
      ]),
      ac_user_type_cod: new FormControl('', [
        Validators.pattern('[0-9-+ ]*'),
        Validators.maxLength(20),
      ]),
      ac_expiration_date: new FormControl(addYears(this.today, 1)),
      ac_phone: new FormControl('', [
        Validators.pattern('[0-9-+ ]*'),
        Validators.maxLength(20),
      ]),
      ac_active: new FormControl(''),
      tab_user_type_desc: new FormControl('', [
        // Validators.required,
        Validators.maxLength(35),
        Validators.pattern('[a-zA-Z. ]*'),
      ]),
    });
    this.account = {
      account_id: 0,
      ac_first_name: '',
      ac_last_name: '',
      ac_email: '',
      ac_user_type_cod: 0,
      ac_expiration_date: new Date(),
      ac_phone: '',
      ac_mobile: '',
      ac_active: '',
      ac_note: '',
      tab_user_type_desc: '',
      ac_photo_url: '',
    };
  }

  addAgent(addAccountForm: any): void {
    const ac_expiration_date = new Date(
      this.addAccountForm.value.ac_expiration_date
    ).toISOString();
    let account: IAccount = {
      account_id: this.addAccountForm.value.account_id,
      ac_first_name: this.addAccountForm.value.ac_first_name,
      ac_last_name: this.addAccountForm.value.ac_last_name,
      ac_email: this.addAccountForm.value.ac_email,
      ac_user_type_cod: this.addAccountForm.value.ac_user_type_cod,
      ac_expiration_date: ac_expiration_date,
      ac_mobile: this.addAccountForm.value?.ac_mobile,
      ac_phone: this.addAccountForm.value.ac_phone,
      ac_photo_url: this.addAccountForm.value?.ac_photo_url,
      ac_active: 'S',
      ac_note: this.addAccountForm.value?.ac_note,
      tab_user_type_desc: this.addAccountForm.value.tab_user_type_desc,
    };
    this.accServ.saveAccount(account).subscribe((accountsData: IWsResult) => {
      this.account = accountsData.data;
    });
    console.log(account);
    this.addAccountForm.markAllAsTouched();
    this.router.navigate(['/accounts']);
    Swal.fire({
      icon: 'success',
      title: 'Aggiunto!',
      text: 'Nuovo utente aggiunto con successo!!!',
      timer: 1500,
      timerProgressBar: true,
    });
  }

  clearForm(): void {
    this.addAccountForm.reset();
  }

}

import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ICarrier } from 'src/app/models/ICarrier.model';
import { IWorker } from 'src/app/models/IWorker.model';
import { Orders } from 'src/app/models/Orders.model';
import { Priority } from 'src/app/models/Priority.model';
import { NewProduct, Products } from 'src/app/models/Product.model';
import { IWsResult } from 'src/app/models/ws_result.model';
import { OrdersService } from 'src/app/services/orders.service';
import { ProductsService } from 'src/app/services/products.service';
import { DropdownService } from 'src/app/services/shared/dropdown.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-assegna-ordine-dettagli',
  templateUrl: './assegna-ordine-dettagli.component.html',
  styleUrls: ['./assegna-ordine-dettagli.component.scss'],
})

export class AssegnaOrdineDettagliComponent implements OnInit {

  @Input() code: any;
  @Input() gridData: Orders.ICMOrderList[] = [];
  public dataSaved = false;
  public assignOrderForm: FormGroup;
  public orderNew: Orders.IOrderNew;
  public productsNew: Products.IProductToSend[] = [];
  public orderToSend: Orders.IOrderToSend;
  public formattedDate: string = '-';
  public priorityOptions: Priority.IPriority[] = [];
  public carriers: ICarrier[] = [];
  public workers: IWorker[] = [];
  public product: NewProduct[] = [];
  public addOrder: Orders.IOrder;
  public assignOrder: Orders.IAddOrder[] = [];
  public selectedProds: Orders.IAddOrder[] = [];
  public maxlength = 250;
  public minWidth = 1400;
  public dialogWidth = 600;
  public dialogHeight = 1017;
  public opened = true;

  isEditable = false;
  areSelected: boolean = false;
  clickedProduct: any;
  numbers: number[] = [];
  partialOrder: string = null;
  chosenProd: string = null;

  constructor(
    private ordersServ: OrdersService,
    private productServ: ProductsService,
    private route: ActivatedRoute,
    private dropdownServ: DropdownService,
    private fb: FormBuilder,
    private formBuilder: FormBuilder,
    private router: Router,
    public closeServ: OpenModalService
  ) { }

  public close() {
    this.opened = false;
  }

  public goBackFunction() {
    this.router.navigate(['/prepare-order']);
  }

  public goBack() {
    this.router.navigate(['/assign-order']);
  }

  open() {
    this.closeServ.open();
  }

  modify() {
    this.isEditable = !this.isEditable;
  }

  opChecked(product: Products.IProductToSend, index: number) {
    this.productsNew[index].op_checked = !this.productsNew[index].op_checked;
    this.partialOrder =
      this.productsNew.every((value) => value.op_checked == true) &&
      this.productsNew.every((value) => +value.op_qta === value.orQtaNeeded)
        ? 'N'
        : 'S';
  }

  selectAllProducts() {
    this.areSelected = !this.areSelected;
    this.productsNew.forEach((item: any) => {
      item.op_checked = this.areSelected;
    });
    console.log('The selected products are: ', this.productsNew);
  }

  changeProductNumber(n) {
    this.numbers = Array.from({ length: n }, (_element, index) => index + 1);
  }

  productDetails(productId: any) {
    console.debug('Merge?', productId);
    this.productServ
      .getProduct(this.code)
      .subscribe((productData: IWsResult) => {
        this.product = productData.data;
      });
    this.clickedProduct = productId;
    this.closeServ.openProd();
  }

  async updateProduct(value, i) {
    this.productsNew[i].orQtaNeeded = value;
    this.productsNew[i].op_checked = true;
    this.partialOrder =
      this.productsNew.every((value) => value.op_checked == true) &&
      this.productsNew.every((value) => +value.op_qta === value.orQtaNeeded)
        ? 'N'
        : 'S';
  }

  ngOnInit(): void {
    this.assignOrderForm = this.formBuilder.group({
      or_order_number: [''],
      or_order_date: [''],
      or_order_priority_cod: ['', Validators.required],
      carrier_id: [''],
      or_customer: [''],
      or_shipping_address: [''],
      or_shipping_zip: [''],
      or_shipping_city: [''],
      or_shipping_prov: [''],
      or_shipping_country: [''],
      or_assigned_id: ['', Validators.required],
    });
    this.ordersServ
      .getNewOrder(this.route.snapshot.paramMap.get('id')!)
      .subscribe((orderData: IWsResult) => {
        this.orderNew = new Orders.OrderNew(orderData.data);
        console.log('Quote date is: ', typeof this.orderNew.or_quote_date);
        this.productsNew = this.orderNew.products.map((item) => {
          let product: Products.IProductToSend = {
            op_note: '',
            op_checked: false,
            op_code: item.op_code,
            op_description: item.op_description,
            op_qta: item.op_qta,
            op_weigth: item.op_weigth,
            op_stock: item.op_stock,
            orQtaNeeded: +item.op_qta,
            op_qta_ordered: item.op_qta_ordered,
            op_positions: [],
            tab_order_status_cod: []
          };
          return product;
        });
        this.dropdownServ
          .getPriorities()
          .subscribe((priorityData: Priority.IPriority[]) => {
            this.priorityOptions = priorityData;
          });
        if (this.orderNew.or_order_date)
          this.formattedDate = formatDate(
            this.orderNew.or_order_date,
            'dd/MM/yyyy',
            'it'
          );
        this.dropdownServ
          .getCarriers()
          .subscribe((carriersData: ICarrier[]) => {
            this.carriers = carriersData;
          });
        this.dropdownServ.getWorkers().subscribe((workersData: IWorker[]) => {
          workersData.forEach(
            (element) =>
              (element.full_name =
                element.ac_first_name + ' ' + element.ac_last_name)
          );
          this.workers = workersData;
        });
        this.assignOrderForm.patchValue({
          or_order_number: this.orderNew.or_order_number,
          or_order_date: this.formattedDate,
          or_order_priority_cod: 'Select',
          carrier_id: this.orderNew?.or_carrier_id,
          or_customer: this.orderNew.or_customer,
          or_shipping_address: this.orderNew.or_shipping_address,
          or_shipping_zip: this.orderNew.or_shipping_zip,
          or_shipping_city: this.orderNew.or_shipping_city,
          or_shipping_prov: this.orderNew.or_shipping_prov,
          or_shipping_country: this.orderNew.or_shipping_country,
        });
        console.log(
          'Data w/o new Date: ',
          this.assignOrderForm.value.or_quote_date
        );
        console.log(
          'Date is',
          new Date(this.assignOrderForm.value.or_quote_date)
        );
        if (!new Date(this.assignOrderForm.value.or_quote_date))
          console.log('It is invalid!');
        if (!new Date(this.assignOrderForm.value.or_order_date))
          console.log('It is invalid!');
      });
  }

  clearForm(): void {
    this.assignOrderForm.reset();
  }

  addOrderDialog() {
    this.assignOrderForm = new FormGroup({
      or_order_number: new FormControl(''),
      or_order_date: new FormControl('', [
        Validators.required,
        Validators.maxLength(35),
        Validators.pattern('[a-zA-Z. ]*'),
      ]),
      or_order_priority_cod: new FormControl('', [
        Validators.required,
        Validators.maxLength(35),
        Validators.pattern('[a-zA-Z. ]*'),
      ]),
      or_carrier_id: new FormControl('', [
        Validators.required,

        Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-z]{2,4}$'),
      ]),
      ac_user_type_cod: new FormControl('', [
        Validators.pattern('[0-9-+ ]*'),
        Validators.maxLength(20),
      ]),
      ac_expiration_date: new FormControl('', [
        Validators.pattern(
          /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/
        ),
      ]),
      or_customer: new FormControl('', [
        Validators.pattern('[0-9-+ ]*'),
        Validators.maxLength(20),
      ]),
      or_shipping_address: new FormControl('', [
        Validators.pattern('[0-9-+ ]*'),
        Validators.maxLength(20),
      ]),

      or_shipping_city: new FormControl(''),
      or_shipping_prov: new FormControl('', [
        Validators.required,
        Validators.maxLength(35),
        Validators.pattern('[a-zA-Z. ]*'),
      ]),
      or_shipping_country: new FormControl('', [
        Validators.required,
        Validators.maxLength(35),
        Validators.pattern('[a-zA-Z. ]*'),
      ]),
    });
  }

  addOrderMethod(assignOrderToSendForm: any) {
    let assignOrder: Orders.IOrderToSend = {
      or_assignment_date: this.orderNew.or_order_date,
      or_assigned_id: this.assignOrderForm.value.or_assigned_id,
      or_order_priority_cod: this.assignOrderForm.value.or_order_priority_cod,
      or_carrier_id: this.assignOrderForm.value.carrier_id,
      or_carrier_desc: this.orderNew.or_carrier_desc,
      or_partial: this.partialOrder,
      or_customer_reference: '123',
      products: this.productsNew.map((item) => {
        item.op_qta = item.orQtaNeeded;
        return item;
      }),
      or_order_number: this.assignOrderForm.value.or_order_number,
      or_order_date: this.orderNew.or_order_date,
      or_customer: this.assignOrderForm.value.or_customer,
      or_shipping_address: this.assignOrderForm.value.or_shipping_address,
      or_shipping_city: this.assignOrderForm.value.or_shipping_city,
      or_shipping_prov: this.assignOrderForm.value.or_shipping_prov,
      or_shipping_country: this.assignOrderForm.value.or_shipping_country,
      or_gamma_id: this.orderNew.or_gamma_id,
      or_agent_id: '1',
      or_customer_code: this.orderNew.or_customer_code,
      or_customer_mail: this.orderNew.or_customer_mail,
      or_customer_phone: this.orderNew.or_customer_phone,
      or_shipping_zip: this.assignOrderForm.value.or_shipping_zip,
      or_quote_number: this.orderNew.or_quote_number,
      or_quote_date: this.orderNew.or_quote_date,
      or_subject: this.orderNew.or_subject,
      or_note: this.orderNew.or_note, // '1',
      or_boxes: this.orderNew.or_boxes,
      or_shipping_customer: this.orderNew.or_shipping_customer,
    };
    console.log(assignOrder);
    console.log(this.orderNew);
    this.ordersServ.addOrder(assignOrder).subscribe((ordersData) => {
      if (ordersData.success) {
        this.goBack();
        Swal.fire({
          icon: 'success',
          title: 'Assegnato!',
          text: 'Ordine assegnato con successo!!!',
          timer: 1500,
          timerProgressBar: true,
        });
      } 
      else {
        Swal.fire({
          icon: 'error',
          title: 'Non assegnato!',
          text: 'Si è verificato un problema, riprova!!!',
          timer: 1500,
          timerProgressBar: true,
        });
      }
    });
  }

}

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})

export class SettingsService {

    private obiettivoGiornaliero: number;
    private obiettivoMensile: number;
    private obiettivoAnnuale: number;

    getObiettivoGiornaliero(): number {
        return this.obiettivoGiornaliero;
    }

    setObiettivoGiornaliero(value: number): void {
        this.obiettivoGiornaliero = value;
    }

    getObiettivoMensile(): number {
        return this.obiettivoMensile;
    }

    setObiettivoMensile(value: number): void {
        this.obiettivoMensile = value;
    }

    getObiettivoAnnuale(): number {
        return this.obiettivoAnnuale;
    }

    setObiettivoAnnuale(value: number): void {
        this.obiettivoAnnuale = value;
    }

}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
import { NotificationService } from '@progress/kendo-angular-notification';
import { AuthService } from 'src/app/services/auth.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})

export class ProfileComponent implements OnInit {

  @ViewChild('old_password') public textbox!: TextBoxComponent;
  @ViewChild('new_password') public repTextbox!: TextBoxComponent;
  @Input() passOpen = false;
  @Input() account_id: string = '-1';
  public disabledButton: boolean = false;
  
  public ngAfterViewInit(): void {
    this.textbox.input.nativeElement.type = 'password';
    this.repTextbox.input.nativeElement.type = 'password';
  }

  public toggleVisibility(): void {
    const inputEl = this.textbox.input.nativeElement;
    inputEl.type = inputEl.type === 'password' ? 'text' : 'password';
  }

  public toggleVisibilityRep(): void {
    const inputEl = this.repTextbox.input.nativeElement;
    inputEl.type = inputEl.type === 'password' ? 'text' : 'password';
  }

  public changePassForm: FormGroup = new FormGroup({
    old_password: new FormControl('', [Validators.required]),
    new_password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
    rep_password: new FormControl('', [Validators.required]),
  });

  pattern: RegExp =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!"£/()=^'ì,.;:_è+òàùéç°§%*#?&])[A-Za-z\d$@$!"£/()=^'ì,.;:_è+òàùéç°§%*#?&]{8,}$/;

  constructor(
    private authServ: AuthService,
    private notificationService: NotificationService,
    public closeSrv: OpenModalService,
    private router: Router
  ) { }

  ngOnInit(): void {}

  close() {
    this.closeSrv.closePass();
  }

  changePassword(formValues: any): void {
    this.disabledButton = true;
    this.authServ
      .changePassword(
        formValues.old_password,
        formValues.new_password,
        formValues.rep_password
      )
      .subscribe((res: any) => {
        switch (res?.error_message?.msg_code) {
          case 0:
            this.showNotification('Password cambiata con successo.', 'success');
            Swal.fire({
              icon: 'success',
              title: 'Aggiornata!',
              text: 'Password cambiata con successo!!!',
              timer: 1500,
              timerProgressBar: true,
            });
            this.closeSrv.closePass();
            this.closeSrv.close();
            break;
          case 12:
            this.showNotification('Utente non attivo', 'error');
            Swal.fire({
              icon: 'error',
              title: 'Errore!',
              text: 'Utente non attivo!!!',
              timer: 1500,
              timerProgressBar: true,
            });
            break;
          case 13:
            this.showNotification('Account scaduto', 'error');
            Swal.fire({
              icon: 'error',
              title: 'Errore!',
              text: 'Account scaduto!!!',
              timer: 1500,
              timerProgressBar: true,
            });
            break;
          case 14:
            this.showNotification('Utente inesistente', 'error');
            Swal.fire({
              icon: 'error',
              title: 'Errore!',
              text: 'Utente inesistente!!!',
              timer: 1500,
              timerProgressBar: true,
            });
            break;
          case 15:
            this.showNotification(
              'La password non soddisfa i requisiti della politica della password',
              'error'
            );
            Swal.fire({
              icon: 'error',
              title: 'Errore!',
              text: 'La vecchia password è errata!!!',
              timer: 1500,
              timerProgressBar: true,
            });
            break;
          case 17:
            this.showNotification(
              'La password non soddisfa i requisiti della politica della password',
              'error'
            );
            Swal.fire({
              icon: 'error',
              title: 'Errore!',
              text: 'La password non soddisfa i requisiti della politica delle password!!!',
              timer: 1500,
              timerProgressBar: true,
            });
            break;
          default:
            this.showNotification('Si è verificato un problema', 'warning');
            Swal.fire({
              icon: 'warning',
              title: 'Attenzione!',
              text: 'Si è verificato un problema, riprova!!!',
              timer: 1500,
              timerProgressBar: true,
            });
            break;
        }
      });
  }

  public showNotification(
    messageText: string,
    styleType: 'none' | 'success' | 'warning' | 'error' | 'info'
  ): void {
    this.notificationService.show({
      content: messageText,
      hideAfter: 600,
      position: { horizontal: 'center', vertical: 'top' },
      animation: { type: 'fade', duration: 2000 },
      type: { style: styleType, icon: true },
    });
  }
  
}

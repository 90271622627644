export namespace Documents {

  export interface IDocumentId {
    document_id: number;
  }

  export interface IDocument {
    document_id: number;
    td_document: string;
    td_file: string;
  }

  export class Document implements IDocument {
    document_id: number;
    td_document: string;
    td_file: string;
    checked: boolean = false;
    indexNo: number;

    constructor(document: IDocument, index: number) {
      this.document_id = document.document_id;
      this.td_document = document.td_document;
      this.td_file = document.td_file;
      this.indexNo = index;
    }    
  }

}

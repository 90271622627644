import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Errors,
  OrderErrors,
  TypesOfErrors,
} from 'src/app/models/Orders.model';
import { OrdersService } from 'src/app/services/orders.service';
import { ProductsService } from 'src/app/services/products.service';
import { DropdownService } from 'src/app/services/shared/dropdown.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-errori',
  templateUrl: './errori.component.html',
  styleUrls: ['./errori.component.scss'],
})

export class ErroriComponent {

  @Input() order_id: number;
  @Output() modalClosed1 = new EventEmitter<void>();
  public errorNew: Errors;
  public errorForm: FormGroup;
  public opened = true;
  isEditable = false;
  errori: OrderErrors = new OrderErrors(null);
  selectedErrorType: string;
  selectedErrorDescription: string;
  values: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  errorTypes: TypesOfErrors[] = [null];

  constructor(
    private ordersServ: OrdersService,
    private productServ: ProductsService,
    private route: ActivatedRoute,
    private dropdownServ: DropdownService,
    private fb: FormBuilder,
    private formBuilder: FormBuilder,
    private router: Router,
    public closeServ: OpenModalService
  ) { }

  ngOnInit(): void {
    console.log('Order is brossini', this.order_id);
    this.ordersServ.getTypesOfOrderErrors().subscribe((data) => {
      if (!data) 
        return;
      console.log('Data is', data);
      this.errorTypes = data;
      console.log('Error types are', this.errorTypes);
    });
    this.errorForm = this.formBuilder.group({
      order_id: [''],
      // oe_error_type_id: [''],
      oe_error_gravity: [''],
      oe_description: [''],
      oe_error_type_id: [''],
      // order_error_type_description: [''],
      order_error_id: [''],
    });
  }

  clearForm(): void {
    this.errorForm.reset();
  }

  addErrorMethod(assignOrderToSendForm: any) {
    console.log('adding error', assignOrderToSendForm);
    let assignError: OrderErrors = {
      oe_description: this.errorForm.value.oe_description,
      oe_error_gravity: this.errorForm.value.oe_error_gravity,
      oe_error_type_id: this.errorForm.value.oe_error_type_id,
      order_error_id: 0,
      order_id: +this.order_id,
    };
    console.log(assignError);
    console.log(this.errorNew);
    this.ordersServ.createOrderError(assignError).subscribe((ordersData) => {
      if (ordersData.success) {
        this.modalClosed1.emit();
        this.closeServ.closeErrorModal();
        Swal.fire({
          title: 'Impostato!',
          text: 'Errore salvato con successo!!!',
          icon: 'success',
          customClass: {
            popup: 'pop-your-mom',
          },
          timerProgressBar: true,
        });
      } 
      else {
        this.closeServ.closeErrorModal();
        Swal.fire({
          title: 'Non impostato!',
          text: 'Si è verificato un problema, riprova!!!',
          icon: 'error',
          timer: 3500,
          timerProgressBar: true,
        });
      }
    });
  }

  validateError(error: number): boolean {
    if (error >= 0) 
      return true;
    return false;
  }

  public close() {
    this.closeServ.closeErrorModal();
  }

  public goBackFunction() {
    // this.router.navigate(['/history']);
    this.closeServ.closeErrorModal();
  }

  public goBack() {
    this.router.navigate(['/history']);
  }

  open() {
    this.closeServ.open();
  }

  ngOnDestroy(): void {
    console.log('I am destroyed');
    this.closeServ.closeErrorModal();
  }
  
}

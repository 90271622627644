import { Component, OnInit, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Orders } from 'src/app/models/Orders.model';
import { Error } from 'src/app/models/error.model';
import { OrdersService } from 'src/app/services/orders.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { formatDate } from '@angular/common';
import { GridDataResult, PageChangeEvent } from "@progress/kendo-angular-grid";
import { Statistics } from 'src/app/models/stats';
import { Subscription } from 'rxjs';
import { PickerService } from 'src/app/services/picker.service';
import { Chart, registerables } from 'chart.js';
//@ts-ignore
import charts from './stats.json';

@Component({
  selector: 'app-statistiche',
  templateUrl: './statistiche.component.html',
  styleUrls: ['./statistiche.component.scss']
})

export class StatisticheComponent implements OnInit {
  
  @ViewChild('totaleOperatoriCanvas') private totaleOperatoriCanvas!: ElementRef;
  @ViewChild('totaleCorrieriCanvas') private totaleCorrieriCanvas!: ElementRef;
  @ViewChild('corriere2Canvas') private corriere2Canvas!: ElementRef;
  @ViewChild('corriereCanvas') private corriereCanvas!: ElementRef;
  @ViewChild('operatoreCanvas') private operatoreCanvas!: ElementRef;
  @ViewChild('numeroErroriCanvas') private numeroErroriCanvas!: ElementRef;  
  @ViewChild('percentualeErroriCanvas') private percentualeErroriCanvas!: ElementRef; 
  @ViewChild('ordiniEvasiCanvas') private ordiniEvasiCanvas!: ElementRef; 
  @ViewChild('colliSpeditiCanvas') private colliSpeditiCanvas!: ElementRef; 
  @ViewChild('giorniLavorativiCanvas') private giorniLavorativiCanvas!: ElementRef; 
  @ViewChild('mediaSpedizioniCanvas') private mediaSpedizioniCanvas!: ElementRef; 
  @ViewChild('mediaOrdiniCanvas') private mediaOrdiniCanvas!: ElementRef; 

  public ordersStock: Orders.IOrderClosed[] = [];
  public filteredOrder: Orders.IOrderClosed[];
  public customers: string[];
  public dates: string[];
  public opened = false;
  public loading: boolean = false;  
  public isSearched: boolean = false;
  public pageSize = 20;
  public searchString = 'Filtra ordini per cliente';
  public searchDate: Date = new Date();
  public toDate = 'al giorno'; 
  public datasets: any;
  public data: any;
  public salesChart;
  public clicked: boolean = true;
  public clicked1: boolean = false;
  public gridView: GridDataResult;
  public report: Statistics.Report;
  public corrieri: Statistics.OrdiniCorriere;
  public operatori: Statistics.OrdiniOperatore;
  public skip = 0;
  public orders: Orders.OrderSignature[] = [];
  private subscription: Subscription;

  selectedOrders: Orders.OrderSelectedToSign[] = [];
  totaleOperatoriChart: any;
  totaleCorrieriChart: any;
  corriere2: any;
  corriere: any;
  operatore: any;  
  numeroErroriChart: any;
  percentualeErroriChart: any;
  ordiniEvasiChart: any;
  colliSpeditiChart: any;
  giorniLavorativiChart: any;
  mediaSpedizioniChart: any;
  mediaOrdiniChart: any;
  filterTerm: string | undefined;
  clickedOrder: string;
  clientOrder: string = 'Cliente';
  customer: string = '';
  date: string = '';
  showAll: string = 'Mostra tutti';
  showAll2: string = 'Mostra tutti';
  customerIndex: number;
  startDate: Date = new Date(2021, 0, 1);
  endDate: Date = new Date();
  dateBy: any[] = [
    {
      text: 'Data ordine',
      value: 'or_order_number',
    },
  ];  

  filters = {
    dateOrginizedBy: {
      text: 'Data ordine',
      value: 'or_order_number',
    },
    startingDate: this.startDate,
    endDate: this.endDate,
    minDate: new Date(),
    maxDate: new Date(),
  };

  errori: Error.Error = new Error.Error(
    /* code */ 1,
  /* type */ 'Tipo di errore',
  /* description */ 'Descrizione dell\'errore',
  /* value */ 42,
  /* color */ '#FF0000', // Colore personalizzato
  /* setColor */ true, // Imposta il colore sul documento
  );

  constructor(
    private orderSrv: OrdersService,
    private route: ActivatedRoute,
    private router: Router,
    public closeSrv: OpenModalService,
    private render: Renderer2,
    private translate: TranslateService,
    private openOrdersServ: OrdersService,
    public openModal: OpenModalService,
    public pageService: PickerService
  ) { 
      Chart.register(...registerables);
  }  

  ngAfterViewInit() { 
    this.totaleOperatori();
    this.totaleCorrieri();      
    this.corriereMethod();
    this.corriere2Method();
    this.operatoreMethod();   
    this.ordiniEvasi();
    this.colliSpediti();
    this.giorniLavorativi();
    this.mediaSpedizioni();
    this.mediaOrdini();
    this.numeroErrori();
    if (charts.percentuale_errori) {
      this.percentualeErrori();
    } else {
      console.error('Errore: charts.percentuale_errori è undefined o null');
    }
    //this.percentualeErrori();
  }

  ngOnInit(): void {
    this.subscription = this.orderSrv.getErrors().subscribe((err: Error.Error) => {
      this.errori.err_type = err.err_type;
      //this.updateErroriChart();
    });
  }

  ngOnDestroy(): void {    
    this.subscription.unsubscribe();
  }

  updateErroriChart() {
    this.numeroErroriChart.data.datasets[0].data = [this.errori.err_type];
    this.percentualeErroriChart.data.datasets[0].data = [this.errori.err_type];
    this.numeroErroriChart.update();
    this.percentualeErroriChart.update();
  }

  totale_operatori_data = charts.ordini_operatore.data.map((user) => {
    const nome = user.nome;
    const datiTotali = user.dati ? this.calcTot(user.dati) : 0;
    return {
      nome: nome,
      datiTotali: datiTotali
    };
  });

  calcTot(array: number[]): number {
    return array.reduce((acc, valore) => acc + valore, 0);
  }

  totaleOperatori() {
    this.totaleOperatoriChart = new Chart(this.totaleOperatoriCanvas.nativeElement, {
      type: 'polarArea',
      data: {
        labels: charts.totale_operatori.labels,
        datasets: [{
          label: '',
          //data: this.totale_operatori_data.map((user) => user.datiTotali),
          data: charts.ordini_operatore.data.map((user) => this.calcTot(user.dati)),
          backgroundColor: charts.totale_operatori.bkgColor,
          hoverOffset: 4
        }]
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: charts.totale_operatori.title,
            font: {
              size: charts.font.sizeM,
              weight: charts.font.weight,
              family: charts.font.family
            },
            padding: {
              top: charts.font.top,
              bottom: charts.font.bottom
            }
          },
          legend: {
            display: true,
            labels: {
              font: {
                size: charts.font.sizeS,
                family: charts.font.family
              }
            }
          }
        },
        scales: {
          r: {
            suggestedMax: charts.font.max,
            suggestedMin: charts.font.min
          }
        }
      },
    });
  }
  
  totaleCorrieri() {
    this.totaleCorrieriChart = new Chart(this.totaleCorrieriCanvas.nativeElement, {
      type: 'radar',
      data: {
        labels: charts.totale_corrieri.labels,
        datasets: [{
          label: '',
          backgroundColor: charts.totale_corrieri.bkgColor,
          borderWidth: 1,
          data: charts.totale_corrieri.data
        }]
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: this.translate.instant(charts.totale_corrieri.title),
            font: {
              size: charts.font.sizeM,
              weight: charts.font.weight,
              family: charts.font.family
            },
            padding: {
              top: charts.font.top,
              bottom: charts.font.bottom
            }
          },
          legend: {
            display: true,
            labels: {
              font: {
                size: charts.font.sizeS,
                family: charts.font.family
              }
            }
          }
        },
        scales: {
          r: {
            pointLabels: {
              color: 'red'
            },
            suggestedMax: charts.font.max,
            suggestedMin: charts.font.min
          }
        }
      },
    });
  }

  corriereMethod() {
    this.corriere = new Chart(this.corriereCanvas.nativeElement, {
      type: 'line',
      data: {
        labels: charts.ordini_corriere.labels,
        datasets: [
          {
            label: 'TNT',
            fill: false,
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 0.8)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.50,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75, 192, 192, 1)',
            pointBackgroundColor: 'rgba(75, 192, 192, 0.4)',
            pointBorderWidth: 2,
            pointHoverRadius: 8,
            pointHoverBackgroundColor: 'rgba(75, 192, 192, 0.4)',
            pointHoverBorderColor: 'rgba(75, 192, 192, 1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: charts.ordini_corriere.TNT,
            spanGaps: false,
            tension: 0.1
          },
          {
            label: 'SDA',
            fill: false,
            backgroundColor: 'rgba(35, 52, 252, 0.2)',
            borderColor: 'rgba(35, 52, 252, 0.8)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(35, 52, 252, 1)',
            pointBackgroundColor: 'rgba(35, 52, 252, 0.4)',
            pointBorderWidth: 2,
            pointHoverRadius: 8,
            pointHoverBackgroundColor: 'rgba(35, 52, 252, 0.4)',
            pointHoverBorderColor: 'rgba(35, 52, 252, 1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: charts.ordini_corriere.SDA,
            spanGaps: false,
            tension: 0.1
          },
          {
            label: 'FERCAM',
            fill: false,
            backgroundColor: 'rgba(135, 12, 43, 0.2)',
            borderColor: 'rgba(135, 12, 43, 0.8)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(135, 12, 43, 1)',
            pointBackgroundColor: 'rgba(135, 12, 43, 0.4)',
            pointBorderWidth: 2,
            pointHoverRadius: 8,
            pointHoverBackgroundColor: 'rgba(135, 12, 43, 0.4)',
            pointHoverBorderColor: 'rgba(135, 12, 43, 1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: charts.ordini_corriere.FERCAM,
            spanGaps: false,
            tension: 0.1
          },
          {
            label: 'DELLY',
            fill: false,
            backgroundColor: 'rgba(167, 252, 100, 0.2)',
            borderColor: 'rgba(167, 252, 100, 0.8)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(167, 252, 100, 1)',
            pointBackgroundColor: 'rgba(167, 252, 100, 0.4)',
            pointBorderWidth: 2,
            pointHoverRadius: 8,
            pointHoverBackgroundColor: 'rgba(167, 252, 100, 0.4)',
            pointHoverBorderColor: 'rgba(167, 252, 100, 1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: charts.ordini_corriere.DELLY,
            spanGaps: false,
            tension: 0.1
          },
          {
            label: 'EUROSTAR',
            fill: false,
            backgroundColor: 'rgba(255, 145, 28, 0.2)',
            borderColor: 'rgba(255, 145, 28, 0.8)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(255, 145, 28, 1)',
            pointBackgroundColor: 'rgba(255, 145, 28, 0.4)',
            pointBorderWidth: 2,
            pointHoverRadius: 8,
            pointHoverBackgroundColor: 'rgba(255, 145, 28, 0.4)',
            pointHoverBorderColor: 'rgba(255, 145, 28, 1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: charts.ordini_corriere.EUROSTAR,
            spanGaps: false,
            tension: 0.1
          },
          {
            label: 'Altro',
            fill: false,
            backgroundColor: 'rgba(255, 0, 0, 0.2)',
            borderColor: 'rgba(255, 0, 0, 0.8)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(255, 0, 0, 1)',
            pointBackgroundColor: 'rgba(255, 0, 0, 0.4)',
            pointBorderWidth: 2,
            pointHoverRadius: 8,
            pointHoverBackgroundColor: 'rgba(255, 0, 0, 0.4)',
            pointHoverBorderColor: 'rgba(255, 0, 0, 1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: charts.ordini_corriere.ALTRO,
            spanGaps: false,
            tension: 0.1
          },
          {
            label: 'Ritiro in sede',
            fill: false,
            backgroundColor: 'rgba(166, 0, 255, 0.2)',
            borderColor: 'rgba(166, 0, 255, 0.8)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(166, 0, 255, 1)',
            pointBackgroundColor: 'rgba(166, 0, 255, 0.4)',
            pointBorderWidth: 2,
            pointHoverRadius: 8,
            pointHoverBackgroundColor: 'rgba(166, 0, 255, 0.4)',
            pointHoverBorderColor: 'rgba(166, 0, 255, 1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: charts.ordini_corriere.RITIRO,
            spanGaps: false,
            tension: 0.1
          }
        ]
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: charts.ordini_corriere.subtitle,
            font: {
              size: charts.font.sizeM,
              weight: charts.font.weight,
              family: charts.font.family
            },
            padding: {
              top: charts.font.top,
              bottom: charts.font.bottom
            }
          },
          legend: {
            display: true,
            labels: {
              font: {
                size: charts.font.sizeS,
                family: charts.font.family
              }
            }
          }
        }
      },
    });
  }  

  corriere2Method() {
    this.corriere2 = new Chart(this.corriere2Canvas.nativeElement, {
      type: 'bar',
      data: {
        labels: charts.totale_corriere.labels,
        datasets: [
          {
            label: "TNT",
            data: charts.totale_corriere.TNT,
            backgroundColor: "rgba(75, 192, 192, 0.7)"
          },
          {
            label: "SDA",
            data: charts.totale_corriere.SDA,
            backgroundColor: "rgba(35, 52, 252, 0.7)"
          },
          {
            label: "FERCAM",
            data: charts.totale_corriere.FERCAM,
            backgroundColor: "rgba(135, 12, 43, 0.7)", 
          },
          {
            label: "DELLY",
            data: charts.totale_corriere.DELLY,
            backgroundColor: "rgba(167, 252, 100, 0.7)", 
          },
          {
            label: "EUROSTAR",
            data: charts.totale_corriere.EUROSTAR,
            backgroundColor: "rgba(255, 145, 28, 0.7)",
          },
          {
            label: "Altro",
            data: charts.totale_corriere.ALTRO,
            backgroundColor: "rgba(255, 0, 0, 0.7)", 
          },
          {
            label: "Ritiro in sede",
            data: charts.totale_corriere.RITIRO,
            backgroundColor: "rgba(166, 0, 255, 0.7)"
          }
        ]
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: charts.totale_corriere.subtitle,
            font: {
              size: charts.font.sizeM,
              weight: charts.font.weight,
              family: charts.font.family
            },
            padding: {
              top: charts.font.top,
              bottom: charts.font.bottom
            }
          },
          legend: {
            display: true,
            labels: {
              font: {
                size: charts.font.sizeS,
                family: charts.font.family
              }
            }
          }
        },
        scales: {
          x: {
            stacked: true
          },
          y: {
            stacked: true
          }
        }
      },
    });
  }

  operatoreMethod() {
    const datasets = charts.ordini_operatore.data.map((operatore) => {
      const colorKey = `color${charts.ordini_operatore.data.indexOf(operatore) + 1}`;
      const backgroundColor = charts.colors[colorKey];
      const borderColor = charts.colors[colorKey];  
      return {
        label: operatore.nome,
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        data: operatore.dati
      };
    });
    this.operatore = new Chart(this.operatoreCanvas.nativeElement, {
      type: 'bar',
      data: {
        labels: charts.ordini_operatore.labels,
        datasets: datasets
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: charts.ordini_operatore.subtitle,
            font: {
              size: charts.font.sizeM,
              weight: charts.font.weight,
              family: charts.font.family
            },
            padding: {
              top: charts.font.top,
              bottom: charts.font.bottom
            }
          },
          legend: {
            display: true,
            labels: {
              font: {
                size: charts.font.sizeS,
                family: charts.font.family
              }
            }
          }
        }
      },
    });
  } 

  totaleOrdiniEvasi = charts.ordini_evasi.data.reduce((acc, value) => acc + value, 0);
  ordiniEvasi() {
    this.ordiniEvasiChart = new Chart(this.ordiniEvasiCanvas.nativeElement, {
      type: 'bar',
      data: {
        labels: charts.ordini_evasi.labels,
        datasets: [{
          label: charts.ordini_evasi.subtitle,
          data: charts.ordini_evasi.data,
          backgroundColor: charts.ordini_evasi.bkgColor,
          borderColor: charts.ordini_evasi.borderColor,
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          y: {
              beginAtZero: true          
          }
        },
        plugins: {
          title: {
            display: true,
            text: charts.ordini_evasi.title + `${this.totaleOrdiniEvasi}`,
            font: {
              size: charts.font.sizeM,
              weight: charts.font.weight,
              family: charts.font.family
            },
            padding: {
              top: charts.font.top,
              bottom: charts.font.bottom
            }
          },
          legend: {
            display: true,
            labels: {
              font: {
                size: charts.font.sizeS,
                family: charts.font.family
              }
            }
          }
        }
      }
    });
  }

  totaleColliSpediti = charts.colli_spediti.data.reduce((acc, value) => acc + value, 0);
  colliSpediti() {
    this.colliSpeditiChart = new Chart(this.colliSpeditiCanvas.nativeElement, {
      type: 'bar',
      data: {
        labels: charts.colli_spediti.labels,
        datasets: [{
          label: charts.colli_spediti.subtitle,
          data: charts.colli_spediti.data,
          backgroundColor: charts.colli_spediti.bkgColor,
          borderColor: charts.colli_spediti.borderColor,
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          y: {
              beginAtZero: true           
          }
        },
        plugins: {
          title: {
            display: true,
            text: charts.colli_spediti.title + `${this.totaleColliSpediti}`,
            font: {
              size: charts.font.sizeM,
              weight: charts.font.weight,
              family: charts.font.family
            },
            padding: {
              top: charts.font.top,
              bottom: charts.font.bottom
            }
          },
          legend: {
            display: true,
            labels: {
              font: {
                size: charts.font.sizeS,
                family: charts.font.family
              }
            }
          }
        }
      }
    });
  }

  totaleGiorniLavorativi = charts.giorni_lavorativi.data.reduce((acc, value) => acc + value, 0);
  giorniLavorativi() {
    this.giorniLavorativiChart = new Chart(this.giorniLavorativiCanvas.nativeElement, {
      type: 'bar',
      data: {
        labels: charts.giorni_lavorativi.labels,
        datasets: [{
          label: charts.giorni_lavorativi.subtitle,
          data: charts.giorni_lavorativi.data,
          backgroundColor: charts.giorni_lavorativi.bkgColor,
          borderColor: charts.giorni_lavorativi.borderColor,
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          y: {
              beginAtZero: true             
          }
        },
        plugins: {
          title: {
            display: true,
            text: charts.giorni_lavorativi.title + `${this.totaleGiorniLavorativi}`,
            font: {
              size: charts.font.sizeM,
              weight: charts.font.weight,
              family: charts.font.family
            },
            padding: {
              top: charts.font.top,
              bottom: charts.font.bottom
            }
          },
          legend: {
            display: true,
            labels: {
              font: {
                size: charts.font.sizeS,
                family: charts.font.family
              }
            }
          }
        }
      }
    });
  }

  media_spedizioni = this.calcMedia(charts.colli_spediti.data, charts.giorni_lavorativi.data);
  totaleMediaSpedizioni = this.media_spedizioni.reduce((acc, value) => acc + value, 0);
  mediaMediaSpedizioni = this.totaleMediaSpedizioni / this.media_spedizioni.length;
  mediaSpedizioni() {    
    this.mediaSpedizioniChart = new Chart(this.mediaSpedizioniCanvas.nativeElement, {
      type: 'bar',
      data: {
        labels: charts.media_spedizioni.labels,
        datasets: [{
          label: charts.media_spedizioni.subtitle,
          data: this.media_spedizioni,
          backgroundColor: charts.media_spedizioni.bkgColor,
          borderColor: charts.media_spedizioni.borderColor,
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          y: {
              beginAtZero: true             
          }
        },
        plugins: {
          title: {
            display: true,
            text: charts.media_spedizioni.title + `${this.mediaMediaSpedizioni.toFixed(2)}`,
            font: {
              size: charts.font.sizeM,
              weight: charts.font.weight,
              family: charts.font.family
            },
            padding: {
              top: charts.font.top,
              bottom: charts.font.bottom
            }
          },
          legend: {
            display: true,
            labels: {
              font: {
                size: charts.font.sizeS,
                family: charts.font.family
              }
            }
          }
        }
      }
    });
  }

  media_ordini = this.calcMedia(charts.ordini_evasi.data, charts.giorni_lavorativi.data);
  totaleMediaOrdini = this.media_ordini.reduce((acc, value) => acc + value, 0);
  mediaMediaOrdini = this.totaleMediaOrdini / this.media_ordini.length;
  mediaOrdini() {    
    this.mediaOrdiniChart = new Chart(this.mediaOrdiniCanvas.nativeElement, {
      type: 'bar',
      data: {
        labels: charts.media_ordini.labels,
        datasets: [{
          label: charts.media_ordini.subtitle,
          data: this.media_ordini,
          backgroundColor: charts.media_ordini.bkgColor,
          borderColor: charts.media_ordini.borderColor,
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          y: {
              beginAtZero: true            
          }
        },
        plugins: {
          title: {
            display: true,
            text: charts.media_ordini.title + `${this.mediaMediaOrdini.toFixed(2)}`,
            font: {
              size: charts.font.sizeM,
              weight: charts.font.weight,
              family: charts.font.family
            },
            padding: {
              top: charts.font.top,
              bottom: charts.font.bottom
            }
          },
          legend: {
            display: true,
            labels: {
              font: {
                size: charts.font.sizeS,
                family: charts.font.family
              }
            }
          }
        }
      }
    });
  }
  calcMedia(total, days) {
    return total.map((value, index) => {
        if (index < days.length) {
            return value / days[index];
        } 
        else {
            return 0;
        }
    });
  }

  totaleErrori = charts.numero_errori.data.reduce((acc, value) => acc + value, 0);
  numeroErrori() {
    this.numeroErroriChart = new Chart(this.numeroErroriCanvas.nativeElement, {
      type: 'doughnut',
      data: {
        labels: charts.numero_errori.labels,
        datasets: [{
          label: charts.numero_errori.subtitle,
          data: charts.numero_errori.data,
          //data: this.errori.tp_error,
          backgroundColor: charts.numero_errori.bkgColor,
          hoverBackgroundColor: charts.numero_errori.hoverbkgColor
        }]
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: charts.numero_errori.title + `${this.totaleErrori}`,
            font: {
              size: charts.font.sizeM,
              weight: charts.font.weight,
              family: charts.font.family
            },
            padding: {
              top: charts.font.top,
              bottom: charts.font.bottom
            }
          },
          legend: {
            display: true,
            labels: {
              font: {
                size: charts.font.sizeS,
                family: charts.font.family
              }
            }
          }
        },
      }
    });
  }

  percentualeErrorj = this.calcPercentuale(charts.numero_errori.data, charts.totale_operatori.data);
  totalPercentualeErrori = this.percentualeErrorj.reduce((acc, value) => acc + value, 0);
  mediaPercentualeErrori = this.percentualeErrorj.length > 0 ? (this.totaleErrori / this.totaleOrdiniEvasi)*100 : 0;
  percentualeErrori() {
    if (charts.percentuale_errori && charts.percentuale_errori.data) {
      console.log('Il controllo ha avuto successo!!');   
      console.log('percentualeErrorj:', this.percentualeErrorj);      
      console.log('totalPercentualeErrori:', this.totalPercentualeErrori);
      console.log('mediaPercentualeErrori:', this.mediaPercentualeErrori);
      this.percentualeErroriChart = new Chart(this.percentualeErroriCanvas.nativeElement, {
        type: 'doughnut',
        data: {
          labels: charts.percentuale_errori.labels,
          datasets: [{
            label: charts.percentuale_errori.subtitle,
            data: this.percentualeErrorj,
            backgroundColor: charts.percentuale_errori.bkgColor,
            hoverBackgroundColor: charts.percentuale_errori.hoverbkgColor
          }]
        },
        options: {
          plugins: {
            title: {
              display: true,
              text: charts.percentuale_errori.title + `${this.mediaPercentualeErrori.toFixed(2)}%`,
              font: {
                size: charts.font.sizeM,
                weight: charts.font.weight,
                family: charts.font.family
              },
              padding: {
                top: charts.font.top,
                bottom: charts.font.bottom
              }
            },
            legend: {
              display: true,
              labels: {
                font: {
                  size: charts.font.sizeS,
                  family: charts.font.family
                }
              }
            }
          }
        }
      });
    } 
    else {
      console.error('Errore: charts.percentuale_errori o charts.percentuale_errori.data è undefined o null');
    }
  }

  calcPercentuale(totalErrors, totalOrders) {
    return totalErrors.map((errors, index) => {
      if (index < totalOrders.length && totalOrders[index] !== 0) {
        return (errors / totalOrders[index]) * 100;
      } else {
        return 0;
      }
    });
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadItems();
  }

  private loadItems(): void {
  }

  getOrders() {
    this.orderSrv.getClosedOrders().subscribe((ordersData) => {
      this.ordersStock = ordersData;
      this.dates = [
        ...new Set(
          ordersData.map((date) => {
            let formattedDate = formatDate(
              date?.or_order_date,
              'dd/MM/yyyy',
              'it'
            );
            console.log('Formatted date is: ', formattedDate);
            console.log('Date is: ', date?.or_order_date);
            return formattedDate;
          })
        ),
      ];
      this.customers = [
        ...new Set(
          ordersData.map((client) => {
            return client.or_customer;
          })
        ),
      ];
      this.customers.unshift(this.showAll);
      this.dates.unshift(this.showAll2);
      console.debug('Orders are: ', this.ordersStock);
      console.debug('From server: ', ordersData);
      this.loading = false;
    });
  }

  public updateOptions() {
    this.salesChart.data.datasets[0].data = this.data;
    this.salesChart.update();
  }

}
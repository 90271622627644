import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { IAccount } from 'src/app/models/IAccount.model';
import { AccountsService } from 'src/app/services/accounts.service';
import { AuthService } from 'src/app/services/auth.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})

export class HeaderComponent implements OnInit {  

  @Input() account_id: string = '-1';

  public account: IAccount;
  public clickMessage: string = '';
  public toolbarWidth = 100;

  clickedId: string;

  constructor(
    public authServ: AuthService,
    public closeSrv: OpenModalService,
    private router: Router,
    private accountsService: AccountsService,
    private formBuilder: FormBuilder,
    public accServ: AccountsService
  ) { }

  ngOnInit(): void { }

  goToDetails(account_id: any) {
    this.clickedId = account_id;
    this.closeSrv.openAcc();
    console.debug(this.clickedId);
  }

  public splitButtonData: Array<{ [key: string]: unknown }> = [
    {
      text: 'Impostazioni',
      icon: 'k-i-cog',
      click: (): void => this.onItemClick('Impostazioni clicked'),
    },
    {
      text: 'Logout',
      icon: 'paste-as-html',
      click: (): void => this.onItemClick('Logout button clicked'),
    },
  ];

  public dropdownButtonData: Array<{ [key: string]: unknown }> = [
    {
      text: 'Impostazioni',
      icon: 'k-i-cog',
      click: (): void => this.onItemClick('Impostazioni clicked'),
    },
    {
      text: 'Logout',
      icon: 'paste-as-html',
      click: (): void => this.onItemClick('Logout button clicked'),
    },
  ];

  public onItemClick(message: string): void {
    this.clickMessage = message;
  }
  
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { Orders } from 'src/app/models/Orders.model';
import { Objectives } from 'src/app/models/Orders.model';
import { Status } from 'src/app/models/status';
import { IWsResult } from 'src/app/models/ws_result.model';
import { OrdersService } from 'src/app/services/orders.service';
import { SettingsService } from 'src/app/services/settings.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-controllo-quality',
  templateUrl: './controllo-quality.component.html',
  styleUrls: ['./controllo-quality.component.scss']
})

export class ControlloQualityComponent implements OnInit {

  public gridData: Orders.IOrderNewList[] = [];
  public pmOrders: Orders.OrderPM[] = [];
  public partials: Orders.IOrderNewList[] = [];  
  public order: Orders.IOrder = null;
  public loading: boolean = false;
  public value = 0;
  public indeterminate = false;
  public min = -10;
  public max = 10;
  public gridView: GridDataResult;
  public pageSize = 20;
  public skip = 0;
  public data: unknown[];

  gridDataInfinity: Orders.IOrderNewList[] = [];
  ordiniParziali: any[] = [];
  ordiniPM: any[] = [];  
  filteredOrders: Orders.IOrderNewList[] = [];
  searchText: string = '';
  filterTerm: string | undefined;
  sameIndex: boolean = true;
  id: number;
  timer = null;
  refreshInterval: number = 60000;
  startDate: Date = new Date(2021, 0, 1);
  endDate: Date = new Date();
  obiettivi: Objectives;
  objectives: Objectives = new Objectives(null);

  dateBy: any[] = [
    {
      text: 'Data ordine',
      value: 'or_order_number',
    },
  ];

  filters = {
    dateOrginizedBy: {
      text: 'Data ordine',
      value: 'or_order_number',
    },
    startingDate: this.startDate,
    endDate: this.endDate,
    minDate: new Date(),
    maxDate: new Date(),
  };

  constructor(
    private ordersServ: OrdersService,
    private router: Router,
    private settings: SettingsService
  ) { }

  loadMore() {
    this.gridDataInfinity = [
      ...this.gridDataInfinity,
      ...this.gridData.slice(this.skip, this.skip + this.pageSize),
    ];
    this.skip += this.pageSize;
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadProducts();
  }

  private loadProducts(): void {
    this.gridView = {
      data: this.gridData.slice(this.skip, this.skip + this.pageSize),
      total: this.gridData.length,
    };
  }

  searchOrders() {
    if (!this.filterTerm || this.filterTerm == '') {
      this.getNewOrders();
      return;
    }
    this.ordersServ
      .searchNewOrders(this.filterTerm)
      .subscribe((newOrdersData: IWsResult) => {
        if (newOrdersData.error_message.msg_code === 8) 
          this.gridData = [];
        else 
          this.gridData = newOrdersData.data;
        this.skip = 0;
        this.gridDataInfinity = [];
        this.loadMore();
      });
  }

  onInputChanged(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.validity && inputElement.validity.valueMissing) {
      this.getNewOrders();
    }
  }

  ngOnInit(): void {
    this.loading = true;
    this.getNewOrders();
    this.ordersServ.getObjectives().subscribe((obj: Objectives) => {
      this.obiettivi = obj;
    });
  }  

  getNewOrders() {
    this.loading = true;
    this.ordersServ.getNewOrders().subscribe((openOrdersData: any) => {
      if (openOrdersData.data === null) {
        this.loading = false;
        return;
      } 
      else if (openOrdersData.data) {
        this.gridData = openOrdersData.data;
        this.gridData = this.gridData;
        this.skip = 0;
        this.gridDataInfinity = [];
        this.loadMore();
        this.loading = false;
      }
    });
  }

  gotoDetails(order_id: number) {
    console.log('Order ID:', order_id);
    if (order_id !== undefined && order_id !== null) {
        this.router.navigate(['/quality', order_id]);
    } 
    else {
        console.error('order_id non è definito o null');
    }
  }

  reloadFunction() {
    this.getNewOrders();
  }

}

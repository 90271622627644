import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-confirm-complete',
  templateUrl: './confirm-complete.component.html',
  styleUrls: ['./confirm-complete.component.scss'],
})

export class ConfirmCompleteComponent {

  @Output() modalClosed = new EventEmitter<void>();
  @Input() modalOpened: boolean = false;
  @Input() order_id: string = '-1';

  public maxlength = 250;
  public minWidth = 200;
  public dialogWidth = 200;
  public dialogHeight = 1017;

  constructor(public openModal: OpenModalService) { }

  onNoClick(): void {
    this.openModal.offConfirmComplete();
    Swal.fire({
      icon: 'success',
      title: 'Annullato!',
      text: 'Tutto invariato, ci sei andato vicino!!!',
      timer: 1500,
      timerProgressBar: true,
    });
  }

  onYesClick(): void {
    this.modalClosed.emit();
    this.openModal.offConfirmComplete();
    console.log('Triggered the yes');
  }
}

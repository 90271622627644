import { Helpers } from './helpters';
import { Image } from './image';

export interface IMessageList {
  message_id: number;
  ms_datetime: string;
  ms_title: string;
  ms_object: string;
  ms_sender_name: string;
  ms_recipient_name: string;
  ms_status: string;
  ms_message;
  // Not from Server
  bellOn: string;
  bellOf: string;
}

export interface IMessage {
  ms_message: string;
  ms_recipient_id: string;
  ms_title: string;
}

export namespace Notifications {
  export interface INotification {
    message_id: number;
    ms_datetime: string;
    ms_title: string;
    ms_message?: string;
    ms_sender_name: string;
    ms_recipient_name: string;
    ms_order_code: string;
    ms_status: string;
  }

  export interface IMessage {
    ms_title: string;
    ms_message: string;
    ms_recipient_id: number;
    ms_order_id: number;
  }

  export interface IConversationMessage {
    ms_title: string;
    ms_message: string;
    ms_recipient_id: number;
    ms_order_id?: number;
    ms_sender_id?: number;
    ms_datetime: string;
  }

  export interface IConversation {
    ms_recipient_name: string;
    ms_recipient_id: number;
    messages: IConversationMessage[];
  }

  export interface IConversations extends INotification {
    ms_sender_id: number;
    ms_recipient_id: number;
    order_id: number;
  }
  export class Notification implements INotification {
    message_id: number;
    ms_datetime: string;
    ms_title: string;
    ms_message?: string;
    ms_sender_name: string;
    ms_recipient_name: string;
    ms_status: string;
    ms_order_code: string;

    date: string;
    time: string;
    icon: Image.IImage = Helpers.iconMail;

    constructor(notification: INotification) {
      this.message_id = notification.message_id;
      this.ms_datetime = notification.ms_datetime;
      this.ms_title = notification.ms_title;
      if (notification.ms_message) this.ms_message = notification.ms_message;
      this.ms_sender_name = notification.ms_sender_name;
      this.ms_recipient_name = notification.ms_recipient_name;
      this.ms_status = notification.ms_status;
      this.ms_order_code = notification.ms_order_code;

      if (this.ms_status && this.ms_status == 'U')
        this.icon = Helpers.iconMailOpen;
      this.date = Helpers.getDate(this.ms_datetime);
      this.time = Helpers.getTime(this.ms_datetime);
    }
  }

  export class Conversations extends Notification implements IConversations {
    ms_sender_id: number;
    ms_recipient_id: number;
    order_id: number;

    constructor(notification: IConversations) {
      super(notification);
      this.ms_sender_id = notification.ms_sender_id;
      this.ms_recipient_id = notification.ms_recipient_id;
      this.order_id = notification?.order_id;
    }
  }

  export class Message implements IMessage {
    ms_title: string;
    ms_message: string;
    ms_recipient_id: number;
    ms_order_id: number;

    constructor(message: IMessage) {
      if (!message) return;
      this.ms_title = message.ms_title;
      this.ms_message = message.ms_message;
      this.ms_recipient_id = message.ms_recipient_id;
      this.ms_order_id = message.ms_order_id;
    }
  }

  export class Conversation implements IConversation {
    ms_recipient_name: string;
    ms_recipient_id: number;
    messages: IConversationMessage[] = [];

    constructor(message: IConversation) {
      if (!message) return;
      this.ms_recipient_name = message.ms_recipient_name;
      this.ms_recipient_id = message.ms_recipient_id;
      this.messages = message.messages;
    }
  }

  export interface IConversationGroup {
    ms_datetime: string;
    ms_message: string;
    ms_status: string;
    sender: string;
    sender_id: number;
    recipient_id: number;
    recipient: string;
    order_number: string;
  }

  export class ConversationGroup implements IConversationGroup {
    ms_datetime: string = '';
    ms_message: string = '';
    ms_status: string = '';
    sender: string = '';
    sender_id: number = 0;
    recipient_id: number = 0;
    recipient: string = '';
    order_number: string = '';

    constructor(conversation: IConversationGroup | null) {
      if (!conversation) return;
      this.ms_datetime = conversation.ms_datetime;
      this.ms_message = conversation.ms_message;
      this.ms_status = conversation.ms_status;
      this.sender = conversation.sender;
      this.sender_id = conversation.sender_id;
      this.recipient_id = conversation.recipient_id;
      this.recipient = conversation.recipient;
      this.order_number = conversation.order_number;
    }
  }
}

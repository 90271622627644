// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  username: 'warehouse@livith.it',
  password: 'BGHj!87Q>I',
  production: false,
  urlServer: 'https://salvatempo.connecta.ovh/api/services',
  // urlServer: 'http://salvatempo.livith.it:88/api/services',
  KENDO_UI_LICENSE:
    'eyJhbGciOiJSUzI1NiIsInR5cCI6IkxJQyJ9.eyJwcm9kdWN0cyI6W3sidHJpYWwiOmZhbHNlLCJjb2RlIjoiS0VORE9VSVJFQUNUIiwibGljZW5zZUV4cGlyYXRpb25EYXRlIjoxNjYwMTIyMzg3fSx7InRyaWFsIjpmYWxzZSwiY29kZSI6IktFTkRPVUlDT01QTEVURSIsImxpY2Vuc2VFeHBpcmF0aW9uRGF0ZSI6MTY2MDEyMjM4N30seyJ0cmlhbCI6ZmFsc2UsImNvZGUiOiJLRU5ET1VJVlVFIiwibGljZW5zZUV4cGlyYXRpb25EYXRlIjoxNjYwMTIyMzg3fSx7InRyaWFsIjpmYWxzZSwiY29kZSI6IktFTkRPVUlBTkdVTEFSIiwibGljZW5zZUV4cGlyYXRpb25EYXRlIjoxNjYwMTIyMzg3fV0sImludGVncml0eSI6IjVhNlVtem1aSWtobnNUdVwvOW1aT1dKVU5VRUk9IiwibGljZW5zZUhvbGRlciI6Im0uc2FuaUBtZXN5cy5pdCIsImlhdCI6MTYzNzMxNDIyMSwiYXVkIjoibS5zYW5pQG1lc3lzLml0In0.DnUfWuEWhZ_2KLf5_WhAuZrrr-UHy0vZKW8KAc0Wsk3tjK06Z1YwDNqQnvAtOyqZcOx9M_WXL7DLZWl3kgJ4ginBmR3zcNlISr89oPv4FjsZ0xB--pQJg4wLH9jKplZ4dKpU8ZfOqqnPYdpteH16SGWrIvYzJjqNVA2mKlJcjXzn3f7f8YNm7DX0ZpAvLfMge33JFQqnGIob4ccXakrZnjrFIU1kD0dAkVbvl6YfZC1rW91VeeGwl-2v2Vvqr7gFK-xI1UUEeYMMNdIJtXHxa57aGB6j8roBVxJH6SP8scbhj3rPThRpynv7OKdP_AYZvN24ZpY3c14ofzWROK5d3w',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

<div class="text-center">
  <label for="" class="custom-file-upload">
    <input
      class="form-control"
      type="file"
      placeholder=".dadsadas"
      (change)="onFileLoad($event)"
    />
    <div class="">
      <i class="bx bxs-camera icon-info-top"></i>
    </div>
  </label>
</div>

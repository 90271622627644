import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { isAfter, isBefore, parseISO } from 'date-fns';
import { Subscription } from 'rxjs';
import { Helpers } from 'src/app/models/helpters';
import { ICarrier } from 'src/app/models/ICarrier.model';
import { IWorker } from 'src/app/models/IWorker.model';
import { Orders } from 'src/app/models/Orders.model';
import { Priority } from 'src/app/models/Priority.model';
import { Products } from 'src/app/models/Product.model';
import { OrdersService } from 'src/app/services/orders.service';
import { DropdownService } from 'src/app/services/shared/dropdown.service';
import { SearchServiceService } from 'src/app/services/shared/search-service.service';

@Component({
  selector: 'app-ordini-pm',
  templateUrl: './ordini-pm.component.html',
  styleUrls: ['./ordini-pm.component.scss'],
})

export class OrdiniPMComponent implements OnInit {

  public opened = false;
  public dataSaved = false;
  public assignOrderForm: FormGroup;
  public orderNew: Orders.IOrderNew;
  public productsNew: Products.IProductNew[] = [];
  public orderToSend: Orders.IOrderToSend;
  public formattedDate: string = '-';
  public priorityOptions: Priority.IPriority[] = [];
  public carriers: ICarrier[] = [];
  public workers: IWorker[] = [];
  public staticHelper = Helpers;
  public gridData: Orders.ICMOrderList[] = [];
  public loading: boolean = true;
  public filteredOrder: Orders.OrderList[] = null;
  public isSearched: boolean = false;

  filterTerm: string = '';
  maxPriority: number;
  timer = null;
  refreshInterval: number = 600000;
  text: string;
  startDate: Date = new Date(2021, 0, 1);
  endDate: Date = new Date();

  dateBy: any[] = [
    {
      text: 'Data ordine',
      value: 'or_order_number',
    },
  ];

  filters = {
    dateOrginizedBy: {
      text: 'Data ordine',
      value: 'or_order_number',
    },
    startingDate: this.startDate,
    endDate: this.endDate,
    minDate: new Date(),
    maxDate: new Date(),
  };

  searchService = inject(SearchServiceService);
  subscriber: Subscription[] = [];
  searchParam: string = '';

  constructor(
    private ordersServ: OrdersService,
    private route: ActivatedRoute,
    private dropdownServ: DropdownService,
    private fb: FormBuilder,
    private openOrdersServ: OrdersService
  ) { }

  getCMOrders() {
    this.loading = true;
    this.ordersServ
      .getCMOrders()
      .subscribe((CMOrdersData: Orders.ICMOrderList[]) => {
        this.gridData = CMOrdersData;
        console.debug('Data is: ', this.gridData);
      });
    this.loading = false;
  }

  onInputChanged(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.validity && inputElement.validity.valueMissing) {
      this.getCMOrders();
    }
  }

  // async filterList(searchString?: string) {
  //   // const searchTerm = evt.srcElement.value;
  //   this.filterTerm = searchTerm;
  //   this.isSearched = true;
  //   if (!this.filterTerm || this.filterTerm == '') {
  //     this.isSearched = false;
  //     this.filteredOrder = null;
  //     return;
  //   }
  //   else
  //     this.ordersServ.searchOpenOrders(searchTerm).subscribe((result) => {
  //       this.filteredOrder = result.data;
  //     });
  // }

  async clearList(evt) {
    this.isSearched = false;
    this.filteredOrder = null;
    this.filterTerm = null;
  }

  ngOnInit(): void {
    this.loading = true;
    this.subscriber.push(
      this.route.queryParams.subscribe((params) => {
        console.log('Params is', params);

        if (Object.keys(params).length === 0) {
          this.createForm();
          this.getCMOrders();
          this.loading = false;
          console.log('Params is an empty object. Reloaded.');
        } 
        else {
          this.searchParam = params['search'];
          const dateParam = params['data'];
          this.filters.startingDate = dateParam ? parseISO(dateParam) : null;
          console.log('Date param is', dateParam);
          console.log('Param is', this.searchParam);
          this.searchOrders(this.searchParam);
          this.loading = false;
        }
      })
    );

    //     if (!search) {
    //     this.createForm();
    //     this.getCMOrders();
    //     this.loading = false;
    //   } else this.searchOrders(search);
    //   this.loading = false;
    // })
  }

  assignOrder(assignOrderForm: any): void {
    let orderToSend: Orders.IOrderToSend = {
      or_order_number: this.assignOrderForm.value.or_order_number,
      or_order_date: this.orderNew.or_order_date,
      or_quote_date: this.orderNew.or_quote_date,
      or_order_priority_cod: this.assignOrderForm.value.or_order_priority_cod,
      or_carrier_id: this.assignOrderForm.value.or_carrier_id,
      or_carrier_desc: this.orderNew.or_carrier_desc,
      products: this.assignOrderForm.value.products,
      or_customer: this.assignOrderForm.value.or_customer,
      or_shipping_address: this.assignOrderForm.value.or_shipping_address,
      or_assigned_id: this.assignOrderForm.value.or_assigned_id,
      or_assignment_date: new Date().toString(),
      or_partial: '',
      or_customer_reference: '',
      or_gamma_id: this.orderNew.or_gamma_id,
      or_customer_code: this.orderNew.or_customer_code,
      or_customer_mail: this.orderNew.or_customer_mail,
      or_customer_phone: this.orderNew.or_customer_phone,
      or_quote_number: this.orderNew.or_quote_number,
      or_subject: this.orderNew.or_subject,
      or_note: this.orderNew.or_note,
      or_shipping_city: this.orderNew.or_shipping_city,
      or_shipping_prov: this.orderNew.or_shipping_prov,
      or_shipping_zip: this.orderNew.or_shipping_zip,
      or_shipping_country: this.orderNew.or_shipping_country,
      or_shipping_customer: this.orderNew.or_shipping_country,
      or_agent_id: '',
      or_boxes: this.orderNew.or_boxes,
    };
    console.log(orderToSend);
    this.assignOrderForm.markAllAsTouched();
  }

  clearForm(): void {
    this.assignOrderForm.reset();
  }

  createForm() {
    this.assignOrderForm = this.fb.group({
      or_order_number: [''],
      or_order_date: [''],
      or_order_priority_cod: ['', Validators.required],
      or_carrier_id: ['', Validators.required],
      products: {},
      or_customer: [''],
      or_shipping_address: [''],
      or_note: [''],
      or_assigned_id: ['', Validators.required],
    });
  }

  searchOrders(searchString?: string) {
    this.loading = true;
    if (searchString) 
      this.filterTerm = searchString;
    if (!this.filterTerm || this.filterTerm == '') {
      this.getCMOrders();
    } 
    else
      this.openOrdersServ
        .searchCmOrder(this.filterTerm)
        .subscribe((openOrdersData: Orders.ICMOrderList[]) => {
          this.gridData = openOrdersData;
          console.debug('Data is', this.gridData);
        });
    this.gridData.forEach((element) => {
      switch (this.filters.dateOrginizedBy.value) {
        case 'or_order_number':
          if (isBefore(parseISO(element.or_order_date), this.filters.minDate)) {
            this.filters.startingDate = parseISO(element.or_order_date);
            this.filters.minDate = parseISO(element.or_order_date);
          }
          if (isAfter(parseISO(element.or_order_date), this.filters.maxDate)) {
            this.filters.startingDate = parseISO(element.or_order_date);
            this.filters.maxDate = parseISO(element.or_order_date);
          }
          break;
      }
    });
    this.loading = false;
  }

  ngOnDestroy() {
    this.subscriber.forEach((subscriber) => subscriber.unsubscribe());
  }
  
}

<div class="k-overlay dark-background"></div>

<div class="dark-background">
  <kendo-dialog
    title=""
    (close)="goBackFunction()"
    *ngIf="opened"
    [minWidth]="250"
    [width]="550"
    [maxWidth]="1150"
    class="k-form"
    [formGroup]="addMsgForm"
    style="left: 6%;"
  >
    <div 
      style="
        display: flex;
        flex-direction: row;
        justify-content: end;
        margin-top: -15px;
        z-index: 1000;
        position: fixed;
        margin-left: -35px;
      "
    >
      <button
        (click)="close()"
        class="btn btn3"
        rounded="full"
        style="
          background-color: #e90000;
          color: #ffffff;
          width: 35px !important;
          height: 35px !important;
        "
      >    
        <i
          class="bx bx-x bx-sm posRelBox2"
          style="
            color: #ffffff; 
            bottom: 10px; 
            position: relative !important;
            left: -80% !important;
            top: -1px !important;
          "
        ></i>
      </button> 
    </div>
    <h1 class="page-title">Nuovo Messaggio</h1>
    <hr class="hr-dialog" style="top: 10px !important" />
    <form 
      class="k-form" 
      [formGroup]="addMsgForm"
      (ngSubmit)="addMsg(addMsgForm)">
      <fieldset class="k-form-fieldset det mb-2">
        <kendo-formfield class="setDisplay-Prep">
          <kendo-label 
            [for]="ms_title"
            class="title" 
            text="Oggetto"
          ></kendo-label>
          <input
            formControlName="ms_title"
            kendoTextBox
            #ms_title
            [required]="true"
            rounded="full"
            class="setBorder-Prepp"
          />
          <kendo-formerror
            *ngIf="
              (addMsgForm.get('ms_title')?.touched ||
                addMsgForm.get('ms_title')?.dirty) &&
              addMsgForm.get('ms_title')?.errors
            "
          >
            Campo obbligatorio!
          </kendo-formerror>
        </kendo-formfield>
      </fieldset>

      <div class="row det mb-2">
        <kendo-formfield class="col-md setDisplay-Prep">
          <kendo-label 
            [for]="account_id"
            class="title" 
            text="Operatore"
          ></kendo-label>
          <kendo-combobox
            [data]="workers"
            textField="full_name"
            valueField="account_id"
            placeholder="Operatore"
            rounded="full"
            formControlName="account_id"
            [valuePrimitive]="true"
            [required]="true"
            #account_id
            kendoComboBox
            class="setBorder-Prepp"
          >
          </kendo-combobox>
          <kendo-formerror
            *ngIf="
              (addMsgForm.get('account_id')?.touched ||
                addMsgForm.get('account_id')?.dirty) &&
              addMsgForm.get('account_id')?.errors
            "
          >
            Campo obbligatorio!
          </kendo-formerror>
        </kendo-formfield>
      </div>

      <div class="row det mb-2">
        <kendo-formfield class="col-md setDisplay-Prep">
          <kendo-label 
            [for]="ms_message"
            class="title" 
            text="Descrizione"
          ></kendo-label>
          <input
            formControlName="ms_message"
            kendoTextBox
            #ms_message
            [required]="true"
            rounded="full"
            class="col-desc setBorder-Prepp"
          />
        </kendo-formfield>
      </div>      

      <div
        class="col"
        style="
          display: flex; 
          justify-content: center; 
          margin-top: 10px
        "
      >
        <button
          class="btn btn-primary btn1 mr-100 kDisplayFlex"
          rounded="full"
          type="submit"
          [disabled]="!addMsgForm.valid"
          (click)="addMsg(addMsgForm)"
          style="
            position: relative; 
            left: 30px
          "
        >
          <i 
            class="bx bx-send bx-sm posRelBox2"
            style="
              color: #ffffff; 
              right: 3px; 
              position: relative; 
              bottom: 10px
            "
          ></i>
          <p class="posRelBox2">INVIA</p>
        </button>
      </div>
    </form>
  </kendo-dialog>
</div>

<kendo-dialog
  *ngIf="opened"
  class="k-form"
  [formGroup]="addProductForm"
  (close)="close()"
>
  <div class="container" *ngIf="product">
    <div class="row align-items-start">
      <h2
        class="title-text"
        style="
          display: flex; 
          justify-content: center; 
          margin-top: 10px
        "
      >
        Nuovo prodotto
      </h2>
      <hr class="hr-dialog" />
      <div class="col">
        <div class="col-container">
          <kendo-formfield class="col-md">
            <kendo-label
              [for]="pr_description"
              text="N°/Nome prodotto"
            ></kendo-label>
            <kendo-textbox
              formControlName="pr_description"
              kendoTextBox
              #pr_description
              [readonly]="false"
              rounded="full"
            ></kendo-textbox>
          </kendo-formfield>

          <div class="row">
            <kendo-formfield class="col-md-6">
              <kendo-label [for]="pr_code" text="Marca"></kendo-label>
              <kendo-textbox
                formControlName="pr_code"
                kendoTextBox
                #pr_code
                [readonly]="false"
                rounded="full"
                placeholder=""
              ></kendo-textbox>
            </kendo-formfield>

            <kendo-formfield class="col-md-6">
              <kendo-label
                [for]="pr_b64_image_position"
                text="Modello"
              ></kendo-label>
              <kendo-textbox
                formControlName="pr_b64_image_position"
                kendoTextBox
                #pr_b64_image_position
                [readonly]="false"
                rounded="full"
                placeholder=""
              ></kendo-textbox>
            </kendo-formfield>
          </div>

          <div class="row">
            <kendo-formfield class="col-md-6">
              <kendo-label
                [for]="pr_image_position"
                text="Categoria"
              ></kendo-label>
              <kendo-textbox
                formControlName="pr_image_position"
                kendoTextBox
                #pr_image_position
                [readonly]="false"
                rounded="full"
                placeholder=""
              ></kendo-textbox>
            </kendo-formfield>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="col img-col"></div>
      </div>
    </div>
    <div
      class="col"
      style="
        display: flex;
        justify-content: center;
        padding-top: 20px;
        padding-bottom: 20px;
      "
    >
      <button
        kendoButton
        rounded="full"
        (click)="close()"
        type="button"
        class="button-r"
      >
        ANNULLA
      </button>
      <button
        kendoButton
        themeColor="primary"
        rounded="full"
        (click)="addProduct(addProductForm)"
        type="button"
        class="button-l"
      >
        AGGIUNGI
      </button>
    </div>
  </div>
</kendo-dialog>

import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Documents } from 'src/app/models/documents.models';
import { Products } from 'src/app/models/Product.model';
import { ProductsService } from 'src/app/services/products.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';

@Component({
  selector: 'app-product-detail-modal',
  templateUrl: './product-detail-modal.component.html',
  styleUrls: ['./product-detail-modal.component.scss'],
})

export class ProductDetailModalComponent implements OnInit {

  @Input() opened = false;
  @Input() storicoOn = false;
  @Input() pr_code: string;

  productPos: Products.IProductDetails;
  productPosition: Products.IProductPosition[] = [];
  doc: Documents.IDocument[] = [];
  productsDetailForm!: FormGroup;
  isEditable: boolean = false;

  constructor(
    public productSrv: ProductsService,
    public openSrv: OpenModalService,
    private formbuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public closeServ: OpenModalService
  ) { }

  ngOnInit(): void {
    this.productsDetailForm = this.formbuilder.group({
      product_id: [''],
      pr_description: [''],
      family_desc: [''],
      sub_family_desc: [''],
      group_desc: [''],
      pr_stock: [''],
      pr_weight: [''],
      pr_code: [''],
      pr_b64_image: [''],
      pr_b64_position_image: [''],
      pr_lane: [''],
      pr_shelf: [''],
      pr_position: [''],
      product_position_id: [''],
    });
    console.log('This is the ID: ', this.pr_code);
    {
      console.log('This is the ID: ', this.pr_code);
      this.productSrv.getProduct(this.pr_code).subscribe((productData) => {
        this.productPos = productData;
        this.productPosition = this.productPos.pr_positions;
        console.log('The product is: ', this.productPos);
        console.log('Product pos: ', productData);
        this.productsDetailForm.patchValue({
          pr_description: this.productPos.pr_description,
          family_desc: this.productPos.family_desc,
          sub_family_desc: this.productPos?.sub_family_desc,
          group_desc: this.productPos.group_desc,
          pr_stock: parseInt(this.productPos?.pr_stock.toString(), 10),
          pr_weight: parseInt(this.productPos?.pr_weight.toString(), 10),
          pr_lane: this.productPosition[0]?.pr_lane,
          pr_shelf: this.productPosition[0]?.pr_shelf,
          pr_position: this.productPosition[0]?.pr_position,
        });
      });
    }
  }  

  goBackFunction() {
    this.closeServ.close();
    this.closeServ.prodDetOff();
    this.closeServ.offStoricoProd();
  }
  
}

<h1 class="page-title">Controllo Qualità ( IN LAVORAZIONE!!!)</h1>
<h1 class="page-title">DATI FITTIZZI - NON USARE!!!</h1>

<div
    class="filterContainer k-display-flex"
    style="flex-direction: row; justify-content: center"
>
    <form
        class="new-search"
        role="search"
        style="
            display: flex !important;
            flex-direction: row !important;
            justify-content: center !important;
        "
    >
        <label for="search"></label>
        <input
            [(ngModel)]="filterTerm"
            id="search"
            name="search"
            pattern=".*\S.*"
            type="search"
            placeholder="Cerca ordine..."
            autofocus
            required
            (input)="this.onInputChanged($event)"
        />
        <button type="submit" (click)="searchOrders()">
            <i
                class="bx bx-search bx-md"
                style="
                    margin: 0;
                    padding-top: 10px;
                    padding-left: 5px;
                    transform: scale(0.8);
                "
            >
            </i>
        </button>
    </form>
    <form
        action=""
        class="search-bar"
        style="
            display: flex !important;
            flex-direction: row !important;
            justify-content: space-between !important;
            text-align: center !important;
        "
        *ngIf="obiettivi"
    >
    </form>
    <div class="dateContainer k-display-flex justify-content-evenly">
        <div class="col mx-2">
            <h5 class="text-center">DA:</h5>
            <kendo-datepicker
                [(ngModel)]="filters.startingDate"
                class="dropdown-field drop-the-border"
                style="
                    background: #ffffff;
                    border: 1px solid #e0e0e0;
                    border-radius: 45px;
                "
            ></kendo-datepicker>
        </div>
        <div class="col">
            <h5 class="text-center">A:</h5>
            <kendo-datepicker
                [(ngModel)]="filters.endDate"
                class="dropdown-field drop-the-border"
                style="
                    background: #ffffff;
                    border: 1px solid #e0e0e0;
                    border-radius: 45px;
                "
            ></kendo-datepicker>
        </div>
    </div>
</div>

<hr class="hr-newBlue" />

<div class="row k-display-flex justify-content-end" style="padding-left: 20px">
    <div style="display: flex; flex-direction: row; justify-content: center">
        <div class="k-display-flex flex flex-row">
            <h2 class="col-header">
                Totale ordini in Arrivo: {{ gridData?.length }}
            </h2>     
            <i
                class="bx bx-refresh bx-md"
                style="
                    cursor: pointer;
                    color: rgb(0, 52, 97);
                    justify-content: end;
                    text-align: right;
                "
                (click)="reloadFunction()"
                title="Aggiorna lista"
            ></i>       
            <h2 class="col-header">&nbsp;|&nbsp;
                Totale ordini da Controllare: {{ gridData?.length }}     
            </h2> 
            <i
                class="bx bx-refresh bx-md"
                style="
                    cursor: pointer;
                    color: #017EFA;
                    justify-content: end;
                    text-align: right;
                "
                (click)="reloadFunction()"
                title="Aggiorna lista"
            ></i>
            <h2 class="col-header">&nbsp;|&nbsp;&nbsp;
                Totale ordini Controllati: {{ ordiniParziali?.length }}
            </h2>
            <i
                class="bx bx-refresh bx-md"
                style="
                    cursor: pointer;
                    color: #00AB44;
                    justify-content: end;
                    text-align: right;
                "
                (click)="reloadFunction()"
                title="Aggiorna lista"
            ></i>
            <h2 class="col-header">&nbsp;|&nbsp;&nbsp;
                Totale ordini Non Conformi: {{ ordiniPM?.length }}
            </h2>
            <i
                class="bx bx-refresh bx-md"
                style="
                    cursor: pointer;
                    color: #E00000;
                    justify-content: end;
                    text-align: right;
                "
                (click)="reloadFunction()"
                title="Aggiorna lista"
            ></i>
        </div>
    </div>

    <div
        *ngIf="loading"
        style="display: flex; flex-direction: column; align-items: center"
    >
        <mat-spinner></mat-spinner>
        <p>Attendi..</p>
    </div>

    <div class="grid-wrapper" *ngIf="!loading">
        <kendo-grid
            [kendoGridBinding]="
                gridData
                    | byToDate
                : [
                    filters.startingDate,
                    filters.endDate,
                    filters.dateOrginizedBy.value
                ]
            "
            [rowHeight]="36"
            [height]="680"
            scrollable="virtual"
            (scrollBottom)="loadMore()"
            [navigable]="true"
            [sortable]="true"
            class="maxHeight"
            [reorderable]="true"
        >
        <kendo-grid-column
            [width]="100"
            field="or_order_number"
            title="N° Ordine"
            [headerStyle]="{ 'justify-content': 'center' }"
            [style]="{ 'tetx-align': 'center' }"
        >
        </kendo-grid-column>

        <kendo-grid-column
            [width]="400"
            field="or_customer"
            title="Fornitore"
            [headerStyle]="{ 'justify-content': 'left' }"
            [style]="{
                display: 'grid',
                'justify-content': 'left',
                'tetx-align': 'left'
            }"
        >
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.or_customer }}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
            [width]="100"
            field="or_order_date"
            title="Data prevista consegna"
            [headerStyle]="{ 'justify-content': 'center' }"
            [style]="{ 'tetx-align': 'center' }"
        >
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.or_order_date | date : "dd/MM/yyyy" }}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
            [width]="100"
            field="or_order_date"
            title="Data ordine"
            [headerStyle]="{ 'justify-content': 'center' }"
            [style]="{ 'tetx-align': 'center' }"
        >
            <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.or_order_date | date : "dd/MM/yyyy" }}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-command-column
            [width]="100"
            title="Controlla"
            [headerStyle]="{ 'justify-content': 'center' }"
            [style]="{ 'text-align': 'center' }"
        >
            <ng-template kendoGridCellTemplate let-dataItem>
                <button
                    kendoGridEditCommand
                    rounded="full"
                    class="btn-det"
                    style="margin: 0; padding: 0"
                    (click)="gotoDetails(dataItem.order_id)"
                >
                    <div class="setDisplayToFlex">
                        <i
                            class="bx bx-edit bx-md"
                            style="margin: 0; padding: 0; transform: scale(0.8)"
                        >
                        </i>
                        <p class="parButton2"></p>
                    </div>
                </button>
            </ng-template>
        </kendo-grid-command-column>
        </kendo-grid>

        <div *ngIf="loading" class="k-i-loading"></div>
    </div>
</div>
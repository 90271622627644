<kendo-dialog
  *ngIf="isShipOn"
  (close)="close()"
  [[minWidth]="250"
  [width]="450"
  [maxWidth]="1150"
  style="left: 6%"
>
  <div
    style="
      display: flex;
      flex-direction: row;
      justify-content: end;
      margin-top: -25px;
      z-index: 1000;
      position: fixed;
      margin-left: -45px;
    "
  >
    <button
      (click)="close()"
      class="btn btn3"
      rounded="full"
      style="
        background-color: #e90000;
        color: #ffffff;
        width: 35px !important;
        height: 35px !important;
      "
    >
      <i
        class="bx bx-x bx-sm posRelBox2"
        style="
          color: #ffffff;
          bottom: 10px;
          position: relative !important;
          left: -80% !important;
          top: -1px !important;
        "
      ></i>
    </button>
  </div>
  <h1 class="page-title">Spedizione</h1>
  <div class="container mt-3" *ngIf="order">
    <div class="row mt-2">
      <div class="col setDisplay-Prep">
        <div class="row mt-2" *ngIf="orderToUpdate.or_carrier_id != 5">
          <div class="col setDisplay-Prep">
            <span class="title">Corriere: </span>
            <input
              class="setBorder-Prep center"
              style="
                padding: 5px;
                color: #0d6efd !important;
                background-color: white !important;
                font-weight: bold;
              "
              [readonly]="!canEdit"
              placeholder="{{
                order.vs_details.carrier_desc
                  ? order.vs_details.carrier_desc
                  : order.vs_details.carrier_other
              }}"
            />
          </div>
        </div>
        <span class="title" *ngIf="canEditCor === true">Corriere: </span>

        <kendo-combobox
          *ngIf="canEditCor === true"
          [data]="carriers"
          textField="carrier_desc"
          valueField="carrier_id"
          placeholder="Corriere"
          rounded="full"
          [allowCustom]="true"
          [valuePrimitive]="true"
          required="true"
          #carrier_id
          [(ngModel)]="orderToUpdate.or_carrier_id"
        >
        </kendo-combobox>
      </div>
    </div>
    <div class="spedizioneButtons" *ngIf="canEditCor || canEdit">
      <button
        (click)="updateCor()"
        [disabled]="isLabelGenerated"
        class="btn btn3"
        rounded="full"
        style="
          background: rgb(0, 146, 73);
          color: #ffffff;
          justify-content: center !important;
          display: flex;
          width: 262px !important;
          height: 50px !important;
        "
      >
        <i
          class="bx bx-save bx-sm posRelBox2"
          style="
            color: #ffffff;
            right: 6px;
            bottom: 10px;
            position: relative;
            margin-top: 4px;
          "
        ></i>
        <p class="posRelBox2">SALVA</p>
      </button>
    </div>
    <div class="row mt-2" *ngIf="canEditCor === true">
      <div class="col setDisplay-Prep">
        <span class="title">Altro corriere: </span>
        <input
          style="
            color: #0d6efd !important;
            background-color: white !important;
            font-weight: bold;
          "
          class="setBorder-Prep center"
          [(ngModel)]="orderToUpdate.or_carrier_other"
          placeholder="Enter Carrier"
          style="color: white"
          (input)="clearComboBox()"
        />
      </div>
    </div>
    <div class="row mt-3" *ngIf="orderToUpdate.or_carrier_id != 5">
      <div class="col setDisplay-Prep">
        <span class="title">Destinatario: </span>
        <input
          style="
            color: #0d6efd !important;
            background-color: white !important;
            font-weight: bold;
          "
          class="setBorder-Prep center"
          [readonly]="!canEdit"
          [(ngModel)]="orderToUpdate.or_shipping_customer"
          placeholder="{{ order.or_shipping_customer }}"
        />
      </div>
    </div>
    <div class="row mt-3" *ngIf="orderToUpdate.or_carrier_id != 5">
      <div class="col setDisplay-Prep">
        <span class="title">Indirizzo di spedizione: </span>
        <input
          style="
            color: #0d6efd !important;
            background-color: white !important;
            font-weight: bold;
          "
          class="setBorder-Prep center"
          [readonly]="!canEdit"
          [(ngModel)]="orderToUpdate.or_shipping_address"
          placeholder="{{ order.or_shipping_address }}"
        />
      </div>
    </div>
    <div class="row mt-3" *ngIf="orderToUpdate.or_carrier_id != 5">
      <div class="col setDisplay-Prep">
        <span class="title">Città: </span>
        <input
          style="
            color: #0d6efd !important;
            background-color: white !important;
            font-weight: bold;
          "
          class="setBorder-Prep center"
          [readonly]="!canEdit"
          [(ngModel)]="orderToUpdate.or_shipping_city"
          placeholder="{{ order.or_shipping_city }}"
        />
      </div>
    </div>
    <div class="row mt-3" *ngIf="orderToUpdate.or_carrier_id != 5">
      <div class="col setDisplay-Prep">
        <span class="title">Provincia: </span>
        <input
          style="
            color: #0d6efd !important;
            background-color: white !important;
            font-weight: bold;
          "
          class="setBorder-Prep center"
          [readonly]="!canEdit"
          [(ngModel)]="orderToUpdate.or_shipping_prov"
          placeholder="{{ order.or_shipping_prov }}"
        />
      </div>
    </div>
    <div class="row mt-3" *ngIf="orderToUpdate.or_carrier_id != 5">
      <div class="col setDisplay-Prep">
        <span class="title">CAP: </span>
        <input
          style="
            color: #0d6efd !important;
            background-color: white !important;
            font-weight: bold;
          "
          class="setBorder-Prep center"
          [readonly]="!canEdit"
          [(ngModel)]="orderToUpdate.or_shipping_zip"
          placeholder="{{ order.or_shipping_zip }}"
        />
      </div>
    </div>
    <div class="row mt-3" *ngIf="orderToUpdate.or_carrier_id != 5">
      <div class="col setDisplay-Prep">
        <span class="title">Nazione: </span>
        <input
          style="
            color: #0d6efd !important;
            background-color: white !important;
            font-weight: bold;
          "
          class="setBorder-Prep center"
          [readonly]="!canEdit"
          [(ngModel)]="orderToUpdate.or_shipping_country"
          placeholder="{{ order.or_shipping_country }}"
        />
      </div>
    </div>
    <div class="row mt-3" *ngIf="orderToUpdate.or_carrier_id != 5">
      <div class="col setDisplay-Prep">
        <span class="title">Note: </span>
        <input
          style="
            color: #0d6efd !important;
            background-color: white !important;
            font-weight: bold;
          "
          class="setBorder-Prep center"
          [readonly]="!canEdit"
          [(ngModel)]="orderToUpdate.or_note"
          placeholder="{{ order.or_note }}"
        />
      </div>
    </div>

    <div class="spedizioneButtons">
      <button
        (click)="triggerCor()"
        [disabled]="isLabelGenerated"
        class="btn btn3"
        rounded="full"
        style="
          background: rgb(180, 3, 3);
          color: #ffffff;
          justify-content: center !important;
          display: flex;
          width: 262px !important;
          height: 50px !important;
        "
      >
        <i
          class="bx bx-edit bx-sm posRelBox2"
          style="
            color: #ffffff;
            right: 6px;
            bottom: 10px;
            position: relative;
            margin-top: 4px;
          "
        ></i>
        <p class="posRelBox2">CAMBIA CORRIERE</p>
      </button>
      <button
        (click)="triggerEdit()"
        [disabled]="isLabelGenerated"
        class="btn btn3"
        rounded="full"
        style="
          background: rgb(180, 3, 3);
          color: #ffffff;
          justify-content: center !important;
          display: flex;
          width: 262px !important;
          height: 50px !important;
        "
      >
        <i
          class="bx bx-edit bx-sm posRelBox2"
          style="
            color: #ffffff;
            right: 6px;
            bottom: 10px;
            position: relative;
            margin-top: 4px;
          "
        ></i>
        <p class="posRelBox2">CAMBIA INDIRIZZO</p>
      </button>
    </div>

    <div *ngIf="!canEditCor && !canEdit">
      <div class="center">
        <button
          (click)="generateLabel()"
          [disabled]="isLabelGenerated"
          class="btn btn3"
          rounded="full"
          style="
            background: rgb(0, 146, 73);
            color: #ffffff;
            justify-content: center !important;
            display: flex;
            width: 262px !important;
            height: 50px !important;
          "
        >
          <i
            class="bx bx-printer bx-sm posRelBox2"
            style="
              color: #ffffff;
              right: 6px;
              bottom: 10px;
              position: relative;
              margin-top: 4px;
            "
          ></i>
          <p class="posRelBox2">GENERA ETICHETTA</p>
        </button>
      </div>
      <hr class="hr-newBlue" />
      <div class="center">
        <button
          (click)="check()"
          [disabled]="isLabelGenerated"
          class="btn btn3"
          rounded="full"
          style="
            background: rgb(64, 64, 241);
            color: #ffffff;
            justify-content: center !important;
            display: flex;
            width: 262px !important;
            height: 50px !important;
          "
        >
          <i
            class="bx bx-right-arrow-circle bx-sm posRelBox2"
            style="
              color: #ffffff;
              right: 6px;
              bottom: 10px;
              position: relative;
              margin-top: 4px;
            "
          ></i>
          <p class="posRelBox2">CONTINUA</p>
        </button>
      </div>
    </div>
  </div>
</kendo-dialog>

<app-confirm-courrier
  *ngIf="modalService.openCour"
  [opened]="modalService.openCour"
  [product_id]="checkCour"
>
</app-confirm-courrier>

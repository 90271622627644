import { Component, OnInit, Renderer2, RendererFactory2 } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { SidebarService } from 'src/app/services/shared/sidebar.service';
import { AccountsService } from 'src/app/services/accounts.service';
import { IAccount } from 'src/app/models/IAccount.model';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})

export class SidebarComponent implements OnInit {

  public account: IAccount;
  private renderer!: Renderer2;

  isDark: boolean = false;
  theme: string = 'Light mode';  
  dataItem: any;
  clickedId: any;

  constructor(
    public rendererFactory: RendererFactory2,
    private router: Router,
    public sidebarService: SidebarService,
    private accountsService: AccountsService,
    public authServ: AuthService,
    public closeSrv: OpenModalService,
  ) {
    this.renderer = rendererFactory!.createRenderer(null, null);
  }

  ngOnInit(): void {
    this.getAccounts();
    this.account = JSON.parse(localStorage.getItem('currentUser') || '{}');
    console.log('Account is', this.account);
  }

  toggleSidenav() {
    this.sidebarService.isClosed = !this.sidebarService.isClosed;
    this.sidebarService.toggleSidebar();
  }

  toggleDarkMode() {
    this.isDark = !this.isDark;
    if (this.isDark) {
      this.renderer.setAttribute(document.body, 'class', 'dark');
      this.theme = 'Tema scuro';
    } 
    else {
      this.renderer.setAttribute(document.body, 'class', '');
      this.theme = 'Tema chiaro';
    }
  }

  getAccounts() {
    this.account = JSON.parse(localStorage.getItem('currentUser') || '{}');
  }

  goToAccDetails() {
    this.clickedId = this.account.account_id;
    this.closeSrv.onPhoto();
  }
  
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ByOrderNumberPipe } from './by-order-number.pipe';

@NgModule({
  exports: [ByOrderNumberPipe],
  declarations: [ByOrderNumberPipe],
  imports: [CommonModule],
})

export class ByOrderNumberModule {

  static forRoot() {
    return {
      ngModule: ByOrderNumberModule,
      providers: [],
    };
  }
  
}

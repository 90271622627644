<h1 class="page-title">Lista ordini da firmare</h1>

<div
  class="filterContainer k-display-flex"
  style="flex-direction: row; justify-content: center"
>
  <form
    *ngIf="orders && chosenCarrier === null"
    class="new-search"
    role="search"
    style="
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;
    "
  >
    <label for="search"></label>
    <input
      [(ngModel)]="filterTerm"
      id="search"
      name="search"
      pattern=".*\S.*"
      type="search"
      placeholder="Cerca ordine..."
      autofocus
      required
      (input)="this.onInputChanged($event)"
    />
    <button type="submit" (click)="searchOrders()">
      <i
        class="bx bx-search bx-md"
        style="
          margin: 0;
          padding-top: 10px;
          padding-left: 5px;
          transform: scale(0.8);
        "
      >
      </i>
    </button>
  </form>

  <form
    action=""
    class="search-bar"
    style="
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;
      visibility: hidden !important;
    "
  >
    <button
      class="btn btn-primary msg-btn"
      routerLink="/new-msg"
      style="
        display: flex !important;
        flex-direction: row !important;
        justify-content: center !important;
        visibility: hidden !important;
      "
    >
      + Messaggio
    </button>
  </form>

  <form
    action=""
    class="search-bar activeHover"
    *ngIf="chosenCarrier !== null && selectedOrders"
  >
    <input
      type="text"
      [(ngModel)]="filterTerm"
      name="search"
      pattern=".*\S.*"
      placeholder="cerca ordine.."
      required
      (input)="this.onInputChanged($event)"
    />
    <button class="search-btn" type="submit" (click)="searchAfterCarrier()">
      <span>Cerca</span>
    </button>
  </form>

  <div class="dateContainer k-display-flex justify-content-evenly">
    <div class="col mx-2">
      <h5 class="text-center">DA:</h5>
      <kendo-datepicker
        [(ngModel)]="filters.startingDate"
        class="dropdown-field drop-the-border"
        style="
          background: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 45px;
        "
        >{{ filters.startingDate | date }}
      </kendo-datepicker>
    </div>
    <div class="col">
      <h5 class="text-center">A:</h5>
      <kendo-datepicker
        [(ngModel)]="filters.endDate"
        class="dropdown-field drop-the-border"
        style="
          background: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 45px;
        "
      ></kendo-datepicker>
    </div>
    <div class="col mx-2" style="padding-top: 25px">
      <kendo-dropdownlist
        [data]="carriers"
        class="drop-the-border"
        #dropdownList
        [selectedIndex]="customerIndex"
        style="
          text-align: center;
          background: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 45px;
        "
        (valueChange)="changeCarier($event)"
        [(ngModel)]="chosenCarrier"
        defaultItem="Seleziona il corriere"
      >
        <ng-template let-dataItem> </ng-template>
      </kendo-dropdownlist>
    </div>
  </div>
</div>

<hr class="hr-newBlue" />

<div class="row mt-2" *ngIf="chosenCarrier !== null && selectedOrders">
  <div class="col" style="display: flex; justify-content: flex-end">
    <button
      class="btn btn-primary k-display-flex align-items: flex-end;"
      (click)="openModalDamnIt()"
      target="_blank"
      style="background: #017efa; border-radius: 50px"
      [disabled]="!this.isOneSelected"
    >
      <i class="bx bxs-file-pdf bx-sm"></i>
      <span>GENERA BORDERO'</span>
    </button>
    <button
      class="btn btn-primary k-display-flex align-items: flex-end;"
      (click)="selectAllOrdersPerCarr()"
      style="background: #017efa; border-radius: 50px"
    >
      <i class="bx bxs-file-pdf bx-sm"></i>
      <span>SELEZIONA TUTTI</span>
    </button>
  </div>
</div>

<div
  class="grid-wrapper"
  style="padding-left: 20px"
  *ngIf="orders && chosenCarrier === null"
>
  <div style="display: flex; flex-direction: row; justify-content: center">
    <h2 class="col-header">Totale ordini da firmare: {{ orders?.length }}</h2>
  </div>

  <div
    *ngIf="loading"
    style="display: flex; flex-direction: column; align-items: center"
  >
    <mat-spinner></mat-spinner>
    <p>Attendi..</p>
  </div>

  <kendo-grid
    [kendoGridBinding]="
      orders
        | byCustomerName : customer
        | byToDate
          : [
              filters.startingDate,
              filters.endDate,
              filters.dateOrginizedBy.value
            ]
    "
    [skip]="skip"
    [rowHeight]="36"
    [height]="750"
    [pageSize]="pageSize"
    scrollable="virtual"
    [navigable]="true"
    [sortable]="true"
    [reorderable]="true"
  >
    <kendo-grid-column
      [width]="100"
      field="or_order_number"
      title="N° Ordine"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.or_order_number }}</ng-template
      >
    </kendo-grid-column>

    <kendo-grid-column
      [width]="200"
      field="ac_last_name"
      title="Operatore"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.ac_first_name }} {{ dataItem.ac_last_name }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="200"
      field="or_carrier_desc"
      title="Corriere"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.or_carrier_desc }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="350"
      field="or_customer"
      title="Cliente"
      [headerStyle]="{ 'justify-content': 'left' }"
      [style]="{
        display: 'grid',
        'justify-content': 'left',
        'tetx-align': 'left'
      }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.or_customer }}</ng-template
      >
    </kendo-grid-column>

    <kendo-grid-column
      [width]="100"
      field="or_boxes"
      title="N° Colli"
      [headerStyle]="{ 'justify-content': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.or_boxes }}</ng-template
      >
    </kendo-grid-column>

    <kendo-grid-column
      [width]="110"
      field="or_order_date"
      title="Data ordine"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.or_order_date | date : "dd/MM/yyyy" }}</ng-template
      >
    </kendo-grid-column>

    <kendo-grid-column
      [width]="140"
      field="or_assigned_date"
      title="Data evasione"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.or_assigned_date | date : "dd/MM/yyyy" }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="140"
      title="Completa"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <button
          class="btn"
          rounded="full"
          style="margin: 0; padding: 0"
          (click)="openConfirmModal(dataItem.order_id)"
        >
          <div
            class="setDisplayToFlexBlue"
            style="
              margin: 0;
              padding: 0;
              width: 120px !important;
              background-color: #0053b4 !important;
            "
          >
            <p class="parButton2" style="padding-left: 10px; font-size: 15px">
              COMPLETATO
            </p>
          </div>
        </button>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-command-column
      title="Dettagli"
      [width]="100"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <button
          kendoGridEditCommand
          rounded="full"
          class="btn-det"
          style="margin: 0; padding: 0; background-color: #017efa !important"
          (click)="gotoDetails(dataItem.order_id)"
        >
          <div class="setDisplayToFlexBlue btn-det">
            <i class="bx bx-info-circle bx-md" style="margin: 0; padding: 0">
            </i>
            <p class="parButton2"></p>
          </div>
        </button>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
</div>

<div
  class="container"
  *ngIf="chosenCarrier !== null && selectedOrders"
  style="height: 100%; max-height: 56%; overflow: auto"
>
  <h1
    style="
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      /* identical to box height */
      color: var(--text-color-k);
    "
  >
    TOTALE ORDINI DA FIRMARE: {{ selectedOrders.length }}
  </h1>
  <kendo-grid
    class="grid-wrapper"
    [kendoGridBinding]="selectedOrders"
    [rowHeight]="12"
    [height]="650"
    [pageSize]="pageSize"
    scrollable="virtual"
    [sortable]="true"
  >
    <kendo-grid-column
      [width]="50"
      title="Seleziona"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <i
          class="bx bx-check-circle bx-md"
          *ngIf="dataItem.op_checked === false"
          (click)="chooseItem(dataItem.indexNo, $event)"
          style="cursor: pointer"
        ></i>
        <i
          class="bx bxs-check-circle bx-md"
          *ngIf="dataItem.op_checked === true"
          (click)="chooseItem(dataItem.indexNo, $event)"
          style="cursor: pointer; color: green"
        ></i>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="100"
      field="or_order_number"
      title="N° ordine"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.order_number }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="500"
      field="or_or_customer"
      title="Cliente"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.or_customer }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="500"
      field="order_boxes"
      title="N° colli"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'text-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.or_boxes }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="100"
      title="Dettagli"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <button
          kendoGridEditCommand
          rounded="full"
          class="btn-det"
          style="margin: 0; padding: 0; background-color: #017efa !important"
          (click)="gotoDetails(dataItem.order_id)"
        >
          <div class="setDisplayToFlexBlue btn-det">
            <i class="bx bx-info-circle bx-md" style="margin: 0; padding: 0">
            </i>
            <p class="parButton2"></p>
          </div>
        </button>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>

<app-ordini-da-firmare-detaggli
  *ngIf="openModal.isDaFirmare"
  [openedFIRMARE]="openModal.isDaFirmare"
  [order_id]="clickedId"
>
</app-ordini-da-firmare-detaggli>
<app-confirm-complete
  *ngIf="openModal.isConfirmComplete"
  [modalOpened]="openModal.isConfirmComplete"
  [order_id]="clickedIdComplete"
  (modalClosed)="this.completeOrder(clickedIdComplete)"
>
</app-confirm-complete>

<app-bordero-page
  *ngIf="openModal.isBordero"
  [openedBorder]="openModal.isBordero"
  (answerSubmitted)="sign($event)"
>
</app-bordero-page>

<h1 class="page-title">Ordini Parziali</h1>

<div
  class="filterContainer k-display-flex"
  style="flex-direction: row; justify-content: center"
>
  <form
    class="new-search"
    role="search"
    style="
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;
    "
  >
    <label for="search"></label>
    <input
      [(ngModel)]="filterTerm"
      id="search"
      name="search"
      pattern=".*\S.*"
      type="search"
      placeholder="Cerca ordine..."
      autofocus
      required
      (input)="this.onInputChanged($event)"
    />
    <button type="submit" (click)="searchOrders()">
      <i
        class="bx bx-search bx-md"
        style="
          margin: 0;
          padding-top: 10px;
          padding-left: 5px;
          transform: scale(0.8);
        "
      >
      </i>
    </button>
  </form>

  <form
    action=""
    class="search-bar"
    style="
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;
      visibility: hidden !important;
    "
  >
    <button
      class="btn btn-primary msg-btn"
      routerLink="/new-msg"
      style="
        display: flex !important;
        flex-direction: row !important;
        justify-content: center !important;
        visibility: hidden !important;
      "
    >
      + Messaggio
    </button>
  </form>

  <div class="dateContainer k-display-flex justify-content-evenly">
    <div class="col mx-2">
      <h5 class="text-center">DA:</h5>
      <kendo-datepicker
        [(ngModel)]="filters.startingDate"
        class="dropdown-field drop-the-border"
        style="
          background: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 45px;
        "
      ></kendo-datepicker>
    </div>
    <div class="col">
      <h5 class="text-center">A:</h5>
      <kendo-datepicker
        [(ngModel)]="filters.endDate"
        class="dropdown-field drop-the-border"
        style="
          background: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 45px;
        "
      ></kendo-datepicker>
    </div>
  </div>
</div>

<hr class="hr-newBlue" />

<div
  class="row k-display-flex justify-content-end"
  style="padding-left: 20px"
>   

    <div style="display:flex; flex-direction:row; justify-content:center;">
      <h2 class="col-header">
        Totale ordini parziali: {{ gridData.length }}
      </h2>   
    </div>

    <div
      *ngIf="loading"
      style="display: flex; flex-direction: column; align-items: center"
    >
      <mat-spinner></mat-spinner>
      <p>Attendi..</p>
    </div>

    <div class="grid-wrapper" *ngIf="!loading">
      <kendo-grid
        [kendoGridBinding]="
          gridDataInifinity
            | byToDate
              : [
                  filters.startingDate,
                  filters.endDate,
                  filters.dateOrginizedBy.value
                ]
        "
        [rowHeight]="36"
        [height]="800"
        scrollable="virtual"
        (scrollBottom)="loadMore()"
        [navigable]="true"
        [sortable]="true"
        class="maxHeight"
        [reorderable]="true"
      >
        <kendo-grid-column
          [width]="100"
          field="or_order_number"
          title="N° Ordine"
          [headerStyle]="{ 'justify-content': 'center' }"
          [style]="{ 'tetx-align': 'center' }"
        >
        </kendo-grid-column>

        <kendo-grid-column
          [width]="390"
          field="or_customer"
          title="Cliente"
          [headerStyle]="{ 'justify-content': 'left' }"
          [style]="{
            display: 'grid',
            'justify-content': 'left',
            'tetx-align': 'left'
          }"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.or_customer }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
          [width]="100"
          [width]="100"
          field="or_articles_number"
          title="N° Articoli"
          [headerStyle]="{ 'justify-content': 'center' }"
          [style]="{ 'tetx-align': 'center' }"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.or_articles_number }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
          [width]="120"
          field="or_order_date"
          title="Data ordine"
          [headerStyle]="{ 'justify-content': 'center' }"
          [style]="{ 'tetx-align': 'center' }"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.or_order_date | date : "dd/MM/yyyy" }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-command-column
          title="Assegna"
          [width]="100"
          [headerStyle]="{ 'justify-content': 'center' }"
          [style]="{ 'tetx-align': 'center' }"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <button
              kendoGridEditCommand
              rounded="full"
              class="btn-det"
              style="margin: 0; padding: 0"
              (click)="gotoDetails(dataItem.or_gamma_id)"
            >
              <div class="setDisplayToFlex">
                <i
                  class="bx bx-edit bx-md"
                  style="margin: 0; padding: 0; transform: scale(0.8)"
                >
                </i>
                <p class="parButton2"></p>
              </div>
            </button>
          </ng-template>
        </kendo-grid-command-column>

        <kendo-grid-command-column
          title="Blocca/Sblocca"
          [width]="120"
          [headerStyle]="{ 'justify-content': 'center' }"
          [style]="{ 'tetx-align': 'center' }"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <button
              kendoGridEditCommand
              rounded="full"
              class="btn-det"
              style="
                margin: 0;
                padding: 0;
                background-color: #017efa !important;
              "
              (click)="block(dataItem)"
              *ngIf="!dataItem?.or_status"
              title="Blocca"
            >
              <div class="setDisplayToFlex btn-blocca">
                <i
                  class="bx bx-lock-open bx-md"
                  style="margin: 0; padding: 0; transform: scale(0.8)"
                >
                </i>
                <p class="parButton2"></p>
              </div>
            </button>
            <button
              kendoGridEditCommand
              rounded="full"
              class="btn-det"
              style="
                margin: 0;
                padding: 0;
                background-color: #017efa !important;
              "
              *ngIf="dataItem?.or_status === 'B'"
              (click)="unblock(dataItem)"
              title="Sblocca"
            >
              <div class="setDisplayToFlex btn-sblocca">
                <i
                  class="bx bx-lock bx-md"
                  style="margin: 0; padding: 0; transform: scale(0.8)"
                >
                </i>
                <p class="parButton2"></p>
              </div>
            </button>
          </ng-template>
        </kendo-grid-command-column>
      </kendo-grid>

      <div *ngIf="loading" class="k-i-loading"></div>
    </div>
</div>

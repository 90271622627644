import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IAccount } from 'src/app/models/IAccount.model';
import { IWsResult } from 'src/app/models/ws_result.model';
import { AccountsService } from 'src/app/services/accounts.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';

@Component({
  selector: 'app-account-list',
  templateUrl: './gestione-account-list.component.html',
  styleUrls: ['./gestione-account-list.component.scss'],
})
export class GestioneAccountListComponent implements OnInit {
  public existingAccounts: IAccount[];
  public searchAccount: IWsResult[] = [];
  public data2: IAccount[] = [];
  public id: string;
  public loading: boolean = false;
  public pageSize = 20;

  searchTerm: string;
  clickedId: string;
  newId: string;
  account: any;
  ac_token: string;

  constructor(
    private accountsService: AccountsService,
    public closeSrv: OpenModalService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.getAccounts();
    // this.searchAccounts();
  }

  getAccounts() {
    this.accountsService.getAccounts().subscribe((accountsData) => {
      this.existingAccounts = accountsData;
      console.debug(this.existingAccounts);
      this.loading = false;
    });
  }

  searchAccounts() {
    this.accountsService
      .searchAccounts(this.searchTerm)
      .subscribe((accountsData: IWsResult) => {
        this.existingAccounts = accountsData.data;
        console.debug('Accounts are: ', this.existingAccounts);
        console.debug('From server: ', accountsData);
      });
  }

  ToAccDetails(account_id: any) {
    this.clickedId = account_id;
    this.closeSrv.openAccPage();
  }

  updateIMG() {
    this.getAccounts();
  }
  
}

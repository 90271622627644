import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationService } from '@progress/kendo-angular-notification';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})

export class ResetPasswordComponent implements OnInit {
  
  public resultText: string="";
  public disabledButton: boolean = false;

  constructor(
    private authServ: AuthService,
    private router: Router,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void { }

  public resetForm: FormGroup = new FormGroup({
    username: new FormControl("", [Validators.required,  Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$')])
  });

  resetPassword(username: object): void {
    this.resultText="";
    this.disabledButton=true;
    this.authServ.resetPassword(this.resetForm.value)
      .subscribe((res: any) => {
        switch(res?.error_message?.msg_code){
          case 0: 
            this.showNotification('La nuova password è stata inviata via email.', 'success');
            Swal.fire({
              icon: 'success',
              title: 'Aggiornata!',
              text: 'Password cambiata con successo!!!',
              timer: 1500,
              timerProgressBar: true,
            });
            break;
          case 12: 
            this.showNotification('Utente non attivo', 'error');
            Swal.fire({
              icon: 'error',
              title: 'Errore!',
              text: 'Utente non attivo!!!',
              timer: 1500,
              timerProgressBar: true,
            });
            break;
          case 13: 
            this.showNotification('Account scaduto', 'error');
            Swal.fire({
              icon: 'error',
              title: 'Errore!',
              text: 'Account scaduto!!!',
              timer: 1500,
              timerProgressBar: true,
            });
            break;
          case 14: 
            this.showNotification('Utente inesistente', 'error');
            Swal.fire({
              icon: 'error',
              title: 'Errore!',
              text: 'Utente inesistente!!!',
              timer: 1500,
              timerProgressBar: true,
            });
            break;
          default: 
            this.showNotification('Si è verificato un problema', 'warning');
            Swal.fire({
              icon: 'warning',
              title: 'Attenzione!',
              text: 'Si è verificato un problema, riprova!!!',
              timer: 1500,
              timerProgressBar: true,
            });
            break;
        }
        this.disabledButton = false;
      });
      Swal.fire({
        icon: 'success',
        title: 'Inviata!',
        text: 'Email di recupero inviata con successo!!!',
        timer: 1500,
        timerProgressBar: true,
      });
  }

  async backtoLogin() {
    await new Promise((res: any) => {
      setTimeout(() => {
        this.router.navigate(['/login']);
        res();
      }, 2500);
    })
  }

  public showNotification(messageText:string, styleType: 'none' | 'success' | 'warning' | 'error' | 'info'): void {
    this.notificationService.show({
      content: messageText,
      hideAfter: 600,
      position: { horizontal: "center", vertical: "top" },
      animation: { type: "fade", duration: 2000 },
      type: { style: styleType, icon: true },
    });
  }
  
}

import { Pipe, PipeTransform } from '@angular/core';
import { IMessageList } from '../models/IMessage.model';

@Pipe({
  name: 'byName',
})

export class ByNamePipe implements PipeTransform {

  transform(value: IMessageList[], args?: string): IMessageList[] {
    const uniqueNames = [];
    console.debug('Value is', value);
    console.debug('Args are', args);
    if (value == undefined && args == null && args.toString() == '')
      return value;
    //if(args.toString() == this.selectedYear )
    //  return value;
    return value.filter((item) => item?.ms_sender_name.indexOf(args) > -1);
    return;
    value.forEach((msg) => {
      if (
        !uniqueNames.includes(msg.ms_sender_name) &&
        typeof msg.ms_sender_name === 'string'
      ) {
        uniqueNames.push(msg.ms_sender_name);
      }
    });
    return uniqueNames;
  }
  
}

<h1 class="page-title">Lista Borderò generati</h1>

<div
  class="filterContainer k-display-flex"
  style="flex-direction: row; justify-content: center"
>
  <form
    *ngIf="orders && chosenCarrier === null"
    class="new-search"
    role="search"
    style="
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;
    "
  >
    <label for="search"></label>
    <input
      [(ngModel)]="filterTerm"
      id="search"
      name="search"
      pattern=".*\S.*"
      type="search"
      placeholder="Cerca ordine..."
      autofocus
      required
      (input)="this.onInputChanged($event)"
    />
    <button type="submit" (click)="searchOrders()">
      <i
        class="bx bx-search bx-md"
        style="
          margin: 0;
          padding-top: 10px;
          padding-left: 5px;
          transform: scale(0.8);
        "
      >
      </i>
    </button>
  </form>

  <form
    action=""
    class="search-bar"
    style="
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;
      visibility: hidden !important;
    "
  >
    <button
      class="btn btn-primary msg-btn"
      routerLink="/new-msg"
      style="
        display: flex !important;
        flex-direction: row !important;
        justify-content: center !important;
        visibility: hidden !important;
      "
    >
      + Messaggio
    </button>
  </form>

  <div class="dateContainer k-display-flex justify-content-evenly">
    <div class="col mx-2">
      <h5 class="text-center">DA:</h5>
      <kendo-datepicker
        [(ngModel)]="filters.startingDate"
        class="dropdown-field drop-the-border"
        style="
          background: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 45px;
        "
        >{{ filters.startingDate | date }}</kendo-datepicker
      >
    </div>
    <div class="col">
      <h5 class="text-center">A:</h5>
      <kendo-datepicker
        [(ngModel)]="filters.endDate"
        class="dropdown-field drop-the-border"
        style="
          background: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 45px;
        "
      ></kendo-datepicker>
    </div>
  </div>
</div>

<hr class="hr-newBlue" />

<div
  class="grid-wrapper"
  style="padding-left: 20px"
  *ngIf="orders && chosenCarrier === null"
>
  <div style="display: flex; flex-direction: row; justify-content: center">
    <h2 class="col-header">Totale borderò generati: {{ orders.length }}</h2>
  </div>

  <div
    *ngIf="loading"
    style="display: flex; flex-direction: column; align-items: center"
  >
    <mat-spinner></mat-spinner>
    <p>Attendi..</p>
  </div>

  <kendo-grid
    [kendoGridBinding]="orders"
    [data]="
      orders
        | byCustomerName : carrier_id
        | byDatePipe : date
        | byToDate
          : [
              filters.startingDate,
              filters.endDate,
              filters.dateOrginizedBy.value
            ]
    "
    [skip]="skip"
    [rowHeight]="36"
    [height]="750"
    [pageSize]="pageSize"
    scrollable="virtual"
    [navigable]="true"
    [sortable]="true"
    [reorderable]="true"
  >
    <kendo-grid-column
      [width]="100"
      field="or_order_number"
      title="N° spedizioni complessive"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.order_number }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="200"
      field="box_number"
      title="N° colli"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.box_number }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="200"
      field="or_carrier_desc"
      title="Corriere"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.bo_courier_name }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-command-column
      title="Borderò"
      [width]="80"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <a href="{{ dataItem?.bo_pdf_link }}" target="_blank">
          <i class="bx bxs-file-pdf bx-md"></i>
        </a>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
</div>

<app-borderò-dettagli
  *ngIf="openModal.isBordero"
  [opened]="openModal.isBordero"
  [order_id]="clickedOrder"
>
</app-borderò-dettagli>

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})

export class ScreenLoginGuard  {

  constructor(
    private authService: AuthService, 
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.isScreen.pipe(
      filter((val) => val !== null), 
      take(1), 
      map((isScreen) => {
        if (isScreen) {
          this.router.navigateByUrl('/warehouseorders', { replaceUrl: true });
          console.log('Navigated');
        } 
        else {
          return true;
        }
        return false;
      })
    );
  }
  
}

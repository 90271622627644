<div class="k-overlay dark-background" *ngIf="openedDoc"></div>

<kendo-dialog
  *ngIf="openedDoc"
  (close)="close()"
  class="k-form"
  [minHeight]="500"
  [minWidth]="250"
  [width]="750"
  [maxWidth]="950"
  style="left: 6%"
>
  <div
    style="
      display: flex;
      flex-direction: row;
      justify-content: end;
      margin-top: -40px;
      z-index: 1000;
      position: fixed;
      margin-left: -55px;
    "
  >
    <button
      (click)="close()"
      class="btn btn3"
      rounded="full"
      style="
        background-color: #e90000;
        color: #ffffff;
        width: 35px !important;
        height: 35px !important;
      "
    >
      <i
        class="bx bx-x bx-sm posRelBox2"
        style="
          color: #ffffff;
          bottom: 10px;
          position: relative !important;
          left: -80% !important;
          top: -1px !important;
        "
      ></i>
    </button>
  </div>
  <div>
    <h1 class="page-title">Seleziona i documenti</h1>

    <textarea
      style="
        width: 100%; 
        background-color: white; 
        color: black
      "
      placeholder="Type something here"
      class="unbreakable-link background-green color-white"
      *ngIf="isLinkToDDT"
      readonly="readonly"
      unselectable="on"
      [value]="linkToDDT"
    ></textarea>

    <div class="max-height-scroll-250 mt-3">
      <div class="Stampa k-display-flex justify-content-center">
        <button
            class="btn btn3"
            rounded="full"
            style="
              background-color: #f38200f3;
              color: #ffffff;
              justify-content: center !important;
              display: flex;
              width: 192px !important;
              height: 50px !important;
            "
            (click)="printDDTStamp()"
            *ngIf="!isLinkToDDT"
            [disabled]="clickedOnce"
          >
            <i
              class="bx bx-printer bx-sm posRelBox2"
              style="
                color: #ffffff;
                right: 6px;
                bottom: 10px;
                position: relative;
                margin-top: 4px;
              "
            ></i>
            <p class="posRelBox2">STAMPA</p>
          </button>
      </div>
      <div *ngIf="documents?.length > 0"></div>
      <div *ngFor="let item of documents; index as i" class="arrange-filter">
        <button
          size="auto"
          class="col centered text-left k-display-flex justify-content-center new-text-2"
          (click)="checkDocument(i)"
          [class.hr-newGreen]="item.checked"
          style="cursor: pointer"
        >
          <p
            style="
              padding-right: 20px; 
              margin-bottom: 0px !important
            "
            [class.whiteTextColorDocs]="item.checked"
          >
            {{ item.indexNo + "." }}
          </p>
          <p
            style="margin-bottom: 0px !important"
            [class.whiteTextColorDocs]="item.checked"
          >
            {{ item.td_document }}
          </p>
        </button>
      </div>
      <div class="text-buttons my-5">
        <div class="button-left">
          <button
            class="btn btn3"
            rounded="full"
            style="
              background: rgb(0, 146, 73);
              color: #ffffff;
              justify-content: center !important;
              display: flex;
              width: 192px !important;
              height: 50px !important;
            "
            (click)="saveStep('documents')"
            [disabled]="!allDocumentsChecked()"
          >
            <i
              class="bx bx-check-circle bx-sm posRelBox2"
              style="
                color: #ffffff;
                right: 6px;
                bottom: 10px;
                position: relative;
                margin-top: 4px;
              "
            ></i>
            <p class="posRelBox2">SALVA</p>
          </button>
        </div>
      </div>
    </div>
  </div>
</kendo-dialog>

<kendo-dialog
  title=""
  *ngIf="isList && products"
  (close)="close()"
  maxHeight="100%"
  maxWidth="100%"
  class="k-form"
  style="left: 6%"
>
  <h1 class="page-title">Prodotti dell'ordine</h1>
  <div class="container">
    <div
      class="productContainer d-flex align-items-center pb-3"
      *ngFor="let product of products; let i = index"
    >
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          value=""
          id="flexCheckDefault"
        />
      </div>
      <div class="pStyleType px-2">{{ product.op_description }}</div>

      <div class="px-2">
        <i
          class="bx bxs-trash bx-md"
          style="color: red"
          (click)="deleteProduct(product)"
        ></i>
      </div>
      <div class="pStyleType px-2">{{ product.op_qta + " pz" }}</div>
    </div>
  </div>

  <br /><br />
  <div 
    class="text-buttons" 
    style="
      position: relative; 
      top: 20px
    "
    >
    <div class="button-right">
      <button
        class="btn btn3"
        rounded="full"
        style="
          background-color: #8d8d8d;
          color: #ffffff;
          justify-content: center !important;
          display: flex;
          width: 192px !important;
          height: 42px !important;
        "
        (click)="close()"
      >
        <i
          class="bx bx-left-arrow-circle bx-sm posRelBox2"
          style="
            color: #ffffff; 
            right: 6px; 
            bottom: 10px; 
            position: relative
          "
        ></i>
        <p class="posRelBox2">INDIETRO</p>
      </button>
    </div>
  </div>
</kendo-dialog>

import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IAccount } from 'src/app/models/IAccount.model';
import { IWsResult } from 'src/app/models/ws_result.model';
import { AccountsService } from 'src/app/services/accounts.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';

@Component({
  selector: 'app-gestione-account-edit',
  templateUrl: './gestione-account-edit.component.html',
  styleUrls: ['./gestione-account-edit.component.scss'],
})

export class GestioneAccountEditComponent implements OnInit {
  
  @Input() account_id: string = '-1';

  public dataSaved = false;
  public isEditable = false;
  public opened = true;
  public account: IAccount;
  public accountTypesSelect: {
    ac_typo: string;
    ac_number: number;
  }[] = [
    { 
      ac_typo: 'Admin', 
      ac_number: 1 
    },
    {
      ac_typo: 'User',
      ac_number: 2,
    },
  ];

  accountEditForm!: FormGroup;
  id: number;
  today: Date = new Date();


  constructor(
    private accountSrv: AccountsService,
    private route: ActivatedRoute,
    public closeSrv: OpenModalService,
    private formBuilder: FormBuilder,
    private router: Router
  ) { }  

  public close() {
    this.opened = false;
  }

  public goBackFunction() {
    this.router.navigate(['/accounts']);
    this.closeSrv.close();
  }

  open() {
    this.opened = true;
  }

  ngOnInit(): void {
    this.accountEditForm = this.formBuilder.group({
      ac_first_name: [''],
      ac_last_name: [''],
      ac_email: [''],
      ac_expiration_date: new Date(),
      ac_phone: [''],
      ac_mobile: [''],
      ac_active: [''],
      ac_note: [''],
      tab_user_type_desc: ['']
    });
    //return;
    this.accountSrv
      .getAccount(this.account_id)
      .subscribe((accountData: IWsResult) => {
        this.account = accountData.data;
        this.accountEditForm.setValue({
          ac_first_name: this.account.ac_first_name,
          ac_last_name: this.account.ac_last_name,
          ac_email: this.account.ac_email,
          // ac_user_type_code: this.account.ac_user_type_cod,
          ac_expiration_date: this.account.ac_expiration_date,
          ac_phone: this.account.ac_phone,
          ac_mobile: this.account.ac_mobile,
          // ac_url_foto: this.account.ac_url_foto,
          ac_active: this.account.ac_active,
          tab_user_type_desc: this.account.tab_user_type_desc,
          ac_note: this.account.ac_note,
        });
      });
      return;
  }

  onAccountTypeSelected(value: any) {
    console.log('Selected account type: ', value);
    this.account.ac_user_type_cod = value.toString();
    console.log('Account type is: ', this.account.ac_user_type_cod);
  }
  
}

<div class="k-overlay dark-background" *ngIf="modalOpened"></div>

<div *ngIf="modalOpened" class="k-form dark-background">
  <kendo-dialog
    title=""
    *ngIf="modalOpened"
    (close)="onNoClick()"
    class="k-form"
    style="
      margin-left: 40px; 
      width: 30%; 
      left: 35%;
    "
  >
    <div class="row k-display-flex justify-content-center">
      <h1
        style="
          font-family: 'Lato';
          font-style: normal;
          font-weight: 700;
          font-size: 28px;
          line-height: normal;
          margin: 20px;
          text-align: center;
        "
      >
        Questa azione è irreversibile. Sei sicuro di voler concludere l'ordine?
      </h1>

      <div class="row">
        <div
          class="col-12"
          style="
            display: flex; 
            flex-direction: row; 
            justify-content: center; 
            padding-left: 60px;
          "
        >
          <div class="col-6">
            <button
              class="btn btn-primary"
              (click)="onYesClick()"
              style="
                margin-bottom: 20px !important;
                width: 120px;
                height: 40px;
                text-align: center;
              "
            >
              <div class="arrangeButtons">
                <i class="bx bx-check bx-sm"></i>
                <p>CONCLUDI</p>
              </div>
            </button>
          </div>        
          <div class="col-6">
            <button
              class="btn btn-primary"
              (click)="onNoClick()"
              style="
                margin-bottom: 20px !important;
                width: 120px;
                height: 40px;
                text-align: center;
                background-color: red;
                border: 1px solid red;
              "
            >
              <div class="arrangeButtons">
                <i class="bx bx-block bx-sm"></i>
                <p>ANNULLA</p>
              </div>
            </button>
          </div>          
        </div>
      </div>
    </div>
  </kendo-dialog>
</div>
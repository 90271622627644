import { Pipe, PipeTransform } from '@angular/core';
import { isAfter, isBefore, parseISO } from 'date-fns';

@Pipe({
  name: 'byToDate'
})

export class ByToDatePipe implements PipeTransform {

  transform(value: any[], ...args: any): any[] {
    args = args[0];
    if (value == undefined || args == null || args[0].toString() == '')
      return value;
    switch(args[2]){
      case "or_order_number":
        return value.filter((item) =>
        isAfter(parseISO(item?.or_order_date), args[0]) &&
        isBefore(parseISO(item?.or_order_date), args[1]));
      case "ms_datetime":
        return value.filter((item) =>
        isAfter(parseISO(item?.ms_datetime), args[0]) &&
        isBefore(parseISO(item?.ms_datetime), args[1]));
    }
    return value;
  }

}

export namespace Categories {

  export interface ICategory {
    category_id: number;
    type: string;
    head_family: string;
    sub_family: string;
    head_group: string;
    sub_group: string;
    description: string;
  }

  export interface ISubGroup {
    sub_group: string;
    description: string;
  }

  export interface IGroup {
    addSubgroups(category: ICategory);
    head_group: string;
    description: string;

    subgroups: ISubGroup[];
  }

  export interface ISubFamily {
    addGroup(category: ICategory);
    sub_family: string;
    description: string;
    groups: IGroup[];
  }

  export interface IFamily {
    addSubfamily(element: ICategory);
    head_family: string;
    description: string;
    subFamilies: ISubFamily[];
  }

  export class Group implements IGroup {
    head_group: string;
    description: string;
    subgroups: ISubGroup[] = null;

    addSubgroups(category: ICategory) {
      if (!category.sub_family) 
        return;
      if (!this.subgroups) 
        this.subgroups = [];
      if (!this.subgroups.some((item) => item.sub_group == category.sub_group)) {
        this.subgroups.push(category);
      }
    }

    filterSubgroups(filterTerm: string, group: Group) {
      if (!group.subgroups) 
        return;
      console.log('in subgroup category', group.head_group, group.subgroups);
      group.subgroups = group.subgroups.filter(
        (item: ISubGroup) =>
          item?.description?.toLowerCase().indexOf(filterTerm) > -1
      );
      let areFiltered = false;
      if (group.subgroups && group.subgroups.length > 0) 
        areFiltered = true;
      console.log('in subgroup return', group.head_group, {
        areSubgroups: areFiltered,
        filtered: group.subgroups,
      });
      return { areSubgroups: areFiltered, filtered: group.subgroups };
    }

    constructor(category: ICategory) {
      this.head_group = category.head_group;
      this.description = category.description;
      if (!category.sub_group) return;
      this.subgroups = [];
    }
  }

  export class SubFamily implements ISubFamily {
    sub_family: string;
    description: string;
    groups: IGroup[] = null;

    addGroup(category: ICategory) {
      if (!category.head_group) 
        return;
      if (!this.groups) 
        this.groups = [];
      if (!this.groups.some((item) => item.head_group == category.head_group)) {
        this.groups.push(new Group(category));
      } 
      else
        this.groups[
          this.groups.findIndex(
            (item) => item.head_group == category.head_group
          )
        ].addSubgroups(category);
    }

    filterGroups(filterTerm: string, subFamily: SubFamily) {
      if (!subFamily.groups) 
        return;
      console.log(
        'in filterGroups category',
        subFamily.description,
        subFamily.groups
      );
      subFamily.groups = subFamily.groups.filter((item: Group) => {
        let fileteredSub = item.filterSubgroups(filterTerm, item);
        console.log(
          'in filterGroups fileteredSub',
          subFamily.description,
          fileteredSub
        );
        if (!fileteredSub || !fileteredSub?.areSubgroups)
          return item?.description?.toLowerCase().indexOf(filterTerm) > -1;
        item.subgroups = fileteredSub.filtered;
        return item;
      });
      let areFiltered = false;
      if (subFamily.groups && subFamily.groups.length > 0) 
        areFiltered = true;
      console.log('in filterGroups return', subFamily.description, {
        areGroups: areFiltered,
        filtered: subFamily.groups,
      });
      return { areGroups: areFiltered, filtered: subFamily.groups };
    }

    constructor(category: ICategory) {
      this.sub_family = category.sub_family;
      this.description = category.description;
      if (!category.head_group) return;
      this.groups = [];
    }
  }

  export class Family implements IFamily {
    head_family: string;
    description: string;
    subFamilies: ISubFamily[] = null;

    addSubfamily(category: ICategory) {
      if (!category.sub_family) 
        return;
      if (!this.subFamilies) 
        this.subFamilies = [];
      if (!this.subFamilies.some((item) => item.sub_family == category.sub_family)) {
        this.subFamilies.push(new SubFamily(category));
      } 
      else
        this.subFamilies[
          this.subFamilies.findIndex(
            (item) => item.sub_family == category.sub_family
          )
        ].addGroup(category);
    }

    filterSubfamily(filterTerm: string, family: Family) {
      if (!family.subFamilies) 
        return;
      console.log(
        'in filterSubfamily category',
        family.description,
        family.subFamilies
      );
      family.subFamilies = family.subFamilies.filter((item: SubFamily) => {
        let fileteredSub = item.filterGroups(filterTerm, item);
        console.log(
          'in filterSubfamily fileteredSub',
          family.description,
          fileteredSub
        );
        if (!fileteredSub || !fileteredSub?.areGroups)
          return item?.description?.toLowerCase().indexOf(filterTerm) > -1;
        item.groups = fileteredSub.filtered;
        return item;
      });
      let areFiltered = false;
      if (family.subFamilies && family.subFamilies.length > 0)
        areFiltered = true;
      console.log('in filterSubfamily return', family.description, {
        areSubfamilies: areFiltered,
        filtered: family.subFamilies,
      });
      return { areSubfamilies: areFiltered, filtered: family.subFamilies };
    }

    constructor(category: ICategory) {
      this.head_family = category.head_family;
      this.description = category.description;
      if (!category.sub_family) return;
      this.subFamilies = [];
    }
  }

  export class Category implements ICategory {
    category_id: number;
    type: string;
    head_family: string;
    sub_family: string;
    head_group: string;
    sub_group: string;
    description: string;
    dFamily?: string;
    dSubFamily?: string;
    dGroup?: string;
    dSubGroup?: string;

    constructor(category: ICategory | Category) {
      if (!category) return;
      this.category_id = category.category_id;
      this.type = category.type;
      this.head_family = category.head_family;
      this.sub_family = category.sub_family;
      this.head_group = category.head_group;
      this.sub_group = category.sub_group;
      this.description = category.description;
    }
  }

  export class Categories extends Category {
    categories: ICategory[];
    categoriesComplete: ICategory[] = [];
    families: Family[] = [];

    constructor(categories: ICategory[]) {
      super(null);
      if (!categories) 
        return;
      this.categories = categories;
      categories.forEach((element) => {
        if (
          !this.families.some((item) => item.head_family == element.head_family)
        ) {
          this.families.push(new Family(element));
        } 
        else
          this.families[
            this.families.findIndex(
              (item) => item.head_family == element.head_family
            )
          ].addSubfamily(element);
      });
      this.families?.forEach((family) => {
        let e1 = new Category(null);
        e1.dFamily = family?.description;
        e1.head_family = family?.head_family;
        if (family?.subFamilies === undefined || !family?.subFamilies) {
          this.categoriesComplete.push(e1);
          return;
        }
        family?.subFamilies?.forEach((subf) => {
          let e2 = new Category(e1);
          e2.dSubFamily = subf?.description;
          e2.sub_family = subf?.sub_family;
          if (subf?.groups === undefined || !subf?.groups) {
            this.categoriesComplete.push(e2);
            return;
          }
          subf?.groups?.forEach((group) => {
            let e3 = new Category(e2);
            e3.dGroup = group?.description;
            e3.head_group = group?.head_group;
            if (group?.subgroups === undefined || !group?.subgroups) {
              this.categoriesComplete.push(e3);
              return;
            }
            group?.subgroups?.forEach((sg) => {
              let e4 = new Category(e3);
              e4.dSubGroup = sg?.description;
              e4.sub_group = sg?.sub_group;
              this.categoriesComplete.push(e4);
            });
          });
        });
      });
      console.log(
        'this.categoriesComplete constructor',
        this.categoriesComplete
      );
    }
  }

  export function filterFamily(filterTerm: string, families: Family[]) {
    if (!families) 
      return;
    console.log('in filterFamily category', families);
    filterTerm = filterTerm?.toLowerCase();
    let filtered: Family[] = families.filter((item: Family) => {
      let fileteredSub = item.filterSubfamily(filterTerm, item);
      console.log('in filterFamily fileteredSub', fileteredSub);
      if (!fileteredSub || !fileteredSub?.areSubfamilies)
        return item?.description?.toLowerCase().indexOf(filterTerm) > -1;
      item.subFamilies = fileteredSub.filtered;
      return item;
    });
    let areFiltered = false;
    if (filtered && filtered.length > 0) 
      areFiltered = true;
    let fCategoriesComplete: ICategory[] = [];
    if (areFiltered) {
      filtered?.forEach((family) => {
        let e1 = new Category(null);
        e1.dFamily = family?.description;
        e1.head_family = family?.head_family;
        if (family?.subFamilies === undefined || !family?.subFamilies) {
          fCategoriesComplete.push(e1);
          return;
        }
        family?.subFamilies?.forEach((subf) => {
          let e2 = new Category(e1);
          e2.dSubFamily = subf?.description;
          e2.sub_family = subf?.sub_family;
          if (subf?.groups === undefined || !subf?.groups) {
            fCategoriesComplete.push(e2);
            return;
          }
          subf?.groups?.forEach((group) => {
            let e3 = new Category(e2);
            e3.dGroup = group?.description;
            e3.head_group = group?.head_group;
            if (group?.subgroups === undefined || !group?.subgroups) {
              fCategoriesComplete.push(e3);
              return;
            }
            group?.subgroups?.forEach((sg) => {
              let e4 = new Category(e3);
              e4.dSubGroup = sg?.description;
              e4.sub_group = sg?.sub_group;
              fCategoriesComplete.push(e4);
            });
          });
        });
      });
    }
    console.log('this.categoriesComplete filterd', fCategoriesComplete);
    console.log('in filterFamily return', {
      areFamilies: areFiltered,
      filtered,
      fCategoriesComplete,
    });
    return { areFamilies: areFiltered, filtered };
  }
  
}

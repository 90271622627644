import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { AutoLoginGuard } from './guards/auto-login.guard';

import { DashboardComponent } from './components/dashboard/dashboard.component';
import { OrdiniPMComponent } from './components/ordini-pm/ordini-pm.component';
import { LoginComponent } from './components/login/login.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { AccessoFunzionariListComponent } from './components/accesso-funzionari/accesso-funzionari-list/accesso-funzionari-list.component';
import { BaseAgenteComponent } from './components/shared/base-agente/base-agente.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ProfileComponent } from './components/profile/profile.component';
import { AssegnaOrdineDettagliComponent } from './components/assegna-ordine/assegna-ordine-dettagli/assegna-ordine-dettagli.component';
import { AssegnaOrdineListComponent } from './components/assegna-ordine/assegna-ordine-list/assegna-ordine-list.component';
import { MessaggiListComponent } from './components/messaggi/messaggi-list/messaggi-list.component';
import { MessaggiDettagliComponent } from './components/messaggi/messaggi-dettagli/messaggi-dettagli.component';
import { MessaggiNewComponent } from './components/messaggi/messaggi-new/messaggi-new.component';
import { GestioneProdottiListComponent } from './components/gestione-prodotti/gestione-prodotti-list/gestione-prodotti-list.component';
import { GestioneProdottiDettagliComponent } from './components/gestione-prodotti/gestione-prodotti-dettagli/gestione-prodotti-dettagli.component';
import { GestioneAccountListComponent } from './components/gestione-account/gestione-account-list/gestione-account-list.component';
import { GestioneAccountDettagliComponent } from './components/gestione-account/gestione-account-dettagli/gestione-account-dettagli.component';
import { GestioneAccountNewComponent } from './components/gestione-account/gestione-account-new/gestione-account-new.component';
import { GestioneAccountEditComponent } from './components/gestione-account/gestion-account-edit/gestione-account-edit.component';
import { StatusSpedizioniComponent } from './components/status-spedizioni/status-spedizioni.component';
import { StoricoOrdiniComponent } from './components/storico-ordini/storico-ordini.component';
import { StatisticheComponent } from './components/statistiche/statistiche.component';
import { ControlloQualityComponent } from './components/controllo-quality/controllo-quality.component';
import { ControlloDettagliComponent } from './components/controllo-quality/preparazione-ordini-detaggli/controllo-dettagli.component';
import { ControlloBollaComponent } from './components/controllo-quality/preparazione-ordini-detaggli/controllo-bolla/controllo-bolla.component';
import { ControlloDDTComponent } from './components/controllo-quality/preparazione-ordini-detaggli/controllo-ddt/controllo-ddt.component';
import { ControlloQuantitàComponent } from './components/controllo-quality/preparazione-ordini-detaggli/controllo-quantità/controllo-quantità.component';
import { ControlloProdottiComponent } from './components/controllo-quality/preparazione-ordini-detaggli/controllo-prodotti/copntrollo-prodotti.component';
import { StoricoOrdiniDettagliComponent } from './components/storico-ordini/storico-ordini-dettagli/storico-ordini-dettagli.component';
import { StoricoOrdiniEditComponent } from './components/storico-ordini/storico-ordini-edit/storico-ordini-edit.component';
import { GestioneProdottiNewComponent } from './components/gestione-prodotti/gestione-prodotti-new/gestione-prodotti-new.component';
import { StoricoOrdiniNewComponent } from './components/storico-ordini/storico-ordini-new/storico-ordini-new.component';
import { PreparazioneOrdiniComponent } from './components/preparazione-ordini/preparazione-ordini.component';
import { PreparazioneOrdiniDetaggliComponent } from './components/preparazione-ordini/preparazione-ordini-detaggli/preparazione-ordini-detaggli.component';
import { CollectionComponent } from './components/preparazione-ordini/collection/collection.component';
import { PrepareOrderComponent } from './components/preparazione-ordini/prepare-order/prepare-order.component';
import { OrdiniAgenteListComponent } from './components/accesso-funzionari/ordini-agente-list/ordini-agente-list.component';
import { InfoAgenteComponent } from './components/accesso-funzionari/info-agente/info-agente.component';
import { ScreenLoginGuard } from './guards/screen.guard';
import { WarehouseOrdersComponent } from './components/preparazione-ordini/prepare-order/warehouse-orders/warehouse-orders.component';
import { OrdiniDaFirmareComponent } from './components/ordini-da-firmare/ordini-da-firmare.component';
import { ProductDetailComponent } from './components/gestione-prodotti/product-detail/product-detail.component';
import { ConfirmProductsComponent } from './components/preparazione-ordini/preparazione-ordini-detaggli/confirm-products/confirm-products.component';
import { SettingsComponent } from './components/settings/settings.component';
import { AssegnaValoreComponent } from './components/settings/assegna-valore/assegna-valore.component';
import { BorderòComponent } from './components/borderò/borderò.component';
import { BorderòDettagliComponent } from './components/borderò/borderò-dettagli/borderò-dettagli.component';
import { OrdiniParzialiListComponent } from './components/ordini-parziali/ordini-parziali-list/ordini-parziali-list.component';
import { OrdiniParzialiDettagliComponent } from './components/ordini-parziali/ordini-parziali-dettagli/ordini-parziali-dettagli.component';
import { OrderscmDetComponent } from './components/shared/base-orderscm-list/orderscm-det/orderscm-det.component';
import { ErrorComponent } from '@progress/kendo-angular-inputs';
import { ChatComponent } from './components/messaggi/chat/chat.component';
//import { ChatComponent } from '@progress/kendo-angular-conversational-ui';

const routes: Routes = [
  // {
  //   path: '*',
  //   redirectTo: 'login',
  //   pathMatch: 'full',
  // },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },

  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: DashboardComponent,
      },
    ],
  },

  {
    path: 'pm-orders',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: OrdiniPMComponent,
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            component: OrderscmDetComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'chat',
    canActivate: [AuthGuard],
    component: ChatComponent,
  },
  {
    path: 'partial-orders',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: OrdiniParzialiListComponent,
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            component: OrdiniParzialiDettagliComponent,
          },
        ],
      },
    ],
  },

  {
    path: 'messages',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: MessaggiListComponent,
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            component: MessaggiDettagliComponent,
          },
        ],
      },
    ],
  },

  {
    path: 'new-msg',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: MessaggiNewComponent,
      },
    ],
  },

  {
    path: 'assign-order',
    canActivate: [AuthGuard, ScreenLoginGuard],
    children: [
      {
        path: '',
        component: AssegnaOrdineListComponent,
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            component: AssegnaOrdineDettagliComponent,
          },
        ],
      },
    ],
  },

  {
    path: 'assign-value',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: AssegnaValoreComponent,
      },
    ],
  },

  {
    path: 'confirm-products',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ConfirmProductsComponent,
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            component: ProductDetailComponent,
          },
        ],
      },
    ],
  },

  {
    path: 'products',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: GestioneProdottiListComponent,
      },

      {
        path: ':productId',
        children: [
          {
            path: '',
            component: ProductDetailComponent,
          },
        ],
      },
      {
        path: ':family',
        children: [
          {
            path: '',
            component: GestioneProdottiDettagliComponent,
          },
        ],
      },
      {
        path: ':family/:subfamily',
        children: [
          {
            path: '',
            component: GestioneProdottiDettagliComponent,
          },
        ],
      },
      {
        path: ':family/:subfamily/:group',
        children: [
          {
            path: '',
            component: GestioneProdottiDettagliComponent,
          },
        ],
      },
      {
        path: ':family/:subfamily/:group/:subgroup',
        children: [
          {
            path: '',
            component: GestioneProdottiDettagliComponent,
          },
          {
            path: ':id',
            children: [
              {
                path: '',
                component: ProductDetailComponent,
              },
            ],
          },
        ],
      },
    ],
  },

  {
    path: 'products',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: GestioneProdottiListComponent,
      },
    ],
  },

  {
    path: 'new-product',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: GestioneProdottiNewComponent,
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            component: GestioneAccountDettagliComponent,
          },
        ],
      },
    ],
  },

  {
    path: 'agents',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: AccessoFunzionariListComponent,
      },
    ],
  },

  {
    path: 'agentsorders',
    children: [
      {
        path: '',
        component: OrdiniAgenteListComponent,
      },
    ],
  },

  {
    path: 'info-agents',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: InfoAgenteComponent,
      },
    ],
  },

  {
    path: 'accounts',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: GestioneAccountListComponent,
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            component: GestioneAccountDettagliComponent,
          },
        ],
      },
    ],
  },

  {
    path: 'edit-account',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: GestioneAccountEditComponent,
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            component: GestioneAccountDettagliComponent,
          },
        ],
      },
    ],
  },

  {
    path: 'new-account',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: GestioneAccountNewComponent,
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            component: GestioneAccountDettagliComponent,
          },
        ],
      },
    ],
  },

  {
    path: 'status',
    component: StatusSpedizioniComponent,
    canActivate: [AuthGuard],
  },

  /*{
    path: 'errors',
    component: ErrorComponent,
    canActivate: [AuthGuard],
  },*/

  {
    path: 'history',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: StoricoOrdiniComponent,
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            component: StoricoOrdiniDettagliComponent,
          },
          {
            path: 'errors',
            component: ErrorComponent,
          },
        ],
      },
    ],
  },

  {
    path: 'borderò',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: BorderòComponent,
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            component: BorderòDettagliComponent,
          },
        ],
      },
    ],
  },

  {
    path: 'history-edit',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: StoricoOrdiniEditComponent,
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            component: StoricoOrdiniDettagliComponent,
          },
        ],
      },
    ],
  },

  {
    path: 'stats',
    component: StatisticheComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'firmare',
    component: OrdiniDaFirmareComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'quality',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ControlloQualityComponent,
      },
      /*{
        path: 'dettagli',
        component: ControlloDettagliComponent,
      },*/
      {
        path: ':id',
        children: [
          {
            path: '',
            component: ControlloDettagliComponent,
          },
          {
            path: 'bolla',
            component: ControlloBollaComponent,
          },
          {
            path: 'ddt',
            component: ControlloDDTComponent,
          },
          {
            path: 'quantity',
            component: ControlloQuantitàComponent,
          },
          {
            path: 'prodotti',
            component: ControlloProdottiComponent,
          },
        ],
      },
    ],
  },

  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'prepare-order',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: PreparazioneOrdiniComponent,
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            component: PreparazioneOrdiniDetaggliComponent,
          },
          {
            path: 'prepare-order',
            component: PrepareOrderComponent,
          },
          {
            path: 'collection',
            component: CollectionComponent,
          },
        ],
      },
    ],
  },

  {
    path: 'warehouseorders',
    canActivate: [AuthGuard],

    children: [
      {
        path: '',
        component: WarehouseOrdersComponent,
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            component: PreparazioneOrdiniDetaggliComponent,
          },
          {
            path: 'prepare-order',
            component: PrepareOrderComponent,
          },
          {
            path: 'collection',
            component: CollectionComponent,
          },
        ],
      },
    ],
  },

  {
    path: 'new-order',
    children: [
      {
        path: '',
        component: StoricoOrdiniNewComponent,
      },
    ],
    canActivate: [AuthGuard],
  },

  {
    path: 'base-agente',
    component: BaseAgenteComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AutoLoginGuard],
  },

  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    canActivate: [AutoLoginGuard],
  },

  {
    path: '**',
    pathMatch: 'full',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

<!-- Kendo dialog-->
<kendo-dialog 
  (close)="close('cancel')" 
  *ngIf="opened" 
  [minWidth]="minWidth" 
  [width]="dialogWidth" 
  [maxWidth]="dialogWidth" 
  [maxHeight]="dialogHeight"
  >
  <kendo-dialog-titlebar>Nuovo Agente</kendo-dialog-titlebar>
  <form class="k-form" [formGroup]="addAgentForm">

    <fieldset class="k-form-fieldset">
      <kendo-formfield>
        <kendo-label [for]="ag_url" text='URL'></kendo-label>
        <input formControlName="ag_url" kendoTextBox #ag_url />
      </kendo-formfield>
    </fieldset>

    <div class="row">
      <kendo-formfield class="col-md-6">
        <kendo-label [for]="ag_first_name" text='Nome'></kendo-label>
        <input formControlName="ag_first_name" kendoTextBox #ag_first_name required />
        <kendo-formerror *ngIf="(addAgentForm.get('ag_first_name')?.touched || addAgentForm.get('ag_first_name')?.dirty) && addAgentForm.get('ag_first_name')?.errors">
            Campo obbligatorio!
        </kendo-formerror>
      </kendo-formfield>
      <kendo-formfield class="col-md-6">
        <kendo-label [for]="ag_last_name" text='Cognome'></kendo-label>
        <input formControlName="ag_last_name" kendoTextBox #ag_last_name required />
        <kendo-formerror *ngIf="(addAgentForm.get('ag_last_name')?.touched|| addAgentForm.get('ag_last_name')?.dirty) && addAgentForm.get('ag_last_name')?.errors">
            Campo obbligatorio!
        </kendo-formerror>
      </kendo-formfield>
    </div>

    <div class="row">
      <kendo-formfield class="col-md-6">
        <kendo-label [for]="ag_email" text="Email"></kendo-label>
        <input formControlName="ag_email" kendoTextBox #ag_email required />
        <kendo-formerror *ngIf="(addAgentForm.get('ag_email')?.touched || addAgentForm.get('ag_email')?.dirty) && addAgentForm.get('ag_email')?.errors">
            Email non valida!
        </kendo-formerror>
      </kendo-formfield>
      <kendo-formfield class="col-md-6">
        <kendo-label [for]="ag_mobile" text='Mobile'></kendo-label>
        <input formControlName="ag_mobile" kendoTextBox #ag_mobile />
      </kendo-formfield>
    </div>

    <kendo-formfield>
      <kendo-label [for]="ag_phone" text='Telefono'></kendo-label>
      <input formControlName="ag_phone" kendoTextBox #ag_phone />
    </kendo-formfield>

    <div class="k-form-buttons">
      <button kendoButton themeColor="primary" rounded="full" (click)="addAgent(addAgentForm)" [disabled]="addAgentForm.invalid">Aggiungi</button>
      <button kendoButton rounded="full" (click)="clearForm()">ANNULLA</button>
    </div>
  </form>
</kendo-dialog>

<h1 class="page-title">Nuovo Agente</h1>
<form class="k-form" [formGroup]="addAgentForm">

  <fieldset class="k-form-fieldset">
    <kendo-formfield>
      <kendo-label [for]="ag_url" text='URL'></kendo-label>
      <input formControlName="ag_url" kendoTextBox #ag_url />
    </kendo-formfield>
  </fieldset>

  <div class="row">
    <kendo-formfield class="col-md-6">
      <kendo-label [for]="ag_first_name" text='Nome'></kendo-label>
      <input formControlName="ag_first_name" kendoTextBox #ag_first_name required />
      <kendo-formerror *ngIf="(addAgentForm.get('ag_first_name')?.touched || addAgentForm.get('ag_first_name')?.dirty) && addAgentForm.get('ag_first_name')?.errors">
          Campo obbligatorio!
      </kendo-formerror>
    </kendo-formfield>
    <kendo-formfield class="col-md-6">
      <kendo-label [for]="ag_last_name" text='Cognome'></kendo-label>
      <input formControlName="ag_last_name" kendoTextBox #ag_last_name required />
      <kendo-formerror *ngIf="(addAgentForm.get('ag_last_name')?.touched|| addAgentForm.get('ag_last_name')?.dirty) && addAgentForm.get('ag_last_name')?.errors">
          Campo obbligatorio!
      </kendo-formerror>
    </kendo-formfield>
  </div>

  <div class="row">
    <kendo-formfield class="col-md-6">
      <kendo-label [for]="ag_email" text="Email"></kendo-label>
      <input formControlName="ag_email" kendoTextBox #ag_email required />
      <kendo-formerror *ngIf="(addAgentForm.get('ag_email')?.touched || addAgentForm.get('ag_email')?.dirty) && addAgentForm.get('ag_email')?.errors">
          Email non valida!</kendo-formerror>
    </kendo-formfield>
    <kendo-formfield class="col-md-6">
      <kendo-label [for]="ag_mobile" text='Mobile'></kendo-label>
      <input formControlName="ag_mobile" kendoTextBox #ag_mobile />
    </kendo-formfield>
  </div>

  <kendo-formfield>
    <kendo-label [for]="ag_phone" text='Telefono'></kendo-label>
      <input formControlName="ag_phone" kendoTextBox #ag_phone />
    </kendo-formfield>

  <div class="k-form-buttons">
    <button kendoButton themeColor="primary" rounded="full" (click)="addAgent(addAgentForm)" [disabled]="addAgentForm.invalid">AGGIUNGI</button>
    <button kendoButton rounded="full" (click)="clearForm()">ANNULLA</button>
  </div>

</form>

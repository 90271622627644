import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ByNamePipe } from './by-name.pipe';

@NgModule({
  exports: [ByNamePipe],
  declarations: [ByNamePipe],
  imports: [CommonModule],
})

export class ByNamePipeModule {

  static forRoot() {
    return {
      ngModule: ByNamePipeModule,
      providers: [],
    };
  }
  
}

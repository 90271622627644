import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class SetAgentService {

  private existAgent: any;

  constructor() { }

  setExistAgent(agent: any, callback: () => void) {
    this.existAgent = agent;
    console.log('Agent name received:', agent);
    callback();
  }

  getExistAgent() {
    return this.existAgent;
  }
  
}

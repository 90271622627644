import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AgentOrders, IAgent, IAgentOrders } from '../models/IAgent.model';
import { IWsResult } from '../models/ws_result.model';
import { ErrorHandlerService } from './shared/error-handler.service';

@Injectable({
  providedIn: 'root',
})

export class AgentsService {

  url: string = environment.urlServer;

  constructor(
    private http: HttpClient,
    private errorHandlerServ: ErrorHandlerService
  ) { }

  getAgents(): Observable<IWsResult> {
    return this.http
      .get<IWsResult>(`${this.url}/get_agents/`)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getAgents')
        )
      );
  }

  getAgent(id: string): Observable<IWsResult> {
    return this.http
      .get<IWsResult>(`${this.url}/get_agent/` + id)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getClosedOrders')
        )
      );
  }

  searchAgentOrders(
    searchString: string = '',
    agent_token: string
  ): Observable<IWsResult> {
    return this.http
      .get<IWsResult>(`${this.url}/get_agent_orders/`, {
        headers: {
          search: `${searchString}`,
          token: agent_token,
        },
        params: { token: agent_token },
      })
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getAgentOrders/search')
        )
      );
  }

  getAgentOrders(agent_token: string): Observable<AgentOrders[]> {
    console.debug('Token is', agent_token);
    return this.http
      .get<IWsResult>(`${this.url}/get_agent_orders`, {
        headers: {
          token: agent_token,
        },
        params: { token: agent_token },
      })
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getAgentOrders')
        )
      )
      .pipe(
        map((result: IWsResult) => {
          if (result.success == true)
            return result.data.map((item) => {
              let agents = new IAgentOrders(item);
              return agents;
            });
          else return [];
        })
      );
  }

  getAgentOrder(
    order_id: string,
    agent_id: string,
    agent_token: string
  ): Observable<IWsResult> {
    console.debug('Token is', agent_token);
    return this.http
      .get<IWsResult>(`${this.url}/get_agent_order/${order_id}`, {
        headers: { token: agent_token },
      })
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getAgentOrderByID')
        )
      );
  }

  getAgentOrderNew(
    order_id: string,
    agent_id: string,
    agent_token: string
  ): Observable<IWsResult> {
    console.debug('Token is', agent_token);
    return this.http
      .get<IWsResult>(`${this.url}/get_agent_new_order/${order_id}`, {
        headers: { token: agent_token },
      })
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getAgentOrderByID')
        )
      );
  }

  updateAgent(agent: IAgent): Observable<IWsResult> {
    return this.http
      .post<IWsResult>(`${this.url}/put_agent/`, agent)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('putAgent')
        )
      );
  }

  searchAgents(searchString: string = ''): Observable<IWsResult> {
    return this.http
      .get<IWsResult>(`${this.url}/get_agents`, {
        headers: { search: `${searchString}` },
      })
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getAgents/search')
        )
      );
  }

  generateURL(agent_id: string) {
    return this.http
      .post<IWsResult>(`${this.url}/generate_url/`, { agent_id })
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('generateUrl')
        )
      );
  }

  deleteURL(agent_id: string) {
    return this.http
      .post<IWsResult>(`${this.url}/delete_url/`, { agent_id })
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('generateUrl')
        )
      );
  }
  
}

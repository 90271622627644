import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAccount } from '../models/IAccount.model';
import { IUserAvatar } from '../models/IUser.model';
import { IWsResult } from '../models/ws_result.model';
import { ErrorHandlerService } from './shared/error-handler.service';

@Injectable({
  providedIn: 'root',
})

export class AccountsService {

  url: string = environment.urlServer;
  facAvatar: IUserAvatar;

  constructor(
    private http: HttpClient,
    private errorHandlerServ: ErrorHandlerService
  )  { }

  getAccount(id: string): Observable<IWsResult> {
    return this.http
      .get<IWsResult>(`${this.url}/get_account/` + id)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getAccount')
        )
      );
  }

  getAccounts(searchString: string = ''): Observable<IAccount[]> {
    return this.http
      .get<IWsResult>(`${this.url}/get_accounts/`, {
        headers: { search: `${searchString}` },
      })
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getAccounts')
        )
      )
      .pipe(
        map((result: IWsResult) => result.data)
      );
  }

  searchAccounts(searchString: string = ''): Observable<IWsResult> {
    return this.http
      .get<IWsResult>(`${this.url}/get_accounts/`, {
        headers: { search: `${searchString}` },
      })
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getAccounts')
        )
      );
  }

  saveAvatar(user: IUserAvatar) {
    console.debug('UserAvatar', user);
    return this.http.post<IWsResult>(`${this.url}/put_avatar`, user);
  }

  saveAccount(user: IAccount) {
    return this.http
      .post<IWsResult>(`${this.url}/save_account/`, user)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getAccount')
        )
      );
  }

  deleteAccount(account_id: string) {
    return this.http
      .post<IWsResult>(`${this.url}/del_account`, { account_id })
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('deleteAccount')
        )
      );
  }
  
}

<div 
  class="text-center d-flex justify-content-center"
  style="margin-top: -5px;"
>
  <p class="alert alert-error" *ngIf="this.invalidLogin">
    Errore in fase di registrazione!
  </p>

  <div class="polygon1"></div>
  <div class="polygon2"></div>

  <form class="k-form" [formGroup]="loginForm" autocomplete="on">
    <fieldset class="k-form-fieldset">
      <h1 class="log-title">Login</h1>
      <h2 class="effect-title">
        Effetua il login per iniziare ad utilizzare Diakonix
      </h2>
      <div class="row d-flex justify-content-center">
        <kendo-formfield class="col-md-6">
          <kendo-label [for]="email" text="Email"></kendo-label>
          <kendo-textbox
            formControlName="email"
            rounded="full"
            [clearButton]="true"
            type="email"
            #email
            required
            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-z]{2,4}$"
            min-length="6"
          ></kendo-textbox>
          <kendo-formerror
            *ngIf="
              (loginForm.get('email')?.touched ||
                loginForm.get('email')?.dirty) &&
              loginForm.get('email')?.errors
            "
          >
            Email mancante/invalida!
          </kendo-formerror>
        </kendo-formfield>
      </div>
      <div class="row d-flex justify-content-center">
        <kendo-formfield class="col-md-6">
          <kendo-label [for]="password" text="Password"></kendo-label>
          <kendo-textbox
            formControlName="password"
            #password
            required
            rounded="full"
            [clearButton]="true"
          >
            <ng-template kendoTextBoxSuffixTemplate>
              <button
                kendoButton
                look="clear"
                icon="eye"
                (click)="toggleVisibility()"
              ></button>
            </ng-template>
          </kendo-textbox>
          <kendo-formerror>Password obbligatoria!</kendo-formerror>
        </kendo-formfield>
        <a class="forgot-pwd" routerLink="/reset-password">Password dimenticata?</a>
      </div>

      <kendo-formfield>
        <div class="k-checkbox-wrap">
          <input
            #keepLogged
            type="checkbox"
            kendoCheckBox
            formControlName="keepLogged"
          />
          <kendo-label
            [for]="keepLogged"
            class="k-checkbox-label"
            text="Ricordami"
          ></kendo-label>
        </div>
      </kendo-formfield>

      <div class="k-form-buttons my-4 d-flex justify-content-center">
        <div class="button-left">
          <button
            [disabled]="loginForm.invalid && this.invalidLogin"
            class="btn btn3"
            rounded="full"
            style="
              background-color: #017efa;
              color: #ffffff;
              justify-content: center !important;
              display: flex;
              width: 192px !important;
              height: 45px !important;
            "
            (click)="login(loginForm.value)"
          >    
            <p class="posRelBox2">ACCEDI</p>
          </button>
        </div>
        <div class="button-right">
          <button
            class="btn btn3"
            rounded="full"
            style="
              background-color: #8d8d8d;
              color: #ffffff;
              justify-content: center !important;
              display: flex;
              width: 192px !important;
              height: 45px !important;
            "
            (click)="clearForm()"
          >    
            <p class="posRelBox2">ANNULLA</p>
          </button>
        </div>        
      </div>      
    </fieldset>
    <div class="footer">
      Made with love by Connecta | All rights reserved ©2023
    </div>
  </form>   
</div>

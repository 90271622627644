import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ByMsCarrierPipe } from './by-ms-carrier.pipe';

@NgModule({
  exports: [ByMsCarrierPipe],
  declarations: [ByMsCarrierPipe],
  imports: [CommonModule],
})

export class ByMsCarrierModule {

  static forRoot() {
    return {
      ngModule: ByMsCarrierModule,
      providers: [],
    };
  }

}

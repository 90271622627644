import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})

export class SharedDataService {
    
    private totalOrdersSource = new BehaviorSubject<number>(0);
    totalOrders$ = this.totalOrdersSource.asObservable();

    setTotalOrders(totalOrders: number) {
        this.totalOrdersSource.next(totalOrders);
    }
}
import { formatDate } from '@angular/common';
import { parseISO } from 'date-fns';
import { Image } from './image';
import { Status } from './status';
import {
  faSignature,
  faTruck,
  faTruckFast,
  faTruckRampBox,
} from '@fortawesome/free-solid-svg-icons';
import { ProgressBarCustom } from './progressBarColor';

export class Helpers {
  static adminMessageId: number = -99;

  static getDate(datefrom): string {
    return formatDate(parseISO(datefrom), 'dd/MM/yy', 'it-IT');
  }

  static getTime(timeFrom): string {
    return formatDate(parseISO(timeFrom), 'HH:mm', 'it-IT');
  }

  static base64Length: number = 19;
  static base64PNGLength: number = 3;

  static iconFree: Image.IImage = {
    icon: 'checkmark-circle',
    label: 'Libero',
    status: Status.StatusesUser.assigned,
    class: 'color-green',
    bgClass: 'background-green',
  };

  static iconUnavailable: Image.IImage = {
    icon: 'ban',
    label: 'Prenotato',
    status: -1,
    class: 'color-red',
    bgClass: 'background-unavailable-item',
  };

  static iconTaskWorking: Image.IImage = {
    icon: 'timer',
    label: 'In lavorazione',
    status:
      Status.StatusesUser.step1 |
      Status.StatusesUser.step2 |
      Status.StatusesUser.step3 |
      Status.StatusesUser.step4,
    class: 'color-orange',
    bgClass: 'background-orange',
  };

  static iconTaskSuspended: Image.IImage = {
    icon: 'pause-circle',
    label: 'Sospeso',
    status: Status.StatusesUser.suspended,
    class: 'color-red',
    bgClass: 'background-red',
  };

  static iconTaskBlocked: Image.IImage = {
    icon: 'lock',
    label: 'Bloccato',
    status: Status.StatusesUser.blocked,
    class: 'color-white',
    bgClass: 'background-black',
  };

  static iconTaskUnBlocked: Image.IImage = {
    icon: 'lock-open',
    label: 'Sbloccato',
    status: Status.StatusesUser.unblocked,
    class: 'color-black',
    bgClass: 'background-orange',
  };

  static iconTaskComplete: Image.IImage = {
    icon: 'checkmark-circle',
    label: 'Completato',
    status: Status.StatusesUser.step5,
    class: 'color-inactive',
    bgClass: 'white-transparency',
  };

  static iconSignature: Image.IImage = {
    faElement: faSignature,
    icon: null, //'pencil-outline',
    label: 'Firma richesta',
    status: Status.StatusesUser.pronto,
    class: 'color-red',
    bgClass: '',
  };

  static iconTaskNew: Image.IImage = {
    icon: 'play-circle',
    label: 'Inizia',
    status: Status.StatusesUser.intiated,
    class: 'color-green',
    bgClass: 'background-green',
  };

  static iconMail: Image.IImage = {
    icon: '',
    label: 'Letto',
    status: 1,
    class: 'color-green',
    bgClass: '',
  };

  static iconMailOpen: Image.IImage = {
    icon: 'alert-circle',
    label: 'Da Leggere',
    status: -1,
    class: 'color-red',
    bgClass: '',
  };

  static iconShowProducts: Image.IImage = {
    icon: 'albums',
    label: 'Pulsante mostra prodotti',
    status: -1,
    class: 'color-light-blue',
    bgClass: '',
  };

  static iconCarrierDelivered: Image.IImage = {
    faElement: faTruck,
    icon: null,
    label: 'Consegnato',
    status: 6 | Status.StatusesUser.atClient,
    class: 'color-green',
    bgClass: '',
  };

  static iconInfoReceived: Image.IImage = {
    faElement: faTruckRampBox,
    icon: null,
    label: 'Informazioni ricevute',
    status: 1,
    class: 'color-light-blue',
    bgClass: '',
  };

  static iconInTransit: Image.IImage = {
    faElement: faTruck,
    icon: null,
    label: 'In transito',
    status: 2 | Status.StatusesUser.shipped,
    class: 'color-light-blue',
    bgClass: '',
  };

  static iconOutForDelevery: Image.IImage = {
    faElement: faTruckFast,
    icon: null,
    label: 'In consegna',
    status: 6 | Status.StatusesUser.inTranzit,
    class: 'color-light-blue',
    bgClass: '',
  };

  static iconMissedDelivery: Image.IImage = {
    faElement: faTruck,
    icon: null,
    label: 'Consegna persa',
    status: 6,
    class: 'color-red',
    bgClass: '',
  };

  static iconException: Image.IImage = {
    icon: 'close-circle',
    label: 'Eccezione',
    status: 6,
    class: 'color-red',
    bgClass: '',
  };

  static iconAttention: Image.IImage = {
    icon: 'alert',
    label: 'Ordini parziali',
    status: -1,
    class: 'color-white',
    bgClass: 'background-white',
  };

  static checkCBoxForNull(
    ts_box_height: number,
    ts_box_length: number,
    ts_box_depth: number,
    ts_box_weight: number
  ): boolean {
    if (!ts_box_height) return true;
    if (!ts_box_length) return true;
    if (!ts_box_depth) return true;
    if (!ts_box_weight) return true;
    return false;
  }

  static progresBar0p: ProgressBarCustom.IProgresBar = {
    perc: 0,
    cssClass: 'k-selected color-red width-0',
  };

  static progresBar20p: ProgressBarCustom.IProgresBar = {
    perc: 0.2,
    cssClass: 'k-selected color-red width-20',
  };

  static progresBar40p: ProgressBarCustom.IProgresBar = {
    perc: 0.7,
    cssClass: 'k-selected color-light-orange width-70',
  };

  static progresBar60p: ProgressBarCustom.IProgresBar = {
    perc: 0.6,
    cssClass: 'k-selected color-yellow width-60',
  };

  static progresBar80p: ProgressBarCustom.IProgresBar = {
    perc: 0.8,
    cssClass: 'k-selected color-light-yellow width-80',
  };

  static progresBar100p: ProgressBarCustom.IProgresBar = {
    perc: 1,
    cssClass: ' k-selected color-green width-100',
  };
}

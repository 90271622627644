import { Injectable } from '@angular/core';
import { Helpers } from '../models/helpters';

@Injectable({
  providedIn: 'root',
})

export class IconsService {
  
  constructor() { }

  changeIconToWorking(order) {
    order.icon = Helpers.iconTaskWorking;
  }

  changeIconToSuspended(order) {
    order.icon = Helpers.iconTaskSuspended;
  }

  changeIconToBlocked(order) {
    order.icon = Helpers.iconTaskBlocked;
  }

  changeIconToUnBlocked(order) {
    order.icon = Helpers.iconTaskUnBlocked;
  }

  changeIconToCompleted(order) {
    order.icon = Helpers.iconTaskComplete;
  }

  changeIconToFree(order) {
    order.icon = order.iconFree;
    order.status = order.iconFree.status;
  }

  changeIconToUnavailable(order) {
    order.icon = order.iconUnavailable;
    order.status = order.iconUnavailable.status;
  }

  changeIconToRead(notification) {
    notification.icon = notification.iconMail;
    notification.status = notification.iconMail.status;
  }
  
}

import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Products } from 'src/app/models/Product.model';
import { ProductsService } from 'src/app/services/products.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-gestione-prodotti-new',
  templateUrl: './gestione-prodotti-new.component.html',
  styleUrls: ['./gestione-prodotti-new.component.scss'],
})

export class GestioneProdottiNewComponent implements OnInit {

  @Input() product_id: string;
  @Input() openedProdPos: boolean;

  public product: Products.IProductDetails[] = [];

  addProductForm!: FormGroup;
  pr_positions: any;
  opened = true;  

  constructor(
    private router: Router,
    private closeServ: OpenModalService,
    private productServ: ProductsService
  ) { }

  close() {
    this.closeServ.close();
    this.opened = !this.opened;
    this.router.navigate(['/products']);
  }

  ngOnInit(): void {
    this.addProductDialog();
    console.log('Product is my boy: ', this.product_id);
  }

  addProductDialog() {
    this.addProductForm = new FormGroup({
      product_id: new FormControl(''),
      pr_code: new FormControl('', [
        Validators.required,
        Validators.maxLength(35),
      ]),
      pr_description: new FormControl('', [Validators.required]),
      pr_b64_image_position: new FormControl(''),
      pr_image_position: new FormControl(''),
    });
  }

  addProduct(addProductForm: any): void {
    let pr_positions: Products.IProductPosition = {
      product_id: '',
      pr_description: '',
      pr_code: '',
      pr_lane: '',
      pr_shelf: '',
      pr_position: '',
      product_position_id: '',
      pr_qty: 0
    };
    let product: Products.IProductDetails = {
      product_id: this.addProductForm.value.product_id,
      pr_code: this.addProductForm.value.pr_code,
      pr_description: this.addProductForm.value.pr_description,
      family_desc: '',
      sub_family_desc: '',
      group_desc: '',
      sub_group_desc: '',
      pr_stock: 0,
      pr_weight: 0,
      pr_volume: '',
      pr_price: '',
      pr_positions: [pr_positions],
      pr_qty: 0,
      product_position_id: '',
      pr_b64_image: '',
      pr_b64_position_image: '',
    };
    console.log(product);
    this.productServ.addProduct(product).subscribe((productsData) => {
      this.product = productsData.data;
    });
    this.addProductForm.markAllAsTouched();
    this.close();
    Swal.fire({
      icon: 'success',
      title: 'Aggiunto!',
      text: 'Prodotto aggiunto con successo!!!',
      timer: 1500,
      timerProgressBar: true,
    });
  }

  clearForm(): void {
    this.addProductForm.reset();
  }

}

<div
  *ngIf="
    authServ.isUser.value && !authServ.isScreen.value && !isAgentsOrdersRoute()
  "
>
  <app-sidebar *ngIf="!isWarehouseOrdersRoute()"></app-sidebar>
  <!--<app-right-sidebar></app-right-sidebar>-->
</div>

<div
  [class.home-closed]="!sidebarService.isOpen && !isAgentsOrdersRoute()"
  [class.home]="sidebarService.isOpen && !isAgentsOrdersRoute()"
  [class.AgentHome]="isAgentsOrdersRoute()"
  [class.footer-fullwidth]="!authServ.isUser.value"
  [class.setWarehouseWidthScreen]="authServ.isScreen.value"
>
  <app-header></app-header>
  <section>
    <div
      class="main-content"
      [class.main-content]="!isMessageRoute()"
      style="margin-right: 30px"
    >
      <router-outlet></router-outlet>
    </div>
  </section>
  <!-- <app-footer class="mt-auto"></app-footer> -->
</div>

<div *ngIf="opened || storicoOn" class="k-form dark-background">
  <kendo-dialog
    class="k-form"
    (close)="goBackFunction()"
    [formGroup]="productsDetailForm"
    style="left: 6%"
  >
    <div 
      style="
        display: flex;
        flex-direction: row;
        justify-content: end;
        margin-top: -15px;
        z-index: 1000;
        position: fixed;
        margin-left: -35px;
      "
    >
      <button
        (click)="goBackFunction()"
        class="btn btn3"
        rounded="full"
        style="
          background-color: #e90000;
          color: #ffffff;
          width: 35px !important;
          height: 35px !important;
        "
      >    
        <i
          class="bx bx-x bx-sm posRelBox2"
          style="
            color: #ffffff; 
            bottom: 10px; 
            position: relative !important;
            left: -80% !important;
            top: -1px !important;
          "
        ></i>
      </button> 
    </div>
    <h1 class="page-title">Gestione Prodotto</h1>
    <form class="k-form dialog-form" [formGroup]="productsDetailForm">
      <div class="innerContainer">
        <div class="row">
          <div class="col">
            <div class="col-md">
              <div class="col-md">
                <kendo-formfield>
                  <kendo-label
                    [for]="pr_description"
                    text="N°/Nome prodotto"
                  ></kendo-label>
                  <kendo-textbox
                    formControlName="pr_description"
                    kendoTextBox
                    #pr_description
                    [readonly]="!isEditable"
                    rounded="full"
                  >
                  </kendo-textbox>
                </kendo-formfield>
              </div>
              
              <div class="row">
                <kendo-formfield class="col-md-4">
                  <kendo-label 
                    [for]="family_desc" 
                    text="Marca"
                  ></kendo-label>
                  <kendo-textbox
                    formControlName="family_desc"
                    kendoTextBox
                    #family_desc
                    [readonly]="!isEditable"
                    rounded="full"
                  >
                  </kendo-textbox>
                </kendo-formfield>
                <kendo-formfield class="col-md-4">
                  <kendo-label
                    [for]="sub_family_desc"
                    text="Modello"
                  ></kendo-label>
                  <kendo-textbox
                    formControlName="sub_family_desc"
                    kendoTextBox
                    #sub_family_desc
                    [readonly]="!isEditable"
                    rounded="full"
                  >
                  </kendo-textbox>
                </kendo-formfield>
                <kendo-formfield class="col-md-4">
                  <kendo-label
                    [for]="family_desc"
                    text="Categoria"
                  ></kendo-label>
                  <kendo-textbox
                    formControlName="family_desc"
                    kendoTextBox
                    #family_desc
                    [readonly]="!isEditable"
                    rounded="full"
                  >
                  </kendo-textbox>
                </kendo-formfield>
              </div>

              <div class="row">
                <kendo-formfield class="col-md-4">
                  <kendo-label 
                    [for]="pr_lane" 
                    text="Corsia"
                  ></kendo-label>
                  <kendo-textbox
                    formControlName="pr_lane"
                    kendoTextBox
                    #pr_lane
                    [readonly]="!isEditable"
                    rounded="full"
                  >
                  </kendo-textbox>
                </kendo-formfield>
                <kendo-formfield class="col-md-4">
                  <kendo-label 
                    [for]="pr_shelf" 
                    text="Ripiano"
                  ></kendo-label>
                  <kendo-textbox
                    formControlName="pr_shelf"
                    kendoTextBox
                    #pr_shelf
                    [readonly]="!isEditable"
                    rounded="full"
                  >
                  </kendo-textbox>
                </kendo-formfield>
                <kendo-formfield class="col-md-4">
                  <kendo-label 
                    [for]="isEditable" 
                    text="Scaffale"
                  ></kendo-label>
                  <kendo-textbox
                    formControlName="pr_position"
                    kendoTextBox
                    #pr_position
                    [readonly]="!isEditable"
                    rounded="full"
                  >
                  </kendo-textbox>
                </kendo-formfield>
              </div>

              <div class="row">
                <kendo-formfield class="col-md-6">
                  <kendo-label
                    [for]="isEditable"
                    text="Quantità magazzino"
                  ></kendo-label>
                  <kendo-textbox
                    formControlName="pr_stock"
                    kendoTextBox
                    #pr_stock
                    [readonly]="!isEditable"
                    rounded="full"
                  >
                  </kendo-textbox>
                </kendo-formfield>

                <kendo-formfield class="col-md-6">
                  <kendo-label 
                    [for]="pr_weight" 
                    text="Peso"
                  ></kendo-label>
                  <kendo-textbox
                    formControlName="pr_weight"
                    kendoTextBox
                    #pr_weight
                    [readonly]="!isEditable"
                    rounded="full"
                  >
                  </kendo-textbox>
                </kendo-formfield>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </kendo-dialog>
</div>

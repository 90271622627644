export namespace Priority {

  export interface IPriority {
    tab_priority_order_cod: number;
    tab_priority_order_desc: string;
    tab_priority_order_color: string;
    class: string;
  }

  export class Priority implements IPriority {
    tab_priority_order_cod: number;
    tab_priority_order_desc: string;
    tab_priority_order_color: string;
    class: string = '';

    constructor(
      code,
      description,
      color,
      setColor = false,
      partial: boolean = false
    ) {
      this.tab_priority_order_cod = code;
      this.tab_priority_order_desc = description;
      this.tab_priority_order_color = color;

      switch (code) {
        case 1:
          this.class = 'high-priority';
          if (setColor)
            document.body.style.setProperty(
              '--app-color-high',
              this.tab_priority_order_color
            );
          if (partial) this.class = 'partial-order-high';
          break;
        case 2:
          this.class = 'medium-priority';
          //if(setColor)document.body.style.setProperty('--app-color-medium', this.tab_priority_order_color);
          if (partial) this.class = 'partial-order-medium';
          break;
        case 3:
          this.class = 'low-priority';
          //if(setColor)document.body.style.setProperty('--app-color-low', this.tab_priority_order_color);
          if (partial) this.class = 'partial-order-low';
          break;
      }
    }
  }
  
}

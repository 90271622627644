import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Categories } from '../models/categories';
import { Orders } from '../models/Orders.model';
import { ProductImage, Products } from '../models/Product.model';
import { IWsResult } from '../models/ws_result.model';
import { ErrorHandlerService } from './shared/error-handler.service';

@Injectable({
  providedIn: 'root',
})

export class ProductsService {

  url: string = environment.urlServer;
  productAvatar: ProductImage;

  constructor(
    private http: HttpClient,
    private errorHandlerServ: ErrorHandlerService
  ) { }

  getProduct(code: string) {
    return this.http
      .get<IWsResult>(`${this.url}/get_product/${code}`)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getProduct')
        )
      )
      .pipe(
        map((result: IWsResult) => {
          if(!result.success)
            return null;
          return result.data
        })
      );
  }

  getProducts() {
    return this.http
      .get<IWsResult>(`${this.url}/get_products/A/A/A/A`)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getProducts')
        )
      )
      .pipe(
        map((result: IWsResult) => result.data)
      );
  }

  addProduct(product: Products.IProductDetails) {
    return this.http
      .post<IWsResult>(`${this.url}/put_product`, product)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getProducts')
        )
      );
  }

  searchProducts(searchString: string = '') {
    return this.http
      .get<IWsResult>(`${this.url}/get_products/A/A/A/A`, {
        headers: { search: `${searchString}` },
      })
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getCategories')
        )
      )
      .pipe(
        map((result: IWsResult) => result.data)
      );
  }

  getCategories(
    searchString: string = null
  ): Observable<Categories.ICategory[]> {
    return this.http
      .get<IWsResult>(
        `${this.url}/get_categories/`,
        searchString
          ? { headers: { search: `${searchString}` } }
          : { headers: {} }
      )
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getCategories')
        )
      )
      .pipe(
        map((result: IWsResult) => {
          if (result.success == true) 
            return result.data;
        })
      );
  }

  getProductCategories(
    family: string = 'A',
    subFamily: string = 'A',
    group: string = 'A',
    subGroup: string = 'A'
  ): Observable<Products.IProductDetail[]> {
    return this.http
      .get<IWsResult>(
        this.url +
          `/get_products/${family.trim()}/${subFamily.trim()}/${group.trim()}/${subGroup.trim()}`
      )
      .pipe(
        map((result: IWsResult) => {
          if (result.success == true) 
            return result.data;
        })
      );
  }

  addProductPosition(product: Products.IProductPosition) {
    return this.http
      .post<IWsResult>(`${this.url}/put_product_position`, product)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('putProductPosition')
        )
      );
  }

  addProductPositionImg(product: ProductImage) {
    return this.http
      .post<IWsResult>(`${this.url}/put_product_position`, { product })
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('putProductPosition')
        )
      );
  }

  deleteProduct(product_position_id: string) {
    return this.http
      .post<IWsResult>(`${this.url}/del_product_position`, {
        product_position_id,
      })
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('deleteProduct')
        )
      );
  }

  searchCategoryProducts(
    searchString: string = null,
    family: string = 'A',
    subFamily: string = 'A',
    group: string = 'A',
    subGroup: string = 'A'
  ): Observable<Products.IProductDetail[]> {
    return this.http
      .get<IWsResult>(
        `${
          this.url
        }/get_products/${family.trim()}/${subFamily.trim()}/${group.trim()}/${subGroup.trim()}`,
        { headers: { search: `${searchString}` } }
      )
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getNewOrders/search')
        )
      )
      .pipe(
        map((result: IWsResult) => {
          if (result.success == true) 
            return result.data;
        })
      );
  }

  setStatus(order_id: number, status: number) {
    console.debug('status no', status);
    return this.http
      .post(`${this.url}/status_order/`, {
        order_id,
        os_order_status_cod: status,
      })
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('setStatus')
        )
      );
  }

  prepareOrder(order: Orders.OrderPrepared) {
    return this.http
      .post(`${this.url}/prepare_order/`, order)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('setStatus')
        )
      );
  }
  
}

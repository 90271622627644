import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  LOCALE_ID,
  isDevMode,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { IonicModule } from '@ionic/angular';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';

import { byDatePipeModule } from './pipes/by-date-pipe.module';
import { ByOrderNumberModule } from './pipes/by-order-number.module';
import { ByCustomerNameModule } from './pipes/by-customer-name/by-customer-name.module';
import { ByNamePipeModule } from './pipes/by-name.module';
import { ByMsCarrierModule } from './pipes/by-ms-carrier.module';
import { ByToDateModule } from './pipes/by-to-date/by-to-date.module';
import { ByPriorityCodeModule } from './pipes/by-priority-code.module';
import { ByStatusCodeModule } from './pipes/by-status-code/by-status-code.module';

import { NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import '@progress/kendo-angular-intl/locales/it/all';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { LabelModule } from '@progress/kendo-angular-label';
import { ToolBarModule } from '@progress/kendo-angular-toolbar';
import {
  InputsModule,
  FormFieldModule,
  TextBoxModule,
  TextAreaModule,
  SwitchModule,
  SliderModule,
} from '@progress/kendo-angular-inputs';
import { GridModule } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { IconsModule } from '@progress/kendo-angular-icons';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { FileSelectModule } from '@progress/kendo-angular-upload';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';

import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { RightSidebarComponent } from './components/right-sidebar/right-sidebar.component';
import { FooterComponent } from './components/footer/footer.component';

import { DashboardComponent } from './components/dashboard/dashboard.component';
import { OrdiniPMComponent } from './components/ordini-pm/ordini-pm.component';
import { LoginComponent } from './components/login/login.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { WarehouseOrdersComponent } from './components/preparazione-ordini/prepare-order/warehouse-orders/warehouse-orders.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

import { ControlloQualityComponent } from './components/controllo-quality/controllo-quality.component';
import { ControlloDettagliComponent } from './components/controllo-quality/preparazione-ordini-detaggli/controllo-dettagli.component';
import { ControlloBollaComponent } from './components/controllo-quality/preparazione-ordini-detaggli/controllo-bolla/controllo-bolla.component';
import { ControlloDDTComponent } from './components/controllo-quality/preparazione-ordini-detaggli/controllo-ddt/controllo-ddt.component';
import { ControlloQuantitàComponent } from './components/controllo-quality/preparazione-ordini-detaggli/controllo-quantità/controllo-quantità.component';
import { ControlloProdottiComponent } from './components/controllo-quality/preparazione-ordini-detaggli/controllo-prodotti/copntrollo-prodotti.component';

import { StatisticheComponent } from './components/statistiche/statistiche.component';
import { SettingsComponent } from './components/settings/settings.component';
import { AssegnaValoreComponent } from './components/settings/assegna-valore/assegna-valore.component';

import { OrderDetailsComponent } from './components/shared/order-details/order-details.component';
import { BaseOrdersListComponent } from './components/shared/base-orders-list/base-orders-list.component';
import { BaseOrderscmListComponent } from './components/shared/base-orderscm-list/base-orderscm-list.component';

import { AssegnaOrdineListComponent } from './components/assegna-ordine/assegna-ordine-list/assegna-ordine-list.component';
import { AssegnaOrdineDettagliComponent } from './components/assegna-ordine/assegna-ordine-dettagli/assegna-ordine-dettagli.component';

import { PreparazioneOrdiniComponent } from './components/preparazione-ordini/preparazione-ordini.component';
import { PreparazioneOrdiniDetaggliComponent } from './components/preparazione-ordini/preparazione-ordini-detaggli/preparazione-ordini-detaggli.component';
import { PrepareOrderComponent } from './components/preparazione-ordini/prepare-order/prepare-order.component';
import { CollectionComponent } from './components/preparazione-ordini/collection/collection.component';
import { ConfirmProductsComponent } from './components/preparazione-ordini/preparazione-ordini-detaggli/confirm-products/confirm-products.component';
import { AddBoxComponent } from './components/shared/order-details/add-box/add-box.component';
import { ConfirmBoxesComponent } from './components/preparazione-ordini/preparazione-ordini-detaggli/confirm-boxes/confirm-boxes.component';
import { BoxDetailsComponent } from './components/shared/order-details/add-box/box-details/box-details.component';
import { ConfirmDocumentsComponent } from './components/preparazione-ordini/preparazione-ordini-detaggli/confirm-documents/confirm-documents.component';
import { ConfirmShipmentComponent } from './components/preparazione-ordini/preparazione-ordini-detaggli/confirm-shipment/confirm-shipment.component';
import { ConfirmCourrierComponent } from './components/preparazione-ordini/preparazione-ordini-detaggli/confirm-courrier/confirm-courrier.component';

import { OrdiniDaFirmareComponent } from './components/ordini-da-firmare/ordini-da-firmare.component';
import { OrdiniDaFirmareDetaggliComponent } from './components/ordini-da-firmare/ordini-da-firmare-detaggli/ordini-da-firmare-detaggli.component';
import { ConfirmCompleteComponent } from './components/ordini-da-firmare/confirm-complete/confirm-complete.component';

import { StoricoOrdiniComponent } from './components/storico-ordini/storico-ordini.component';
import { StoricoOrdiniDettagliComponent } from './components/storico-ordini/storico-ordini-dettagli/storico-ordini-dettagli.component';
import { StoricoOrdiniNewComponent } from './components/storico-ordini/storico-ordini-new/storico-ordini-new.component';
import { StoricoOrdiniEditComponent } from './components/storico-ordini/storico-ordini-edit/storico-ordini-edit.component';

import { BorderòComponent } from './components/borderò/borderò.component';
import { BorderoPageComponent } from './components/ordini-da-firmare/bordero-page/bordero-page.component';
import { BorderòDettagliComponent } from './components/borderò/borderò-dettagli/borderò-dettagli.component';

import { OrdiniParzialiListComponent } from './components/ordini-parziali/ordini-parziali-list/ordini-parziali-list.component';
import { OrdiniParzialiDettagliComponent } from './components/ordini-parziali/ordini-parziali-dettagli/ordini-parziali-dettagli.component';

import { StatusSpedizioniComponent } from './components/status-spedizioni/status-spedizioni.component';
import { StatusSpedizioniDettaggliComponent } from './components/status-spedizioni/status-spedizioni-dettaggli/status-spedizioni-dettaggli.component';

import { OrdiniAgenteListComponent } from './components/accesso-funzionari/ordini-agente-list/ordini-agente-list.component';
import { BaseAgenteComponent } from './components/shared/base-agente/base-agente.component';
import { AccessoFunzionariDettagliComponent } from './components/accesso-funzionari/accesso-funzionari-dettagli/accesso-funzionari-dettagli.component';
import { AccessoFunzionariListComponent } from './components/accesso-funzionari/accesso-funzionari-list/accesso-funzionari-list.component';
import { AccessoFunzionariTrackingComponent } from './components/accesso-funzionari/accesso-funzionari-tracking/accesso-funzionari-tracking.component';
import { OrdiniAgenteDettaggliComponent } from './components/accesso-funzionari/ordini-agente-dettaggli/ordini-agente-dettaggli.component';
import { InfoAgenteComponent } from './components/accesso-funzionari/info-agente/info-agente.component';

import { GestioneAccountListComponent } from './components/gestione-account/gestione-account-list/gestione-account-list.component';
import { GestioneAccountDettagliComponent } from './components/gestione-account/gestione-account-dettagli/gestione-account-dettagli.component';
import { GestioneAccountNewComponent } from './components/gestione-account/gestione-account-new/gestione-account-new.component';
import { GestioneAccountEditComponent } from './components/gestione-account/gestion-account-edit/gestione-account-edit.component';
import { UploadComponentComponent } from './components/gestione-account/gestione-account-dettagli/uploadcomponent/upload-component/upload-component.component';

import { MessaggiListComponent } from './components/messaggi/messaggi-list/messaggi-list.component';
import { MessaggiDettagliComponent } from './components/messaggi/messaggi-dettagli/messaggi-dettagli.component';
import { MessaggiNewComponent } from './components/messaggi/messaggi-new/messaggi-new.component';
import { ChatComponent } from './components/messaggi/chat/chat.component';

import { GestioneProdottiListComponent } from './components/gestione-prodotti/gestione-prodotti-list/gestione-prodotti-list.component';
import { GestioneProdottiDettagliComponent } from './components/gestione-prodotti/gestione-prodotti-dettagli/gestione-prodotti-dettagli.component';
import { GestioneProdottiNewComponent } from './components/gestione-prodotti/gestione-prodotti-new/gestione-prodotti-new.component';
import { ProductDetailComponent } from './components/gestione-prodotti/product-detail/product-detail.component';
import { ProductPositionComponent } from './components/gestione-prodotti/product-position/product-position.component';

import { OrderscmDetComponent } from './components/shared/base-orderscm-list/orderscm-det/orderscm-det.component';
import { ProductDetailModalComponent } from './components/gestione-prodotti/product-detail-modal/product-detail-modal.component';
import { ProductDetailsListComponent } from './components/shared/order-details/product-details-list/product-details-list.component';
import { ConfirmCancelComponent } from './components/preparazione-ordini/confirm-cancel/confirm-cancel.component';
import { PopoverWarningComponent } from './components/preparazione-ordini/preparazione-ordini-detaggli/popover-warning/popover-warning.component';
import { ErroriComponent } from './components/storico-ordini/storico-ordini-dettagli/errori/errori.component';
import { ModifyErrorComponent } from './components/storico-ordini/storico-ordini-dettagli/errori/modify-error/modify-error.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ChangeOperatorComponent } from './components/preparazione-ordini/change-operator/change-operator.component';

//import { ChatModule } from '@progress/kendo-angular-conversational-ui';
/*import { ChartsModule } from 'ng2-charts';
import * as Chart from 'chart.js';*/
declare global {
  interface Window {
    WonderPush?: any;
  }
}
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    DashboardComponent,
    OrdiniPMComponent,
    LoginComponent,
    FooterComponent,
    PageNotFoundComponent,
    BaseAgenteComponent,
    ResetPasswordComponent,
    ProfileComponent,
    BaseOrdersListComponent,
    AssegnaOrdineListComponent,
    RightSidebarComponent,
    OrderDetailsComponent,
    BaseOrderscmListComponent,
    MessaggiListComponent,
    MessaggiDettagliComponent,
    MessaggiNewComponent,
    StatusSpedizioniComponent,
    StoricoOrdiniComponent,
    StatisticheComponent,
    ControlloQualityComponent,
    ControlloDettagliComponent,
    ControlloBollaComponent,
    ControlloDDTComponent,
    ControlloQuantitàComponent,
    ControlloProdottiComponent,
    AccessoFunzionariDettagliComponent,
    AccessoFunzionariListComponent,
    GestioneAccountListComponent,
    GestioneAccountDettagliComponent,
    GestioneAccountNewComponent,
    AssegnaOrdineDettagliComponent,
    UploadComponentComponent,
    StoricoOrdiniDettagliComponent,
    GestioneAccountEditComponent,
    StoricoOrdiniEditComponent,
    GestioneProdottiNewComponent,
    StoricoOrdiniNewComponent,
    ProductDetailComponent,
    GestioneProdottiListComponent,
    GestioneProdottiDettagliComponent,
    ProductPositionComponent,
    PreparazioneOrdiniComponent,
    AddBoxComponent,
    BoxDetailsComponent,
    PreparazioneOrdiniDetaggliComponent,
    PrepareOrderComponent,
    CollectionComponent,
    ConfirmProductsComponent,
    ConfirmBoxesComponent,
    ConfirmDocumentsComponent,
    ConfirmShipmentComponent,
    ConfirmCourrierComponent,
    OrdiniAgenteListComponent,
    WarehouseOrdersComponent,
    StatusSpedizioniDettaggliComponent,
    AccessoFunzionariTrackingComponent,
    OrdiniAgenteDettaggliComponent,
    OrdiniDaFirmareComponent,
    OrdiniDaFirmareDetaggliComponent,
    ConfirmCompleteComponent,
    OrderscmDetComponent,
    BorderoPageComponent,
    ProductDetailModalComponent,
    ProductDetailsListComponent,
    SettingsComponent,
    ConfirmCancelComponent,
    ChangeOperatorComponent,
    PopoverWarningComponent,
    AssegnaValoreComponent,
    ChatComponent,
    BorderòComponent,
    BorderòDettagliComponent,
    OrdiniParzialiListComponent,
    OrdiniParzialiDettagliComponent,
    ErroriComponent,
    InfoAgenteComponent,
    ModifyErrorComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'it-IT',
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    IonicModule.forRoot({
      sanitizerEnabled: true,
      backButtonText: '',
    }),
    FileSelectModule,
    NgbModule,
    NgbPopoverModule,
    BrowserModule,
    AppRoutingModule,
    LayoutModule,
    LabelModule,
    BrowserAnimationsModule,
    ToolBarModule,
    GridModule,
    UploadsModule,
    InputsModule,
    DropDownsModule,
    SliderModule,
    SwitchModule,
    FormFieldModule,
    TextBoxModule,
    TextAreaModule,
    ListViewModule,
    HttpClientModule,
    LabelModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    DialogsModule,
    NotificationModule,
    DateInputsModule,
    IconsModule,
    ByPriorityCodeModule.forRoot(),
    ByOrderNumberModule.forRoot(),
    ByStatusCodeModule.forRoot(),
    ProgressBarModule,
    NgbModule,
    ByNamePipeModule.forRoot(),
    ByCustomerNameModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    byDatePipeModule,
    ByToDateModule,
    ByMsCarrierModule,
    FontAwesomeModule,
    MatProgressSpinnerModule,
    //ChatModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
})
export class AppModule {}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

/*platformBrowserDynamic().bootstrapModule(AppModule, {
  providers: [{
      provide: LOCALE_ID,
      useValue: 'it-IT'
  }]
});*/

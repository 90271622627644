import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { Orders } from 'src/app/models/Orders.model';
import { OrdersService } from 'src/app/services/orders.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';

@Component({
  selector: 'app-base-orderscm-list',
  templateUrl: './base-orderscm-list.component.html',
  styleUrls: ['./base-orderscm-list.component.scss'],
})

export class BaseOrderscmListComponent implements OnInit {

  @Input() data: Orders.ICMOrderList[];
  @Input() days_remaining: Orders.ICMOrderList[]
  @Input() order_date: Orders.ICMOrderList[];
  @Input() filters: any;

  public loading: boolean = false;
  public pageSize = 25;
  public count: number = 20;
  public isSearched: boolean = false;
  public gridView: GridDataResult;
  public skip = 0;

  evasione_date = [];
  clickedID: string;
  startDate: Date = new Date(2021, 0, 1);
  endDate: Date = new Date();
  maxPriority: number;
  dateBy: any[] = [
    {
      text: 'Data ordine',
      value: 'or_order_number',
    },
  ];

  constructor(
    private ordersServ: OrdersService,
    private router: Router,
    public openModal: OpenModalService
  ) { }

  ngOnInit(): void {}

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
  }  

  gotoDetails(order_id: string) {
    this.clickedID = order_id;
    this.openModal.onCmOrder();
  }

  assignPM(order_id: number) {
    this.router.navigate(['/pm-orders', order_id]);
  }

  expirationDate(date: Date): Date {
    const dataScadenza = this.deadline(date);
    dataScadenza.setFullYear(dataScadenza.getFullYear());
    return dataScadenza;
  }

  /*It verifies if the date is expired */
  isExpired(date: Date): boolean {
    const lastDate = this.expirationDate(date);
    const today = new Date();
    return today > lastDate;
  }  

  /* I've divided the progress bar into monthly ranges (cause divide it by days would cause too many ranges..*/
  progress(date: Date): number {
    const finalDate = this.deadline(date);
    const startDate = new Date(date);
    const months = (finalDate.getFullYear() - startDate.getFullYear()) * 12 + (finalDate.getMonth() - startDate.getMonth());
    const progresso = (months / 24) * 100;
    return Math.max(0, Math.min(100, progresso));
  }

  /* It calculate if the month is completed, to set the percentage by month */
  isMonthComplete(date: Date): boolean {
    const startDate = new Date(date);
    const today = new Date();
    const months = (today.getFullYear() - startDate.getFullYear()) * 12 + today.getMonth() - startDate.getMonth();
    const finalDate = new Date(startDate.getFullYear() + 2, startDate.getMonth(), startDate.getDate());
    const lastDate = new Date(finalDate.getTime() + (24 * 60 * 60 * 1000));
    return months <= 24 || today.getTime() <= lastDate.getTime();
  }

  /* It calculates the remaining days to the expiration date */
  daysRemaining(date: any): number {
    const orderDate = new Date(date.or_order_date);
    const daysRemaining = date.or_days_remaining;
    const deadlineDate = new Date(orderDate.getTime() + (Math.abs(daysRemaining) * 24 * 60 * 60 * 1000));
    const today = new Date();
    const remainingDays = Math.ceil((deadlineDate.getTime() - today.getTime()) / (24 * 60 * 60 * 1000));
    return remainingDays > 0 ? remainingDays : 0;
  }

  /* It calculates the expiration date */
  deadline(date: any): Date {
    const orderDate = new Date(date.or_order_date);
    const daysRemaining = date.or_days_remaining;
    const deadlineDate = new Date(orderDate.getTime() + (Math.abs(daysRemaining) * 24 * 60 * 60 * 1000));
    console.log('deadline', deadlineDate)
    console.log('order date:', date.or_order_date);
    console.log('remaining days:', date.or_days_remaining);
    return deadlineDate;
  }

  dayz(dataItem: any): number {
    const orderDate = new Date(dataItem.or_order_date);
    const daysRemaining = dataItem.or_days_remaining;    
    const deadlineDate = new Date(orderDate.getTime() - (daysRemaining * 24 * 60 * 60 * 1000));    
    const today = new Date();
    const remainingDays = Math.floor((deadlineDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
    return !this.isExpired(dataItem.or_order_date) ? Math.abs(remainingDays) : remainingDays;
  }
  
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class PushNotificationDataService {

  public notificationDataSubject = new BehaviorSubject<any>(null);
  public notificationData$ = this.notificationDataSubject.asObservable();

  constructor() { }

  updateNotificationData(data: any) {
    this.notificationDataSubject.next(data);
    console.log('Data is on notification', this.notificationDataSubject);
  }
  
}

import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Orders } from 'src/app/models/Orders.model';
import { AgentOrders } from 'src/app/models/IAgent.model';
import { IWsResult } from 'src/app/models/ws_result.model';
import { AgentsService } from 'src/app/services/agents.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import { filter } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { formatDate } from '@angular/common';
import { SetAgentService } from 'src/app/services/shared/set-agent.service';
import { isAfter, isBefore, parseISO } from 'date-fns';

@Component({
  selector: 'app-ordini-agente-list',
  templateUrl: './ordini-agente-list.component.html',
  encapsulation: ViewEncapsulation.None,
})

export class OrdiniAgenteListComponent implements OnInit {

  constructor(
    private sanitizer: DomSanitizer,
    public closeServ: OpenModalService,
    private agentsServ: AgentsService,
    private route: ActivatedRoute,
    private setAgentServ: SetAgentService
  ) { }

  @Input() agent_token: string;

  private pageOpenedSubject = new Subject<boolean>();
  public dates: any[];
  public searchDate = 'Filtra ordini per data';
  public searchOrderNumber = ' Filtra ordini per n° ordine';
  public order_numbers: any[];

  pageOpened$ = this.pageOpenedSubject.asObservable();
  track_ID: any;
  public orderr: Orders.Order;
  agent_order: AgentOrders[] = [];
  agent: any;
  filterTerm: string | undefined;
  date: string = '';
  customerIndex: number;
  order_number: string = '';
  showAll: string = 'Mostra tutti';
  showAll2: string = 'Mostra tutti';
  or_assigned_date: string;
  or_gamma_id: number;
  clickedId: any;
  orderId: any;
  token_agent: string;
  token: string;
  shipment_id: string;
  agentID: string;
  riferimento: string;
  startDate: Date = new Date(2021, 0, 1);
  endDate: Date = new Date();
  isAccordionOpen = false;
  panelOpenState: boolean = false;

  dateBy: any[] = [
    {
      text: 'Data ordine',
      value: 'or_order_number',
    },
  ];

  filters = {
    dateOrginizedBy: {
      text: 'Data ordine',
      value: 'or_order_number',
    },
    startingDate: this.startDate,
    endDate: this.endDate,
    minDate: new Date(),
    maxDate: new Date(),
  };

  onDateSelect(name: string, i: number) {
    this.date = name;
    console.log('I is: ', i);
    if (name === this.showAll2) 
      this.date = null;
  }

  onOrderNumber(name: string, i: number) {
    this.order_number = name;
    console.log('I is: ', i);
    if (name === this.showAll) 
      this.order_number = null;
  }

  ngOnInit(): void {
    this.agent = this.setAgentServ.getExistAgent();
    console.log('The AGENT now is: ', this.agent);
    this.route.queryParams
      .pipe(filter((params) => params['token']))
      .subscribe((params) => {
        console.log('Params is: ', params['token']);
        this.agent_token = params['token'];
        this.getAgentOrders(this.agent_token);
        this.pageOpenedSubject.next(true);
      });
  }

  onInputChanged(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.validity && inputElement.validity.valueMissing) {
      this.getAgentOrders(this.agent_token);
    }
  }

  fromToFilter(by: string, filters: any) {
    console.log('value changed: ', by);
    console.log('value changed: ', filters);
  }

  // printDDT() {
  //   const link = this.agent_order?;
  //   const a = document.createElement('a');
  //   a.href = link;
  //   a.target = '_blank';
  //   a.click();
  //   console.log('DDT link is: ', link);
  // }
  getAgentOrders(agent_token) {
    this.agentsServ
      .getAgentOrders(agent_token)
      .subscribe((agentsData: AgentOrders[]) => {
        if (agentsData?.length === 0) {
          console.log('Empty array: ');
        } else {
          this.agent_order = agentsData;
          this.agent_order.forEach((element) => {
            switch (this.filters.dateOrginizedBy.value) {
              case 'or_order_number':
                if (
                  isBefore(
                    parseISO(element.or_order_date),
                    this.filters.minDate
                  )
                ) {
                  this.filters.startingDate = parseISO(element.or_order_date);
                  this.filters.minDate = parseISO(element.or_order_date);
                }
                if (
                  isAfter(parseISO(element.or_order_date), this.filters.maxDate)
                ) {
                  this.filters.startingDate = parseISO(element.or_order_date);
                  this.filters.maxDate = parseISO(element.or_order_date);
                }
                break;
            }
          });
          this.agent_order.forEach((data) => {
            data.or_closing_date === '0001-01-01T00:00:00'
              ? ''
              : data.or_closing_date;
          });
          console.log('THE AGENT IS ', this.agent);
          this.dates = [
            ...new Set(
              agentsData.map((date) => {
                let formattedDate = formatDate(
                  date?.or_order_date,
                  'dd/MM/yyyy',
                  'it'
                );
                console.log('Formatted date is: ', formattedDate);
                console.log('Date is: ', date?.or_order_date);
                return formattedDate;
              })
            ),
          ];
          this.order_numbers = [
            ...new Set(
              agentsData.map((client) => {
                return client.or_order_number;
              })
            ),
          ];
          console.log('Data is: ', agentsData);
        }
      });
  }

  goToTracking(token, trackId, agentID) {
    this.shipment_id = trackId;
    this.token = token;
    this.agentID = agentID;
    console.log('ID is: ', trackId);
    console.log('TOKEN is: ', token);
    this.closeServ.onTrack();
  }

  searchOrders() {
    const newToken = this.agent_token;
    if (!this.filterTerm || this.filterTerm == '') {
      this.agentsServ
        .searchAgentOrders(this.filterTerm, newToken)
        .subscribe((agentsOrderData: IWsResult) => {
          this.agent_order = agentsOrderData.data;
        });
    }
    this.agentsServ
      .searchAgentOrders(this.filterTerm, newToken)
      .subscribe((agentsOrderData: IWsResult) => {
        this.agent_order = agentsOrderData.data;
      });
  }

  goToOrderDetails(
    agent_id: any,
    order_id: any,
    or_gamma_id: any,
    or_assigned_date: string,
    token_agent: string,
    ag_note: string
  ) {
    this.clickedId = agent_id;
    this.orderId = order_id;
    this.or_gamma_id = or_gamma_id;
    this.or_assigned_date = or_assigned_date;
    this.token_agent = encodeURIComponent(token_agent);
    this.riferimento = ag_note;
    console.log('Token agent: ', this.agent_token);
    this.closeServ.onOrderA();
  }

  ngOnDestroy(): void {
    this.pageOpenedSubject.next(false);
    this.pageOpenedSubject.complete();
  }

  toggleAccordion() {
    this.isAccordionOpen = !this.isAccordionOpen;
  }
  
}

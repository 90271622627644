import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'byCustomerName',
})

export class ByCustomerNamePipe implements PipeTransform {
  
  transform(value: any[], args?: string): any[] {
    // args = args[0];
    console.debug('Value is', value);
    console.debug('Args are', args);
    if (value == undefined || args == null || args.toString() == '')
      return value;
    //if(args.toString() == this.selectedYear )
    //  return value;
    return value.filter((item) => item?.or_customer.indexOf(args) > -1);
  }
  
}

import { te } from 'date-fns/locale';
import { Buttons } from './buttons';
import { Helpers } from './helpters';
import { Image } from './image';
import { Labels } from './labels';

export namespace Shipments {

  export interface IShipment {
    os_tracking_number: string;
    os_tracking_external_link: string;
    os_label_url: Labels.IShipment[];
  }

  export interface IShipmentList {
    order_id: number;
    or_order_date: string;
    or_closing_date: string;
    or_assigned_date: string;
    or_order_number: string;
    or_customer: string;
    shipment_date: string;
    sm_carrier_desc: string;
    sm_ext_link: string;
    sm_tracking_number: string;
    sm_status_cod: number;
    sm_status_desc: string;
  }

  export interface IDetails {
    date: string;
    message: string;
    city: string;
    StatusCode: number;
  }

  export interface IShipmentItem
    extends Omit<IShipmentList, 'or_assigned_date'> {
    Details: IDetails[];
    estimated_shipment_date: string;
    or_shipping_address: string;
    sm_carrier_logo: string;
    sm_ext_link: string;
    sm_status_complete: boolean;
  }

  export class ShipmentList implements IShipmentList {
    order_id: number;
    or_order_date: string;
    or_assigned_date: string;
    or_order_number: string;
    or_customer: string;
    shipment_date: string;
    sm_carrier_desc: string;
    sm_ext_link: string;
    sm_tracking_number: string;
    sm_status_cod: number;
    sm_status_desc: string;
    or_closing_date: string;
    icon: Image.IImage;

    constructor(shipment: IShipmentList) {
      if (!shipment) return;
      this.order_id = shipment.order_id;
      this.or_order_date = shipment.or_order_date;
      this.or_assigned_date = shipment.or_assigned_date;
      this.or_order_number = shipment.or_order_number;
      this.or_customer = shipment.or_customer;
      this.shipment_date = shipment.shipment_date;
      this.sm_carrier_desc = shipment.sm_carrier_desc;
      this.sm_ext_link = shipment.sm_ext_link;
      this.sm_tracking_number = shipment.sm_tracking_number;
      this.sm_status_cod = shipment.sm_status_cod;
      this.sm_status_desc = shipment.sm_status_desc;
      this.or_closing_date = shipment.or_closing_date;

      switch (shipment.sm_status_cod) {
        case 1:
          // Helpers.iconInfoReceived.label = shipment.sm_status_desc;
          this.icon = Helpers.iconInfoReceived;
          break;
        case 2:
          // Helpers.iconInTransit.label = shipment.sm_status_desc;
          this.icon = Helpers.iconInTransit;
          break;
        case 3:
          // Helpers.iconOutForDelevery.label = shipment.sm_status_desc;
          this.icon = Helpers.iconOutForDelevery;
          break;
        case 4:
          // Helpers.iconMissedDelivery.label = shipment.sm_status_desc;
          this.icon = Helpers.iconMissedDelivery;
          break;
        case 5:
          // Helpers.iconException.label = shipment.sm_status_desc;
          this.icon = Helpers.iconException;
          break;
        case 6:
          // Helpers.iconCarrierDelivered.label = shipment.sm_status_desc;
          this.icon = Helpers.iconCarrierDelivered;
          break;

        default:
          // Helpers.iconException.label = shipment.sm_status_desc;
          this.icon = Helpers.iconException;
          break;
      }
    }
  }

  export class Details implements IDetails {
    date: string = null;
    message: string = null;
    city: string = null;
    StatusCode: number = -1;
    icon?: Image.IImage;
  }

  export class ShipmentItem implements IShipmentItem {
    Details: IDetails[];
    estimated_shipment_date: string;
    or_shipping_address: string;
    sm_carrier_logo: string;
    sm_ext_link: string;
    sm_status_complete: boolean;
    or_closing_date: string;
    order_id: number;
    or_order_date: string;
    or_order_number: string;
    or_customer: string;
    shipment_date: string;
    sm_carrier_desc: string;
    sm_tracking_number: string;
    sm_status_cod: number;
    sm_status_desc: string;
    icon: Image.IImage;
    dateS: string;
    dateO: string;
    buttons: Buttons.IButton[];
    DetailsReorded: IDetails[][];
    DetailsIcons: Details[];

    make6Cases(details: IDetails[]) {
      let temp = Array.from({ length: 6 }, (_element: Details[], index) => {
        return [];
      });
      details?.forEach((element) => {
        element.date = `${Helpers.getDate(element.date)} ${Helpers.getTime(
          element.date
        )}`;
        temp[element.StatusCode - 1].push(element);
      });
      return temp;
    }

    make6CasesIcons(details: IDetails[]) {
      let temp: Details[] = [];
      details?.forEach((element: Details) => {
        //console.log("elemeemneenen",element)
        //element.date = `${Helpers.getDate(element.date)} ${Helpers.getTime(element.date)}`;
        switch (element.StatusCode) {
          case 1:
            element.icon = Helpers.iconInfoReceived;
            break;
          case 2:
            element.icon = Helpers.iconInTransit;
            break;
          case 3:
            element.icon = Helpers.iconOutForDelevery;
            break;
          case 4:
            element.icon = Helpers.iconMissedDelivery;
            break;
          case 5:
            element.icon = Helpers.iconException;
            break;
          case 6:
            element.icon = Helpers.iconCarrierDelivered;
            break;

          default:
            element.icon = Helpers.iconException;
            break;
        }
        temp.push(element);
      });
      return temp;
    }

    constructor(shipment: IShipmentItem) {
      if (!shipment) return;
      this.order_id = shipment.order_id;
      this.or_order_date = shipment.or_order_date;
      this.or_order_number = shipment.or_order_number;
      this.or_customer = shipment.or_customer;
      this.shipment_date = shipment.shipment_date;
      this.sm_carrier_desc = shipment.sm_carrier_desc;
      this.sm_ext_link = shipment.sm_ext_link;
      this.sm_tracking_number = shipment.sm_tracking_number;
      this.sm_status_cod = shipment.sm_status_cod;
      this.sm_status_desc = shipment.sm_status_desc;
      this.Details = shipment.Details;
      this.DetailsReorded = this.make6Cases(shipment.Details);
      this.DetailsIcons = this.make6CasesIcons(shipment.Details);
      this.estimated_shipment_date = shipment.estimated_shipment_date;
      this.or_shipping_address = shipment.or_shipping_address;
      this.sm_carrier_logo = shipment.sm_carrier_logo;
      this.sm_ext_link = shipment.sm_ext_link;
      this.sm_status_complete = shipment.sm_status_complete;
      this.dateS = Helpers.getDate(shipment.shipment_date);
      this.dateO = Helpers.getDate(shipment.or_order_date);

      switch (shipment.sm_status_cod) {
        case 1: //Helpers.iconInfoReceived.label = shipment.sm_status_desc;
          this.icon = Helpers.iconInfoReceived;
          break;
        case 2: //Helpers.iconInTransit.label = shipment.sm_status_desc;
          this.icon = Helpers.iconInTransit;
          break;
        case 3: //Helpers.iconOutForDelevery.label = shipment.sm_status_desc;
          this.icon = Helpers.iconOutForDelevery;
          break;
        case 4: //Helpers.iconMissedDelivery.label = shipment.sm_status_desc;
          this.icon = Helpers.iconMissedDelivery;
          break;
        case 5: //Helpers.iconException.label = shipment.sm_status_desc;
          this.icon = Helpers.iconException;
          break;
        case 6: //Helpers.iconCarrierDelivered.label = shipment.sm_status_desc;
          this.icon = Helpers.iconCarrierDelivered;
          break;

        default: //Helpers.iconException.label = shipment.sm_status_desc;
          this.icon = Helpers.iconException;
          break;
      }
    }
  }
  
}

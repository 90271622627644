<div class="k-overlay dark-background" *ngIf="passOpen"></div>

<div class="k-form dark-background">
  <kendo-dialog
    class="k-form"
    [maxHeight]="650"
    [width]="850"
    style="left: 6%"
  >  
    <div 
      style="
        display: flex;
        flex-direction: row;
        justify-content: end;
        margin-top: -20px;
        z-index: 1000;
        position: fixed;
        margin-left: -35px;
      "
    >
      <button
        (click)="close()"
        class="btn btn3"
        rounded="full"
        style="
          background-color: #e90000;
          color: #ffffff;
          width: 35px !important;
          height: 35px !important;
        "
      >    
        <i
          class="bx bx-x bx-sm posRelBox2"
          style="
            color: #ffffff; 
            bottom: 10px; 
            position: relative !important;
            left: -80% !important;
            top: -1px !important;
          "
        ></i>
      </button> 
    </div>
    <form class="k-form" [formGroup]="changePassForm" autocomplete="on">
      <fieldset class="k-form-fieldset">
        <h1 class="page-title">Cambia password</h1>

        <div class="row d-flex justify-content-center">
          <kendo-formfield class="col-md-6">
            <kendo-label
              [for]="old_password"
              text="Password attuale"
            ></kendo-label>
            <kendo-textbox
              formControlName="old_password"
              [clearButton]="true"
              rounded="full"
              type="text"
              #old_password
              required
              min-length="6"
            >
            </kendo-textbox>
            <kendo-formerror
              *ngIf="
                (changePassForm.get('old_password')?.touched ||
                  changePassForm.get('old_password')?.dirty) &&
                changePassForm.get('old_password')?.errors
              "
              class="error"
            >
              Campo obbligatorio!
            </kendo-formerror>
          </kendo-formfield>
        </div>

        <div class="row d-flex justify-content-center">
          <kendo-formfield class="col-md-6">
            <kendo-label
              [for]="new_password"
              text="Nuova password"
            ></kendo-label>
            <kendo-textbox
              formControlName="new_password"
              [clearButton]="true"
              rounded="full"
              type="text"
              #new_password
              required
              min-length="8"
              [pattern]="pattern"
            >
              <ng-template kendoTextBoxSuffixTemplate>
                <button
                  kendoButton
                  look="clear"
                  icon="eye"
                  (click)="toggleVisibility()"
                ></button>
              </ng-template>
            </kendo-textbox>
            <kendo-formerror
              *ngIf="
                changePassForm.get('new_password')?.touched &&
                changePassForm.get('new_password').errors?.['required']
              "
              class="error"
            >
              Password obbligatoria!!!
            </kendo-formerror>
            <kendo-formerror
            *ngIf="
              (changePassForm.get('new_password')?.value ===
              changePassForm.get('old_password')?.value) && 
              changePassForm.get('new_password')?.errors
            "
            class="error"
          >
            La passowrd deve essere DIVERSA dalla precedente!!!
          </kendo-formerror>
            <kendo-formerror
              *ngIf="
                changePassForm.get('new_password').errors?.['minlength'] &&
                changePassForm.get('new_password')?.touched
              "
              class="error"
            >
              La password deve essere di ALMENO 8 caratteri!!!
            </kendo-formerror>
            <kendo-formerror
              *ngIf="
                changePassForm.get('new_password').errors?.['pattern'] &&
                changePassForm.get('new_password')?.touched
              "
              class="error"
            >
              La password deve essere lunga ALMENO 8 caratteri e deve contenere ALMENO una minuscola, una maiuscola, un numero e un carattere speciale!!!
            </kendo-formerror>
          </kendo-formfield>
        </div>

        <div class="row d-flex justify-content-center">
          <kendo-formfield class="col-md-6">
            <kendo-label
              [for]="rep_password"
              text="Ripeti la password"
            ></kendo-label>
            <kendo-textbox
              formControlName="rep_password"
              [clearButton]="true"
              rounded="full"
              type="text"
              #rep_password
              required
              min-length="8"
            >
              <ng-template kendoTextBoxSuffixTemplate>
                <button
                  kendoButton
                  look="clear"
                  icon="eye"
                  (click)="toggleVisibilityRep()"
                ></button>
              </ng-template>
            </kendo-textbox>
            <kendo-formerror
              *ngIf="
                changePassForm.get('rep_password')?.touched &&
                changePassForm.get('rep_password').errors?.['required']
              "
              class="error"
            >
              Password obbligatoria!!!
            </kendo-formerror>
            <kendo-formerror
              *ngIf="
                (changePassForm.get('rep_password')?.value !==
                changePassForm.get('new_password')?.value) && 
                changePassForm.get('rep_password')?.errors
              "
              class="error"
            >
              Le password NON coincidono!!!
            </kendo-formerror>
          </kendo-formfield>
        </div>

        <div class="k-form-buttons d-flex justify-content-center">
          <div class="button-left">
            <button
              class="btn btn3"
              rounded="full"
              style="
                background: rgb(0, 146, 73);
                color: #ffffff;
                justify-content: center !important;
                display: flex;
                width: 192px !important;
                height: 45px !important;
              "
              [disabled]="!changePassForm.valid"
              (click)="changePassword(changePassForm.value)"
            >
              <i
                class="bx bx-check-circle bx-sm posRelBox2"
                style="
                  color: #ffffff;
                  right: 6px;
                  bottom: 10px;
                  position: relative;
                "
              ></i>
              <p class="posRelBox2">SALVA</p>
            </button>
          </div>
        </div>
      </fieldset>
    </form>
  </kendo-dialog>
</div>

import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { Helpers } from 'src/app/models/helpters';
import { IUserAvatar } from 'src/app/models/IUser.model';
import { AccountsService } from 'src/app/services/accounts.service';

export interface ImageInfo {
  src: string;
  width: number;
  height: number;
}

@Component({
  selector: 'app-upload-component',
  templateUrl: './upload-component.component.html',
  styleUrls: ['./upload-component.component.scss'],
})

export class UploadComponentComponent {

  @Output() public valueChange: EventEmitter<ImageInfo> = new EventEmitter<ImageInfo>();
  @Output() updateImg = new EventEmitter<void>();
  @Input() accountId: number;
  @Input() imageStr: string;

  public myRestrictions: FileRestrictions = {
    allowedExtensions: ['.png', '.jpg', '.jpeg'],
  };
  public uploadSaveUrl = 'http://salvatempo.connecta.ovh/api/services/put_avatar';
  public uploadRemoveUrl = '';
  public avatarData: IUserAvatar = {
    account_id: 0,
    ac_avatar: '',
  };

  shortLink: string = '';
  loading: boolean = false;
  show: boolean = false;
  file: IUserAvatar;

  constructor(private avatarSrv: AccountsService) { }

  onFileLoad(event: any) {
    console.debug(event);
    {
      this.avatarData = {
        account_id: 0,
        ac_avatar: '',
      };
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e: any) => {
        this.avatarData.account_id = this.accountId;
        this.avatarData.ac_avatar = reader.result?.toString();
        this.avatarSrv.facAvatar = this.avatarData;
        let termination =
          this.avatarData.ac_avatar?.indexOf(';') -
          this.avatarData.ac_avatar?.indexOf('/') -
          1;
        this.avatarData.ac_avatar = this.avatarData.ac_avatar?.substring(
          Helpers.base64Length + termination
        );
        console.log('Avatar data is: ', this.avatarData.ac_avatar);
        console.log('Original avatar is: ', reader.result?.toString());
        console.log('Name is: ', event.target.files[0].name);
        this.avatarSrv.saveAvatar(this.avatarData).subscribe();
      };
      reader.onerror = (error) => {
      };
    }
    this.getAvatarByURL();
    this.updateImg.emit();
  }  

  onChange(event: any) {
    this.file = event.target.files[0];
    this.show = true;
    this.onUpload();
  }

  onUpload() {
    this.loading = !this.loading;
    this.avatarData.ac_avatar = this.file.ac_avatar?.toString();
    console.log(this.file);
    this.avatarSrv.saveAvatar(this.avatarData).subscribe((event: any) => {
      if (typeof event === 'object') {
        this.shortLink = event.link;
        this.loading = false;
      }
    });
  }

  public onRemove(): void {
    this.valueChange.emit();
  }
  
  getAvatarByURL() { }
  
}

import { Component, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  GridDataResult,
  PageChangeEvent,
  RowClassArgs,
} from '@progress/kendo-angular-grid';
import { Orders } from 'src/app/models/Orders.model';
import { Objectives } from 'src/app/models/Orders.model';
import { Status } from 'src/app/models/status';
import { IWsResult } from 'src/app/models/ws_result.model';
import { OrdersService } from 'src/app/services/orders.service';
import { SettingsService } from 'src/app/services/settings.service';
import { IconsService } from '../../../services/icons.service';
import Swal from 'sweetalert2';
import { SharedDataService } from '../../../services/shared/total.service';
import { SearchServiceService } from 'src/app/services/shared/search-service.service';
import { Subscriber, Subscription } from 'rxjs';
import { parseISO } from 'date-fns';

@Component({
  selector: 'app-assegna-ordine-list',
  templateUrl: './assegna-ordine-list.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./assegna-ordine-list.component.scss'],
})

export class AssegnaOrdineListComponent implements OnInit {

  public gridData: Orders.IOrderNewList[] = [];
  public pmOrders: Orders.OrderPM[] = [];
  public partials: Orders.IOrderNewList[] = [];
  public order: Orders.IOrder = null;
  public loading: boolean = false;
  public value = 0;
  public indeterminate = false;
  public min = -10;
  public max = 10;
  public gridView: GridDataResult;
  public pageSize = 20;
  public skip = 0;
  public data: unknown[];

  gridDataInfinity: Orders.IOrderNewList[] = [];
  ordiniParziali: any[] = [];
  ordiniPM: any[] = [];
  filteredOrders: Orders.IOrderNewList[] = [];
  searchText: string = '';
  filterTerm: string | undefined;
  sameIndex: boolean = true;
  id: number;
  timer = null;
  refreshInterval: number = 60000;
  startDate: Date = new Date(2021, 0, 1);
  endDate: Date = new Date();
  obiettivi: Objectives;
  objectives: Objectives = new Objectives(null);
  totalAmount: any = 0;
  totaleImponibile: number = 0
  totaleImporto = this.gridData.reduce((total, item) => total + item.op_articles_number, 0);
  totz = this.gridData.length


  dateBy: any[] = [
    {
      text: 'Data ordine',
      value: 'or_order_number',
    },
  ];

  filters = {
    dateOrginizedBy: {
      text: 'Data ordine',
      value: 'or_order_number',
    },
    startingDate: this.startDate,
    endDate: this.endDate,
    minDate: new Date(),
    maxDate: new Date(),
  };

  searchService = inject(SearchServiceService);
  subscriber: Subscription[] = [];
  searchParam!: string;

  constructor(
    private ordersServ: OrdersService,
    private router: Router,
    private iconService: IconsService,
    private settings: SettingsService,
    private share: SharedDataService,
    private route: ActivatedRoute
  ) {
    this.calculateSumOfColumn();
    this.totalAmount = this.gridData.reduce((total, currentItem) => total + currentItem.or_articles_number, 0);
  }

  loadMore() {
    this.gridDataInfinity = [
      ...this.gridDataInfinity,
      ...this.gridData.slice(this.skip, this.skip + this.pageSize),
    ];
    // this.updateGridData();
    this.skip += this.pageSize;
  }

  isOrPartialS(element: any): boolean {
    return element.or_partial === 'S';
  }

  updateGridData() {
    this.gridData = [
      ...this.partials,
      ...this.ordiniPM,
      ...this.gridDataInfinity,
    ];
  }

  public rowCallback = (context: RowClassArgs) => {
    if (context.dataItem.or_partial === 'S') {
      return { partialOrderStyle: true };
    }
  };

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadProducts();
  }

  private loadProducts(): void {
    this.gridView = {
      data: this.gridData.slice(this.skip, this.skip + this.pageSize),
      total: this.gridData.length,
    };
  }

  searchOrders(searchString?: string) {
    if (searchString) this.filterTerm = searchString;
    if (!this.filterTerm || this.filterTerm == '') {
      this.reloadFunction();
      return;
    }
    this.ordersServ
      .searchNewOrders(this.filterTerm)
      .subscribe((newOrdersData: IWsResult) => {
        if (newOrdersData.error_message.msg_code === 8) this.gridData = [];
        else this.gridData = newOrdersData.data;
        this.skip = 0;
        this.gridDataInfinity = [];
        console.log('Grid data after search is', this.gridData);
        // this.updateGridData();
      });
  }

  onInputChanged(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.validity && inputElement.validity.valueMissing) {
      this.filters.startingDate = this.startDate;
      this.getNewOrders();
    }
  }

  ngOnInit(): void {
    this.loading = true;
    this.calcolaTotaleImponibile();
    this.calculateSumOfColumn();
    this.subscriber.push(
      this.route.queryParams.subscribe((params) => {
        console.log('Params is', params);
        // Check if params is an empty object
        if (Object.keys(params).length === 0) {
          this.reloadFunction();
          console.log('Params is an empty object. Reloaded.');
        } else {
          this.searchParam = params['search'];
          const dateParam = params['data'];
          this.filters.startingDate = dateParam ? parseISO(dateParam) : null;
          console.log('Param is', this.searchParam);
          this.searchOrders(this.searchParam);
          this.loading = false;
        }
      })
    );
  }

  getObjectives() {
    this.ordersServ.getObjectiveProgress().subscribe((data: any) => {
      console.log('Data: ', data);
    });
  }

  checkPartial: boolean = false;

  getNewOrders() {
    this.loading = true;
    this.ordersServ.getNewOrders().subscribe((openOrdersData: any) => {
      if (openOrdersData.data === null) {
        this.loading = false;
        return;
      } 
      else if (openOrdersData.data) {
        console.log('Orders are', openOrdersData.data);
        this.gridData = openOrdersData.data;
        console.log('GridData is', this.gridData);
        this.skip = 0;
        this.gridDataInfinity = [];
        // this.loadMore();
        this.checkPartial = false; // Reset to false before checking
        for (const el of this.gridData) {
          if (el.or_partial === 'S') {
            this.checkPartial = true;
            console.log('Check partial is', this.checkPartial); // Break the loop if a match is found
          }
        }
        this.loading = false;
        // If you want to set it to false when no match is found, you can add an else block
      }
    });
  }

  getOrdineParziali() {
    this.loading = true;
    this.ordersServ.getNewOrdersPartial().subscribe((openOrdersData) => {
      this.ordiniParziali = openOrdersData.data;
      console.log('Ordini parziali: ', this.ordiniParziali);
      this.skip = 0;
      this.gridDataInfinity = [];
      // this.loadMore();
      // this.loading = false;
    });
  }

  getPMOrders() {
    this.loading = true;
    this.ordersServ.getCMOrders().subscribe((openOrdersData) => {
      this.ordiniPM = openOrdersData;
      console.log('Ordini PM: ', this.ordiniPM);
      this.skip = 0;
      this.gridDataInfinity = [];
      // this.loadMore();
      // this.loading = false;
    });
  }

  gotoDetails(order_id: number) {
    this.router.navigate(['/assign-order', order_id]);
  }

  block(order) {
    this.iconService.changeIconToBlocked(order);
    console.log('BLOCKED!', order);
    this.ordersServ
      .lockNewOrder(order.or_gamma_id)
      .subscribe((result: IWsResult) => {
        if (!result.success) return;
        this.reloadFunction();
        Swal.fire({
          icon: 'success',
          title: 'Bloccato!',
          text: 'Ordine bloccato con successo!!!',
          timer: 1500,
          timerProgressBar: true,
        });
        console.log('BLOCKED!');
      });
  }

  async unblock(order) {
    console.log('BLOCKED!', order);
    this.iconService.changeIconToWorking(order);
    this.ordersServ
      .unlockNewOrder(order.or_gamma_id)
      .subscribe((result: IWsResult) => {
        if (!result.success) return;
        this.reloadFunction();
        Swal.fire({
          icon: 'success',
          title: 'Sbloccato!',
          text: 'Ordine sbloccato con successo!!!',
          timer: 1500,
          timerProgressBar: true,
        });
        console.debug('This order', order);
        console.log('UNBLOCKED!');
      });
  }

  async isInWork(): Promise<boolean> {
    if (!this.ordersServ.isUser.getValue()) {
      this.sameIndex = false;
      return true;
    }
    let indexToSuspend = this.gridData.findIndex(
      (order) =>
        (order.tab_order_status_cod >= Status.StatusesUser.step1 &&
          order.tab_order_status_cod <= Status.StatusesUser.step4) ||
        order.tab_order_status_cod == Status.StatusesUser.intiated
    );
    console.debug('indexToSuspend', indexToSuspend);
    this.sameIndex =
      indexToSuspend != -1
        ? this.gridData[indexToSuspend].order_id == this.id
        : false;
    console.debug('this.sameIndex', this.sameIndex);
    if (indexToSuspend != -1 && !this.sameIndex) {
      this.ordersServ
        .setStatus(
          this.gridData[indexToSuspend].order_id,
          Status.StatusesUser.blocked
        )
        .subscribe((result: IWsResult) => {
          if (!result.success) {
            Swal.fire({
              icon: 'error',
              title: 'Attenzione!',
              text: 'L`Ordine non può essere sospeso!!!',
              timer: 1500,
              timerProgressBar: true,
            });
            return;
          }
        });
    }
    return true;
  }

  reloadFunction() {
    this.getNewOrders();
    this.getOrdineParziali();
    this.getPMOrders();
    this.ordersServ.getObjectives().subscribe((obj: Objectives) => {
      this.obiettivi = obj;
      // return;
    });
  }

  ngOnDestroy() {
    this.subscriber.forEach((item) => item.unsubscribe());
  }

  calcolaTotaleImponibile() {
    this.gridData.forEach(item => {
      this.totaleImponibile += item.or_articles_number;
    });
    return this.totaleImponibile
  }

  calculateSumOfColumn() {
    console.log('IMPONIBILE: ', this.totaleImponibile);
    console.log('valori per test 1: ', this.gridData);
    return this.totaleImponibile = this.gridData.reduce((sum, item) => sum + (item.or_articles_number * 4), 0);
  }
  
}

<div class="k-form dark-background" *ngIf="openedWarningMessage">
  <kendo-dialog
    (close)="close()"
    class="k-form"
    style="margin-left: 40px; width: 30%; left: 35%"
  >
    <div class="row k-display-flex justify-content-center">
      <p
        style="
          font-family: 'Lato';
          font-style: normal;
          font-weight: 700;
          font-size: 28px;
          line-height: normal;
          margin: 20px;
          text-align: center;
        "
      >
        Attenzione, questa azione comporterà modifiche significative all'ordine,
        incluse ma non limitate a prodotti aggiunti/rimossi, indirizzo di
        spedizione, ecc.
      </p>
      <p
        style="
          font-family: 'Lato';
          font-style: normal;
          font-weight: 700;
          font-size: 28px;
          line-height: normal;
          margin: 20px;
          text-align: center;
        "
      >
        Premi "Continua" se sei d'accordo
      </p>
      <button class="btn btn-primary m-3" (click)="close()">Continua</button>
    </div>
  </kendo-dialog>
</div>

<h1 class="page-title">Ordini PM</h1>

<div 
  class="filterContainer k-display-flex"
  style=" 
    flex-direction: row;
    justify-content: center;
  "
>

  <form 
    class="new-search"  
    role="search"
    style="
      display:flex !important;
      flex-direction: row !important;
      justify-content: center !important;
    "
  >
    <label for="search"></label>
    <input 
    [(ngModel)]="filterTerm"
      id="search" 
      name="search"
      pattern=".*\S.*"
      type="search" 
      placeholder="Cerca ordine..." 
      autofocus 
      required 
      (input)="this.onInputChanged($event)"
    />
    <button 
      type="submit"
      (click)="searchOrders()"
    >
      <i
        class="bx bx-search bx-md"
        style="
          margin: 0;
          padding-top: 10px;
          padding-left: 5px;
          transform:scale(.8)
        "
      >
      </i>    
    </button>    
  </form>

  <form
    action=""
    class="search-bar"
    style="
      display:flex !important;
      flex-direction: row !important;
      justify-content: center !important;
      visibility: hidden !important;
    "
  >
    <button
      class="btn btn-primary msg-btn"
      routerLink="/new-msg"
      style="
        display:flex !important;
        flex-direction: row !important;
        justify-content: center !important;
        visibility: hidden !important;
      "
    >
      + Messaggio
    </button>
  </form>

  <div class="dateContainer k-display-flex justify-content-evenly">
    <div class="col mx-2">
      <h5 class="text-center">DA:</h5>
      <kendo-datepicker
        [(ngModel)]="filters.startingDate"
        class="dropdown-field drop-the-border"
        style="
          background: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 45px;
        "
      ></kendo-datepicker>
    </div>
    <div class="col">
      <h5 class="text-center">A:</h5>
      <kendo-datepicker
        [(ngModel)]="filters.endDate"
        class="dropdown-field drop-the-border"
        style="
          background: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 45px;
        "
      ></kendo-datepicker>
    </div>
  </div>
</div>

<hr class="hr-newBlue" />

<div
  class="row k-display-flex justify-content-end align-items-center"
  style="padding-left: 20px;"
>
  <app-base-orderscm-list [data]="gridData" [filters]="filters"></app-base-orderscm-list>
</div>

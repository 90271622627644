export namespace Pages {

  export interface IPage {
    title: string;
    url: string;
    icon: string;
  }

  export class Page implements IPage {
    title: string;
    url: string;
    icon: string;

    constructor(title, url, icon) {
      this.title = title;
      this.url = url;
      this.icon = icon;
    }
  }

  export class Pages implements IPage {
    title: string;
    url: string;
    icon: string;
    public pages: IPage[] = [];

    public getDesktopPages() {
      let page = new Page('pages.dashboard', '/pm-orders', 'home');
      this.pages.push(page);
      page = new Page('pages.messages', '/messages', 'chatbubbles');
      this.pages.push(page);
      page = new Page('pages.backOffice', '/back-office', 'bulb');
      this.pages.push(page);
      page = new Page('pages.products', '/products', 'server');
      this.pages.push(page);
      page = new Page('pages.orderPreparation', '/order-preparation', 'car');
      this.pages.push(page);
      page = new Page('pages.orders', '/orders', 'cart');
      this.pages.push(page);
      page = new Page('pages.gamma', '/gamma', 'desktop');
      this.pages.push(page);
      page = new Page('pages.statistics', '/statistics', 'stats-chart');
      this.pages.push(page);
      page = new Page('pages.settings', '/settings', 'settings');
      this.pages.push(page);
      return this.pages;
    }

    public getMobileUserPages() {
      this.pages = [];
      // let page = new Page('pages.orderPreparation','/order-preparation','archive');
      // this.pages.push(page);
      // page = new Page('pages.prenotation','/order-prenotation','list');
      // this.pages.push(page);
      return this.pages;
    }

    public getMobileAdminPages() {
      this.pages = [];
      // let page = new Page('pages.statistics','/order-prenotation','bar-chart');
      // this.pages.push(page);
      return this.pages;
    }

    constructor() { }

  }

}

import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { PageChangeEvent } from '@progress/kendo-angular-pager';
import { Categories } from 'src/app/models/categories';
import { Products } from 'src/app/models/Product.model';
import { ProductPosition } from 'src/app/models/productPosition';
import { OrdersService } from 'src/app/services/orders.service';
import { PagesService } from 'src/app/services/pages.service';
import { ProductsService } from 'src/app/services/products.service';
import { DropdownService } from 'src/app/services/shared/dropdown.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';

@Component({
  selector: 'app-gestione-prodotti-dettagli',
  templateUrl: './gestione-prodotti-dettagli.component.html',
  styleUrls: ['./gestione-prodotti-dettagli.component.scss'],
})

export class GestioneProdottiDettagliComponent implements OnInit {

  @Input() opened = false;
  @Input() isProdOpen = false;

  public products: Products.IProductDetail[] = [];
  public gridData: Products.IProductDetail[] = [];
  public pos: ProductPosition.IPosition;  
  public loading: boolean = false;
  public data: unknown[];
  public gridView: GridDataResult;
  public pageSize = 20;
  public skip = 0;
  public allCategories: Categories.ICategory[];
  public categories: Categories.Family[];

  family: any;
  subFamily: any;
  group: any;
  subGroup: any;
  isEditable = false;
  newField = false;
  clickedProduct: any;
  filterTerm: string = '';
  productsDetailForm!: FormGroup;
  clickedId: string;

  constructor(
    private productServ: ProductsService,
    private ordersServ: OrdersService,
    private route: ActivatedRoute,
    private dropdownServ: DropdownService,
    private formBuilder: FormBuilder,
    public closeSrv: OpenModalService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public pagesServ: PagesService
  ) { }  

  edit() { }

  onInputChanged(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.validity && inputElement.validity.valueMissing) {
      this.getCategories();
    }
  }

  getCategories() {
    this.productServ.getCategories().subscribe((result) => {
      console.debug('results: ', result);
      if (!result) 
        return;
      this.allCategories = result;
      this.categories = new Categories.Categories(result).families;
      console.debug('categories: ', new Categories.Categories(result));
      this.loading = false;
    });
  }

  open() {
    this.opened = true;
  }

  close() {
    this.closeSrv.close();
  }

  editFields() {
    this.isEditable = !this.isEditable;
  }  

  showDetails(productId: string, productDesc: string) {
    console.debug('productId');
    this.clickedId = productId;
    console.log('This ID is: ', this.clickedId);
    this.closeSrv.openProd();
    productDesc = productDesc.trim();
    productDesc = this.pagesServ.replaceAll(productDesc, ' ', '-');
    this.router.navigate([`${productDesc.trim()}-${productId.trim()}`], {
      relativeTo: this.activatedRoute,
    });
  }

  newFields() {
    this.newField = !this.newField;
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadProducts();
  }

  searchProducts(evt) {
    if (!this.filterTerm || this.filterTerm == '') {
      this.getProducts();
    }
    console.debug('Family', this.family);
    this.productServ
      .searchCategoryProducts(
        this.filterTerm,
        this.family,
        this.subFamily,
        this.group,
        this.subGroup
      )
      .subscribe((openOrdersData: Products.IProductDetail[]) => {
        this.gridData = openOrdersData;
        console.debug('Asta e data', this.gridData);
        this.loadProducts();
      });
  }

  private loadProducts(): void {
    this.gridView = {
      data: this.gridData.slice(this.skip, this.skip + this.pageSize),
      total: this.gridData.length,
    };
    this.loading = false;
  }

  ngOnInit(): void {
    this.loading = true;
    this.getProducts1();
    this.pos = {
      lane: '',
      shelf: '',
      position: '',
    };
  }

  clearForm() {
    this.productsDetailForm.reset();
  }

  editForm() {
    this.isEditable = true;
  }

  getProducts1() {
    this.productServ.getProducts().subscribe((result) => {
      console.debug('result: ', result);
      if (!result) 
        return;
      this.products = result;
      this.gridData = this.products;
      this.loadProducts();
      this.loading = false;
    });    
  }

  getProducts() {
    this.family = this.activatedRoute.snapshot.paramMap.get('family');
    this.subFamily = this.activatedRoute.snapshot.paramMap.get('subfamily');
    this.group = this.activatedRoute.snapshot.paramMap.get('group');
    this.subGroup = this.activatedRoute.snapshot.paramMap.get('subgroup');
    this.productServ
      .getProductCategories(
        this.family,
        this.subFamily,
        this.group,
        this.subGroup
      )
      .subscribe((result) => {
        console.debug('result', result);
        if (!result) return;
        this.products = result;
        this.gridData = this.products;
        this.loadProducts();        
      });    
  }
  
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Boxes } from '../models/Boxes.models';
import { Documents } from '../models/documents.models';
import {
  Objectives,
  Orders,
  OrderSignature,
  Errors,
  OrderErrors,
} from '../models/Orders.model';
import { Shipments } from '../models/shipment';
import { IWsResult } from '../models/ws_result.model';
import { ErrorHandlerService } from './shared/error-handler.service';
import { Products } from '../models/Product.model';

const ASSIGNEDORDERS_KEY = 'newOrdersAssigned';
const ORDERS_KEY = 'WorkingOrders';
const ORDERDOCUMENTS_KEY = 'OrderDocuments';

@Injectable({
  providedIn: 'root',
})

export class OrdersService {

  static isDesktoPlatform: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(null);

  url: string = environment.urlServer;
  pages = new BehaviorSubject(-1);
  signature: OrderSignature;
  isDesktop: boolean;
  isUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  constructor(
    private http: HttpClient,
    private errorHandlerServ: ErrorHandlerService
  ) { }

  getOpenOrders(): Observable<Orders.OrderOpenList[]> {
    return this.http
      .get<IWsResult>(`${this.url}/get_open_orders`)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getOpenOrders')
        )
      )
      .pipe(
        map((result: IWsResult) => {
          if (result.success == true)
            return result.data.map((item) => {
              let order = new Orders.OrderOpenList(item);
              return order;
            });
          else 
            return [];
        })
      );
  }

  getOrdersToSign(search?: string): Observable<Orders.OrderSignature[]> {
    let isHeader = !!search;
    return this.http
      .get<IWsResult>(
        `${this.url}/get_tosign_orders/`,
        isHeader ? { headers: { search: `${search}` } } : {}
      )
      .pipe(
        catchError(this.errorHandlerServ.handleError<IWsResult>('getOrders'))
      )
      .pipe(
        map((result: IWsResult) =>
          result.data.map((item) => {
            let order = new Orders.OrderSignature(item);
            return order;
          })
        )
      );
  }

  getShippmentsList(
    searchString: string = ''
  ): Observable<Shipments.ShipmentList[]> {
    return this.http
      .get<IWsResult>(`${this.url}/get_shipments_orders`, {
        headers: { search: `${searchString}` },
      })
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getShippmentsList')
        )
      )
      .pipe(
        map((result: IWsResult) => {
          if (result.success)
            return result.data.map((item) => {
              let shipment = new Shipments.ShipmentList(item);
              return shipment;
            });
        })
      );
  }

  getShippmentById(
    agent_token: string,
    orderId: number,
    agent_id: number
  ): Observable<Shipments.ShipmentItem> {
    return this.http
      .get<IWsResult>(
        `${this.url}/get_agent_shipments_status/${orderId}/${agent_id}`,
        {
          headers: { token: agent_token },
        }
      )
      .pipe(catchError(this.errorHandlerServ.handleError<IWsResult>('getBox')))
      .pipe(
        map((result: IWsResult) => new Shipments.ShipmentItem(result.data))
      );
  }

  getShippmentById1(orderId: number): Observable<Shipments.ShipmentItem> {
    return this.http
      .get<IWsResult>(`${this.url}/get_shipments_status/${orderId}`)
      .pipe(catchError(this.errorHandlerServ.handleError<IWsResult>('getBox')))
      .pipe(
        map((result: IWsResult) => new Shipments.ShipmentItem(result.data))
      );
  }

  geShipmentLabel(orderId: number) {
    return this.http
      .get<IWsResult>(`${this.url}/get_shipment_labels/${orderId}`)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('geShipmentLabel')
        )
      );
  }

  searchOpenOrders(searchString: string = ''): Observable<IWsResult> {
    return this.http
      .get<IWsResult>(`${this.url}/get_open_orders`, {
        headers: { search: `${searchString}` },
      })
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getOpenOrders')
        )
      );
  }

  searchClosedOrders(searchString: string = ''): Observable<IWsResult> {
    return this.http
      .get<IWsResult>(`${this.url}/get_closed_orders`, {
        headers: { search: `${searchString}` },
      })
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getClosedOrders')
        )
      );

    // .pipe(
    //   map((result: IWsResult) => {
    //     if (result.error_message.msg_code !== 0) {
    //       return [];
    //     }
    //     return result.data;
    //   })
    // );
  }

  getClosedOrders(): Observable<Orders.IOrderClosed[]> {
    return this.http
      .get<IWsResult>(`${this.url}/get_closed_orders`)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getClosedOrders')
        )
      )
      .pipe(
        map((result: IWsResult) => {
          if (result.error_message.msg_code !== 0) {
            return [];
          }
          return result.data;
        })
      );
  }

  searchOrdersToSign(searchString: string = ''): Observable<IWsResult> {
    return this.http
      .get<IWsResult>(`${this.url}/get_tosign_orders`, {
        headers: { search: `${searchString}` },
      })
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getOpenOrders')
        )
      );
  }

  async getOrderAssigned(id): Promise<Orders.OrderToSend> {
    return await this.getOrdersAssigned().then(
      async (orders: Map<number, Orders.OrderToSend>) => {
        if (orders && orders.has(id)) {
          return orders.get(id);
        }
        return null;
      }
    );
  }

  private async getOrdersAssigned() {
    return JSON.parse(localStorage.getItem(ASSIGNEDORDERS_KEY) || '{}');
  }

  async setOrderAssigned(order: Orders.OrderToSend) {
    return await this.getOrdersAssigned().then(
      async (orders: Map<number, Orders.OrderToSend>) => {
        if (orders && orders[order.or_gamma_id]) {
          orders[order.or_gamma_id].value = order;
        } 
        else {
          if (!orders) orders = new Map<number, Orders.OrderToSend>();
          orders.set(order.or_gamma_id, order);
        }
        await localStorage.setItem(ASSIGNEDORDERS_KEY, JSON.stringify(orders));
      }
    );
  }

  async removeOrderAssigned(order: Orders.IOrderToSend) {
    return await this.getOrdersAssigned().then(
      async (orders: Map<number, Orders.IOrderToSend>) => {
        if (orders && orders.has(order.or_gamma_id)) {
          orders.delete(order.or_gamma_id);
        }
        await localStorage.setItem(ASSIGNEDORDERS_KEY, JSON.stringify(orders));
      }
    );
  }

  searchNewOrders(searchString: string = ''): Observable<IWsResult> {
    return this.http
      .get<IWsResult>(`${this.url}/get_new_orders`, {
        headers: { search: `${searchString}` },
      })
      .pipe(
        catchError(this.errorHandlerServ.handleError<IWsResult>('getNewOrders'))
      );
  }

  getNewOrders(): Observable<IWsResult> {
    return this.http
      .get<IWsResult>(`${this.url}/get_new_orders`)
      .pipe(
        catchError(this.errorHandlerServ.handleError<IWsResult>('getNewOrders'))
      );
  }

  getNewOrdersPartial(): Observable<IWsResult> {
    return this.http
      .get<IWsResult>(`${this.url}/get_new_orders_partial`)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getNewOrdersPartial')
        )
      );
  }

  searchPartialOrders(searchString: string): Observable<IWsResult> {
    return this.http
      .get<IWsResult>(`${this.url}/get_new_orders_partial`, {
        headers: { search: `${searchString}` },
      })
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('searchPartialOrders')
        )
      );
  }

  getCMOrders(): Observable<Orders.ICMOrderList[]> {
    return this.http
      .get<IWsResult>(`${this.url}/get_cm_orders`)
      .pipe(
        catchError(this.errorHandlerServ.handleError<IWsResult>('getCMOrders'))
      )
      .pipe(
        map((result: IWsResult) => {
          if (result.success == true)
            return result.data.map((item) => {
              let order = new Orders.ICMOrderOpenList(item);
              return order;
            });
          else return [];
        })
      );
  }

  getOrder(id: string): Observable<IWsResult> {
    return this.http
      .get<IWsResult>(`${this.url}/get_order/` + id)
      .pipe(
        catchError(this.errorHandlerServ.handleError<IWsResult>('getOrder'))
      );
  }

  getSimilarProducts(searchString: string = ''): Observable<IWsResult> {
    return this.http
      .get<IWsResult>(`${this.url}/get_similar_products/`, {
        headers: { search: `${searchString}` },
      })
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getSimilarProducts')
        )
      );
  }

  getOrder1(id: number): Observable<Orders.Order> {
    return this.http
      .get<IWsResult>(`${this.url}/get_order/${id}`)
      .pipe(
        catchError(this.errorHandlerServ.handleError<IWsResult>('getOrder/id'))
      )
      .pipe(
        map((result: IWsResult) => {
          if (result.success) 
            return new Orders.Order(result.data);
        })
      );
  }

  async getOrderSaved(id): Promise<Orders.Order> {
    return await this.getOrdersSaved().then(
      async (orders: Map<number, Orders.Order>) => {
        console.log('GetOrdersSaved', orders);
        if (orders && orders.has(id)) {
          return orders.get(id);
        }
        return null;
      }
    );
  }

  private async getOrdersSaved() {
    console.log(
      'New Map is',
      new Map(JSON.parse(localStorage.getItem(ORDERS_KEY)))
    );
    return new Map(JSON.parse(localStorage.getItem(ORDERS_KEY)));
  }

  async setProductsChecked(order: Orders.Order) {
    return await this.getOrdersSaved().then(
      async (orders: Map<number, Orders.Order>) => {
        console.log('Order setProductsCheck', orders);
        if (orders && orders[order.order_id]) {
          orders[order.order_id].value = order;
        } 
        else {
          if (!orders) orders = new Map<number, Orders.Order>();
          orders.set(order.order_id, order);
        }
        console.log('GetOrdersSaved2', orders);
        const objectFromMap = Object.fromEntries(orders);
        console.log('GetOrdersSaved2 Json', JSON.stringify(objectFromMap));
        await localStorage.setItem(
          ORDERS_KEY,
          JSON.stringify(Array.from(orders.entries()))
        );
      }
    );
  }

  async setDocumentsChecked(orderid: number, documents: Documents.Document[]) {
    return await this.getOrdersDocuments().then(
      async (docs: Map<number, Documents.Document[]>) => {
        if (docs && docs[orderid]) {
          docs[orderid].value = documents;
        } 
        else {
          if (!docs) docs = new Map<number, Documents.Document[]>();
          docs.set(orderid, documents);
        }
        await localStorage.setItem(
          ORDERS_KEY,
          JSON.stringify(Array.from(docs.entries()))
        );
      }
    );
  }

  async removeProductsChecked(order: Orders.Order) {
    return await this.getOrdersSaved().then(
      async (orders: Map<number, Orders.Order>) => {
        if (orders && orders.has(order.order_id)) {
          orders.delete(order.order_id);
        }
        await localStorage.setItem(
          ORDERS_KEY,
          JSON.stringify(Array.from(orders.entries()))
        );
      }
    );
  }

  getNewOrder(id: string): Observable<IWsResult> {
    return this.http
      .get<IWsResult>(`${this.url}/get_new_order/` + id)
      .pipe(
        catchError(this.errorHandlerServ.handleError<IWsResult>('getNewOrder'))
      );
  }

  addOrder(order: Orders.IOrderToSend) {
    return this.http
      .post<IWsResult>(`${this.url}/add_order`, order)
      .pipe(
        catchError(this.errorHandlerServ.handleError<IWsResult>('addOrder'))
      );
  }

  prepareOrder(order: Orders.OrderPrepared) {
    return this.http
      .post(`${this.url}/prepare_order/`, order)
      .pipe(
        catchError(this.errorHandlerServ.handleError<IWsResult>('setStatus'))
      );
  }

  completeOrder(order_id: string) {
    return this.http
      .post(`${this.url}/complete_order/${order_id}`, { headers: {} })
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('completeOrder')
        )
      );
  }

  getBordero(order: Orders.OrderBordero) {
    return this.http
      .post<IWsResult>(`${this.url}/generate_bordero/`, order)
      .pipe(
        catchError(this.errorHandlerServ.handleError<IWsResult>('setStatus'))
      );
  }

  searchCmOrder(searchString: string = ''): Observable<Orders.ICMOrderList[]> {
    return this.http
      .get<IWsResult>(`${this.url}/get_cm_orders/`, {
        headers: { search: `${searchString}` },
      })
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('searchCmOrder')
        )
      )
      .pipe(map((result: IWsResult) => result.data));
  }

  updateOrder(order: Orders.OrderUpdate) {
    console.debug('sent order', order);
    return this.http
      .post<IWsResult>(`${this.url}/update_order/`, order)
      .pipe(
        catchError(this.errorHandlerServ.handleError<IWsResult>('updateOrder'))
      );
  }

  getAllDocuments(orderId: number) {
    return this.http
      .get<IWsResult>(`${this.url}/get_all_documens/${orderId}`)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getAllDocuments')
        )
      )
      .pipe(map((result: IWsResult) => result.data));
  }

  getDocuments(id: number): Observable<Documents.Document[]> {
    return this.http
      .get<IWsResult>(`${this.url}/get_documents/${id}`)
      .pipe(
        catchError(this.errorHandlerServ.handleError<IWsResult>('getOrder/id'))
      )
      .pipe(
        map((result: IWsResult) => {
          if (result.success)
            return result.data.map((item, index) => {
              let order = new Documents.Document(item, index + 1);
              return order;
            });
          else return null;
        })
      );
  }

  getBox(): Observable<Boxes.Box[]> {
    return this.http
      .get<IWsResult>(`${this.url}/get_box_types/`)
      .pipe(catchError(this.errorHandlerServ.handleError<IWsResult>('getBox')))
      .pipe(
        map((result: IWsResult) => {
          if (result.success)
            return result.data.map((item) => {
              let order = new Boxes.Box(item);
              return order;
            });
        })
      );
  }
  synchronizeOrders(orderId: number, gammaId: number) {
    return this.http
      .post(`${this.url}/update_from_gamma/${gammaId}/${orderId}`, {
        orderId,
        gammaId,
      })
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('SynchronizeOrder')
        )
      );
  }

  async getOrderDocuments(id): Promise<Documents.Document[]> {
    return await this.getOrdersDocuments().then(
      (docs: Map<number, Documents.Document[]>) => {
        console.debug('Docs:', docs);
        if (docs && docs.has(id)) 
          return docs.get(id);
        return null;
      }
    );
  }

  private async getOrdersDocuments() {
    return new Map(JSON.parse(localStorage.getItem(ORDERDOCUMENTS_KEY)));
  }

  getAllBoxLabels(orderId: number, boxes: number[]) {
    return this.http
      .post<IWsResult>(`${this.url}/get_all_box_label/`, {
        order_id: orderId,
        box_numbers: boxes,
      })
      .pipe(catchError(this.errorHandlerServ.handleError<IWsResult>('getBox')))
      .pipe(map((result: IWsResult) => result.data));
  }

  setStatus(order_id: number, status: number) {
    console.debug('status no', status);
    return this.http
      .post(`${this.url}/status_order/`, {
        order_id,
        os_order_status_cod: status,
      })
      .pipe(
        catchError(this.errorHandlerServ.handleError<IWsResult>('setStatus'))
      );
  }

  saveSignature(sign: OrderSignature) {
    console.debug('Signature', sign);
    return this.http.post<IWsResult>(`${this.url}/put_signature`, sign);
  }

  getGenerateDDT(orderId: number, printerId: number) {
    return this.http
      .get<IWsResult>(`${this.url}/generate_ddt/${orderId}/${printerId}`)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getAllDocuments')
        )
      );
    // .pipe(map((result: IWsResult) => result.data));
  }

  getGenerateELG(orderId: string | number, printerId: number | string) {
    return this.http
      .get<IWsResult>(`${this.url}/generate_elg/${orderId}/${printerId}`)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getGenerateELG')
        )
      );
  }

  saveOrder(order: Orders.IOrderDetails) {
    return this.http
      .post<IWsResult>(`${this.url}/save_order/`, order)
      .pipe(
        catchError(this.errorHandlerServ.handleError<IWsResult>('getOrder'))
      );
  }

  addProductToOrder(product: Products.ProductToAdd) {
    return this.http
      .post(`${this.url}/add_product_to_order/`, product)
      .pipe(
        catchError(this.errorHandlerServ.handleError<IWsResult>('setStatus'))
      );
  }

  deleteProductFromOrder(id: number) {
    return this.http
      .post<IWsResult>(`${this.url}/delete_product_from_order/${id}`, {
        headers: {},
      })
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('deleteProductFromOrder')
        )
      );
  }

  cancelOrder(orderId: string, email: string, password: string) {
    const url = `${this.url}/delete_order/${orderId}`;
    const body = {
      email: email,
      password: password,
    };
    return this.http
      .post<IWsResult>(url, body)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('deleteAccount')
        )
      );
  }

  lockNewOrder(orderId: string) {
    return this.http
      .post<IWsResult>(`${this.url}/lock_new_order/${orderId}`, {})
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('deleteAccount')
        )
      );
  }

  unlockNewOrder(orderId: string) {
    return this.http
      .post<IWsResult>(`${this.url}/unlock_new_order/${orderId}`, {})
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('deleteAccount')
        )
      );
  }

  getNewBorderos() {
    return this.http
      .get<IWsResult>(`${this.url}/get_borderos/`)
      .pipe(
        catchError(this.errorHandlerServ.handleError<IWsResult>('getBorderos'))
      );
  }

  getObjectives(): Observable<any> {
    return this.http
      .get<IWsResult>(`${this.url}/get_objectives`)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getObjectives')
        )
      )
      .pipe(map((response: IWsResult) => response.data));
  }

  setObjectives(objectives: Object) {
    return this.http.post<IWsResult>(`${this.url}/set_objectives`, objectives);
  }

  getObjectiveProgress() {
    return this.http.get<IWsResult>(`${this.url}/objective_progress`);
  }

  /************************* to be done!!!!***********************************/
  getErrors(): Observable<any> {
    return this.http
      .get<IWsResult>(`${this.url}/get_errors`)
      .pipe(
        catchError(this.errorHandlerServ.handleError<IWsResult>('getErrors'))
      )
      .pipe(map((response: IWsResult) => response.data));
  }

  setErrors(errors: Errors) {
    return this.http.post<IWsResult>(`${this.url}/set_errors`, errors);
  }

  modifyError(errors: Errors) {
    return this.http.post<IWsResult>(`${this.url}/modify_errors`, errors);
  }

  /************************************************************************ */
  getOrderErrors(order_id: number) {
    return this.http
      .get<IWsResult>(`${this.url}/get_order_errors/${order_id}`)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getOrderErrors')
        )
      )
      .pipe(map((response: IWsResult) => response.data));
  }

  createOrderError(error: OrderErrors) {
    return this.http.post<IWsResult>(`${this.url}/set_order_error`, error);
  }

  getTypesOfOrderErrors() {
    return this.http
      .get<IWsResult>(`${this.url}/get_type_of_order_errors`)
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getTypesOfErrors')
        )
      )
      .pipe(map((response: IWsResult) => response.data));
  }

  updateError(error) {
    return this.http.post<IWsResult>(`${this.url}/put_order_error`, error);
  }

  deleteError(error_order_id) {
    return this.http.post<IWsResult>(
      `${this.url}/delete_order_error/${error_order_id}`,
      error_order_id
    );
  }

  getInfoOrders(searchType: string, startDate: string, searchItem: string) {
    return this.http
      .get<IWsResult>(`${this.url}/get_info_orders`, {
        headers: {
          searchType: `${searchType}`,
          startDate: `${startDate}`,
          search: `${searchItem}`,
        },
      })
      .pipe(
        catchError(
          this.errorHandlerServ.handleError<IWsResult>('getInfoOrdersError')
        )
      )
      .pipe(map((result: IWsResult) => result.data));
  }
  getInfo(date: string) {
    return this.http
      .get<IWsResult>(`${this.url}/get_info`, {
        headers: {
          startDate: `${date}`,
        },
      })
      .pipe(
        catchError(this.errorHandlerServ.handleError<IWsResult>('getInfoError'))
      )
      .pipe(map((result: IWsResult) => result.data));
  }
  
}

import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Buttons } from 'src/app/models/buttons';
import { Orders } from 'src/app/models/Orders.model';
import { Shipments } from 'src/app/models/shipment';
import { AgentsService } from 'src/app/services/agents.service';
import { OrdersService } from 'src/app/services/orders.service';
import { IconsService } from 'src/app/services/shared/icons.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';

@Component({
  selector: 'app-accesso-funzionari-tracking',
  templateUrl: './accesso-funzionari-tracking.component.html',
  styleUrls: ['./accesso-funzionari-tracking.component.scss'],
})

export class AccessoFunzionariTrackingComponent {

  @Input() track_ID: any;
  @Input() token: string;
  @Input() shipment_id: any;
  @Input() openTrack = false;
  @Input() newTrack = false;
  @Input() agentID: string;

  public shipments: Shipments.ShipmentList;
  public orderS: Shipments.ShipmentItem = null;
  public order: Orders.Order = null;
  agentsTrackForm!: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private agentServ: AgentsService,
    private formBuilder: FormBuilder,
    private router: Router,
    public closeServ: OpenModalService,
    private iconService: IconsService,
    public shipmentServ: OrdersService
  ) { } 

  ngOnInit(): void {
    console.log('Token is: ', this.token);
    console.log('AgentID: ', this.agentID);
    console.log('TrackID: ', this.track_ID);
    console.log('ShipmentID: ', this.shipment_id);
    this.getTrackDetails();
  }

  public goBack() {
    this.close();
  }

  openNewWindow(url) {
    window.open(url, '_blank');
  }

  getTrackDetails() {
    this.agentsTrackForm = this.formBuilder.group({
      order_id: [''],
      or_order_date: [''],
      or_assigned_date: [''],
      or_order_number: [''],
      or_customer: [''],
      shipment_date: [''],
      sm_carrier_desc: [''],
      sm_ext_link: [''],
      sm_tracking_number: [''],
      sm_status_cod: [''],
      sm_status_desc: [''],
      or_shipping_address: [''],
    });
    if (this.track_ID) {
      this.shipmentServ
        .getShippmentById(this.token, this.track_ID, +this.agentID)
        .subscribe((shipmentData) => {
          console.log('This is the first shipment: ', shipmentData);
          this.orderS = shipmentData;
          console.log('This is the first shipment: ', this.orderS);
          this.orderS.buttons = new Buttons.Buttons().getShipmentDetails();
          console.debug('order', shipmentData);
        });
    }
    if (this.shipment_id) {
      this.shipmentServ
        .getShippmentById1(this.shipment_id)
        .subscribe((shipmentData) => {
          this.orderS = shipmentData;
          console.log("Mor de dorul lu' ma-ta");

          this.orderS.buttons = new Buttons.Buttons().getShipmentDetails();
          console.debug('order', shipmentData);
        });
    }
  }

  close() {
    if (this.shipment_id) {
      this.closeServ.offOrderTrack();
    } 
    else 
      this.closeServ.offTrack();
  }

}

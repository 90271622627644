import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class SidebarService {
  
  isClosed: boolean = false;
  isOpen: boolean = true;
  isOpenR: boolean = true;
  isBody: boolean = true;

  constructor() { }

  toggleSidebar() {
    this.isOpen = !this.isOpen;
  }

  toggleRightSidebar() {
    this.isOpenR = !this.isOpenR;
  }

  toggleBodyClass() {
    this.isBody = !this.isBody;
  }

}

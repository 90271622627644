<div class="k-overlay dark-background" *ngIf="opened"></div>

<div *ngIf="opened">
  <kendo-dialog
    title="Modifica Account"
    *ngIf="opened"
    (close)="goBackFunction()"
    [minWidth]="250"
    [width]="450"
    class="k-form dialog-text"
    [formGroup]="accountEditForm"
  >
    <hr class="hr-dialog" />
    <app-upload-component *ngIf="account" [accountId]="account.account_id">
    </app-upload-component>

    <form class="k-form" [formGroup]="accountEditForm">
      <div class="row" *ngIf="account">
        <kendo-formfield class="col-md-6">
          <kendo-label [for]="ac_first_name" text="Nome"></kendo-label>
          <kendo-textbox
            formControlName="ac_first_name"
            kendoTextBox
            #ac_first_name
            [readonly]="isEditable"
            rounded="full"
          ></kendo-textbox>
        </kendo-formfield>

        <kendo-formfield class="col-md-6">
          <kendo-label [for]="ac_last_name" text="Cognome"></kendo-label>
          <kendo-textbox
            formControlName="ac_last_name"
            kendoTextBox
            #ac_last_name
            [readonly]="isEditable"
            rounded="full"
          ></kendo-textbox>
        </kendo-formfield>
      </div>

      <div class="row">
        <kendo-formfield class="col-md-3">
          <kendo-label [for]="ac_phone" text="Telefono"></kendo-label>
          <kendo-textbox
            formControlName="ac_phone"
            kendoTextBox
            #ac_phone
            [readonly]="isEditable"
            rounded="full"
          ></kendo-textbox>
        </kendo-formfield>

        <kendo-formfield class="col-md-4">
          <kendo-label [for]="ac_email" text="Email"></kendo-label>
          <kendo-textbox
            formControlName="ac_email"
            kendoTextBox
            #ac_email
            [readonly]="isEditable"
            rounded="full"
          ></kendo-textbox>
        </kendo-formfield>
      </div>

      <div class="row">
        <kendo-formfield class="col-md-3 setDisplay-Prep">
          <kendo-label 
            [for]="ac_user_type_cod"
            class="title" 
            text="Tipologia"
          ></kendo-label>
          <kendo-combobox
            [data]="accountTypesSelect"
            formControlName="ac_user_type_cod"
            kendoTextBox
            #ac_user_type_cod
            (valueChange)="onAccountTypeSelected($event)"
            required
            textField="ac_typo"
            [valuePrimitive]="true"
            valueField="ac_number"
            class="dropdown-field setBorder-Prepp"
            placeholder="Tipo di account (admin/operatore)"
          >
          </kendo-combobox>
        </kendo-formfield>
      </div>

      <div class="row">
        <kendo-formfield class="col-md-3 setDisplay-Prep">
          <kendo-label 
            [for]="ac_expiration_date"
            class="title" 
            text="Data di scadenza"
          ></kendo-label>
          <kendo-datepicker
            formControlName="ac_expiration_date"
            #ac_expiration_date
            [readonly]="isEditable"
            class="dropdown-field setBorder-Preppp"
          ></kendo-datepicker>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label [for]="ac_note" text="Descrizione"> </kendo-label>
          <kendo-textbox
            formControlName="ac_note"
            kendoTextBox
            #ac_note
            [readonly]="isEditable"
            rounded="full"
            placeholder="Descrizione a caso.."
          ></kendo-textbox>
        </kendo-formfield>
      </div>

      <div class="text-buttons">
        <div class="button-right">
          <button kendoButton (click)="goBackFunction()" class="button-r">
            ANNULLA
          </button>
        </div>
        <div class="button-left">
          <button kendoButton (click)="goBackFunction()" class="button-l">
            SALVA
          </button>
        </div>
      </div>
      
    </form>
  </kendo-dialog>
</div>

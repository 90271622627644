<div class="setWarehouseWidthScreen">
  <div class="k-display-flex justify-content-center">
    <h1 class="setBlueList1">DIAKONIX</h1>
  </div>
  <hr class="hr-newBlue" />
  <div class="k-display-flex justify-content-center">
    <h1 class="setBlueList">Lista Ordini</h1>
  </div>

  <!-- <div class="k-display-flex justify-content-center">
    <h1 class="setBlueList" *ngIf="objectiveArray && progressArray">
      Obiettivo del mese: {{ this.progressArray.tp_monthly_objective }}
    </h1>
  </div>

  <div style="position: relative">
    <kendo-progressbar
      *ngIf="objectiveArray && progressArray"
      [value]="this.objectiveArray.tl_monthly_orders"
      [max]="this.progressArray.tp_monthly_objective"
      [label]="false"
    ></kendo-progressbar>

    <div
      style="position: absolute; top: 0; right: 0; padding: 0 8px"
      *ngIf="objectiveArray && progressArray"
    >
      <span>{{ objectiveArray.tl_monthly_orders }}</span>
      <span> / {{ progressArray.tp_monthly_objective }}</span>
    </div>
  </div> -->
  <div
    class="row"
    *ngFor="let order of openOrders; let i = index"
    style="padding-top: 5px; padding-bottom: 5px"
  >
    <div
      class="col-sm-1"
      [class.setBgForAlta]="order && order.tab_order_priority_desc == 'Alta'"
      [class.setBgForLow]="order && order.tab_order_priority_desc == 'Bassa'"
      [class.setBgForMedium]="order && order.tab_order_priority_desc == 'Media'"
      [class.setBgForSuspend]="order && order.tab_order_priority_desc == null"
      style="padding-top: 10px; padding-bottom: 10px"
    >
      <div
        class="k-display-flex justify-content-center buttonCircleWarehouse"
        style="background-color: white"
        [class.buttonCircleWarehouse3Dig]="order && i >= 98"
        [class.numberStyleWarehouse3Dig]="order && i >= 98"
      >
        <h1 class="numberStyleWarehouse">{{ i + 1 }}</h1>
      </div>
    </div>

    <div
      class="col-md-2 k-display-flex justify-content-center"
      style="padding-top: 10px; padding-bottom: 10px"
      [class.setBgForLow]="order && order.tab_order_priority_desc == 'Bassa'"
      [class.setBgForAlta]="order && order.tab_order_priority_desc == 'Alta'"
      [class.setBgForMedium]="order && order.tab_order_priority_desc == 'Media'"
      [class.setBgForSuspend]="order && order.tab_order_priority_desc == null"
    >
      <h1
        class="setWareHouseText"
        style="padding-top: 10px; font-size: 28px !important"
      >
        {{ order.or_order_number }}
      </h1>
    </div>
    <div
      class="col-md-3 k-display-flex justify-content-center"
      style="padding-top: 10px; padding-bottom: 10px"
      [class.setBgForLow]="order && order.tab_order_priority_desc == 'Bassa'"
      [class.setBgForAlta]="order && order.tab_order_priority_desc == 'Alta'"
      [class.setBgForMedium]="order && order.tab_order_priority_desc == 'Media'"
      [class.setBgForSuspend]="order && order.tab_order_priority_desc == null"
    >
      <h1
        class="setWareHouseText"
        style="padding-top: 10px; font-size: 28px !important"
      >
        {{ order.ac_first_name }} {{ order.ac_last_name }}
      </h1>
    </div>
    <div
      class="col k-display-flex justify-content-center"
      style="padding-top: 10px; padding-bottom: 10px"
      [class.setBgForLow]="order && order.tab_order_priority_desc == 'Bassa'"
      [class.setBgForAlta]="order && order.tab_order_priority_desc == 'Alta'"
      [class.setBgForMedium]="order && order.tab_order_priority_desc == 'Media'"
      [class.setBgForSuspend]="order && order.tab_order_priority_desc == null"
    >
      <kendo-progressbar
        style="position: relative; background: #f1f1f1; border-radius: 10px"
        class="cacat2555 jumari-prajite"
        [class.green-bg]="order && order.tab_order_status_perc == 100"
        [value]="
          order.tab_order_status_cod == 100
            ? order.tab_order_status_previous_perc
            : order.tab_order_status_perc
        "
        [label]="label_open"
      >
      </kendo-progressbar>
    </div>
    <div
      class="col-sm-2 k-display-flex justify-content-center"
      style="padding-top: 10px; padding-bottom: 10px"
      [class.setBgForLow]="order && order.tab_order_priority_desc == 'Bassa'"
      [class.setBgForAlta]="order && order.tab_order_priority_desc == 'Alta'"
      [class.setBgForMedium]="order && order.tab_order_priority_desc == 'Media'"
      [class.setBgForSuspend]="order && order.tab_order_priority_desc == null"
    >
      <h1 style="padding-top: 10px" class="setWareHouseText">
        {{ order.or_order_date | date : "dd/MM/yyyy" }}
      </h1>
    </div>
  </div>

  <div
    class="row"
    *ngFor="let cOrder of closedOrders; let i = index"
    style="padding-top: 5px; padding-bottom: 5px"
  >
    <div
      class="col-sm-1"
      [class.setBgForComplete]="cOrder && cOrder.complete_state == 'Complete'"
      style="padding-top: 10px; padding-bottom: 10px"
    >
      <div
        class="k-display-flex justify-content-center buttonCircleWarehouse jumari-prajite"
        style="background-color: white"
        [class.buttonCircleWarehouse3Dig]="cOrder && i >= 99"
        [class.numberStyleWarehouse3Dig]="cOrder && i >= 99"
      >
        <h1 class="numberStyleWarehouse">{{ i + 1 }}</h1>
      </div>
    </div>

    <div
      class="col-md-2 k-display-flex justify-content-center"
      style="padding-top: 10px; padding-bottom: 10px"
      [class.setBgForComplete]="cOrder && cOrder.complete_state == 'Complete'"
    >
      <h1 class="setWareHouseText" style="padding-top: 10px">
        {{ cOrder.or_order_number }}
      </h1>
    </div>
    <div
      class="col-md-3 k-display-flex justify-content-center"
      style="padding-top: 10px; padding-bottom: 10px"
      [class.setBgForComplete]="cOrder && cOrder.complete_state == 'Complete'"
    >
      <h1 class="setWareHouseText" style="padding-top: 10px">
        {{ cOrder.ac_first_name }} {{ cOrder.ac_last_name }}
      </h1>
    </div>
    <div
      class="col k-display-flex justify-content-center"
      style="padding-top: 10px; padding-bottom: 10px"
      [class.setBgForComplete]="cOrder && cOrder.complete_state == 'Complete'"
    >
      <kendo-progressbar
        style="
          position: relative;
          background: #f1f1f1;
          border-radius: 10px;
          right: 10px;
        "
        class="cacat2555 jumari-prajite"
        [class.green-bg1]="cOrder && cOrder.status_perc == 100"
        [value]="
          cOrder.status_perc == 100 ? cOrder.status_perc : cOrder.status_perc
        "
        [label]="label"
      >
      </kendo-progressbar>
    </div>
    <div
      class="col-sm-2 k-display-flex justify-content-center"
      style="padding-top: 10px; padding-bottom: 10px"
      [class.setBgForComplete]="cOrder && cOrder.complete_state == 'Complete'"
    >
      <h1
        style="padding-top: 10px; font-size: 24px !important"
        class="setWareHouseText"
      >
        {{ myDate | date : "dd/MM/yyyy" }}
      </h1>
    </div>
  </div>
  <div
    class="row"
    style="padding-top: 5px; padding-bottom: 5px; background-color: #0055aa"
  >
    <div class="k-display-flex pt-3 justify-content-center">
      <p
        style="
          font-family: 'Lato';
          font-style: normal;
          font-weight: 700;
          font-size: 40px;
          line-height: 48px;
          color: #ffffff;
        "
      >
        Ordini Completati: {{ closedOrders.length }}
      </p>
    </div>
  </div>
  <div
    class="row pt-3"
    style="
      padding-top: 5px;
      padding-bottom: 5px;
      background-color: #001c37;
      margin-top: 10px;
    "
    *ngIf="obiettivi"
  >
    <div       
      class="k-display-flex justify-content-center"
    >
      <p
        style="
          font-family: 'Lato';
          font-style: normal;
          font-weight: 700;
          font-size: 40px;
          line-height: 48px;
          color: #ffffff;
        "
      >
        Obiettivo del giorno: {{ obiettivi.tp_daily_objective }}
      </p>
      <!--<div
        class="col-sm-1"
        [class.setBgForComplete]="prog && prog.complete_state == 'Complete'"
        style="padding-top: 10px; padding-bottom: 10px"
      >
        <div
          class="k-display-flex justify-content-center buttonCircleWarehouse jumari-prajite"
          style="background-color: white"
          [class.buttonCircleWarehouse3Dig]="prog && i >= 99"
          [class.numberStyleWarehouse3Dig]="prog && i >= 99"
        >
          <h1 class="numberStyleWarehouse">{{ i + 1 }}</h1>
        </div>
      </div>
      <div
        class="col k-display-flex justify-content-center"
        style="padding-top: 10px; padding-bottom: 10px"
        [class.setBgForComplete]="prog && prog.complete_state == 'Complete'"
      >
        <kendo-progressbar
          style="
            position: relative;
            background: #f1f1f1;
            border-radius: 10px;
            right: 10px;
          "
          class="cacat2555 jumari-prajite"
          [class.green-bg1]="prog && prog.status_perc == 100"
          [value]="
            prog.status_perc == 100 ? prog.status_perc : prog.status_perc
          "
          [label]="label"
        >
        </kendo-progressbar>
      </div>-->
    </div>
    <div class="k-display-flex justify-content-center">
      <p
        style="
          font-family: 'Lato';
          font-style: normal;
          font-weight: 700;
          font-size: 40px;
          line-height: 48px;
          color: #ffffff;
        "
      >
        Obiettivo del mese: {{ obiettivi.tp_monthly_objective }}
      </p>
    </div>
  </div>
</div>

import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { parseISO } from 'date-fns';
import { Subscription } from 'rxjs';
import { Orders } from 'src/app/models/Orders.model';
import { Shipments } from 'src/app/models/shipment';
import { OrdersService } from 'src/app/services/orders.service';
import { PagesService } from 'src/app/services/pages.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';

@Component({
  selector: 'app-status-spedizioni',
  templateUrl: './status-spedizioni.component.html',
  styleUrls: ['./status-spedizioni.component.scss'],
})

export class StatusSpedizioniComponent implements OnInit {

  public shipments: Shipments.ShipmentList[] = [];
  public gridData: Shipments.ShipmentList[] = [];
  public order: Orders.BaseOrder;
  public filteredShipments: Shipments.ShipmentList[] = null;
  public isSearched: boolean = false;
  public pageSize = 20;
  public customers: string[];
  public dates: string[];
  public order_numbers: string[];
  public gridView: any;
  public skip = 0;
  public data: unknown[];
  public loading: boolean = false;
  public searchString = 'Filtra ordini per cliente';
  public carrierString = 'Filtra ordini per corriere';
  public searchDate = 'Filtra ordini per data';
  public searchOrderNumber = ' Filtra ordini per ordini numero';

  filterTerm: string = '';
  clickedId: any;
  chosenCarrier: string = null;
  carriers: string[] = [];
  shipment_id: any;
  customer: string = '';
  carrierIndex: number;
  carrier: string = '';
  date: string = '';
  order_number: string = '';
  showAll: string = 'Mostra tutti';
  showAll2: string = 'Mostra tutti';
  showAll3: string = 'Mostra tutti';
  customerIndex: number;
  startDate: Date = new Date(2021, 0, 1);
  endDate: Date = new Date();

  filterzm = {
    startingDate: null,
  };
  min: Date = new Date(2020, 0, 1); 
  max: Date = new Date(); 
  yearRange = "2000:2099"; 


  filters = {
    dateOrginizedBy: {
      text: 'Data ordine',
      value: 'or_order_number',
    },
    startingDate: this.startDate,
    endDate: this.endDate,
    minDate: new Date(),
    maxDate: new Date(),
  };

  dateBy: any[] = [
    {
      text: 'Data ordine',
      value: 'or_order_number',
    },
  ];
  subscriber: Subscription[] = [];
  searchParam: string = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private orderService: OrdersService,
    public closeServ: OpenModalService,
    private pagesService: PagesService
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.subscriber.push(
      this.activatedRoute.queryParams.subscribe((params) => {
        console.log('Params are', params);

        if (Object.keys(params).length === 0) {
          this.getShipmentList();
          this.loading = false;
        } 
        else {
          this.searchParam = params['search'];
          const dateParam = params['data'];
          this.filters.startingDate = dateParam ? parseISO(dateParam) : null;
          this.getShipmentList(this.searchParam);
          this.loading = false;
        }
      })
    );
    this.getShipmentList();
  }

  monthNames: string[] = [
    'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno',
    'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'
  ];

  selectedMonth: string;

  onMonthSelect(month: string) {
    // Qui puoi gestire la selezione del mese
    console.log('Mese selezionato:', month);
  }

  getFormattedMonth(date: Date): string {
    const monthNames = [
      'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno',
      'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'
    ];
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const capitalizedMonth = monthNames[monthIndex].charAt(0).toUpperCase() + monthNames[monthIndex].slice(1);
    return `${capitalizedMonth} ${year}`;
  }

  filterByMonth(month: string) {
    if (month) {
      const monthIndex = this.monthNames.findIndex(m => m === month);
      const filteredShipments = this.shipments.filter(shipment => {
        const shipmentMonthIndex = new Date(shipment.shipment_date).getMonth();
        return shipmentMonthIndex === monthIndex;
      });
      this.gridData = filteredShipments;
    } else {
      this.gridData = this.shipments;
    }
  }

  onInputChanged(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.validity && inputElement.validity.valueMissing) {
      this.getShipmentList();
    }
  }

  onMessengerNameSelect(name: string, i: number) {
    this.customer = this.searchString;
    console.log('I is: ', this.searchString);
    if (name === this.showAll) 
      this.customer = null;
  }

  onCarrierSelect(name: string, i: number) {
    this.carrier = name;
    if (name === this.showAll) 
      this.carrier = null;
  }

  onOrderNumber(name: string, i: number) {
    this.order_number = name;
    console.log('I is: ', i);
    if (name === this.showAll3) 
      this.order_number = null;
  }

  onDateSelect(name: string, i: number) {
    this.date = name;
    console.log('I is: ', i);
    if (name === this.showAll2) 
      this.date = null;
  }

  private loadProducts(): void {
    this.gridView = {
      data: this.gridData.slice(this.skip, this.skip + this.pageSize),
      total: this.gridData.length,
    };
    this.loading = false;
  }

  deepCopy(obj) {
    let copy;
    if (null == obj || 'object' != typeof obj) 
      return obj;
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }
    if (obj instanceof Array) {
      copy = [];
      for (let i = 0, len = obj.length; i < len; i++) {
        copy[i] = this.deepCopy(obj[i]);
      }
      return copy;
    }
    if (obj instanceof Object) {
      copy = {};
      for (let attr in obj) {
        if (obj.hasOwnProperty(attr)) {
          copy[attr] = this.deepCopy(obj[attr]);
        }
      }
      return copy;
    }
    throw new Error('Impossibile copiare obj! Il suo tipo non è supportato.');
  }

  getShipmentList(searchString?: string) {
    if (searchString) 
      this.filterTerm = searchString;
    this.orderService.getShippmentsList(this.filterTerm).subscribe((result) => {
      this.shipments = result;
      this.gridData = this.shipments;
      this.loadProducts();
      this.dates = [
        ...new Set(
          result.map((date) => {
            let formattedDate = formatDate(
              date?.or_order_date,
              'dd/MM/yyyy',
              'it'
            );
            console.log('Formatted date is: ', formattedDate);
            console.log('Date is: ', date?.or_order_date);
            return formattedDate;
          })
        ),
      ];
      this.order_numbers = [
        ...new Set(
          result.map((client) => {
            return client.or_order_number;
          })
        ),
      ];

      this.carriers = [
        ...new Set(
          result.map((item) => {
            return item.sm_carrier_desc;
          })
        ),
      ];
      this.customers = [
        ...new Set(
          result.map((client) => {
            return client.or_customer;
          })
        ),
      ];
      this.carriers.unshift(this.showAll);
      this.customers.unshift(this.showAll);
      this.dates.unshift(this.showAll2);
      this.order_numbers.unshift(this.showAll3);
      this.loading = false;
    });
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadProducts();
  }

  async filterList(evt) {
    const searchTerm = evt.srcElement.value;
    this.filterTerm = searchTerm;
    this.isSearched = true;
    if (!this.filterTerm || this.filterTerm == '') {
      this.isSearched = false;
      this.filteredShipments = null;
      return;
    }
    this.orderService.getShippmentsList(searchTerm).subscribe((result) => {
      this.filteredShipments = result;
    });
  }

  searchOrders() {
    this.getShipmentList();
  }

  async clearList(evt) {
    this.isSearched = false;
    this.filteredShipments = null;
    this.filterTerm = null;
  }

  doRefresh(event) {
    console.debug('Begin async operation');
    this.orderService.getShippmentsList().subscribe((result) => {
      this.shipments = result;
      console.debug('orders: ', this.shipments);
    });
    setTimeout(() => {
      console.debug('Async operation has ended');
      event.target.complete();
    }, 2000);
  }

  goToDetails(id: any) {
    this.clickedId = id;
    this.closeServ.onId();
    console.log('ID is: ', id);
  }

  goToTracking(trackId) {
    this.shipment_id = trackId;
    this.closeServ.onOrderTrack();
  }

  ngOnDestroy() {
    this.subscriber.forEach((subscriber) => subscriber.unsubscribe());
  }
  
}

<div class="k-overlay dark-background"></div>

<div class="dark-background">
  <kendo-dialog
    title=""
    (close)="goBackFunction()"
    *ngIf="opened"
    [minWidth]="250"
    [width]="850"
    [maxWidth]="1150"
    class="k-form"
    [formGroup]="errorForm"
    style="left: 6%"
  >
    <div
      style="
        display: flex;
        flex-direction: row;
        justify-content: end;
        margin-top: -15px;

        position: fixed;
        margin-left: -35px;
      "
    >
      <button
        (click)="goBackFunction()"
        class="btn btn3"
        rounded="full"
        style="
          background-color: #e90000;
          color: #ffffff;
          width: 35px !important;
          height: 35px !important;
        "
      >
        <i
          class="bx bx-x bx-sm posRelBox2"
          style="
            color: #ffffff;
            bottom: 10px;
            position: relative !important;
            left: -80% !important;
            top: -1px !important;
          "
        ></i>
      </button>
    </div>
    <h1 class="page-title">
      Inserisci Errore
      <span *ngIf="errorNew">{{ errorNew.err_id }}</span>
    </h1>
    <hr class="hr-dialog" />
    <div class="container">
      <form
        class="k-form white-bg cont-sx"
        [formGroup]="errorForm"
        (ngSubmit)="addErrorMethod(errorForm)"
      >
        <fieldset class="k-form-fieldset">
          <div class="row">
            <!-- <kendo-formfield class="col-md setDisplay-Prep">
                  <kendo-label class="title" text="ID errore"></kendo-label>
                  <input
                    formControlName="order_id"
                    kendoTextBox
                    #order_id
                    [readonly]="!isEditable"
                    rounded="full"
                    class="setBorder-Prepp"
                  />
                </kendo-formfield> -->

            <!-- <kendo-formfield class="col-md setDisplay-Prep">
                                    <kendo-label
                                        class="title"
                                        text="Tipo"
                                    ></kendo-label>
                                    <input
                                        formControlName="oe_error_gravity"
                                        kendoTextBox
                                        #oe_error_gravity
                                        rounded="full"
                                        [required]="true"
                                        class="setBorder-Prepp"
                                    />
                                </kendo-formfield> -->

            <kendo-formfield class="col-md setDisplay-Prep">
              <kendo-label class="title" text="Descrizione"></kendo-label>
              <input
                formControlName="oe_description"
                kendoTextBox
                #oe_description
                rounded="full"
                [required]="true"
                class="setBorder-Prepp"
              />
            </kendo-formfield>

            <kendo-formfield class="col-md setDisplay-Prep">
              <kendo-label class="title" text="Valore"></kendo-label>
              <kendo-combobox
                [data]="values"
                valueField="oe_error_gravity"
                textField="oe_error_gravity"
                rounded="full"
                formControlName="oe_error_gravity"
                [valuePrimitive]="true"
                [required]="true"
                #oe_error_gravity
                class="setBorder-Prepp"
              >
              </kendo-combobox>
              <kendo-formerror
                *ngIf="
                  (errorForm.get('oe_error_gravity')?.touched ||
                    errorForm.get('oe_error_gravity')?.dirty) &&
                  errorForm.get('oe_error_gravity')?.errors
                "
                >Valore non impostato!
              </kendo-formerror>
            </kendo-formfield>

            <kendo-formfield class="col-md setDisplay-Prep">
              <kendo-label class="title" text="Tipo"></kendo-label>
              <kendo-combobox
                [data]="errorTypes"
                valueField="error_type_code"
                textField="error_type_desc"
                rounded="full"
                formControlName="oe_error_type_id"
                [valuePrimitive]="true"
                [required]="true"
                #oe_error_type_id
                class="setBorder-Prepp"
              >
              </kendo-combobox>
              <kendo-formerror
                *ngIf="
                  (errorForm.get('oe_error_type_id')?.touched ||
                    errorForm.get('oe_error_type_id')?.dirty) &&
                  errorForm.get('oe_error_type_id')?.errors
                "
                >Valore non impostato!
              </kendo-formerror>
            </kendo-formfield>
          </div>
        </fieldset>
      </form>
    </div>
    <div class="text-buttons" style="margin-top: 0.5em">
      <div class="button-left">
        <button
          (click)="addErrorMethod(errorForm)"
          class="btn btn3"
          rounded="full"
          type="submit"
          [disabled]="!errorForm.valid"
          style="
            background-color: #017efa;
            color: #ffffff;
            justify-content: center !important;
            display: flex;
            width: 292px !important;
            height: 42px !important;
          "
          [disabled]="errorForm.invalid"
        >
          <i
            class="bx bx-save bx-sm posRelBox2"
            style="color: #ffffff; right: 6px; bottom: 10px; position: relative"
          ></i>
          <p class="posRelBox2">SALVA</p>
        </button>
      </div>
    </div>
  </kendo-dialog>
</div>

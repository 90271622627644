export interface IAgent {
  agent_id: string;
  ag_first_name: string;
  ag_last_name: string;
  ag_email: string;
  ag_mobile: string;
  ag_phone: string;
  ag_url: string;
  ag_active?: string;
  ag_note: string;
  // ag_token: string;
}

export interface AgentOrders {
  order_id: number;
  or_assigned_id: number;
  or_order_date: string;
  or_assigned_date: number;
  or_order_number: string;
  or_quote_number: string;
  or_closing_date: string;
  or_customer: string;
  or_agent_id: string;
  tab_order_priority_cod: number;
  tab_order_priority_desc: string;
  tab_order_priority_color: string;
  tab_order_status_desc: string;
  tab_order_status_cod: number;
  tab_order_status_perc: number;
  tab_order_status_previous_perc: number;
  ac_first_name: string;
  ac_last_name: string;
  ag_first_name: string;
  ag_last_name: string;
  op_articles_number: number;
  or_partial: string;
  ag_token: string;
  os_tracking_number: string;
  or_gamma_id: number;
  ag_note: string;
  or_note: string;
  or_ddt_number?: string;
  or_address: string;
  or_url_ddt: string;
}

export class IAgentOrders implements AgentOrders {
  order_id: number;
  or_assigned_id: number;
  or_order_date: string;
  or_assigned_date: number;
  or_order_number: string;
  or_quote_number: string;
  or_customer: string;
  or_agent_id: string;
  tab_order_priority_cod: number;
  tab_order_priority_desc: string;
  tab_order_priority_color: string;
  tab_order_status_desc: string;
  tab_order_status_cod: number;
  tab_order_status_perc: number;
  tab_order_status_previous_perc: number;
  ac_first_name: string;
  ac_last_name: string;
  ag_first_name: string;
  ag_last_name: string;
  op_articles_number: number;
  or_partial: string;
  ag_token: string;
  os_tracking_number: string;
  or_gamma_id: number;
  or_closing_date: string;
  ag_note: string;
  or_note: string;
  or_ddt_number?: string;
  or_address: string;
  or_url_ddt: string;
  constructor(AgentsOrdersData: IAgentOrders) {
    if (!AgentsOrdersData) return;
    this.order_id = AgentsOrdersData.order_id;
    this.or_gamma_id = AgentsOrdersData.or_gamma_id;
    this.or_assigned_id = AgentsOrdersData.or_assigned_id;
    this.or_order_date = AgentsOrdersData.or_order_date;
    this.or_assigned_date = AgentsOrdersData.or_assigned_date;
    this.or_closing_date = AgentsOrdersData.or_closing_date;
    this.or_order_number = AgentsOrdersData.or_order_number;
    this.or_quote_number = AgentsOrdersData.or_quote_number;
    this.or_customer = AgentsOrdersData.or_customer;
    this.or_agent_id = AgentsOrdersData.or_agent_id;
    this.tab_order_priority_cod = AgentsOrdersData.tab_order_priority_cod;
    this.tab_order_priority_desc = AgentsOrdersData.tab_order_priority_desc;
    this.tab_order_priority_color = AgentsOrdersData.tab_order_priority_color;
    this.tab_order_status_desc = AgentsOrdersData.tab_order_status_desc;
    this.tab_order_status_cod = AgentsOrdersData.tab_order_status_cod;
    this.tab_order_status_perc = AgentsOrdersData.tab_order_status_perc;
    this.tab_order_status_previous_perc =
      AgentsOrdersData.tab_order_status_previous_perc;
    this.ac_first_name = AgentsOrdersData.ac_first_name;
    this.ac_last_name = AgentsOrdersData.ac_last_name;
    this.ag_first_name = AgentsOrdersData.ag_first_name;
    this.ag_last_name = AgentsOrdersData.ag_last_name;
    this.op_articles_number = AgentsOrdersData.op_articles_number;
    this.or_partial = AgentsOrdersData.or_partial;
    this.ag_token = AgentsOrdersData.ag_token;
    this.os_tracking_number = AgentsOrdersData.os_tracking_number;
    this.ag_note = AgentsOrdersData.ag_note;
    this.or_note = AgentsOrdersData.or_note;
    this.or_ddt_number = AgentsOrdersData?.or_ddt_number;
    this.or_address = AgentsOrdersData.or_address;
    this.or_url_ddt = AgentsOrdersData.or_url_ddt;
  }
}

import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IMessageList } from 'src/app/models/IMessage.model';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';
import { Products } from 'src/app/models/Product.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-info-agente',
  templateUrl: './info-agente.component.html',
  styleUrls: ['./info-agente.component.scss'],
})

export class InfoAgenteComponent implements OnInit {

  @Input() opened = true;
  @Input() message_id: string = '-1';
  @Input() isPhotoOn: string = '-1';
  @Output() modalClosed = new EventEmitter<void>();
  public productsNew: Products.IProductToSend[] = [];

  message: IMessageList = null;
  id: number = 0;

  constructor(
    private router: Router, 
    public closeSrv: OpenModalService
  ) { }

  ngOnInit(): void { }

  public close() {
    this.opened = false;
  }

}

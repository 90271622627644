<section id="not-found">
    <div id="title">Errore 404</div>
    <div class="circles">
      <p>404<br>
        <small>PAGINA NON TROVATA</small>
      </p>
      <span class="circle big"></span>
      <span class="circle med"></span>
      <span class="circle small"></span>
    </div>
</section>

import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonContent } from '@ionic/angular';
import { MessageService } from '@progress/kendo-angular-l10n';
import {
  SVGIcon,
  xIcon,
  eyeIcon,
  bellIcon,
  infoCircleIcon,
  questionCircleIcon,
} from '@progress/kendo-svg-icons';
import {
  Subject,
  merge,
  from,
  Observable,
  map,
  BehaviorSubject,
  takeUntil,
  of,
} from 'rxjs';
import { scan } from 'rxjs';
import { Notifications } from 'src/app/models/IMessage.model';
import { IWsResult } from 'src/app/models/ws_result.model';
import Swal from 'sweetalert2';
import { MessagesService } from 'src/app/services/messages.service';
import { PushNotificationDataService } from 'src/app/services/shared/push-notification-data.service';
import { compareDesc, parseISO } from 'date-fns';
// import {
//   CommandExecuteEvent,
//   Message,
//   PromptCommand,
//   PromptOutput,
//   PromptRequestEvent,
//   SendMessageEvent,
//   User,
// } from "@progress/kendo-angular-conversational-ui";
//import { Message, User, SendMessageEvent } from '@progress/kendo-angular-conversational-ui';
//import * as  marked from 'marked';
// import * as markdownit from 'markdown-it';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit {
  // scrollPercentage = -1;
  // id: number = 0;

  @ViewChild(IonContent) content: IonContent;
  currentUserId = 0;
  scrollPercentage = 0;

  // public notification: Notifications.Conversation = null;
  // limitedNotifications: Notifications.IConversationMessage[] = [];
  // userId: number = 0;
  // notificationsLimit: number = 1;
  // updateInifiniteElem: number = 20;
  // private ngUnsubscribe = new Subject();
  messages: any[] = [];
  selectedConversation: any;
  messagesInConversation: any;
  public notification: Notifications.Conversation = null;
  limitedNotifications: Notifications.IConversationMessage[] = [];
  //lastMsg: Notifications.IConversationMessage[] = this.limitedNotifications[this.limitedNotifications.length -1];
  notificationsLimit: number = 1;
  updateInifiniteElem: number = 20;
  public message: Notifications.Message = new Notifications.Message(null);
  notificationReceived: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  messagesBeh: BehaviorSubject<Notifications.IConversationMessage> =
    new BehaviorSubject<Notifications.IConversationMessage>(null);
  private ngUnsubscribe = new Subject();
  notificationData: any;
  WonderPush: any;
  firstEnteredOnPage: boolean = false;
  msgTitle: string;
  selectedMessage: any;

  public conversations: Notifications.Conversations[] = [];
  groupChat: any;

  constructor(
    private router: Router,
    private notificationService: MessagesService,
    private messagesService: MessagesService,
    private changeDetectorRef: ChangeDetectorRef,
    private pushNotificationService: PushNotificationDataService
  ) {}

  ngOnInit() {
    this.firstEnteredOnPage = false;
    this.notificationsLimit = this.updateInifiniteElem;
    // this.getUserConsentIfNeeded();
    this.account = JSON.parse(localStorage.getItem('currentUser') || '{}');
    console.log('Account is', this.account);
    navigator.serviceWorker.addEventListener('message', (event) => {
      const message = event.data;
      if (message || message.type === 'pushNotification' || 'simple') {
        // Update BehaviorSubject with data from notification click
        console.log('Received the notification on app component', message);
        this.pushNotificationService.updateNotificationData(message.data);
      }
    });
    window.addEventListener('message', (event) => {
      if (event.data && event.data.type === 'pushNotification') {
        // Store the notification data received from service worker
        this.notificationData = event.data.data;
        console.log('Received push notification data:', this.notificationData);
      }
    });

    this.messagesService.getConversationsGroup().subscribe((data) => {
      console.log('Data on grupus', data);
      console.log('CONVERSATIONS ARE', this.conversations);
      this.conversations = [...this.conversations, ...data].sort((a, b) =>
        compareDesc(parseISO(a.ms_datetime), parseISO(b.ms_datetime))
      );
      console.log('che', this.conversations);
    });
    if (this.content) {
      setTimeout(() => {
        this.content.scrollToBottom(400);
      }, 300);
    }
    this.messagesService.getConversations().subscribe((data) => {
      this.messages = data;
      this.selectedConversation = this.messages[0];
      console.log('Which one do I select', this.selectedConversation);
      if (this.selectedConversation)
        this.enterConversation(this.selectedConversation);
      console.log('Selected conversation is', this.selectedConversation);
      console.log('Data is datatata', this.messages);
    });
    if (this.content) {
      setTimeout(() => {
        this.content.scrollToBottom(400);
      }, 300);
    }
  }

  enterConversation(message: any) {
    console.log('Selected message is', message);
    this.selectedConversation = message;
    this.selectedMessage = message;
    console.log('Conversation is', this.selectedConversation);
    this.messagesService
      .getConversation(message.ms_sender_id)
      .subscribe((data) => {
        this.notification = data.data;
        this.limitedNotifications = this.notification?.messages.slice(
          -this.notificationsLimit
        );
        console.log('DEINE NOTIFIKE IST', this.notification);
        setTimeout(() => {
          if (this.content) {
            console.log('Should be scrolling down');
            this.scrollDown();
          }
        }, 200);
        // Handle push notifications only if there is new data
        if (
          this.pushNotificationService.notificationDataSubject &&
          this.pushNotificationService.notificationDataSubject.getValue()
        ) {
          const data =
            this.pushNotificationService.notificationDataSubject.getValue();
          if (data._wp && data._wp.alert && data._wp.alert.tag !== 'latest') {
            console.log('Data from push notification:', data);
            const ms_datetime = new Date();
            const ms_recipient_id = this.account.account_id;
            const ms_sender_id =
              +data._wp.alert.tag?.substring(
                data._wp.alert.tag?.lastIndexOf('|') + 1
              ) | 0;
            const ms_title = data._wp.alert.body;
            const newNotification: Notifications.IConversationMessage = {
              ms_title: ms_title,
              ms_message: ms_title,
              ms_recipient_id: ms_recipient_id,
              ms_sender_id: ms_sender_id,
              //ms_recipient_id: ms_sender_id,
              //ms_sender_id: ms_recipient_id,
              ms_datetime: ms_datetime.toISOString(),
            };
            console.log('New notification:', newNotification);
            this.notification.messages.push(newNotification);
            // Check if content is ready before scrolling
            setTimeout(() => {
              if (this.content) {
                console.log('Should be scrolling down');
                this.scrollDown();
              }
            }, 200);
          }
        }
      });
  }

  enterGroupChat(message: any) {
    console.log('Selected message is', message);
    this.selectedConversation = message;
    this.selectedMessage = message;

    console.log('Conversation is', this.selectedConversation);
    this.messagesService
      .getConversationGroup(message.order_id)
      .subscribe((data) => {
        if (!data.success) {
          return;
        }

        this.notification.messages = data.data;

        this.limitedNotifications = this.notification?.messages.slice(
          -this.notificationsLimit
        );
        this.scrollDown();
        console.log('this.notification ', this.notification);
      });
  }

  numDisplayedMessages = 10;
  account: any;

  scrollDown() {
    console.log('I should scroll down');
    this.content.scrollToBottom(0);
  }

  async contentScrolled(ev) {
    const scrollElement = await this.content.getScrollElement();
    const scrollPosition = ev.detail.scrollTop;
    const totalContentHeight = scrollElement.scrollHeight;
    this.scrollPercentage =
      scrollPosition / (totalContentHeight - ev.target.clientHeight) + 0.001;
    this.changeDetectorRef.detectChanges();
  }

  async getUserConsentIfNeeded() {
    const userHasConsent = await window.WonderPush.getUserConsent();
    if (!userHasConsent) {
      const userConsent = await this.askForUserConsent();
      if (!userConsent) {
        // Utente ha rifiutato il consenso
        Swal.fire({
          icon: 'error',
          title: 'Oh no!',
          text: 'Per ricevere le notifiche, è necessario accettare il consenso!',
          timer: 3000,
          timerProgressBar: true,
        });
        console.log(
          "L'utente ha rifiutato il consenso per ricevere le notifiche!"
        );
        return;
      }
    }
    // L'utente ha già dato il consenso o l'ha appena accettato, procedi con setUserNotificationToken
    await this.setUserNotificationToken(this.account.ac_notify_token);
  }

  async askForUserConsent(): Promise<boolean> {
    return new Promise((resolve) => {
      Swal.fire({
        title: 'Consenso alle notifiche push',
        text: 'Vuoi ricevere le notifiche push?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sì',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          window.WonderPush.setUserConsent(true);
          Swal.fire({
            icon: 'success',
            title: 'Accettato!',
            text: "L'utente ha accettato di iscriversi alle notifiche!!!",
            timer: 1500,
            timerProgressBar: true,
          });
          console.log("L'utente ha accettato!");
        } else {
          // L'utente ha rifiutato il consenso
          Swal.fire({
            icon: 'error',
            title: 'Oh no!',
            text: "L'utente ha rifiutato il consenso!!!",
            timer: 1500,
            timerProgressBar: true,
          });
          console.log(
            "L'utente ha rifiutato il consenso per ricevere le notifiche!"
          );
        }
        resolve(result.isConfirmed);
      });
    });
  }

  async getUserConsent(): Promise<boolean> {
    return new Promise((resolve) => {
      const userConsent = confirm('Vuoi ricevere notifiche push?');
      resolve(userConsent);
    });
  }

  async setUserNotificationToken(token: string) {
    await window.WonderPush.setUserId(token);
    await window.WonderPush.getUserConsent().then((result) => {
      console.log('getUserConsent', result);
      if (result === false) {
        Swal.fire({
          icon: 'error',
          title: 'Oh no!',
          text: "L'utente ha rifiutato il consenso per ricevere le notifiche!!!",
          timer: 1500,
          timerProgressBar: true,
        });
        console.log("Il consenso dell'utente non è stato dato!");
        return;
      }
      this.subscribeUser(token);
    });
  }

  /*async getUserConsentIfNeeded() {
    const userHasConsent = await window.WonderPush.getUserConsent();
    if (!userHasConsent) {
      const userConsent = confirm("Vuoi ricevere notifiche push?");
      if (userConsent) {
          await this.setUserNotificationToken(this.account.ac_notify_token);
          await this.subscribeUser(this.account.ac_notify_token);
      } 
      else {
        Swal.fire({
          icon: 'error',
          title: 'Oh no!',
          text: 'L\'utente ha rifiutato il consenso per ricevere le notifiche!!!',
          timer: 1500,
          timerProgressBar: true,
        });
        console.log("L'utente ha rifiutato il consenso per ricevere le notifiche.");
      }
    }
  }

  async getUserConsent(userConsent: boolean, token: string) {
    userConsent = confirm("Vuoi ricevere notifiche push?");
    if (userConsent) {
      await this.setUserNotificationToken(token);
      await this.subscribeUser(token);
    } 
    else {
      Swal.fire({
        icon: 'error',
        title: 'Oh no!',
        text: 'L\'tente ha rifiutato il consenso per ricevere le notifiche!!!',
        timer: 1500,
        timerProgressBar: true,
      });
      console.log("L'utente ha rifiutato il consenso per ricevere le notifiche.");
    }
  }*/

  async subscribeUser(token) {
    await window.WonderPush.subscribeToNotifications()
      .then(async (result) => {
        console.log('subscribed', result);
        await window.WonderPush.isSubscribedToNotifications().then((result) =>
          console.log('is subscribed', result)
        );
        if (result) {
          console.log('Bine boss');
          this.subscribeToPushNotifications();
        }
        // this.wonderPush.downloadAllData()
        // .then(result=>alert("downloaded" + result));
        // document.addEventListener('wonderpush.notificationOpen', function(event:any) {
        //   console.log('Notification opened', event?.notification);
        //   alert('Notification opened')
        //   if (event?.notificationType === 'data') {
        //     alert('Silent notification')
        //     console.log('Silent notification', event?.notification);
        //   }
        // });
        return;
      })
      .catch((error: any) => console.error(error));
  }

  async subscribeNotifications() {
    // await this.getUserConsent(true, this.account.ac_notify_token);
    await this.askForUserConsent();
    await this.subscribeUser(this.account.ac_notify_token);
    await this.setUserNotificationToken(this.account.ac_notify_token);
    // await this.router.navigateByUrl(
    //   this.isUser ? '/order-preparation' : '/dashboard',
    //   { replaceUrl: true }
    // );
  }

  async subscribeToPushNotifications() {
    const myDelegate = {
      urlForDeepLink: function (notif, buttonIndex) {
        // Do something on notification opened
        console.log('urlForDeepLink', notif);
        console.log('urlForDeepLink', buttonIndex);
      },
      onNotificationOpened: function (notif, buttonIndex) {
        // Do something on notification opened
        console.log('onNotificationOpened', notif);
        console.log('onNotificationOpened', buttonIndex);
      },
      onNotificationReceived: function (notif) {
        console.log('onNotificationReceived', notif);
        const message: { text: string; tag: string } = notif._wp.alert;
        let newMessage: Notifications.IConversationMessage = {
          ms_title: message.text,
          ms_message: message.text,
          ms_recipient_id:
            +message.tag?.substring(message?.tag?.lastIndexOf('|') + 1) | 0,
          ms_sender_id: 0,
          ms_order_id: null,
          ms_datetime: new Date().toISOString(),
        };
        console.log('onNotificationReceived newMessage', newMessage);
        console.log(
          'onNotificationReceived this.notification',
          this.notificationReceived
        );
        this.notificationReceived.next(newMessage);
        // Do something on notification received.
        //
        // For Cordova, this method is not called on Android devices when your app is not started because we haven't found a safe way to start cordova and execute a single piece of code.
        // The entire webview would be loaded, leading to poor battery life.
        //
        // On iOS, only notifications with content-available=1 will trigger this method.
        // See https://developer.apple.com/documentation/usernotifications/setting_up_a_remote_notification_server/pushing_background_updates_to_your_app?language=objc
        // WonderPush automatically sets content-available=1 when the push notification has a JSON payload.
      },
    };
    window.WonderPush.setDelegate(myDelegate);
    this.notificationReceived.subscribe((result) => {
      console.log(
        'PushNotificationsService.notificationReceived.subscribe',
        result
      );
      this.messagesBeh.next(result);
    });
  }

  sendMessage() {
    if (this.message.ms_title === undefined) {
      return;
    }
    const inputText = this.message.ms_title.trim();
    if (!inputText) {
      return; // Exit the function if input is empty
    }
    // Set recipient ID and message content
    this.message.ms_recipient_id = this.notification.ms_recipient_id;
    this.message.ms_message = inputText;
    console.log('This message is', this.message);
    this.notificationService
      .sendNotification(this.message)
      .subscribe((result: IWsResult) => {
        if (!result.success) {
          Swal.fire({
            icon: 'error',
            title: 'Errore!',
            text: 'Il messaggio non è stato inviato!',
            timer: 1500,
            timerProgressBar: true,
          });
          return alert('Il messaggio non è stato inviato!');
        }
        const newMessage: Notifications.IConversationMessage = {
          ms_title: inputText,
          ms_message: inputText,
          ms_recipient_id: this.account.account_id,
          ms_order_id: null,
          ms_datetime: new Date().toISOString(),
        };
        // Add the new message to the conversation messages
        this.notification.messages.push(newMessage);
        this.limitedNotifications.push(newMessage);
        this.message = new Notifications.Message(null);
        // Scroll down to the latest message after the view has been updated
        setTimeout(() => {
          this.scrollDown();
        }, 200);
      });
  }

  loadMore(event) {
    setTimeout(() => {
      console.log('Done');
      event.target.complete();
      this.notificationsLimit += this.updateInifiniteElem;
      this.limitedNotifications = this.notification?.messages.slice(
        -this.notificationsLimit
      );
    }, 500);
  }

  isExpanded(message: any): boolean {
    return this.selectedMessage === message;
  }
}

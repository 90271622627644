import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Orders } from 'src/app/models/Orders.model';
import { OrdersService } from 'src/app/services/orders.service';
import { PagesService } from 'src/app/services/pages.service';
import { DropdownService } from 'src/app/services/shared/dropdown.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';

@Component({
  selector: 'app-confirm-courrier',
  templateUrl: './confirm-courrier.component.html',
  styleUrls: ['./confirm-courrier.component.scss'],
})

export class ConfirmCourrierComponent implements OnInit {

  @Input() isCourOn = true;

  public order: Orders.Order;
  public orderToUpdate: Orders.OrderUpdate;

  lettra: boolean = false;
  etichetta: boolean = false;
  isLabel: boolean = false;
  canGenerate: boolean = false;
  correctAddress: boolean = false;
  id: string;

  constructor(
    private router: Router,
    private orderService: OrdersService,
    public modalService: OpenModalService,
    private pagesService: PagesService,
    private activatedRoute: ActivatedRoute,
    private dropdownService: DropdownService
  ) { }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.orderService.getOrder1(+this.id).subscribe((result) => {
      this.order = result;
      console.debug('orders: ', this.order);
      this.orderToUpdate = new Orders.OrderUpdate(this.order);
      if (this.orderToUpdate.or_carrier_id == 0)
        this.orderToUpdate.or_carrier_id = null;
      if (
        this.order.vs_details.sp_carrier_id == '0' ||
        this.order.vs_details.sp_carrier_id == null
      )
        this.order.vs_details.sp_carrier_id = null;
      if (
        this.order.or_shipping_address &&
        this.order.or_shipping_address.length > 0 &&
        this.order.or_shipping_customer &&
        this.order.or_shipping_address.length > 0 &&
        this.order.or_shipping_city &&
        this.order.or_shipping_address.length > 0 &&
        this.order.or_shipping_prov &&
        this.order.or_shipping_address.length > 0 &&
        this.order.or_shipping_zip &&
        this.order.or_shipping_address.length > 0 &&
        this.order.or_shipping_country &&
        this.order.or_shipping_address.length > 0
      )
        this.correctAddress = true;
      this.canGenerate =
        this.orderToUpdate.or_carrier_id == null ||
        this.order.vs_details.sp_carrier_id == null
          ? false
          : true;
      this.checkSelectedAll();
    });
  }

  checkSelectedAll(stepBack = false) {
    const stepFrw = 4;
    const stepBck = 3;
    if (!stepBack && (this.isLabel || !this.canGenerate))
      this.orderService.pages.next(stepFrw);
    else {
      this.order.stepCount = stepBck;
      this.orderService.pages.next(stepBck);
      this.order.buttons[stepBck].completed = false;
    }
  }

  changeCarrier() {
    this.dropdownService.getCarriers().subscribe(async (result) => {
      if (result) {
        this.orderService.getOrder1(+this.id).subscribe((result) => {});
      }
    });
  }

  close() { }

}

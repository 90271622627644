import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ByStatusCodePipe } from './by-status-code.pipe';

@NgModule({
  exports: [ByStatusCodePipe],
  declarations: [ByStatusCodePipe],
  imports: [CommonModule],
})

export class ByStatusCodeModule {

  static forRoot() {
    return {
      ngModule: ByStatusCodeModule,
      providers: [],
    };
  }
  
}

import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Orders } from 'src/app/models/Orders.model';
import { IWsResult } from 'src/app/models/ws_result.model';
import { OrdersService } from 'src/app/services/orders.service';
import { OpenModalService } from 'src/app/services/shared/open-modal.service';

@Component({
  selector: 'app-storico-ordini-edit',
  templateUrl: './storico-ordini-edit.component.html',
  styleUrls: ['./storico-ordini-edit.component.scss'],
})

export class StoricoOrdiniEditComponent implements OnInit {
  
  @Input() order_id: string = '-1';

  public order: Orders.IOrder;
  public goBack = false;
  public dataSaved = false;
  public isEditable = false;
  public opened = true;

  ordersDetailsForm!: FormGroup;
  formattedDate: string = '-';  

  constructor(
    private ordersServ: OrdersService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    public closeServ: OpenModalService
  ) { }

  ngOnInit(): void {
    this.ordersDetailsForm = this.formBuilder.group({
      or_assignment_date: [''],
      or_order_number: [''],
      or_customer: [''],
      or_agent_id: [''],
      or_shipping_address: [''],
      or_closing_date: [''],
      vs_details: [''],
      carrier_desc: [''],
      ac_first_name: [''],
      ac_last_name: [''],
      op_weigth: [''],
      boxes: [''],
      op_articles_number: [''],
      op_address: [''],
      op_city: [''],
      or_shipping_zip: [''],
      op_prov: [''],
      or_shipping_country: [''],
    });
    this.ordersServ
      .getOrder(this.order_id)
      .subscribe((orderData: IWsResult) => {
        this.order = orderData.data;
        if (this.order.or_assignment_date)
          this.formattedDate = formatDate(
            this.order.or_assignment_date,
            'dd/MM/yyyy',
            'it'
          );
        this.ordersDetailsForm.setValue({
          or_assignment_date: this.formattedDate,
          or_order_number: this.order.or_order_number,
          or_closing_date: this.order.or_closing_date,
          or_agent_id: this.order.or_agent_id,
          or_customer: this.order.or_customer,
          or_shipping_address: this.order.or_shipping_address,
          op_weigth: this.order.products[0].op_weigth,
          vs_details: this.order.vs_details.tab_order_priority_desc,
          carrier_desc: this.order.vs_details.carrier_desc,
          ac_first_name: this.order.vs_details.ac_first_name,
          ac_last_name: this.order.vs_details.ac_last_name,
          boxes: this.order.boxes,
          op_articles_number: this.order.vs_details.op_articles_number,
          op_address: this.order.or_shipping_address,
          op_city: this.order.or_shipping_city,
          or_shipping_zip: this.order.or_shipping_zip,
          op_prov: this.order.or_shipping_prov,
          or_shipping_country: this.order.or_shipping_country,
        });
      });
  }

  public close() {
    this.opened = false;
  }

  public goBackFunction() {
    this.router.navigate(['/history']);
  }

  open() {
    this.opened = true;
  }
  
}

<div class="k-form" *ngIf="opened || isPhotoOn">
  <kendo-dialog
    [formGroup]="messageDetailsForm"
    class="k-form"
    [minWidth]="250"
    [width]="550"
    [maxWidth]="750"
    style="left: 6%;"
  >
    <div 
      style="
        display: flex;
        flex-direction: row;
        justify-content: end;
        margin-top: -15px;
        z-index: 1000;
        position: fixed;
        margin-left: -35px;
      "
    >
      <button
        (click)="close()"
        class="btn btn3"
        rounded="full"
        style="
          background-color: #e90000;
          color: #ffffff;
          width: 35px !important;
          height: 35px !important;
        "
      >    
        <i
          class="bx bx-x bx-sm posRelBox2"
          style="
            color: #ffffff; 
            bottom: 10px; 
            position: relative !important;
            left: -80% !important;
            top: -1px !important;
          "
        ></i>
      </button> 
    </div>
    <h1 class="page-title">Dettagli Messaggio</h1>
    <hr class="hr-dialog position-relative" style="top: 10px !important" />
    <form class="k-form dialog-form" [formGroup]="messageDetailsForm">
      <fieldset class="k-form-fieldset">
        <div class="row det mb-2">
          <kendo-formfield class="col-md-12 setDisplay-Prep">
            <kendo-label 
              [for]="ms_title"
              class="title" 
              text="Titolo"
            ></kendo-label>
            <kendo-textbox
              formControlName="ms_title"
              kendoTextBox
              #ms_title
              [readonly]="true"
              rounded="full"
              class="setBorder-Prepp"
            ></kendo-textbox>
          </kendo-formfield>         
        </div> 
          
        <div class="row det mb-2">
          <kendo-formfield class="col-md-6 setDisplay-Prep">
            <kendo-label 
              [for]="ms_sender_name"
              class="title" 
              text="Mittente"
            ></kendo-label>
            <kendo-textbox
              formControlName="ms_sender_name"
              kendoTextBox
              #ms_sender_name
              [readonly]="true"
              rounded="full"
              class="setBorder-Prepp"
            >
            </kendo-textbox>
          </kendo-formfield>
          <kendo-formfield class="col-md-5 setDisplay-Prep">
            <kendo-label 
              [for]="ms_datetime"
              class="title" 
              text="Data e Ora"
            ></kendo-label>
            <kendo-textbox
              formControlName="ms_datetime"
              kendoTextBox
              #ms_datetime
              [readonly]="true"
              rounded="full"
              class="setBorder-Prepp"
            >
            </kendo-textbox>
          </kendo-formfield>        
        </div>     
      </fieldset>

      <div class="row det">        
        <kendo-formfield class="col-lg setDisplay-Prep">
          <kendo-label 
            [for]="ms_message"
            class=title 
            text="Descrizione"
          ></kendo-label>
          <kendo-textbox
            formControlName="ms_message"
            kendoTextBox
            #ms_message
            [readonly]="true"
            rounded="full"
            class="col-desc setBorder-Prepp"
          >
          </kendo-textbox>
        </kendo-formfield>
      </div>
    </form>

  </kendo-dialog>
</div>

<h1 class="page-title">Gestione Prodotti</h1>

<div 
  class="filterContainer k-display-flex"
  style=" 
    flex-direction: row;
    justify-content: center;
  "
>
  <form 
    class="new-search"  
    role="search"
    style="
      display:flex !important;
      flex-direction: row !important;
      justify-content: center !important;
    "
  >
    <label for="search"></label>
    <input 
    [(ngModel)]="filterTerm"
      id="search" 
      name="search"
      pattern=".*\S.*"
      type="search" 
      placeholder="Cerca prodotto..." 
      autofocus 
      required 
      (input)="this.onInputChanged($event)"
    />
    <button 
      type="submit"
      (click)="searchProducts($event)"
    >
      <i
        class="bx bx-search bx-md"
        style="
          margin: 0;
          padding-top: 10px;
          padding-left: 5px;
          transform:scale(.8)
        "
      >
      </i>    
    </button>    
  </form>
</div>

<hr class="hr-newBlue" />

<div
    *ngIf="loading"
    style="
      display: flex;
      flex-direction: column;
      align-items: center
    "
  >
    <mat-spinner></mat-spinner>
    <p>Attendi..</p>
</div>

<div class="grid-wrapper">
  <kendo-grid
    [data]="gridView"
    [skip]="skip"
    [rowHeight]="36"
    [height]="720"
    [pageSize]="pageSize"
    scrollable="virtual"
    (pageChange)="pageChange($event)"
    [navigable]="true"
  >
    <kendo-grid-column
      field="family_desc"
      title="Famiglia"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.family_desc }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="sub_family_desc"
      title="Sotto-Famiglia"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.sub_family_desc }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="group_desc"
      title="Gruppo"
      [width]="130"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.group_desc }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="sub_group_desc"
      title="Sotto-Gruppo"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.sub_group_desc }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="pr_code"
      title="Codice"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.pr_code }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="pr_description"
      title="Descrizione"
      [width]="190"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.pr_description }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="pr_stock"
      title="Quantità"
      [width]="100"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.pr_stock }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-command-column
      title="Dettagli"
      [width]="100"
      [headerStyle]="{ 'justify-content': 'center' }"
      [style]="{ 'tetx-align': 'center' }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <button
          kendoGridEditCommand
          rounded="full"
          class="btn-det"
          style="
            margin: 0;
            padding: 0;
            background-color: #017efa !important
          "
          (click)="showDetails(dataItem.pr_code, dataItem.pr_description)"
        >
          <div class="setDisplayToFlexBlue btn-det">
            <i
              class="bx bx-info-circle bx-md"
              style="
                margin: 0;
                padding: 0"
              >
            </i>
            <p class="parButton2"></p>
          </div>
        </button>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
</div>
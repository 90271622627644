<div class="k-overlay dark-background" *ngIf="opened"></div>

<kendo-dialog
  title=""
  *ngIf="opened"
  (close)="goBackFunction()"
  [minWidth]="650"
  [width]="1250"
  [maxHeight]="1000"
  [height]="750"
  [minHeight]="700"
  class="k-form"
  [formGroup]="orderDetailsForm"
>
  <h1 class="page-title">Dettagli Ordine</h1>
  <hr class="hr-dialog" />
  <div class="container" style="display: flex">
    <div class="firstContainer" style="width: 100%">
      <form class="k-form dialog-form" [formGroup]="orderDetailsForm">
        <div class="row">
          <div class="col">

            <div class="row">
              <kendo-formfield class="col-md-6">
                <kendo-label
                  [for]="or_order_number"
                  text="N° Ordine"
                ></kendo-label>
                <kendo-textbox
                  formControlName="or_order_number"
                  kendoTextBox
                  #or_order_number
                  [readonly]="!isEditable"
                  rounded="full"
                ></kendo-textbox>
              </kendo-formfield>

              <kendo-formfield class="col-md-6">
                <kendo-label
                  [for]="or_order_date"
                  text="Data Ordine">
                </kendo-label>
                <kendo-textbox
                  formControlName="or_order_date"
                  kendoTextBox
                  #or_order_date
                  [readonly]="!isEditable"
                  rounded="full"
                ></kendo-textbox>
              </kendo-formfield>
            </div>

            <div class="row">
              <kendo-formfield class="col-md-6">
                <kendo-label
                  [for]="or_customer"
                  text="Cliente"
                ></kendo-label>
                <kendo-textbox
                  formControlName="or_customer"
                  kendoTextBox
                  #or_customer
                  [readonly]="!isEditable"
                  rounded="full"
                ></kendo-textbox>
              </kendo-formfield>

              <kendo-formfield class="col-md-6">
                <kendo-label
                  [for]="or_note"
                  text="Riferimento"
                ></kendo-label>
                <kendo-textbox
                  formControlName="or_note"
                  kendoTextBox
                  #or_note
                  [readonly]="!isEditable"
                  rounded="full"
                ></kendo-textbox>
              </kendo-formfield>
            </div>

            <div class="row">
              <div class="col-md-4">
                <kendo-label class="order-text" text="Indirizzo"></kendo-label>
                <kendo-textbox
                  #or_shipping_address
                  rounded="full"
                  [placeholder]="order.or_shipping_address"
                  class="order-column"
                >
                </kendo-textbox>
              </div>

              <div class="col-md-2">
                <kendo-label class="order-text" text="Provincia"></kendo-label>
                <kendo-textbox
                  #or_shipping_prov
                  rounded="full"
                  [placeholder]="order.or_shipping_prov"
                  class="order-column"
                >
                </kendo-textbox>
              </div>

              <div class="col-md-2">
                <kendo-label class="order-text" text="CAP"></kendo-label>
                <kendo-textbox
                  #or_shipping_zip
                  rounded="full"
                  [placeholder]="
                    order.or_shipping_zip ? order.or_shipping_zip : 'cap'
                  "
                  class="order-column"
                >
                </kendo-textbox>
              </div>

              <div class="col-md-3">
                <kendo-label class="order-text" text="Città"></kendo-label>
                <kendo-textbox
                  #or_shipping_city
                  rounded="full"
                  [placeholder]="order.or_shipping_city"
                  class="order-column"
                  placeholder="Città"
                >
                </kendo-textbox>
              </div>

              <div class="col-md-1">
                <kendo-label class="order-text" text="Nazione"></kendo-label>
                <kendo-textbox
                  #or_shipping_country
                  rounded="full"
                  [placeholder]="order.or_shipping_country"
                  class="order-column"
                >
                </kendo-textbox>
              </div>
            </div>

            <div class="row">
              <kendo-formfield class="col-md-6">
                <kendo-label [for]="operator" text="Operatore"></kendo-label>
                <kendo-textbox
                  formControlName="operator"
                  kendoTextBox
                  #operator
                  [readonly]="!isEditable"
                  rounded="full"
                ></kendo-textbox>
              </kendo-formfield>

              <kendo-formfield class="col-md-6">
                <kendo-label [for]="status" text="Stato"></kendo-label>
                <kendo-textbox
                  formControlName="status"
                  kendoTextBox
                  #status
                  [readonly]="!isEditable"
                  rounded="full"
                ></kendo-textbox>
              </kendo-formfield>
            </div>

          </div>
        </div>

        <div
          class="panelbar-wrapper"
          style="position: relative; top: 20px"
          *ngIf="
            order &&
            order.status[0].os_status_order_cod != 10 &&
            order.status[0].os_status_order_cod > 1
          "
        >
          <kendo-panelbar
            *ngIf="dropProducts"
            [animate]="true"
            style="
              text-align: center;
              border: #fff;
            "
          >
            <kendo-panelbar-item
              [title]="'Prodotti'"
              style="border-radius: 45px"
            >
              <ng-template kendoPanelBarContent>
                <div style="max-height: 150px; overflow-y: auto">
                  <div
                    *ngFor="let product of dropProducts; let i = index"
                    class="row"
                    style="
                      padding: 5px;
                      border: 1px solid #999999;
                      display: flex;
                    "
                  >
                    <div class="row">
                      <div class="col-1">
                        <i
                      class="bx bx-check-circle bx-md"
                      *ngIf="dropProducts[i].op_checked !== true"
                      style="cursor: pointer; margin-right: 20px"
                    ></i>
                    <i
                      class="bx bxs-check-circle bx-md"
                      *ngIf="dropProducts[i].op_checked === true"
                      style="
                        cursor: pointer; 
                        color: green; 
                        margin-right: 20px
                      "
                    ></i>

                      </div>
                      <div class="col-6" style="text-align:left">
                        <span
                        style="
                          font-family: 'Lato';
                          font-style: normal;
                          font-weight: 400;
                          font-size: 16px;
                          line-height: 19px;
                          color: #999999;
                        "
                        >{{
                          product.op_description
                        }}</span>

                      </div>
                      <div class="col-2">
                        <span
                        style="
                          font-family: 'Lato';
                          font-style: normal;
                          font-weight: 400;
                          font-size: 16px;
                          line-height: 19px;
                          color: #999999;
                        "
                        >{{
                          product.op_qta + " pz"
                        }}</span>
                      </div>
                      <div class="col-2" style="text-align:right">
                        <button
                          kendoButton
                          themeColor="secondary"
                          rounded="full"
                          class="btn btn-primary"
                          style="cursor: pointer; margin-left: 20px"
                          (click)="selectProduct(product, i)"
                        >
                          <i
                            class="bx bx-trash bx-sm posRelBox2"
                            style="
                              color: #ff0000;
                              right: 6px;
                              bottom: 10px;
                              position: relative;
                            "
                          ></i>
                          Elimina prodotto
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </kendo-panelbar-item>
          </kendo-panelbar>
        </div>

        <br /><br />
        <div 
          class="text-buttons" 
          style="
            position: relative; 
            top: 20px
          "
          >
          <div class="button-right">
            <button
              class="btn btn3"
              rounded="full"
              style="
                background-color: #8d8d8d;
                color: #ffffff;
                justify-content: center !important;
                display: flex;
                width: 192px !important;
                height: 42px !important;
              "
              (click)="goBackFunction()"
            >
              <i
                class="bx bx-left-arrow-circle bx-sm posRelBox2"
                style="
                  color: #ffffff;
                  right: 6px;
                  bottom: 10px;
                  position: relative;
                "
              ></i>
              <p class="posRelBox2">INDIETRO</p>
            </button>
          </div>

          <div class="button-right">
            <button
              class="btn btn3"
              rounded="full"
              style="
                background-color: #b10000;
                color: #ffffff;
                justify-content: center !important;
                display: flex;
                width: 252px !important;
                height: 42px !important;
              "
              (click)="goToProducts()"
            >
              <i class="bx bx-edit-alt bx-sm posRelBox2"
                style="
                  color: #ffffff;
                  right: 6px;
                  bottom: 10px;
                  position: relative;
                "
              >
              </i>
              <p class="posRelBox2">MODIFICA PRODOTTI</p>
            </button>
          </div>

          <button
            class="btn btn3 save"
            style="
              color: #ffffff;
              justify-content: center !important;
              display: flex;
              width: 192px !important;
              height: 42px !important;
            "
            *ngIf="isEditable === true"
            (click)="save(orderDetailsForm)"
          >
            <i
              class="bx bx-check-circle bx-sm posRelBox2"
              style="
                color: #ffffff;
                right: 6px;
                bottom: 10px;
                position: relative;
              "
            ></i>
            <p class="posRelBox2">SALVA</p>
          </button>
        </div>
      </form>
    </div>
    <div
      class="secondContainer"
      style="width: 100%"
      *ngIf="openedSearch === true"
    >
      <div class="flex-items">
        <form action="" class="search-bar">
          <input
            type="text"
            [(ngModel)]="filterTerm"
            name="search"
            pattern=".*\S.*"
            placeholder="cerca ordine.."
            required
          />
          <button
            class="search-btn"
            type="submit"
            (click)="getSimilarProducts()"
          >
            <span>Cerca</span>
          </button>
        </form>
      </div>

      <div
        class="row"
        style="
          margin-top: 17px;
          display: flex;
          flex-direction: column;
          max-height: 535px;
          overflow-y: auto;
          flex-wrap: nowrap;
        "
        *ngIf="productToAdd"
      >
        <div
          class="col"
          *ngFor="let product of productToAdd; let i = index"
          style="padding-top: 5px; padding-bottom: 5px"
        >
          <div class="containerProducts">
            <i
              class="bx bx-check-circle bx-md"
              *ngIf="productToAdd[i].op_checked !== true"
              style="cursor: pointer; margin-right: 20px"
            ></i>
            <i
              class="bx bxs-check-circle bx-md"
              *ngIf="productToAdd[i].op_checked === true"
              style="
                cursor: pointer; 
                color: green; 
                margin-right: 20px
              "
            ></i>
            <span
              class="product-description"
              (click)="selectProductToAdd(i)"
              title="{{ product.op_description }}"
            >
              {{ product.op_description | slice : 0 : 35
              }}{{ product.op_description.length > 35 ? "..." : "" }}
            </span>
            <button
              class="btn btn-primary"
              kendoButton
              themeColor="primary"
              rounded="full"
              style="margin-left: 20px"
              (click)="addProduct(productToAdd[i], i)"
              *ngIf="productToAdd[i].op_checked === true"
            >
              CAMBIA PRODOTTO
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="secondContainer"
      style="width: 100%"
      *ngIf="openedSearch2 === true"
    >
      <div class="flex-items">
        <form action="" class="search-bar">
          <input
            type="text"
            [(ngModel)]="filterTerm"
            name="search"
            pattern=".*\S.*"
            placeholder="Cerca ordine.."
            required
          />
          <button
            class="search-btn"
            type="submit"
            (click)="getSimilarProductsSolo()"
          >
            <span>Cerca</span>
          </button>
        </form>
      </div>

      <div class="flex-items">
        <form action="" class="search-bar">
          <input
            type="text"
            [(ngModel)]="filterTerm"
            name="search"
            pattern=".*\S.*"
            placeholder="Cerca ordine.."
            required
          />
          <button class="search-btn" type="submit">
            <span>Cerca</span>
          </button>
        </form>
      </div>
      <div
        class="row"
        style="
          margin-top: 17px;
          display: flex;
          flex-direction: column;
          max-height: 535px;
          overflow-y: auto;
          flex-wrap: nowrap;
        "
        *ngIf="productsToAddSolo"
      >
        <div
          class="col"
          *ngFor="let product of productsToAddSolo; let i = index"
          style="padding-top: 5px; padding-bottom: 5px"
        >
          <div class="containerProducts">
            <i
              class="bx bx-check-circle bx-md"
              *ngIf="productsToAddSolo[i].op_checked !== true"
              style="cursor: pointer; margin-right: 20px"
            ></i>
            <i
              class="bx bxs-check-circle bx-md"
              *ngIf="productsToAddSolo[i].op_checked === true"
              style="
                cursor: pointer; 
                color: green; 
                margin-right: 20px
              "
            ></i>
            <span
              class="product-description"
              (click)="selectProductToAddSolo(i)"
              title="{{ product.op_description }}"
            >
              {{ product.op_description | slice : 0 : 35
              }}{{ product.op_description.length > 35 ? "..." : "" }}
            </span>
            <button
              class="btn btn-primary"
              kendoButton
              themeColor="primary"
              rounded="full"
              style="margin-left: 20px"
              (click)="addProductSoloConfirm(productsToAddSolo[i], i)"
              *ngIf="productsToAddSolo[i].op_checked === true"
            >
              CAMBIA PRODOTTO
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</kendo-dialog>

<app-add-box
  (dataInfo)="addBox($event)"
  *ngIf="closeServ.isBoxOpn"
  [openBox]="closeServ.isBoxOpn"
  [ts_box_type_id]="clickedBox"
>
</app-add-box>

<app-box-details
  (dataInfo)="addBox($event)"
  *ngIf="closeServ.isBoxDetOn"
  [ts_box_type_id]="detailsBox"
  [box_id]="closeServ.isBoxDetOn"
></app-box-details>

<app-product-details-list
  [products]="dropProducts"
  *ngIf="closeServ.isOpenProdList"
  [isList]="closeServ.isOpenProdList"
></app-product-details-list>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ByCustomerNamePipe } from './by-customer-name.pipe';

@NgModule({
  exports: [ByCustomerNamePipe],
  declarations: [ByCustomerNamePipe],
  imports: [CommonModule],
})

export class ByCustomerNameModule {

  static forRoot() {
    return {
      ngModule: ByCustomerNameModule,
      providers: [],
    };
  }
  
}

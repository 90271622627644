<div class="k-overlay dark-background" *ngIf="opened && existAgent"></div>

<kendo-dialog
  title=""
  *ngIf="opened && existAgent"
  (close)="close()"
  [minWidth]="250"
  [width]="950"
  [minHeight]="460"
  [maxWidth]="1150"
  class="k-form"
  [formGroup]="agentDetailsForm"
  style="left: 6%"
>
  <div
    style="
      display: flex;
      flex-direction: row;
      justify-content: end;
      margin-top: -15px;
      z-index: 1000;
      position: fixed;
      margin-left: -35px;
    "
  >
    <button
      (click)="close()"
      class="btn btn3"
      rounded="full"
      style="
        background-color: #e90000;
        color: #ffffff;
        width: 35px !important;
        height: 35px !important;
      "
    >
      <i
        class="bx bx-x bx-sm posRelBox2"
        style="
          color: #ffffff;
          bottom: 10px;
          position: relative !important;
          left: -80% !important;
          top: -1px !important;
        "
      ></i>
    </button>
  </div>
  <h1 class="page-title">
    {{ !isEditable ? "Dettagli Agente" : "Modifica Agente" }}
  </h1>
  <hr class="hr-dialog" style="margin-top: 20px !important" />
  <form class="k-form dialog-form" [formGroup]="agentDetailsForm">
    <div class="row det mb-2">
      <!-- <kendo-formfield class="col"> -->
      <!-- <kendo-label [for]="ag_url" text="URL"></kendo-label> -->
      <!-- <input
          formControlName="ag_url"
          kendoTextBox
          #ag_url
          [readOnly]="!isEditable"
          style="border-radius: 45px"
        /> -->
      <div *ngIf="existAgent.ag_url != null || undefined || ''">
        <!-- <span
          style="
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            font-family: 'Verdana';
          "
          >URL</span
        >
        <a

          (click)="goToLink(existAgent)"
        >
          Remove :4200 in production
        </a -->
        <!-- > -->

        <a class="elipText link" href="{{ link }}" target="“_blank”"
          >{{ link }}
        </a>
      </div>
      <!-- </kendo-formfield> -->
    </div>

    <div class="row det mb-2">
      <kendo-formfield class="col-md-5 setDisplay-Prep">
        <kendo-label
          [for]="ag_first_name"
          class="title"
          text="Nome"
        ></kendo-label>
        <input
          formControlName="ag_first_name"
          kendoTextBox
          #ag_first_name
          [readOnly]="!isEditable"
          class="setBorder-Prepp"
        />
        <kendo-formerror
          *ngIf="
            (agentDetailsForm.get('ag_first_name')?.touched ||
              agentDetailsForm.get('ag_first_name')?.dirty) &&
            agentDetailsForm.get('ag_first_name')?.errors
          "
        >
          Campo obbligatorio!
        </kendo-formerror>
      </kendo-formfield>
      <kendo-formfield class="col-md-5 setDisplay-Prep">
        <kendo-label
          [for]="ag_last_name"
          class="title"
          text="Cognome"
        ></kendo-label>
        <input
          formControlName="ag_last_name"
          kendoTextBox
          #ag_last_name
          [readOnly]="!isEditable"
          class="setBorder-Prepp"
        />
        <kendo-formerror
          *ngIf="
            (agentDetailsForm.get('ag_last_name')?.touched ||
              agentDetailsForm.get('ag_last_name')?.dirty) &&
            agentDetailsForm.get('ag_last_name')?.errors
          "
        >
          Campo obbligatorio!
        </kendo-formerror>
      </kendo-formfield>
    </div>

    <div class="row det">
      <kendo-formfield class="col-md-4 setDisplay-Prep">
        <kendo-label [for]="ag_email" class="title" text="Email"></kendo-label>
        <input
          formControlName="ag_email"
          kendoTextBox
          #ag_email
          [readOnly]="!isEditable"
          class="setBorder-Prepp"
        />
        <kendo-formerror
          *ngIf="
            (agentDetailsForm.get('ag_email')?.touched ||
              agentDetailsForm.get('ag_email')?.dirty) &&
            agentDetailsForm.get('ag_email')?.errors
          "
        >
          Email non valida!</kendo-formerror
        >
      </kendo-formfield>
      <kendo-formfield class="col-md-3 setDisplay-Prep">
        <kendo-label
          [for]="ag_mobile"
          class="title"
          text="Mobile"
        ></kendo-label>
        <input
          formControlName="ag_mobile"
          kendoTextBox
          #ag_mobile
          [readOnly]="!isEditable"
          class="setBorder-Prepp"
        />
      </kendo-formfield>
      <kendo-formfield class="col-md-3 setDisplay-Prep">
        <kendo-label
          [for]="ag_phone"
          class="title"
          text="Telefono"
        ></kendo-label>
        <input
          formControlName="ag_phone"
          kendoTextBox
          #ag_phone
          [readOnly]="!isEditable"
          class="setBorder-Prepp"
        />
      </kendo-formfield>
    </div>

    <div class="text-buttons" style="justify-content: space-around !important">
      <!--<div class="button-right">
        <button
          class="btn btn3"
          rounded="full"
          style="
            background-color: #8d8d8d;
            color: #ffffff;
            justify-content: center !important;
            display: flex;
            width: 192px !important;
            height: 42px !important;
          "
          (click)="close()"
        >
          <i
            class="bx bx-left-arrow-circle bx-sm posRelBox2"
            style="
              color: #ffffff;
              right: 6px;
              bottom: 10px;
              position: relative
            "
          ></i>
          <p class="posRelBox2">INDIETRO</p>
        </button>
      </div>-->
      <div class="button-left">
        <button
          (click)="modify()"
          class="btn btn3 edit"
          rounded="full"
          style="
            color: #ffffff;
            justify-content: center !important;
            display: flex;
            width: 192px !important;
            height: 42px !important;
          "
          *ngIf="!isEditable"
        >
          <i
            class="bx bx-edit-alt bx-sm posRelBox2"
            style="color: #ffffff; right: 6px; bottom: 10px; position: relative"
          ></i>
          <p class="posRelBox2">MODIFICA</p>
        </button>

        <button
          (click)="updateAgent(agentDetailsForm)"
          class="btn btn3 save"
          rounded="full"
          style="
            color: #ffffff;
            justify-content: center !important;
            display: flex;
            width: 192px !important;
            height: 42px !important;
          "
          *ngIf="isEditable"
        >
          <i
            class="bx bx-check-circle bx-sm posRelBox2"
            style="color: #ffffff; right: 6px; bottom: 10px; position: relative"
          ></i>
          <p class="posRelBox2">SALVA</p>
        </button>
      </div>
    </div>
  </form>
</kendo-dialog>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from '../shared/error-handler.service';
import { Printing } from 'src/app/models/printer';
import { IWsResult } from 'src/app/models/ws_result.model';

const PRINTERSETTINGS_KEY = 'DiakonixPrintersSettings';

@Injectable({
  providedIn: 'root'
})

export class PrinterService {
  
  url: string = environment.urlServer;
  printers:BehaviorSubject<Printing.PrinterOption[]> = new BehaviorSubject<Printing.PrinterOption[]>([]);
  printerSelection:BehaviorSubject<Printing.IPrinterOptionSelection> = new BehaviorSubject<Printing.PrinterOptionSelection>(null);

  constructor(
    private httpClient: HttpClient,
    private errorHandlerServ: ErrorHandlerService,
    ) { }

  getPrinters(): Observable<Printing.PrinterOption[]>{
    return this.httpClient
    .get<IWsResult>(`${this.url}/get_printers/`)
    .pipe(
      catchError(
        this.errorHandlerServ.handleError<IWsResult>('getPrinters')
      )
    )
    .pipe(map((result:IWsResult) => {
      if(!result.success)return null;
        return result.data.map(
          item => {
            let temp = new Printing.PrinterOption(item)
            return temp;
          }
        )
      }
    ));
  }

  async updateSelections(
    printer:Printing.IPrinterOptionSelection,
    printers:Printing.PrinterOption[]
  ): Promise<Printing.PrinterOption[]> {
      printer.printer_bor_id = printers.some(item => item.printer_id == printer.printer_bor_id)?printer.printer_bor_id:0;
      printer.printer_ddt_id = printers.some(item => item.printer_id == printer.printer_ddt_id)?printer.printer_ddt_id:0;
      printer.printer_box_id = printers.some(item => item.printer_id == printer.printer_box_id)?printer.printer_box_id:0;
      printer.printer_car_id = printers.some(item => item.printer_id == printer.printer_car_id)?printer.printer_car_id:0;
      printers = printers.map((item)=>{
        if(printer.printer_bor_id === 0 && item.printer_bor)
          printer.printer_bor_id = item.printer_id;
        if(printer.printer_ddt_id === 0 && item.printer_ddt)
          printer.printer_ddt_id = item.printer_id;
        if(printer.printer_box_id === 0 && item.printer_box)
          printer.printer_box_id = item.printer_id;
        if(printer.printer_car_id === 0 && item.printer_car)
          printer.printer_car_id = item.printer_id;
        item.printer_bor = item.printer_id === printer.printer_bor_id;
        item.printer_box = item.printer_id === printer.printer_box_id;
        item.printer_car = item.printer_id === printer.printer_car_id;
        item.printer_ddt = item.printer_id === printer.printer_ddt_id;
        return item;
      })
      this.printerSelection.next(printer);
      await this.setPrintersSettings(printer);
      return printers;
  }

  getPrintersSettings() {
    return JSON.parse(localStorage.getItem(PRINTERSETTINGS_KEY));
  }

  async setPrintersSettings(printers: Printing.PrinterOptionSelection) {
    return localStorage.setItem(PRINTERSETTINGS_KEY, JSON.stringify(printers))
  }

  public getPrinterSettings() {
    this.getPrinters()
    .subscribe(async result => {
      if(!result)
        return;
      this.printers.next(result);
      let selectedPrinter = await this.getPrintersSettings();
      if(selectedPrinter) {
        this.printerSelection.next(selectedPrinter);
        let printers = await this.updateSelections(this.printerSelection.value, this.printers.value);
        this.printers.next(printers);
      }
      else {
        let printer = new Printing.PrinterOptionSelection(null);
        printer.getPrintersSelections(this.printers.value);
        this.printerSelection.next(printer);
        await this.setPrintersSettings(printer);
      }
    })
  }

}

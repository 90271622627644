import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { Orders } from 'src/app/models/Orders.model';
import { IWsResult } from 'src/app/models/ws_result.model';
import { OrdersService } from 'src/app/services/orders.service';
import { IconsService } from '../../../services/icons.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-base-orders-list',
  templateUrl: './base-orders-list.component.html',
  styleUrls: ['./base-orders-list.component.scss'],
})

export class BaseOrdersListComponent implements OnInit {

  @Input() partialOrders: Orders.OrderUpdate[];
  
  public orderz: Orders.Order;
  public gridData: Orders.OrderUpdate[] = [];
  public newOrders: Orders.OrderUpdate[] = [];  
  public order: Orders.IOrder = null;
  public loading: boolean = false;
  public tab_values: any;
  public value = 0;
  public indeterminate = false;
  public min = -10;
  public max = 10;
  public gridView: GridDataResult;
  public pageSize = 20;
  public skip = 0;
  public data: unknown[];
  
  gridDataInifinity: Orders.OrderUpdate[] = [];
  filteredOrders: Orders.OrderUpdate[] = [];
  searchText: string = '';
  filterTerm: string | undefined;
  sameIndex: boolean = true;
  id: number;
  timer = null;
  refreshInterval: number = 60000;
  startDate: Date = new Date(2021, 0, 1);
  endDate: Date = new Date();
  dateBy: any[] = [
    {
      text: 'Data ordine',
      value: 'or_order_number',
    },
  ];  

  filters = {
    dateOrginizedBy: {
      text: 'Data ordine',
      value: 'or_order_number'
    },
    startingDate: this.startDate,
    endDate: this.endDate,
    minDate: new Date(),
    maxDate: new Date(),
  };  

  constructor(
      private ordersServ: OrdersService,
      private router: Router,
      private iconService: IconsService
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.getPartialOrders();
  }

  loadMore() {
    this.gridDataInifinity = [...this.gridDataInifinity, ... this.gridData.slice(this.skip, this.skip+this.pageSize)]
    this.skip += this.pageSize
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadProducts();
  }

  private loadProducts(): void {
    this.gridView = {
      data: this.gridData.slice(this.skip, this.skip + this.pageSize),
      total: this.gridData.length,
    };
  }

  searchOrders() {
    if (!this.filterTerm || this.filterTerm == '') {
      this.getPartialOrders();
    }
    this.ordersServ
      .searchNewOrders(this.filterTerm)
      .subscribe((newOrdersData: IWsResult) => {
        if (newOrdersData.error_message.msg_code === 8) 
          this.gridData = [];
        else 
          this.gridData = newOrdersData.data;
        this.skip = 0;
        this.gridDataInifinity = [];
        this.loadMore();
      });
  }

  onInputChanged(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.validity && inputElement.validity.valueMissing) {
      this.getPartialOrders();
    }
  }  

  getPartialOrders() {
    this.loading = false;
    this.ordersServ.getOpenOrders().subscribe(
      (openOrdersData) => {
        if (this.order.or_partial === 'S') {
          this.tab_values = this.order.or_partial
        }
        this.gridData = this.tab_values;
        this.skip = 0;
        this.gridDataInifinity = [];
        this.loadMore();
        this.loading = true;
      }
    );
  }

  gotoDetails(order_id: number) {
    this.router.navigate(['/assign-order', order_id]);
  }

  block(order) {
    this.iconService.changeIconToBlocked(order);
    console.log('BLOCKED!', order);
    this.ordersServ
      .lockNewOrder(order.or_gamma_id)
      .subscribe((result: IWsResult) => {
        if (!result.success) 
          return;
        this.reloadFunction();
        Swal.fire({
          icon: 'success',
          title: 'Bloccato!',
          text: 'Ordine bloccato con successo!!!',
          timer: 1500,
          timerProgressBar: true,
        });
        console.log('BLOCKED!');
      });
  }

  async unblock(order) {
    console.log('BLOCKED!', order);
    this.iconService.changeIconToWorking(order);
    this.ordersServ
    .unlockNewOrder(order.or_gamma_id)
    .subscribe((result: IWsResult) => {
      if (!result.success) 
        return;
      this.reloadFunction();
      Swal.fire({
        icon: 'success',
        title: 'Sbloccato!',
        text: 'Ordine sbloccato con successo!!!',
        timer: 1500,
        timerProgressBar: true,
      });
      console.debug('This order:', order);
      console.log('UNBLOCKED!');
    });
  }

  reloadFunction() {
    this.getPartialOrders();
  }

}
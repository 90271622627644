import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ByPriorityCodePipe } from './by-priority-code.pipe';

@NgModule({
  exports: [ByPriorityCodePipe],
  declarations: [ByPriorityCodePipe],
  imports: [CommonModule],
})

export class ByPriorityCodeModule {

  static forRoot() {
    return {
      ngModule: ByPriorityCodeModule,
      providers: [],
    };
  }
  
}

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '@progress/kendo-angular-notification';
import { Observable, catchError, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IWsResult } from '../models/ws_result.model';
import { AuthService } from '../services/auth.service';

@Injectable()

export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private injector: Injector,
    private router: Router,
    private authServ: AuthService,
    private notificationService: NotificationService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const currentUser = this.authServ.currentUserValue;
    const isLoggedIn = currentUser && currentUser?.ac_token;
    const isApiUrl = request.url.startsWith(environment.urlServer);
    const shouldNotSend = request.headers.has('token');
    if (isLoggedIn && isApiUrl && !shouldNotSend) {
      request = request.clone({
        setHeaders: {
          token: `${currentUser.ac_token}`,
          'Content-Type': 'application/json',
        },
      });
    } 
    else {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
        },
      });
    }
    console.debug('Request is: ', request);
    return next.handle(request).pipe(
      tap((res) => {
        if (res instanceof HttpResponse) {
          if (
            this.instanceOfIWsResult(res.body) &&
            res.body.error_message?.msg_code == 0
          ) { } 
          else if (
            this.instanceOfIWsResult(res.body) &&
            res.body.error_message?.msg_code == 7
          ) {
            this.showNotification(
              'Token scaduto, effettua nuovamente il login!',
              'warning'
            );
            console.log('Error message: ', res.body.error_message),
              this.authServ.logout();
          }
        }
      }),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.error == 'error 0') { }
        }
        return of(err);
      })
    );
  }

  instanceOfIWsResult(object: any): object is IWsResult {
    return 'data' in object;
  }

  public showNotification(
    messageText: string,
    styleType: 'none' | 'success' | 'warning' | 'error' | 'info'
  ): void {
    this.notificationService.show({
      content: messageText,
      hideAfter: 600,
      position: { horizontal: 'center', vertical: 'top' },
      animation: { type: 'fade', duration: 2000 },
      type: { style: styleType, icon: true },
    });
  }
  
}
